<template>
	<Dialog title="Změna hesla" style="width: 700px">

		<Loading v-if="id && !dataLoaded" />

		<form
			class="form form--size20"
			novalidate
			@submit.prevent="submit"
		>

			<Message type="info" class="mb-3">
				Můžete použít náhodně vygenerované heslo nebo si zadat heslo vlastní.
			</Message>

			<div class="form__field">
				<label for="email" class="form__label">E-mail / login</label>
				<input
					class="form__input"
					type="text"
					name="email"
					id="email"
					disabled
					v-model="email"
				>
			</div>

			<div class="form__field">
				<label for="jmeno" class="form__label">Jméno</label>
				<input
					class="form__input"
					type="text"
					name="jmeno"
					id="jmeno"
					disabled
					v-model="jmeno"
				>
			</div>

			<div class="form__field form__field--required">
				<label for="noveheslo" class="form__label">Nové heslo</label>
				<input
					class="form__input"
					type="text"
					name="noveheslo"
					id="noveheslo"
					max-length="50"
					v-model="noveheslo"
				>
			</div>

			<Message class="mt-3"	type="error" v-if="error"	>
				{{ error }}
			</Message>

			<Message class="mt-3"	type="info" v-if="info"	>
				{{ info }}
			</Message>

			<div class="form__buttons form__buttons--space-between">
				<div class="form__buttons-left">
					<button
						type="submit"
						class="button"
						@click.prevent="submit"
					>
						<font-awesome-icon icon="lock" class="button__icon" />
						Změnit heslo
					</button>
					<button
						class="button button--outline"
						@click.prevent="cancel"
					>
						Storno
					</button>
				</div>
				<div class="form__buttons-right">
					<button
						class="button button--outline button--success"
						@click.prevent="generatePassword"
					>
						<font-awesome-icon icon="recycle" class="button__icon" />
						Vygenerovat náhodné heslo
					</button>
				</div>
			</div>

		</form>
	</Dialog>
</template>

<script>
// import bcrypt from 'bcrypt'
import bcrypt from 'bcryptjs'
import UzivateleService from '@/services/UzivateleService'

import Dialog from '@/components/Common/Dialog'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
	name: 'UzivatelZmenaHesla',

	props: {
		id: {
			type: Number,
			default: null,
		},
		onCancel: {
			type: Function,
			default: null
		}
	},

	components: {
		Dialog,
		Message,
		Loading,
	},

	data() {
		return {
			email: '',
			jmeno: '',
			noveheslo: '',

			dataLoaded: false,
			error: '',
			info: '',
		}
	},

	methods: {
		generatePassword() {
			const chars = [
				'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
				'0123456789',
				'!@#$%^&*()_+-=',
			]
			const pwdLength = 12
			let pwd = ''
			let rnd, type

			for (let i = 0; i < pwdLength; i++) {
				rnd = Math.random() * 100
				if (rnd < 70) { type = 0 }
				else if (rnd < 90) { type = 1 }
				else { type = 2 }
				const char = chars[type][Math.floor(Math.random() *chars[type].length)]
				pwd += char
			}
			this.noveheslo = pwd
		},

		submit() {
			this.error = ''
			this.info = ''

			if (this.noveheslo.length < 8) {
				this.error = 'Heslo musí mít alespoň 8 znaků'
				return
			}

			// vytvorime hash hesla
			const rounds = 10
			bcrypt.hash(this.noveheslo, rounds, (err, hash) => {
				if (err) {
					this.error = err
					return
				}

				// mame hash hesla, ulozime do API
				UzivateleService
				.updateHeslo(this.id, {
					heslo: hash
				})
				.then(response => {
					if (response.data.success) {
						this.info = 'Heslo bylo úspěšně změněno.'
					} else {
						this.error = response.data.error
					}
				})
				.catch(error => {
					this.error = error
				})
			})
		},

		cancel() {
			if (this.onCancel) this.onCancel()
		},

		checkForm() {
			this.error = '';

			// let errRequired = false
			// if (!(this.email && this.jmeno && this.prijmeni && this.role)) errRequired = true
			// if (this.role == 3 && !this.kraj) errRequired = true
			// if (this.role == 4 && !this.datumExpirace) errRequired = true
			// if (errRequired) {
			// 	this.error = 'Vyplňte všechny poviné údaje'
			// 	return false
			// }

			// if (!(this.email.includes('@') && this.email.includes('.'))) {
			// 	this.error = 'Neplatná emailová adresa'
			// 	return false
			// }

			return true
		}
	},

	created() {
		if(this.id) {
			UzivateleService.getUzivatel(this.id)
				.then(response => {
					if (response.data.success) {
						this.email = response.data.data.email
						this.jmeno = response.data.data.jmeno + ' ' + response.data.data.prijmeni
						this.dataLoaded = true
					} else {
						this.error = response.data.error
					}
				})
				.catch(error => {
					this.error = error.response.data.error || 'Chyba při načítání uživatele'
				})
		} else {
			this.error = 'Nepředáno ID uživatele'
		}


	}

}
</script>
