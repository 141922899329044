<template>

	<SectionHeader :title="`Přehled o finančních prostředcích získaných na realizaci projektu v roce ${projekt.dotacni_rok} - Rozpočet`" />

	<table class="table table--small-vertical-padding">
		<thead>
			<tr>
				<th class="w30">Nákladová položka</th>
				<th class="w10">Celkové náklady projektu</th>
				<th class="w10">Konečná podoba rozpočtu dotace</th>
				<th class="w10">Skutečně čerpáno z&nbsp;dotace ÚV ČR</th>
				<th>Specifikace položek k&nbsp;rozpočtu dotace ÚV ČR</th>
			</tr>
		</thead>
		<tbody>
			<tr
				v-for="row in schema"
				:key="row.name"
				:class="row.color !==0 ? `table__sum-row-level${row.color}`: null"
			>
				<td :class="`level${row.level}`">{{ row.label }}</td>

				<td class="alr">
					<BaseInput
						v-if="!disabled && !row.sum"
						v-model="data[`np${row.name}`]"
						:name="`np${row.name}`"
						type="text"
						align="right"
						small
						class="w100"
						:error="isErrorInput(data[`np${row.name}`])"
						@keyup="updateField('np', row.name)"
					/>
					<template v-else>
						{{ formatNumberCZ( data[`np${row.name}`] ) }}
					</template>
				</td>

				<td class="alr">
					{{ formatNumberCZ( data[`kr${row.name}`] ) }}
				</td>

				<td class="alr">
					<BaseInput
						v-if="!disabled && !row.sum"
						v-model="data[`sc${row.name}`]"
						:name="`sc${row.name}`"
						type="text"
						align="right"
						small
						class="w100"
						:error="isErrorInput(data[`sc${row.name}`])"
						@keyup="updateField('sc', row.name)"
					/>
					<template v-else>
						{{ formatNumberCZ( data[`sc${row.name}`] ) }}
					</template>
				</td>

				<td class="small">{{ data[`kr${row.name}_upresneni`] }}</td>

			</tr>
		</tbody>
	</table>

</template>

<script>
import { formatNumberCZ } from '@/utils/format.js'
import { toNumberCZ, isNumericCZ } from '@/utils/convert.js'

import SectionHeader from '@/components/Common/SectionHeader'
import BaseInput from '@/components/Forms/Base/BaseInput'

export default {
	name: 'VyuctovaniTab3Rozpocet',

	components: {
		SectionHeader,
		BaseInput,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: true,
		},
	},

	emits: ['update'],

	data() {
		return {

			schema: [
				{ name: '1_provozni_naklady_celkem', label: '1. PROVOZNÍ NÁKLADY celkem', sum: true, comment: false, level: 1, color: 1 },
				{ name: '11_materialove_naklady_celkem', label: '1.1 Materiálové náklady celkem', sum: true, comment: false, level: 2, color: 2 },
				{ name: '111_potraviny', label: '1.1.1 potraviny', sum: false, comment: false, level: 3, color: 0 },
				{ name: '112_zdravotnicky_material', label: '1.1.2 zdravotnický materiál', sum: false, comment: false, level: 3, color: 0 },
				{ name: '113_hygienicky_material', label: '1.1.3 hygienický materiál', sum: false, comment: false, level: 3, color: 0 },
				{ name: '114_kancelarske_potreby', label: '1.1.4 kancelářské potřeby', sum: false, comment: false, level: 3, color: 0 },
				{ name: '115_ddhm', label: '1.1.5 DDHM ', sum: false, comment: true, level: 3, color: 0 },
				{ name: '116_pohonne_hmoty', label: '1.1.6 pohonné hmoty', sum: false, comment: false, level: 3, color: 0 },
				{ name: '117_ostatni_materialove_naklady', label: '1.1.7 ostatní materiálové náklady', sum: false, comment: true, level: 3, color: 0 },
				{ name: '12_opravy_udrzovani', label: '1.2 Opravy a udržování	', sum: false, comment: true, level: 2, color: 2 },
				{ name: '13_cestovne_celkem', label: '1.3 Cestovné celkem	', sum: true, comment: false, level: 2, color: 2 },
				{ name: '131_tuzemske_cestovne', label: '1.3.1 tuzemské cestovné ', sum: false, comment: false, level: 3, color: 0 },
				{ name: '132_zahranicni_cestovne', label: '1.3.2 zahraniční cestovné ', sum: false, comment: false, level: 3, color: 0 },
				{ name: '14_sluzby_celkem', label: '1.4 Služby celkem	', sum: true, comment: false, level: 2, color: 2 },
				{ name: '141_energie', label: '1.4.1 energie', sum: false, comment: false, level: 3, color: 0 },
				{ name: '142_pstatni_sluzby', label: '1.4.2 ostatní služby:', sum: true, comment: false, level: 3, color: 0 },
				{ name: '1421_spoje', label: '- spoje (telefony, internet, poštovné apod.)', sum: false, comment: false, level: 4, color: 0 },
				{ name: '1422_najemne', label: '- nájemné', sum: false, comment: false, level: 4, color: 0 },
				{ name: '1423_operativni_leasing', label: '- operativní leasing', sum: false, comment: false, level: 4, color: 0 },
				{ name: '1424_skoleni_vzdelavani', label: '- školení a vzdělávání', sum: false, comment: false, level: 4, color: 0 },
				{ name: '1425_ekonomicke_ucetni_pravni_sluzby', label: '- ekonomické, účetní a právní služby', sum: false, comment: false, level: 4, color: 0 },
				{ name: '1426_ddnm', label: '- DDNM', sum: false, comment: true, level: 4, color: 0 },
				{ name: '1427_ostatni_sluzby', label: '- jiné ostatní služby', sum: false, comment: true, level: 4, color: 0 },
				{ name: '15_provozni_naklady_celkem', label: '1.5 Jiné provozní náklady celkem	', sum: false, comment: true, level: 2, color: 2 },
				{ name: '2_osobni_naklady_celkem', label: '2. OSOBNÍ NÁKLADY celkem', sum: true, comment: false, level: 1, color: 1 },
				{ name: '21_mzdove_naklady', label: '2.1 Mzdové náklady', sum: true, comment: false, level: 2, color: 2 },
				{ name: '211_hrube_mzdy_platy', label: '2.1.1 hrubé mzdy/platy', sum: false, comment: false, level: 3, color: 0 },
				{ name: '212_on', label: '2.1.2 ON na DPČ/DPP', sum: false, comment: false, level: 3, color: 0 },
				{ name: '213_odvody_socialni_zdravotni', label: '2.1.3 odvody zaměstnavatele na sociální a zdravotní pojištění', sum: false, comment: false, level: 3, color: 0 },
				{ name: '22_socialni_naklady', label: '2.2 Sociální náklady (např. zákonné pojištění odpovědnosti zaměstnavatele…)	', sum: false, comment: true, level: 2, color: 2 },
				{ name: '23_osobni_naklady', label: '2.3  Ostatní osobní náklady (např. práce dobrovolníků, ...)	', sum: false, comment: true, level: 2, color: 2 },
				{ name: '_naklady_projektu_celkem', label: 'CELKOVÉ NÁKLADY PROJEKTU', sum: true, comment: false, level: 1, color: 1 },
			],

		}
	},

	methods: {
		isNumericCZ,
		toNumberCZ,
		formatNumberCZ,

		updateField(prefix, field) {
			this.$emit('update', {
				tab: 'tab3',
				prefix,
				field,
				value: this.data[prefix + field]
			})
		},

		isErrorInput(value) {
			return !(this.isNumericCZ(value) || value.trim() === '')
		}
	},


}
</script>