<template>

	<SectionHeader :title="`Část C1 - Rozpočet projektu podle zdrojů na rok ${projekt.dotacni_rok}`" />

	<table class="table">
		<thead>
			<tr>
				<th class="w55">Přehled finančních zdrojů</th>
				<th class="w15">Plán<br>{{projekt.dotacni_rok}}</th>
				<th class="w15">Skutečnost/odhad<br>{{projekt.dotacni_rok - 1}}</th>
				<th class="w15">Skutečnost<br>{{projekt.dotacni_rok - 2}}</th>
			</tr>
		</thead>
		<tbody>

			<tr class="table__sum-row">
				<td>1. Státní rozpočet celkem</td>
				<td class="alr" v-tippy="chybaTooltip('r_1_statni_rozpocet')" v-bind="chyba('r_1_statni_rozpocet')">{{ formatNumberCZ(data.r_1_statni_rozpocet) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_1_statni_rozpocet')" v-bind="chyba('r1_1_statni_rozpocet')">{{ formatNumberCZ(data.r1_1_statni_rozpocet) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_1_statni_rozpocet')" v-bind="chyba('r2_1_statni_rozpocet')">{{ formatNumberCZ(data.r2_1_statni_rozpocet) }}</td>
			</tr>
			<tr>
				<td class="pl-3">1.1 ÚV ČR</td>
				<td class="alr" v-tippy="chybaTooltip('r_11_uvcr')" v-bind="chyba('r_11_uvcr')">{{ formatNumberCZ(data.r_11_uvcr) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_11_uvcr')" v-bind="chyba('r1_11_uvcr')">{{ formatNumberCZ(data.r1_11_uvcr) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_11_uvcr')" v-bind="chyba('r2_11_uvcr')">{{ formatNumberCZ(data.r2_11_uvcr) }}</td>
			</tr>
			<tr>
				<td class="pl-3">1.2 MPSV</td>
				<td class="alr" v-tippy="chybaTooltip('r_12_mpsv')" v-bind="chyba('r_12_mpsv')">{{ formatNumberCZ(data.r_12_mpsv) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_12_mpsv')" v-bind="chyba('r1_12_mpsv')">{{ formatNumberCZ(data.r1_12_mpsv) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_12_mpsv')" v-bind="chyba('r2_12_mpsv')">{{ formatNumberCZ(data.r2_12_mpsv) }}</td>
			</tr>
			<tr>
				<td class="pl-3">1.3 Úřady práce</td>
				<td class="alr" v-tippy="chybaTooltip('r_13_urady_prace')" v-bind="chyba('r_13_urady_prace')">{{ formatNumberCZ(data.r_13_urady_prace) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_13_urady_prace')" v-bind="chyba('r1_13_urady_prace')">{{ formatNumberCZ(data.r1_13_urady_prace) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_13_urady_prace')" v-bind="chyba('r2_13_urady_prace')">{{ formatNumberCZ(data.r2_13_urady_prace) }}</td>
			</tr>
			<tr>
				<td class="pl-3">1.4 MŠMT</td>
				<td class="alr" v-tippy="chybaTooltip('r_14_msmt')" v-bind="chyba('r_14_msmt')">{{ formatNumberCZ(data.r_14_msmt) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_14_msmt')" v-bind="chyba('r1_14_msmt')">{{ formatNumberCZ(data.r1_14_msmt) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_14_msmt')" v-bind="chyba('r2_14_msmt')">{{ formatNumberCZ(data.r2_14_msmt) }}</td>
			</tr>
			<tr>
				<td class="pl-3">1.5 MZ</td>
				<td class="alr" v-tippy="chybaTooltip('r_15_mz')" v-bind="chyba('r_15_mz')">{{ formatNumberCZ(data.r_15_mz) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_15_mz')" v-bind="chyba('r1_15_mz')">{{ formatNumberCZ(data.r1_15_mz) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_15_mz')" v-bind="chyba('r2_15_mz')">{{ formatNumberCZ(data.r2_15_mz) }}</td>
			</tr>
			<tr>
				<td class="pl-3">1.6 MS</td>
				<td class="alr" v-tippy="chybaTooltip('r_16_ms')" v-bind="chyba('r_16_ms')">{{ formatNumberCZ(data.r_16_ms) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_16_ms')" v-bind="chyba('r1_16_ms')">{{ formatNumberCZ(data.r1_16_ms) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_16_ms')" v-bind="chyba('r2_16_ms')">{{ formatNumberCZ(data.r2_16_ms) }}</td>
			</tr>
			<tr>
				<td class="pl-3">1.7 Ostatní zdroje státní správy</td>
				<td class="alr" v-tippy="chybaTooltip('r_17_ostatni_zdroje')" v-bind="chyba('r_17_ostatni_zdroje')">{{ formatNumberCZ(data.r_17_ostatni_zdroje) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_17_ostatni_zdroje')" v-bind="chyba('r1_17_ostatni_zdroje')">{{ formatNumberCZ(data.r1_17_ostatni_zdroje) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_17_ostatni_zdroje')" v-bind="chyba('r2_17_ostatni_zdroje')">{{ formatNumberCZ(data.r2_17_ostatni_zdroje) }}</td>
			</tr>

			<tr class="table__sum-row">
				<td>2. Územní rozpočty celkem</td>
				<td class="alr" v-tippy="chybaTooltip('r_2_uzemni_rozpocty')" v-bind="chyba('r_2_uzemni_rozpocty')">{{ formatNumberCZ(data.r_2_uzemni_rozpocty) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_2_uzemni_rozpocty')" v-bind="chyba('r1_2_uzemni_rozpocty')">{{ formatNumberCZ(data.r1_2_uzemni_rozpocty) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_2_uzemni_rozpocty')" v-bind="chyba('r2_2_uzemni_rozpocty')">{{ formatNumberCZ(data.r2_2_uzemni_rozpocty) }}</td>
			</tr>
			<tr>
				<td class="pl-3">2.1 Kraj (vč. Hl.m.Prahy) - prostředky MPSV</td>
				<td class="alr" v-tippy="chybaTooltip('r_21_kraj_prostredkympsv')" v-bind="chyba('r_21_kraj_prostredkympsv')">{{ formatNumberCZ(data.r_21_kraj_prostredkympsv) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_21_kraj_prostredkympsv')" v-bind="chyba('r1_21_kraj_prostredkympsv')">{{ formatNumberCZ(data.r1_21_kraj_prostredkympsv) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_21_kraj_prostredkympsv')" v-bind="chyba('r2_21_kraj_prostredkympsv')">{{ formatNumberCZ(data.r2_21_kraj_prostredkympsv) }}</td>
			</tr>
			<tr>
				<td class="pl-3">2.2 Kraj (vč. Hl.m.Prahy) - prostředky kraje</td>
				<td class="alr" v-tippy="chybaTooltip('r_22_kraj_prostredkykraje')" v-bind="chyba('r_22_kraj_prostredkykraje')">{{ formatNumberCZ(data.r_22_kraj_prostredkykraje) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_22_kraj_prostredkykraje')" v-bind="chyba('r1_22_kraj_prostredkykraje')">{{ formatNumberCZ(data.r1_22_kraj_prostredkykraje) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_22_kraj_prostredkykraje')" v-bind="chyba('r2_22_kraj_prostredkykraje')">{{ formatNumberCZ(data.r2_22_kraj_prostredkykraje) }}</td>
			</tr>
			<tr>
				<td class="pl-3">2.3 Města, obce a městské části</td>
				<td class="alr" v-tippy="chybaTooltip('r_23_mesta_obce')" v-bind="chyba('r_23_mesta_obce')">{{ formatNumberCZ(data.r_23_mesta_obce) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_23_mesta_obce')" v-bind="chyba('r1_23_mesta_obce')">{{ formatNumberCZ(data.r1_23_mesta_obce) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_23_mesta_obce')" v-bind="chyba('r2_23_mesta_obce')">{{ formatNumberCZ(data.r2_23_mesta_obce) }}</td>
			</tr>

			<tr class="table__sum-row">
				<td>3. ESIF</td>
				<td class="alr" v-tippy="chybaTooltip('r_3_esif')" v-bind="chyba('r_3_esif')">{{ formatNumberCZ(data.r_3_esif) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_3_esif')" v-bind="chyba('r1_3_esif')">{{ formatNumberCZ(data.r1_3_esif) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_3_esif')" v-bind="chyba('r2_3_esif')">{{ formatNumberCZ(data.r2_3_esif) }}</td>
			</tr>

			<tr class="table__sum-row">
				<td>4. Zahraniční zdroje</td>
				<td class="alr" v-tippy="chybaTooltip('r_4_zahranicni_zdroje')" v-bind="chyba('r_4_zahranicni_zdroje')">{{ formatNumberCZ(data.r_4_zahranicni_zdroje) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_4_zahranicni_zdroje')" v-bind="chyba('r1_4_zahranicni_zdroje')">{{ formatNumberCZ(data.r1_4_zahranicni_zdroje) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_4_zahranicni_zdroje')" v-bind="chyba('r2_4_zahranicni_zdroje')">{{ formatNumberCZ(data.r2_4_zahranicni_zdroje) }}</td>
			</tr>

			<tr class="table__sum-row">
				<td>5. Úhrady ze zdravotních pojišťoven</td>
				<td class="alr" v-tippy="chybaTooltip('r_5_uhrady_zp')" v-bind="chyba('r_5_uhrady_zp')">{{ formatNumberCZ(data.r_5_uhrady_zp) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_5_uhrady_zp')" v-bind="chyba('r1_5_uhrady_zp')">{{ formatNumberCZ(data.r1_5_uhrady_zp) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_5_uhrady_zp')" v-bind="chyba('r2_5_uhrady_zp')">{{ formatNumberCZ(data.r2_5_uhrady_zp) }}</td>
			</tr>

			<tr class="table__sum-row">
				<td>6. Soukromé zdroje celkem</td>
				<td class="alr" v-tippy="chybaTooltip('r_6_soukrome_zdroje')" v-bind="chyba('r_6_soukrome_zdroje')">{{ formatNumberCZ(data.r_6_soukrome_zdroje) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_6_soukrome_zdroje')" v-bind="chyba('r1_6_soukrome_zdroje')">{{ formatNumberCZ(data.r1_6_soukrome_zdroje) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_6_soukrome_zdroje')" v-bind="chyba('r2_6_soukrome_zdroje')">{{ formatNumberCZ(data.r2_6_soukrome_zdroje) }}</td>
			</tr>
			<tr>
				<td class="pl-3">6.1 Nadace, sbírky, sponzorské dary</td>
				<td class="alr" v-tippy="chybaTooltip('r_61_nadace_sbirky_dary')" v-bind="chyba('r_61_nadace_sbirky_dary')">{{ formatNumberCZ(data.r_61_nadace_sbirky_dary) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_61_nadace_sbirky_dary')" v-bind="chyba('r1_61_nadace_sbirky_dary')">{{ formatNumberCZ(data.r1_61_nadace_sbirky_dary) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_61_nadace_sbirky_dary')" v-bind="chyba('r2_61_nadace_sbirky_dary')">{{ formatNumberCZ(data.r2_61_nadace_sbirky_dary) }}</td>
			</tr>
			<tr>
				<td class="pl-3">6.2 Příjmy od klientů <em class="small">(V případě preventivních programů se klientem rozumí škola)</em></td>
				<td class="alr" v-tippy="chybaTooltip('r_62_prijmy_od_klientu')" v-bind="chyba('r_62_prijmy_od_klientu')">{{ formatNumberCZ(data.r_62_prijmy_od_klientu) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_62_prijmy_od_klientu')" v-bind="chyba('r1_62_prijmy_od_klientu')">{{ formatNumberCZ(data.r1_62_prijmy_od_klientu) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_62_prijmy_od_klientu')" v-bind="chyba('r2_62_prijmy_od_klientu')">{{ formatNumberCZ(data.r2_62_prijmy_od_klientu) }}</td>
			</tr>
			<tr>
				<td class="pl-3">6.3 Ostatní: <em>{{data.r_63_ostatni_upresneni}}</em></td>
				<td class="alr" v-tippy="chybaTooltip('r_63_ostatni')" v-bind="chyba('r_63_ostatni')">{{ formatNumberCZ(data.r_63_ostatni) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_63_ostatni')" v-bind="chyba('r1_63_ostatni')">{{ formatNumberCZ(data.r1_63_ostatni) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_63_ostatni')" v-bind="chyba('r2_63_ostatni')">{{ formatNumberCZ(data.r2_63_ostatni) }}</td>
			</tr>

			<tr class="table__sum-row">
				<td>CELKOVÉ NÁKLADY VŠECH ZDROJŮ NA REALIZACI PROJEKTU</td>
				<td class="alr" v-tippy="chybaTooltip('r_celkove_naklady')" v-bind="chyba('r_celkove_naklady')">{{ formatNumberCZ(data.r_celkove_naklady) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r1_celkove_naklady')" v-bind="chyba('r1_celkove_naklady')">{{ formatNumberCZ(data.r1_celkove_naklady) }}</td>
				<td class="alr" v-tippy="chybaTooltip('r2_celkove_naklady')" v-bind="chyba('r2_celkove_naklady')">{{ formatNumberCZ(data.r2_celkove_naklady) }}</td>
			</tr>

			<tr class="table__sum-row">
				<td>% spoluúčast ÚV ČR</td>
				<td class="alr" v-tippy="chybaTooltip('r_spoluucast_uvcr')" v-bind="chyba('r_spoluucast_uvcr')">{{ formatNumberCZ(data.r_spoluucast_uvcr) }}</td>
				<td class="table__no-cell">&nbsp;</td>
				<td class="table__no-cell">&nbsp;</td>
			</tr>

			<tr class="table__sum-row">
				<td>% spoluúčast jiných zdrojů</td>
				<td class="alr" v-tippy="chybaTooltip('r_spoluucast_jinezdroje')" v-bind="chyba('r_spoluucast_jinezdroje')">{{ formatNumberCZ(data.r_spoluucast_jinezdroje) }}</td>
				<td class="table__no-cell">&nbsp;</td>
				<td class="table__no-cell">&nbsp;</td>
			</tr>

		</tbody>
	</table>

</template>

<script>
import { formatNumberCZ } from '@/utils/format.js'

import SectionHeader from '@/components/Common/SectionHeader'

export default {
	name: 'CastC1',

	components: {
		SectionHeader,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		}
	},

	methods: {
		formatNumberCZ,

		importError(field) {
			if (!Array.isArray(this.projekt.kontrola_c)) return undefined
			return this.projekt.kontrola_c.find(err => err.pole === field && err.list === 'C1')
		},

		chyba(field) {
			const importError = this.importError(field)
			return (
				importError
				? {
					class: 'table__error'
				}
				: {}
			)
		},

		chybaTooltip(field) {
			const importError = this.importError(field)
			return (
				importError
				? {
					content: `<strong>Chyba na listu ${importError.list} v buňce ${importError.bunka}</strong>` + (importError.chyba ? `<br>${importError.chyba}` : '' )
				}
				: {}
			)
		},

	},

}
</script>