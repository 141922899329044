<template>

	<PageHeader title="PŘIDAT NOVÝ PROJEKT" />

	<section class="content">

		<Message type="error" class="mb-4" v-if="error">
			{{ error }}
		</Message>

		<Message type="error" class="mb-4" v-if="formErrors.length !== 0">
			Opravte následující chyby:
			<ul>
				<li v-for="(error, index) in formErrors" :key="index">
					{{error}}
				</li>
			</ul>
		</Message>


		<form
			novalidate
			@submit.prevent=""
		>

			<div class="form__field form__field--required w30">
				<label for="kod" class="form__label">Kód projektu</label>
				<input
					class="form__input"
					type="text"
					name="kod"
					id="kod"
					v-model="kod"
				>
			</div>

			<div class="form__field form__field--required">
				<label for="nazev" class="form__label">Název</label>
				<input
					class="form__input"
					type="text"
					name="nazev"
					id="nazev"
					v-model="nazev"
				>
			</div>

			<div class="form__field form__field--required mt-5">
				<label class="form__label">Typ projektu</label>

				<div style="flex: 1 1 auto;">
					<div class="grid" style="gap: 60px;">
						<div class="grid__column d-flex">

							<div class="form__input-element" style="flex: 0 1 auto">
								<input
									type="checkbox"
									class="form__checkbox"
									name="typ1"
									id="typ1"
									:class="{'form__checkbox--checked': typ === 1}"
									:checked="typ === 1"
									@input="zmenitTyp(1)"
								/>
								<label
									for="typ1"
									class="form__checkbox-box"
								>
									<font-awesome-icon
										class="form__checkbox-icon"
										icon="check"
									/>
								</label>
							</div>

							<label for="typ1" style="flex: 0 1 auto; margin-left: 20px; line-height: 1.4;">
								Jde o <strong>službu/projekt</strong>, jehož předmětem je přímé poskytování služeb klientům, včetně primární prevence a služeb poskytovaných prostřednictvím nových technologií.
							</label>

						</div>
						<div class="grid__column d-flex">

							<div class="form__input-element" style="flex: 0 1 auto">
								<input
									type="checkbox"
									class="form__checkbox"
									name="typ2"
									id="typ2"
									:class="{'form__checkbox--checked': typ === 2}"
									:checked="typ === 2"
									@input="zmenitTyp(2)"
								/>
								<label
									for="typ2"
									class="form__checkbox-box"
								>
									<font-awesome-icon
										class="form__checkbox-icon"
										icon="check"
									/>
								</label>

							</div>

							<label for="typ2" style="flex: 0 1 auto; margin-left: 20px; line-height: 1.4;">
								Jde o <strong>projekt</strong>, jehož předmětem je získání informací formou studií, výzkumu, analýz, šíření informací, vzdělávání, konference, nebo vývoj nového produktu, atd.
							</label>

						</div>
					</div>

					<Message type="info" class="mt-3"><strong>Pozor:</strong> Vyberte správný typ projektu. Typ určuje podobu formulářů části B1 a B2 projektu a <strong>nejde později změnit</strong>.</Message>

				</div>

			</div>

			<div class="form__buttons form__buttons--aligned mt-5">
				<button
					type="submit"
					class="button"
					@click.prevent="submit"
				>
					<font-awesome-icon icon="folder-open" class="button__icon" />
					Vytvořit nový projekt
				</button>
				<button
					class="button button--outline"
					@click.prevent="cancel"
				>
					Storno / zpět na hlavní stranu žádosti
				</button>
			</div>

			<Loading title="Vytváření projektu" v-if="isSaving" />

		</form>
	</section>

</template>

<script>
import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'


export default {
	name: 'ProjektNovy',

	components: {
		PageHeader,
		Message,
		Loading,
	},

	props: {
		idZadosti: {
			type: [Number, String],
			required: true,
		},
	},

	data() {
		return {
			formErrors: [],
			error: '',
			isSaving: false,

			kod: '',
			nazev: '',
			typ: 0,
		}
	},

	computed: {
		...authComputed,
	},

	methods: {
		zmenitTyp(typ) {
			this.typ = typ
		},

		validateForm() {
			this.formErrors = []

			if (!this.kod) {
				this.formErrors.push('kód projektu je povinné pole')
			}
			if (!this.nazev) {
				this.formErrors.push('název projektu je povinné pole')
			}
			if (!(this.typ === 1 || this.typ === 2)) {
				this.formErrors.push('musíte vybrat typ projektu')
			}
		},

		submit() {
			this.validateForm()
			if (this.formErrors.length !== 0) return;

			const projekt = {
				id_zadosti: Number(this.idZadosti),
				kod: this.kod,
				nazev: this.nazev,
				typ: this.typ,
				stav: 1,
				datum_odeslani_zadosti: null
			}

			this.isSaving = true

			ProjektyService
			.createProjekt(projekt)
			.then(response => {
				if (response.data.success) {
					// uloženo, přesměrujeme na stránku projektu
					this.$router.push({ name: 'Projekt', params: { id: response.data.data.id }})
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				this.isSaving = false
			})
		},

		cancel() {
			this.$router.push({ name: 'Zadost' })
		}
	},

}
</script>
