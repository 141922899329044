<template>
	<Dialog title="Vrátit vyúčtování žadateli k přepracování">

		<template v-if="!vraceno">
			<Message class="mb-3"	type="info">
				Napište komentář/zdůvodnění, proč závěrečnou zprávu vracíte k&nbsp;přepracování.<br>
				Komentář se žadateli zobrazí na stránce projektu a&nbsp;statutárnímu zástupci organizace se odešle emailem.
			</Message>

			<Message class="mb-3"	type="error"	v-if="error">
				{{ error }}
			</Message>

			<Loading v-if="isSaving" />

			<form
				class="form mt-4"
				novalidate
				@submit.prevent="submit"
			>

				<label for="komentar" class="form__label mb-2">Komentář / zdůvodnění vrácení závěrečné zprávy k přepracování: <span class="required">*</span></label>
				<textarea
					class="form__input w100 mt-2"
					rows="7"
					name="komentar"
					id="komentar"
					v-model="komentar"
				/>

				<hr class="form__divider">

				<div class="buttons">
					<button
						class="button button--outline"
						@click.prevent="saveData"
					>
						<font-awesome-icon icon="recycle" class="button__icon" />
						Vrátit k přepracování
					</button>

					<button
						class="button button--outline button--neutral"
						@click.prevent="cancel"
					>
						Storno
					</button>

				</div>

			</form>
		</template>

		<template v-if="vraceno">
			<Message class="mb-3"	type="info">
				<p>Závěrečná zpráva byla úspěšně vráceno k přepracování.</p>
				<p>Žadatel má závěrečnou zprávu odemčenou pro editaci, může provádět změny a&nbsp;znovu ho odeslat ke kontrole a schválení.</p>
			</Message>

			<hr class="form__divider">

			<div class="buttons">
				<button
					class="button button--outline"
					@click.prevent="confirm"
				>
					<font-awesome-icon icon="check" class="button__icon" />
					OK
				</button>
			</div>
		</template>
	</Dialog>
</template>

<script>
import ProjektyService from '@/services/ProjektyService'

import Dialog from '@/components/Common/Dialog'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
	name: 'VyuctovaniVratitDialog',

	components: {
		Dialog,
		Message,
		Loading,
	},

	props: {
		id: {
			type: Number,
			required: true,
		},
	},

	emits: ['submit', 'cancel'],

	data() {
		return {
			error: '',
			isSaving: false,
			vraceno: false,
			komentar: '',
		}
	},

	methods: {
		cancel() {
			this.$emit('cancel')
		},

		confirm() {
			this.$emit('submit')
		},

		saveData() {
			if (!this.komentar) {
				this.error = 'Musíte napsat zdůvodnění vrácení závěrečné zprávy'
				return
			}

			this.isSaving = true

			const data = {
				duvod: this.komentar,
			}

			ProjektyService
			.vratitZaverecnaZprava(this.id, data)
			.then(response => {
				if (response.data.success) {
					this.vraceno = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				this.isSaving = false
			})
		},
	},

}
</script>
