<template>

	<div class="grid grid--double-gap">
		<div class="grid-column"><!-- v-if="!isGov" -->

			<VyuctovaniExcelDownload
				:idVyuctovani="vyuctovani.id"
				:filename="nazevSouboru"
			/>

		</div>
		<div class="grid-column">

			<VyuctovaniExcelImport
				:idVyuctovani="vyuctovani.id"
				:fileId="vyuctovani.id_soubor_vyuctovani"
				:fileName="vyuctovani.soubor_vyuctovani_nazev"
				:importErrors="importObsahujeChyby"
				:disabled="disabled"
				@uploaded="updateFile($event)"
			/>
		</div>
	</div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'

import Message from '@/components/Common/Message'

import VyuctovaniExcelDownload from '@/components/Projekty/Vyuctovani/VyuctovaniExcelDownload'
import VyuctovaniExcelImport from '@/components/Projekty/Vyuctovani/VyuctovaniExcelImport'


export default {
	name: 'VyuctovaniExcel',

	components: {
		Message,
		VyuctovaniExcelDownload,
		VyuctovaniExcelImport,
	},

	props: {
		vyuctovani: {
			type: Object,
			required: true,
		},
		projekt: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: true,
		},
	},

	emits: ['uploaded'],

	computed: {
		...authComputed,

		nazevSouboru() {
			return `${this.projekt.kod}-vyuctovani-dotace.xlsx`
		},

		importObsahujeChyby() {
			// return Array.isArray(this.projekt.kontrola_c) && this.projekt.kontrola_c.length > 0
			return false
		},
	},

	methods: {
		updateFile(file) {
			this.$emit('uploaded', file)
		},
	},
}
</script>