<template>
  <section v-if="isOrg">

    <SectionHeader title="ČESTNÉ PROHLÁŠENÍ O BEZDLUŽNOSTI" />

    <div class="content">

      <Message type="warning">
        Nezapomeňte do prohlášení doplnit bydliště a opatřit podpisem statutárního zástupce (elektronicky nebo fyzicky) a odeslat datovou schránkou, nebo v listinné podobě na adresu Úřadu vlády ČR, nebo osobně odevzdat na podatelně Úřadu vlády ČR.
      </Message>

      <div class="buttons mt-3">
        <button
            class="button button--outline flex-shrink-0"
            @click="stahnoutPDF"
        >
          <font-awesome-icon icon="download" class="button__icon" />
          Stáhnout Čestné prohlášení o bezdlužnosti
        </button>
      </div>

    </div>

  </section>
</template>

<script>
import ZadostiService from '@/services/ZadostiService'
import { saveAs } from 'file-saver'

import Message from '@/components/Common/Message'
import SectionHeader from '@/components/Common/SectionHeader'
import { authComputed } from "@/store/helpers";

export default {
  name: 'ZadostStazeniCestneProhlaseniBezdluznost',

  components: {
    Message,
    SectionHeader,
  },

  computed: {
    ...authComputed,
  },

  props: {
    idZadosti: {
      type: Number,
      required: true,
    }
  },

  methods: {

    stahnoutPDF() {
      ZadostiService
          .stahnoutCestneProhlaseniBezdluznost(this.idZadosti)
          .then((response) => {
            saveAs(new Blob([response.data]), 'cestne-prohlaseni-o-bezdluznosti.doc')
          })
    },

  },

}
</script>
