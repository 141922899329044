import {
	ROLE
} from '@/constants/role'

import ApiClient from '@/services/ApiClient'

// initial state
const state = () => ({
  user: null,
})



// getters
const getters = {
	loggedIn(state) {
		// tuto funkci nahradit funkcí isAuthenticated (níže)
		return !!state.user
	},

	isAuthenticated(state) {
		return !!state.user
	},

	userId(state) {
		return !state.user ? null : state.user?.id
	},

	userName(state) {
		return !state.user ? null : `${state.user?.jmeno} ${state.user?.prijmeni}`
	},

	userInitials(state) {
		return !state.user ? null : state.user?.jmeno.slice(0, 1) + state.user?.prijmeni.slice(0, 1)
	},

	userOrg(state) {
		return !state.user ? null : {
			idOrganizace: state.user?.id_organizace,
			organizace: state.user?.organizace,
		}
	},

	userRole(state) {
		return state.user?.role;
	},

	isGov(state) {
		// jde o pracovníka vlády (má přístup do admin sekce)
		return [
				ROLE.SUPERADMIN,
				ROLE.ADMIN,
				ROLE.KRAJSKY_KOORDINATOR,
				ROLE.CLEN_DOTACNIHO_VYBORU,
				ROLE.EXTERNI_HODNOTITEL,
				ROLE.KONTROLOR
			].includes(state.user?.role)
	},

	isOrg(state) {
		// jde o pracovníka organizace (statutár nebo jeho pomocníci)
		return [
			ROLE.STATUTAR,
			ROLE.PRACOVNIK_ORGANIZACE,
		].includes(state.user?.role)
	},

	isSuperAdmin(state) {
		return state.user?.role === ROLE.SUPERADMIN
	},

	isAdmin(state) {
		return state.user?.role === ROLE.ADMIN
	},

	isKontrolor(state) {
		return state.user?.role === ROLE.KONTROLOR
	},

	isStatutar(state) {
		return state.user?.role === ROLE.STATUTAR
	},

	isClenDotacnihoVyboru(state) {
		return state.user?.role === ROLE.CLEN_DOTACNIHO_VYBORU
	},

	isKrajskyKoordinator(state) {
		return state.user?.role === ROLE.KRAJSKY_KOORDINATOR
	},

	isExterniHodnotitel(state) {
		return state.user?.role === ROLE.EXTERNI_HODNOTITEL
	},
}


// actions
const actions = {
	login({commit}, userData) {
		commit('SET_USER_DATA', userData)
	},

	logout({commit}) {
		commit('CLEAR_USER_DATA')
	},
}


// mutations
const mutations = {
	SET_USER_DATA(state, userData) {
		state.user = userData
		sessionStorage.setItem('c_user', JSON.stringify(userData))
		ApiClient.defaults.headers.common['Authorization'] = `Bearer ${userData.token}`
	},

	CLEAR_USER_DATA(state) {
		state.user = null
		sessionStorage.removeItem('c_user')
		delete ApiClient.defaults.headers.common["Authorization"]
	},
}


// export
export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
}