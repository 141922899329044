<template>
	<SectionHeader :title="`Přehled o finančních prostředcích získaných na realizaci projektu v roce ${projekt.dotacni_rok} - Zdroje`" />

	<table class="table table--small-vertical-padding w60">
		<thead>
			<tr>
				<th class="w60">Přehled finančních zdrojů</th>
				<th class="w20">Přiděleno</th>
				<th class="w20">Skutečně<br>čerpáno</th>
			</tr>
		</thead>
		<tbody>
			<tr
				v-for="row in schema"
				:key="row.name"
				:class="row.color !==0 ? `table__sum-row-level${row.color}`: null"
			>
				<td :class="`level${row.level}`">{{ row.label }}</td>

				<td class="alr">
					<BaseInput
						v-if="!disabled && !row.sum"
						v-model="data[`p${row.name}`]"
						:name="`p${row.name}`"
						type="text"
						align="right"
						small
						class="w100"
						:error="isErrorInput(data[`p${row.name}`])"
						@keyup="updateField('p', row.name)"
					/>
					<template v-else>
						{{ formatNumberCZ( data[`p${row.name}`] ) }}
					</template>
				</td>

				<td class="alr">
					<BaseInput
						v-if="!disabled && !row.sum"
						v-model="data[`sv${row.name}`]"
						:name="`sv${row.name}`"
						type="text"
						align="right"
						small
						class="w100"
						:error="isErrorInput(data[`sv${row.name}`])"
						@keyup="updateField('sv', row.name)"
					/>
					<template v-else>
						{{ formatNumberCZ( data[`sv${row.name}`] ) }}
					</template>
				</td>
			</tr>
		</tbody>
	</table>
</template>

<script>
import { formatNumberCZ } from '@/utils/format.js'
import { toNumberCZ, isNumericCZ } from '@/utils/convert.js'

import SectionHeader from '@/components/Common/SectionHeader'
import BaseInput from '@/components/Forms/Base/BaseInput'

export default {
	name: 'VyuctovaniTab2Zdroje',

	components: {
		SectionHeader,
		BaseInput,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: true,
		},
	},

	emits: ['update'],

	data() {
		return {

			schema: [
				{ name: '1_statni_rozpocet', label: '1. Státní rozpočet celkem', sum: true, comment: false, level: 1, color: 2 },
				{ name: '11_uvcr', label: '1.1	ÚV ČR', sum: false, comment: false, level: 2, color: 0 },
				{ name: '12_mpsv', label: '1.2	MPSV', sum: false, comment: false, level: 2, color: 0 },
				{ name: '13_urady_prace', label: '1.3	Úřady práce', sum: false, comment: false, level: 2, color: 0 },
				{ name: '14_msmt', label: '1.4	MŠMT', sum: false, comment: false, level: 2, color: 0 },
				{ name: '15_mz', label: '1.5	MZ', sum: false, comment: false, level: 2, color: 0 },
				{ name: '16_ms', label: '1.6	MS', sum: false, comment: false, level: 2, color: 0 },
				{ name: '17_ostatni_zdroje', label: '1.7	Ostatní zdroje státní správy', sum: false, comment: false, level: 2, color: 0 },
				{ name: '2_uzemni_rozpocty', label: '2. Územní rozpočty celkem', sum: true, comment: false, level: 1, color: 2 },
				{ name: '21_kraj_prostredkympsv', label: '2.1	Kraj (vč. Hl.m.Prahy) - prostředky MPSV', sum: false, comment: false, level: 2, color: 0 },
				{ name: '22_kraj_prostredkykraje', label: '2.2	Kraj (vč. Hl.m.Prahy) - prostředky kraje', sum: false, comment: false, level: 2, color: 0 },
				{ name: '23_mesta_obce', label: '2.3	Města, obce a městské části', sum: false, comment: false, level: 2, color: 0 },
				{ name: '3_esif', label: '3. ESIF', sum: false, comment: false, level: 1, color: 2 },
				{ name: '4_zahranicni_zdroje', label: '4. Zahraniční zdroje', sum: false, comment: false, level: 1, color: 2 },
				{ name: '5_uhrady_zp', label: '5. Úhrady ze zdravotních pojišťoven', sum: false, comment: false, level: 1, color: 2 },
				{ name: '6_soukrome_zdroje', label: '6. Soukromé zdroje celkem', sum: true, comment: false, level: 1, color: 2 },
				{ name: '61_nadace_sbirky_dary', label: '6.1	Nadace, sbírky, sponzorské dary', sum: false, comment: false, level: 2, color: 0 },
				{ name: '62_prijmy_od_klientu', label: '6.2	"Příjmy od klientů', sum: false, comment: false, level: 2, color: 0 },
				{ name: '63_ostatni', label: '6.3	Ostatní', sum: false, comment: true, level: 2, color: 0 },
				{ name: '_celkove_naklady', label: 'CELKOVÉ ZDROJE NA REALIZACI PROJEKTU', sum: true, comment: false, level: 1, color: 1 },
				{ name: '_spoluucast_uvcr', label: '% spoluúčast ÚV ČR', sum: true, comment: false, level: 1, color: 1 },
				{ name: '_spoluucast_jinezdroje', label: '% spoluúčast jiných zdrojů', sum: true, comment: false, level: 1, color: 1 },
			],

		}
	},

	methods: {
		isNumericCZ,
		toNumberCZ,
		formatNumberCZ,

		updateField(prefix, field) {
			this.$emit('update', {
				tab: 'tab2',
				prefix,
				field,
				value: this.data[prefix + field]
			})
		},

		isErrorInput(value) {
			return !(this.isNumericCZ(value) || value === '')
		}
	},


}
</script>