<template>

	<PageHeader title="ŽÁDOSTI" />

	<Message type="error" v-if="error">
    {{ error }}
	</Message>

	<Loading v-if="!dataLoaded" />

  <FiltrZadosti
    :filterValues="datagridFilter"
    @updateFilter="updateDatagridFilter($event)"
  />

	<div class="small mt-4">
		Zobrazeno žádostí: <strong>{{ zadostiResult.length }}</strong>
	</div>

	<table v-if="dataLoaded" class="datagrid mt-1">
		<thead>
			<tr>
        <th class="w15">
          Stav žádosti
          <SortArrows field="stav_nazev" :current="datagridSort" @sort="sort" />
        </th>
        <th class="w10 alc">
          Dotační rok
          <SortArrows field="dotacni_rok" :current="datagridSort" @sort="sort" />
        </th>
        <th class="w60">
          Organizace
          <SortArrows field="nazev_organizace" :current="datagridSort" @sort="sort" />
        </th>
        <th class="w15 alc">
          Datum prvního<br>odeslání žádosti
          <SortArrows field="datum_prvniho_odeslani_zadosti" :current="datagridSort" @sort="sort" />
        </th>
			</tr>
		</thead>
		<tbody>
      <tr v-if="zadostiResult.length === 0">
        <td colspan="4" class="alc p-5 color-danger">Nebyly nalezeny žádné žádosti.</td>
      </tr>

			<tr
				v-for="zadost in zadostiResult"
				:key="zadost.id"
        class="datagrid__row datagrid__row--non-hoverable"
			>
				<td>{{ zadost.stav_nazev }}</td>
				<td class="alc">{{ zadost.dotacni_rok }}</td>
        <td><a @click.prevent="zobrazitZadost(zadost.id)"><strong>{{ zadost.nazev_organizace }}</strong></a></td>
				<td class="alc">{{ formatDateCZ(zadost.datum_prvniho_odeslani_zadosti) }}</td>
			</tr>
		</tbody>
	</table>


</template>

<script>
import {
	STAV_ZADOSTI,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import { formatDateCZ } from '@/utils/format.js'

import ZadostiService from '@/services/ZadostiService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

import FiltrZadosti from '@/components/Toolbar/FiltrZadosti'
import SortArrows from '@/components/DataGrid/SortArrows'

export default {
  name: 'Zadosti',

  components: {
    PageHeader,
    Message,
    Loading,
    FiltrZadosti,
    SortArrows,
  },

  data() {
    return {
      error: '',

      dataLoaded: false,
      zadosti: [],

      datagridFilter: {},
      datagridSort: {
        field: 'dotacni_rok',
        dir: 'desc',
      },
    }
  },

  computed: {
    ...authComputed,

    zadostiResult() {
      return this.zadosti.filter(zadost => {
        let result

        // zobrazujeme jen projekty, ktere nejsou rozpracovane
        result = zadost.stav !== this.STAV_ZADOSTI.PODAVANI

        // dotacni rok
        if (this.datagridFilter?.dotacniRok) {
          result = (
            result
            && zadost.dotacni_rok
            && zadost.dotacni_rok == this.datagridFilter.dotacniRok
          )
        }

        // nazev organizace
        if (this.datagridFilter?.organizace) {
          result = (
            result
            && zadost.nazev_organizace
            && zadost.nazev_organizace.toLowerCase().includes(this.datagridFilter.organizace.toLowerCase())
          )
        }

        // stav zadosti
        if (this.datagridFilter?.stav) {
          result = (
            result
            && zadost.stav_zadosti
            && zadost.stav_zadosti == this.datagridFilter.stav
          )
        }

        // vratime, zda projekt splnuje kriteria
        return result
      })
      .sort((itemA, itemB) => {
        if (!this.datagridSort.field) return 0

        const dir = this.datagridSort.dir === 'desc' ? 1 : -1

        let a = itemA[this.datagridSort.field]
        let b = itemB[this.datagridSort.field]

        if (typeof a === 'string') a = a.toLowerCase()
        if (typeof b === 'string') b = b.toLowerCase()

        if (a == b) return 0
        if (a == null || a == undefined) return dir
        if (b == null || b == undefined) return -dir

        return (
          typeof a === 'string'
          ? a.localeCompare(b) * -dir
          : a < b ? dir : -dir
        )
      })
    }
  },

  methods: {
    formatDateCZ,

    sort({field, dir}) {
      this.datagridSort.field = field
      this.datagridSort.dir = dir
 			this.saveDatagridSettings()
    },

    updateDatagridFilter(filterValues) {
      this.datagridFilter = filterValues
      this.saveDatagridSettings()
    },

		saveDatagridSettings() {
			const data = JSON.stringify({
				filter: this.datagridFilter,
				sort: this.datagridSort,
			})
			localStorage.setItem('cadros-zadosti-settings', data)
		},

		loadDatagridSettings() {
			const data = JSON.parse(localStorage.getItem('cadros-zadosti-settings'))
			this.datagridFilter = data?.filter || {}
			this.datagridSort = data?.sort || {
				field: 'dotacni_rok',
				dir: 'desc',
			}
		},

    zobrazitZadost(id) {
      this.$router.push({ name: 'ZadostAdmin', params: { id: id } })
    },

    loadData() {
      ZadostiService
      .getZadosti()
      .then(response => {
        if (response.data.success) {
          this.zadosti = response.data.data
          this.dataLoaded = true
        } else {
          this.error = response.data.error
        }
      })
      .catch(error => {
        this.error = error.message
      })
    },

  },

  created() {
    // constants
		this.STAV_ZADOSTI = STAV_ZADOSTI

    this.loadDatagridSettings()
    this.loadData()
  }
}
</script>