<template>
  <PageHeader title="Dashboard" />

  <ZmenyKeSchvaleni />
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import PageHeader from '@/components/Common/PageHeader'
import ZmenyKeSchvaleni from '@/components/Dashboard/Admin/ZmenyKeSchvaleni'

export default {
  name: 'DashboardAdmin',

  components: {
    PageHeader,
    ZmenyKeSchvaleni,
  },

  computed: {
    ...authComputed
  },

}
</script>
