import ApiClient from './ApiClient';

export default {
	getProjekty() {
		return ApiClient.get(`/projekty/`);
	},

	getProjekt(id) {
		return ApiClient.get(`/projekty/${id}`);
	},

	updateProjekt(id, data) {
		return ApiClient.put(`/projekty/${id}`, data);
	},

	createProjekt(projekt) {
		return ApiClient.post(`/projekty/`, projekt);
	},

	getProjektB1(id) {
		return ApiClient.get(`/projekty-b1/${id}`);
	},

	updateProjektB1(id, data) {
		return ApiClient.put(`/projekty-b1/${id}`, data);
	},

	getProjektB2(id) {
		return ApiClient.get(`/projekty-b2/${id}`);
	},

	updateProjektB2(id, data) {
		return ApiClient.put(`/projekty-b2/${id}`, data);
	},

	downloadVzorC(id) {
		return ApiClient.get('/projekty-c-download/' + id, {
			responseType: 'blob',
		});
	},

	uploadCastC(formData) {
		return ApiClient.post('/projekty-c-import/', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},

	deleteProjekt(id) {
		return ApiClient.delete(`/projekty/${id}`);
	},

	vratitProjekt(id, data) {
		return ApiClient.put(`/projekt-vratit/${id}`, data);
	},

	vratitVyuctovani(id, data) {
		return ApiClient.put(`/vyuctovani-vratit/${id}`, data);
	},

	vratitZaverecnaZprava(id, data) {
		return ApiClient.put(`/zaverecna-zprava-vratit/${id}`, data);
	},

	exportPDF(id) {
		return ApiClient.get(`/export-pdf-projekt/${id}`, { responseType: 'blob' });
	},

	getHodnotitele() {
		return ApiClient.get(`/hodnotitele`);
	},

	nastavitDotacniVybor(data) {
		return ApiClient.put(`/projekty-dotacni-vybor`, data);
	},

	nastavitHodnotitele(data) {
		return ApiClient.put(`/projekty-hodnotitel`, data);
	},

	getHodnoceni(koordinatorId, dotacniRok) {
		return ApiClient.get(`/hodnoceni/${koordinatorId}/${dotacniRok}`);
	},

	updateHodnoceni(koordinatorId, dotacniRok, data) {
		return ApiClient.put(`/hodnoceni/${koordinatorId}/${dotacniRok}`, data);
	},

	getHodnoceniPrehled(dotacniRok) {
		return ApiClient.get(`/hodnotitele-prehled/${dotacniRok}`);
	},

	exportHodnoceniXLS(koordinatorId, dotacniRok) {
		return ApiClient.get(`/export-hodnoceni/${koordinatorId}/${dotacniRok}`, {
			responseType: 'blob',
		});
	},

	stahnoutPrilohuZadosti(id) {
		return ApiClient.get(`/soubor/${id}`, { responseType: 'blob' });
	},

	nastavitCisloJednaci(id, data) {
		return ApiClient.put(`/cislo-jednaci/${id}`, data);
	},

	nastavitSpisovaZnacka(id, data) {
		return ApiClient.put(`/spisova-znacka/${id}`, data);
	},

	nastavitCelkovaDotace(id, data) {
		return ApiClient.put(`/celkova-dotace/${id}`, data);
	},

	nastavitStavProjektu(id, data) {
		return ApiClient.put(`/projekty-stav/${id}`, data);
	},

	getRozpocet(projektId, typ) {
		return ApiClient.get(`/projekty-rozpocty/${projektId}/${typ}`);
	},

	updateRozpocet(projektId, data) {
		return ApiClient.put(`/projekty-rozpocty/${projektId}`, data);
	},

	vratitRozpocet(rozpocetId, data) {
		return ApiClient.put(`/vraceni-rozpoctu/${rozpocetId}`, data);
	},

	exportRozpocetDocx(rozpocetId) {
		return ApiClient.get(`/export-docx-rozpocet/${rozpocetId}`, {
			responseType: 'blob',
		});
	},

	getVyuctovani(projektId) {
		return ApiClient.get(`/vyuctovani/${projektId}`);
	},

	updateVyuctovani(projektId, data) {
		return ApiClient.put(`/vyuctovani/${projektId}`, data);
	},

	downloadVzorVyuctovani(idVyuctovani) {
		return ApiClient.get('/vyuctovani-export/' + idVyuctovani, {
			responseType: 'blob',
		});
	},

	downloadVzorZaverecnaZprava(idZadosti) {
		return ApiClient.get(`/zaverecna-zprava-export/${idZadosti}`, {
			responseType: 'blob',
		});
	},

	uploadVyuctovani(formData) {
		return ApiClient.post('/vyuctovani-import/', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},

	uploadZaverecnaZprava(formData) {
		return ApiClient.post('/zaverecna-zprava-import/', formData, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		});
	},

	getZaverecnaZprava(projektId) {
		return ApiClient.get(`/zaverecna-zprava/${projektId}`);
	},

	updateZaverecnaZprava(projektId, data) {
		return ApiClient.put(`/zaverecna-zprava/${projektId}`, data);
	},
};
