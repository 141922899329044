<template>
	<div>
		<SectionHeader title="Výkaz zisků a ztrát" />

 		<Message type="warning" v-if="!disabled">
			Povinná příloha vyúčtování.
		</Message>

		<div v-if="fileId">
			<hr class="mt-3 mb-3">
			<p><strong>Přiložený soubor</strong>:</p>
			<a
				href=""
				@click.prevent="downloadFile"
			>{{ fileName }}</a>

			<hr class="mt-3 mb-3">

			<p v-if="!disabled">Můžete přiložit pouze jeden soubor. Máte-li příloh více, <strong>přikládejte je všechny zabalené do jednoho ZIP souboru</strong>.</p>
			<p v-if="!disabled">Dokud není odeslané vyúčtování, lze nahrát novou verzi souborů.</p>
		</div>

		<div class="form__field form__field--autosize mt-3">

			<input
				type="file"
				class="form__upload-input"
				name="file"
				id="file_vykaz"
				ref="file"
				:disabled="disabled"
				@change="uploadFile($event)"
			/>

			<label
				v-if="!disabled"
				class="button form__upload-button"
				for="file_vykaz"
			>
				<font-awesome-icon icon="upload" class="button__icon" />
				{{ fileId ? 'Nahradit přílohu' : 'Vybrat přílohu' }}
			</label>

			<div class="form__upload">
				<Loading v-if="uploading" title="Nahrávám soubor" class="m-0" />
				<Message v-if="error" type="error">{{error}}</Message>
			</div>

		</div>
	</div>

</template>

<script>
import { authComputed } from '@/store/helpers.js'

import UploadService from '@/services/UploadService'
import { saveAs } from 'file-saver'

import SectionHeader from '@/components/Common/SectionHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
	name: 'VyuctovaniPrilohyVykaz',

	components: {
		SectionHeader,
		Message,
		Loading,
	},

	props: {
		fileId: {
			type: String,
			default: null,
		},
		fileName: {
			type: String,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: true,
		}
	},

	emits: ['uploaded'],

	data() {
		return {
			error: '',
			uploading: false,
		}
	},

	computed: {
		...authComputed,
	},

	methods: {
		downloadFile() {
			// saveAs = function of FileSaver.js library
			UploadService
			.downloadFile(this.fileId)
			.then((response) => {
				saveAs(new Blob([response.data]), this.fileName)
			})
		},

		uploadFile() {
			const fileRef = this.$refs.file
			const file = fileRef.files[0]

			let formData = new FormData()
      formData.append('file', file)
			if (this.fileId) {
				formData.append('nahradit_id', this.fileId)
			}

			this.uploading = true
			this.error = ''


			UploadService
			.uploadFile(formData)
			.then(response => {
				console.log(response.data)
				if (response.data.success) {
					this.$emit('uploaded', response.data.data)
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				this.uploading = false
				fileRef.value = ''
			})
		},

	},

}
</script>