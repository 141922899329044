<template>
	<PageHeader title="Exporty" />

	<Message type="error" v-if="error">
		{{ error }}
	</Message>

	<Loading v-if="!dataLoaded" />

	<template v-if="dataLoaded">
		<div class="panelgrid mt-5">
			<ExportInformaceOrganizace :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
			<ExportProjektoveZadosti :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
			<ExportZaverecneZpravy :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
		</div>

		<h2 class="section__title mt-5">Hromadné exporty (ZIP soubory)</h2>

		<div class="panelgrid">
			<HromadnyExportProjekty :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
			<HromadnyExportCastiA :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
			<HromadnyExportCastiC :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
			<HromadnyExportPrilohyZadosti :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
			<HromadnyExportKonecneRozpocty :dotacniRoky="dotacniRoky" :aktualniRok="aktualniRok" />
		</div>
	</template>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import CiselnikyService from '@/services/CiselnikyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

import ExportInformaceOrganizace from '@/components/Exporty/ExportInformaceOrganizace'
import ExportProjektoveZadosti from '@/components/Exporty/ExportProjektoveZadosti'
import ExportZaverecneZpravy from '@/components/Exporty/ExportZaverecneZpravy'
import HromadnyExportProjekty from '@/components/Exporty/HromadnyExportProjekty'
import HromadnyExportCastiA from '@/components/Exporty/HromadnyExportCastiA'
import HromadnyExportCastiC from '@/components/Exporty/HromadnyExportCastiC'
import HromadnyExportPrilohyZadosti from '@/components/Exporty/HromadnyExportPrilohyZadosti'
import HromadnyExportKonecneRozpocty from '@/components/Exporty/HromadnyExportKonecneRozpocty'

export default {
	name: 'Exporty',

	components: {
		PageHeader,
		Message,
		Loading,
		ExportInformaceOrganizace,
		ExportProjektoveZadosti,
		ExportZaverecneZpravy,
		HromadnyExportProjekty,
		HromadnyExportCastiA,
		HromadnyExportCastiC,
		HromadnyExportPrilohyZadosti,
		HromadnyExportKonecneRozpocty,
	},

	data() {
		return {
			error: '',
			dataLoaded: false,
			dotacniRoky: [],
			aktualniRok: null,
		}
	},


	computed: {
		...authComputed,

	},


	methods: {

		async loadData() {
			this.dataLoaded = false

			try {
				const response = await CiselnikyService.getDotacniRoky()
				if (!response.data.success) {
					this.error = response.data.error
					return
				}
				const roky = response.data.data
				roky.sort((a, b) => b.rok - a.rok)
				this.dotacniRoky = roky.map(dotacniRok => dotacniRok.rok)
				this.aktualniRok = roky.find(rok => rok.aktivni)?.rok
			} catch(err) {
				this.error = err.message
				return
			}

			this.dataLoaded = true
		},

	},


	created() {
		this.loadData()
	},

}
</script>
