<template>
	<div
		v-if="loggedIn"
		class="current-user"
		:class="{ 'current-user--active': menuOpen }"
		@click="menuOpen = !menuOpen"

	>
		<div class="current-user__icon">{{ userInitials }}</div>
		<CurrentUserMenu
			:name="userName"
			:open="menuOpen"
		/>
	</div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import CurrentUserMenu from '@/components/Header/CurrentUserMenu'

export default {
	name: 'CurrentUser',

	components: {
		CurrentUserMenu,
	},

	data() {
		return {
			menuOpen: false,
		}
	},

	computed: {
		...authComputed
	},

}
</script>
