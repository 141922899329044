<template>
	<header class="header">
		<div class="container">
			<div class="header__content">
				<div class="header__logo">
					<router-link to="/"><img src="@/assets/images/logo.png" alt="Sekretariát Rady vlády pro koordinaci protidrogové politiky"></router-link>
				</div>
				<Menu />
				<CurrentUser />
			</div>
		</div>
	</header>
</template>

<script>
import Menu from '@/components/Menu/Menu'
import CurrentUser from '@/components/Header/CurrentUser'

export default {
	name: 'Header',

	components: {
		Menu,
		CurrentUser,
	},

}
</script>
