<template>
  <PageHeader title="Nastavení aplikace" />

  <LinkTabs :items="tabItems" />

  <router-view class="mt-5"></router-view>
</template>

<script>
import PageHeader from '@/components/Common/PageHeader.vue'
import LinkTabs from '@/components/Common/LinkTabs.vue'

export default {
  name: 'NastaveniAplikace',
  components: {
    PageHeader,
    LinkTabs,
  },
  data() {
    return {
      // First name in array is "main" name used in LinkTabs, others are siblings for activating same tab
      tabItems: [
        {
          id: 1,
          name: ['/nastaveni/dotacni-roky', '/nastaveni/dotacni-rok-novy', '/nastaveni/dotacni-rok-edit'],
          title: 'Dotační roky',
          subtitle: '',
        },
        {
          id: 3,
          name: ['/nastaveni/email'],
          title: 'E-maily',
          subtitle: '',
        },
        /*
        {
          id: 2,
          name: ['rozhodnuti'],
          title: 'Rozhodnutí',
          subtitle: '',
        }
        */
      ]
    }
  }
}
</script>