<template>

	<Modal
		v-if="modalOpened"
		:overlay="true"
	>
		<UzivatelEditFormAdmin
			v-if="modalDialog === 'edit'"
			:id="editId"
			:onSubmit="userUpdated"
			:onCancel="closeModal"
			:onPassword="changePassword"
		/>
		<UzivatelZmenaHesla
			v-if="modalDialog === 'password'"
			:id="editId"
			:onCancel="closeModal"
		/>
	</Modal>

	<PageHeader title="Přehled uživatelů">
		<button
			class="button"
			@click="editUser(null)"
		>
			<font-awesome-icon icon="plus" class="button__icon" />
			Přidat uživatele
		</button>
	</PageHeader>

	<Message type="info">
		Přehled uživatelů můžete filtrovat nebo řadit pomocí prvků v záhlaví tabulky. Najetím myši na zkrácený text v tabulce se zobrazí celý. Kliknutím kamkoliv na řádek v tabulkce se otevře dialogové okno s možností editace.
	</Message>

	<Message v-if="error" type="error">
		{{error}}
	</Message>

	<Loading v-if="!dataLoaded" />

	<FiltrUzivatele
		class="mt-4"
		:filterValues="datagridFilter"
		@updateFilter="updateDatagridFilter($event)"
	/>

	<table v-if="dataLoaded" class="datagrid mt-4">
		<thead>
			<tr>
				<th>
					Příjmení
					<SortArrows field="prijmeni" :current="datagridSort" @sort="sort" />
				</th>
				<th>
					Jméno
					<SortArrows field="jmeno" :current="datagridSort" @sort="sort" />
				</th>
				<th>
					Organizace
					<SortArrows field="organizace" :current="datagridSort" @sort="sort" />
				</th>
				<th>
					Role
					<SortArrows field="role" :current="datagridSort" @sort="sort" />
				</th>
				<th>
					Telefon
					<SortArrows field="telefon" :current="datagridSort" @sort="sort" />
				</th>
				<th>
					E-mail
					<SortArrows field="email" :current="datagridSort" @sort="sort" />
				</th>
				<th>
					Stav
					<SortArrows field="aktivni" :current="datagridSort" @sort="sort" />
				</th>
			</tr>
		</thead>
		<tbody>
			<tr v-if="uzivateleResult.length === 0">
				<td colspan="7" class="alc p-5 color-danger">Nenalezen žádný uživatel.</td>
			</tr>
			<tr
				v-for="uzivatel in uzivateleResult"
				:key="uzivatel.id"
        class="datagrid__row--non-hoverable"
			>
				<td><a @click.prevent="editUser(uzivatel.id)">{{ uzivatel.prijmeni }}</a></td>
				<td>{{ uzivatel.jmeno }}</td>
				<td>{{ uzivatel.organizace }}</td>
				<td>{{ nazevRole(uzivatel.role) }}</td>
				<td>{{ uzivatel.telefon }}</td>
				<td>{{ uzivatel.email }}</td>
				<td>{{ uzivatel.aktivni ? 'Aktivní' : 'Deaktivovaný' }}</td>
			</tr>
		</tbody>
	</table>

</template>

<script>
import UzivateleService from '@/services/UzivateleService'

import Modal from '@/components/Common/Modal'
import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import SortArrows from '@/components/DataGrid/SortArrows'
import FiltrUzivatele from '@/components/Toolbar/FiltrUzivatele'

import UzivatelEditFormAdmin from '@/components/Uzivatele/UzivatelEditFormAdmin'
import UzivatelZmenaHesla from '@/components/Uzivatele/UzivatelZmenaHesla'

export default {
	name: 'UzivateleAdmin',

	components: {
		Modal,
		PageHeader,
		Message,
		Loading,
		SortArrows,
		FiltrUzivatele,
		UzivatelEditFormAdmin,
		UzivatelZmenaHesla,
	},

	data() {
		return {
			error: '',
			uzivatele: [],
			role: [],
			uzivateleLoaded: false,
			roleLoaded: false,


			modalOpened: false,
			modalDialog: null,
			editId: null,

			datagridFilter: {},
			datagridSort: {
				field: 'prijmeni',
				dir: 'asc',
			},
		}
	},

	computed: {
		dataLoaded() {
			return this.uzivateleLoaded && this.roleLoaded
		},

		uzivateleResult() {
			return this.uzivatele.filter(uzivatel => {
				let result = true

				// jméno a příjmení
				if (this.datagridFilter?.jmeno) {
					result = (
						result
						&& (uzivatel.jmeno + uzivatel.prijmeni)
						&& (uzivatel.jmeno + ' ' + uzivatel.prijmeni).toLowerCase().includes(this.datagridFilter.jmeno.toLowerCase())
					)
				}

				// nazev organizace
				if (this.datagridFilter?.organizace) {
					result = (
						result
						&& uzivatel.organizace
						&& uzivatel.organizace.toLowerCase().includes(this.datagridFilter.organizace.toLowerCase())
					)
				}

        // telefon
        if (this.datagridFilter?.telefon) {
          result = (
            result
            && uzivatel.telefon
            && uzivatel.telefon.toLowerCase().includes(this.datagridFilter.telefon.toLowerCase())
          )
        }

        // email
        if (this.datagridFilter?.email) {
          result = (
            result
            && uzivatel.email
            && uzivatel.email.toLowerCase().includes(this.datagridFilter.email.toLowerCase())
          )
        }

				return result
			})
			.sort((itemA, itemB) => {
				if (!this.datagridSort.field) return 0

				const dir = this.datagridSort.dir === 'desc' ? 1 : -1

				let a = itemA[this.datagridSort.field]
				let b = itemB[this.datagridSort.field]

				if (typeof a === 'string') a = a.toLowerCase()
				if (typeof b === 'string') b = b.toLowerCase()

				if (a == b) return 0
				if (a == null || a == undefined) return dir
				if (b == null || b == undefined) return -dir

				return (
					typeof a === 'string'
					? a.localeCompare(b) * -dir
					: a < b ? dir : -dir
				)
			})
		}
	},

	methods: {
		sort({field, dir}) {
			this.datagridSort.field = field
			this.datagridSort.dir = dir
			this.saveDatagridSettings()
		},

		updateDatagridFilter(filterValues) {
			this.datagridFilter = filterValues
			this.saveDatagridSettings()
		},

		saveDatagridSettings() {
			const data = JSON.stringify({
				filter: this.datagridFilter,
				sort: this.datagridSort,
			})
			localStorage.setItem('cadros-uzivatele-settings', data)
		},

		loadDatagridSettings() {
			const data = JSON.parse(localStorage.getItem('cadros-uzivatele-settings'))
			this.datagridFilter = data?.filter || {}
			this.datagridSort = data?.sort || {
				field: 'prijmeni',
				dir: 'asc',
			}
		},

		closeModal() {
			this.modalOpened = false
			this.modalDialog = null
		},

		userUpdated() {
			this.closeModal()
			this.uzivatele = []
			this.uzivateleLoaded = false
			this.loadDataUzivatele()
		},

		editUser(id) {
			this.editId = id
			this.modalDialog = 'edit'
			this.modalOpened = true
		},

		changePassword() {
			this.modalDialog = 'password'
			this.modalOpened = true
		},

		nazevRole(id) {
			return this.role.find(r => r.id === id)?.role
		},

		loadDataUzivatele() {
			UzivateleService.getUzivatele()
			.then(response => {
				if (response.data.success) {
					this.uzivatele = response.data.data
					this.uzivateleLoaded = true
				} else {
					this.error += response.data.error
				}
			})
			.catch(error => {
				this.error += error.message
			})
		}
	},

	created() {
		this.loadDatagridSettings()
		this.loadDataUzivatele()

		UzivateleService
		.getRole()
		.then(response => {
			if (response.data.success) {
				this.role = response.data.data
				this.roleLoaded = true
			} else {
				this.error += response.data.error
			}
		})
		.catch(error => {
			this.error += error.message
		})
	}

}
</script>