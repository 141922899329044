<template>
	<div class="panelgrid__panel">
		<div class="panelgrid__content">
			<h2 class="panelgrid__title">Projektové žádosti</h2>
			<p>Souhrn všech informací o projektech (žádost, popis služby, rozpočty).</p>
			<Message type="error" class="my-3" v-if="error">
				{{ error }}
			</Message>
		</div>
		<div class="panelgrid__buttons">
			<select
				class="form__input form__input--small mr-2"
				v-model="dotacniRok"
			>
				<option
					v-for="rok in dotacniRoky"
					:key="rok"
				>
					{{ rok }}
				</option>
			</select>

			<button
				class="button button--outline button--small"
				:disabled="isLoading"
				@click="download"
			>
				Stáhnout XLS
			</button>
			<span v-if="isLoading" class="spinner-text ml-2"><span class="spinner mr-1"></span>Načítá se</span>
		</div>
	</div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'

import ExportyService from '@/services/ExportyService'
import { saveAs } from 'file-saver'

import Message from '@/components/Common/Message'

export default {
	name: 'ExportProjektoveZadosti',

	components: {
		Message,
	},

	props: {
		dotacniRoky: {
			type: Array,
			default: [],
		},
		aktualniRok: {
			type: [Number, String],
			default: null,
		},
	},

	data() {
		return {
			error: '',
			dotacniRok: null,
			isLoading: false,
		}
	},

	computed: {
		...authComputed,
	},

	methods: {
		async download() {
			this.isLoading = true

			try {
				const response = await ExportyService.exportProjektoveZadosti(this.dotacniRok)
				saveAs(new Blob([response.data]), `export-projektove-zadosti-${this.dotacniRok}.xlsx`)
			}
			catch (error) {
				this.error = error.message
			}

			this.isLoading = false
		},
	},

	created() {
		this.dotacniRok = this.aktualniRok
	},
}
</script>
