<template>
	<div
		v-if="options?.showAsEmpty || (status === 1 && !options.showApproved)"
		class="field-status-icon"
	></div>
	<div
		v-else
		class="field-status-icon"
		:class="`field-status-icon--${statusIcon.name}`"
		v-tippy="{ content: statusIcon.tooltip }"
	>
		<font-awesome-icon
			:icon="statusIcon.icon"
		/>
	</div>
</template>

<script>
export default {
	name: 'FieldStatusIcon',

	props: {
		status: {
			type: Number,
			default: 0,
		},
		options: {
			type: Object,
			default: {},
		},
	},

	computed: {
		statusIcon() {
			switch (this.status) {
				case 1:
					return {
						name: 'approved',
						icon: 'check',
						tooltip: 'Schválený údaj',
					}
				case 2:
					return {
						name: 'declined',
						icon: 'times',
						tooltip: 'Údaj vrácený k opravě',
					}
				default:
					return {
						name: 'none',
						icon: 'question',
						tooltip: 'Změněný údaj ke schválení',
					}
				}
		},
	},

}
</script>
