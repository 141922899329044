export const TYP_PROJEKTU = {
	SLUZBA: 1,
	IVH: 2,
}

export const STAV_PROJEKTU = {
	PODAVANI: 1,
	UZAMCENO: 2,
	VRACENO: 3,
	SCHVALENO: 4,
	ZAMITNUTO: 5,
	UPRAVA_ROZPOCTU: 6,
	VRACENI_ROZPOCTU: 7,
	KONECNY_ROZPOCET: 8,
	NEPODPOREN: 9,
	PODPOREN: 10,
	CEKA_NA_VYUCTOVANI: 11,
	VRACENI_VYUCTOVANI: 12,
	VYUCTOVANI_ODESLANO: 13
}