<template>
  <div>
    <LoginForm />
  </div>
</template>

<script>
import LoginForm from '@/components/Login/LoginForm'

export default {
  name: 'Login',

  components: {
    LoginForm,
  },

}
</script>
