<template>
	<div
		class="form__field form__field--autosize"
		:class="{
			'form__field--required': required,
			'form__field--invalid': valid === false,
		}"
	>

		<label
			v-if="label"
			:for="name"
			class="form__label"
		>{{ label }}</label>

		<input
			v-if="!disabled"
			type="file"
			class="form__upload-input"
			:name="name"
			:id="name"
			:ref="name"
			@change="uploadFile($event)"
		/>

		<label
			v-if="!disabled"
			class="button button--outline form__upload-button"
			:for="name"
		>
			<font-awesome-icon icon="upload" class="button__icon" />
			{{ !file ? buttonTextUpload : buttonTextUpdate }}
		</label>

		<div class="form__upload">

			<Loading v-if="uploading" title="Nahrávám soubor" class="m-0" />

			<Message v-if="error" type="error">{{error}}</Message>

			<template v-if="!uploading && !error">
				<a
					v-if="file"
					href=""
					@click.prevent="downloadFile"
				>{{ file.puvodni_nazev }}</a>

				<span v-if="!file && noFileText">{{ noFileText }}</span>
			</template>

		</div>

	</div>
</template>

<script>
import ApiClient from '@/services/ApiClient'
import { saveAs } from 'file-saver'

import Loading from '@/components/Common/Loading'
import Message from '@/components/Common/Message'

export default {
	name: 'BaseFileUpload',

	components: {
		Loading,
		Message,
	},

	props: {
		file: {
			type: Object,
			default: null,
		},
		uploadType: {
			type: String,
			required: true,
		},
		uploadOptions: {
			type: Object,
			default: {},
		},
		name: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		valid: {
			type: Boolean,
			default: undefined,
		},
		label: {
			type: String,
			default: null,
		},
		buttonTextUpload: {
			type: String,
			default: 'Nahrát soubor',
		},
		buttonTextUpdate: {
			type: String,
			default: 'Nahradit soubor',
		},
		noFileText: {
			type: String,
			default: null,
		}
	},

	data() {
		return {
			uploading: false,
			error: '',
		}
	},

	methods: {
		downloadFile() {
			// saveAs = function of FileSaver.js library
			ApiClient.get(
				'/soubor/' + this.file.nazev,
				{	responseType: 'blob' }, // important
			).then((response) => {
				saveAs(new Blob([response.data]), this.file.puvodni_nazev)
			})
		},

		uploadFile() {
			const file = this.$refs[this.name].files[0]

      let formData = new FormData()
      formData.append('file', file)
			formData.append('typ', this.uploadType)
			Object.keys(this.uploadOptions).forEach(key => {
				formData.append(key, this.uploadOptions[key])
			})

			this.uploading = true
			this.error = ''

      ApiClient.post('/upload',
				formData,
				{
					headers: {
						'Content-Type': 'multipart/form-data',
					}
				}
			)
			.then(response => {
				if (response.data.success) {
					this.$emit('uploaded', response.data.data)
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				this.uploading = false
				this.$refs[this.name].value = ''
			})
		},

	},

}
</script>
