<template>

  <Dialog title="Nové heslo">

    <form class="form form--size20">

      <Message
          class="mb-3"
          type="error"
          v-if="error"
      >
        {{ error }}
      </Message>

      <p>Nastavte si nové heslo do aplikace.</p>

      <div class="form__field form__field--required">
        <label for="email" class="form__label">Nové heslo</label>
        <input
            class="form__input"
            type="password"
            name="password"
            id="password"
            v-model="password"
        >
      </div>

      <div class="form__field form__field--required">
        <label for="password" class="form__label">Heslo pro kontrolu</label>
        <input
            class="form__input"
            type="password"
            name="password-repeat"
            id="password-repeat"
            v-model="passwordRepeat"
        >
      </div>

      <div class="form__buttons form__buttons--aligned">

        <button
            class="button"
            @click.prevent="setPassword"
        >
          <font-awesome-icon icon="sign-in-alt" class="button__icon" />
          Nastavit heslo
        </button>

      </div>

    </form>

  </Dialog>

</template>

<script>
import LoginService from '@/services/LoginService'

import Dialog from '@/components/Common/Dialog'
import Message from '@/components/Common/Message'
import bcrypt from "bcryptjs";

export default {
  name: 'SetPasswordForm',

  components: {
    Dialog,
    Message,
  },

  data() {
    return {
      password: '',
      passwordRepeat: '',
      error: '',
    }
  },

  methods: {
    async setPassword(){
      this.error = ''
      let password = ''

      if (this.password !== this.passwordRepeat) {
        this.error = 'Hesla musí být stejná'
        return
      }

      try {
        password = await bcrypt.hash(this.password, 10)
      } catch(error) {
        this.error = error
        return
      }

      LoginService.hesloObnova({
        heslo: password,
      }, this.$route.params.hash)
          .then(response => {
            if (response.data.success) {
              this.$router.push({ name: 'Login', query: { state: 'heslo-zmeneno'} })
            } else {
              this.error = response.data.error
            }
          })
          .catch(error => {
            this.error = error.response.message || 'Při nastavení hesla došlo k chybě'
          })
    }
  }
}
</script>
