<template>
	<div>
		<Modal
			v-if="modalOpened"
			:overlay="true"
		>
			<PrevodniTabulkaTypuSluzebDialog
				@close="closeModal"
			/>
		</Modal>

		<Message type="info" class="mb-5" v-if="message">
			{{ message }}
		</Message>

		<Message type="error" class="mb-5" v-if="error">
			{{ error }}
		</Message>

		<Loading v-if="!dataLoaded" />


		<form
			novalidate
			v-if="dataLoaded"
			@submit.prevent=""
			class="form--size30 w90"
		>

			<SectionHeader title="1. INFORMACE O ZAŘÍZENÍ REALIZUJÍCÍ SLUŽBU" />

			<h3 class="subsection-title">1.1 Adresa zařízení</h3>

			<div class="form__field">
				<label for="nazev_organizace" class="form__label">
					Název organizace
				</label>

				<BaseInput
					v-model="data.nazev_organizace"
					v-bind="fieldAttrs('nazev_organizace')"
					type="text"
					:disabled="true"
				/>
			</div>

			<div class="form__field">
				<label for="nazev_zarizeni" class="form__label">
					Název zařízení<br>
					<span class="form__label-description">(které službu/projekt realizuje)</span>
				</label>

				<BaseInput
					v-model="data.nazev_zarizeni"
					v-bind="fieldAttrs('nazev_zarizeni')"
					type="text"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="adresa" class="form__label">
          <p class="form__label-title">Adresa zařízení</p>
					<p class="form__label-description">(adresy detašovaných pracovišť uvádějte v bodě 4. této části)</p>
				</label>

				<BaseTextarea
					v-model="data.adresa"
					v-bind="fieldAttrs('adresa')"
					:rows="5"
					class="w100"
				/>
			</div>

			<div class="form__field">
				<label for="kraj" class="form__label">
          <p class="form__label-title">Kraj realizace služby/projektu</p>
          <p class="form__label-description">(Hlavní město Praha, Jihočeský kraj, Jihomoravský kraj, Karlovarský kraj, Kraj Vysočina, Královéhradecký kraj, Liberecký kraj, Moravskoslezský kraj, Olomoucký kraj, Pardubický kraj, Plzeňský kraj, Středočeský kraj, Ústecký kraj, Zlínský kraj, Celá ČR)</p>
        </label>

				<BaseSelect
					v-model="data.kraj"
					v-bind="fieldAttrs('kraj')"
					:options="kraje"
					valueKey="id"
					optionKey="kraj"
					:emptyValue="true"
					class="form__input--size30"
				/>
			</div>




			<h3 class="subsection-title mt-6">1.2 Identifikace služby/projektu</h3>

			<div class="form__field mt-2">
				<label for="kod_projektu" class="form__label">
					Kód služby/projektu
					<span class="form__label-description">(neměňte kód, který Vám byl přidělen ÚV ČR)</span>
        </label>

				<BaseInput
					v-model="data.kod_projektu"
					v-bind="fieldAttrs('kod_projektu')"
					type="text"
					class="form__input--size30"
					:disabled="true"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="nazev_projektu" class="form__label">
					Název služby/projektu
					<span class="form__label-description">(neměňte název, pokud dojde ke změně, informaci zašlete emailem na ÚV ČR)</span>
        </label>

				<BaseInput
					v-model="data.nazev_projektu"
					v-bind="fieldAttrs('nazev_projektu')"
					type="text"
					:disabled="true"
				/>
			</div>

			<h3 class="subsection-title mt-6">1.3 Identifikace služby/projektu</h3>

			<div class="form__field">
				<label class="form__label">
          <p class="form__label-title">Typ služby</p>
					<p class="form__label-description">Typy služeb podle <a target="_blank" href="https://www.vlada.cz/assets/ppov/protidrogova-politika/vyrocni-zpravy/Standardy_odborne_zpusobilosti_adi-sluzeb_2021.pdf">Standardů odborné způsobilosti adiktologických služeb</a> (nová typologie adiktologických služeb platná od listopadu 2021).</p>
          <p class="form__label-description">Vyplňujte pečlivě, tato část slouží k celkovému popisu sítě služeb v ČR.</p>

          <button
						class="button button--small button--outline"
						@click.prevent="openModal"
					>
						Převodní tabulka typologie služeb
					</button>
				</label>

				<CastB2TypologieSluzeb
					v-model="data.typ_sluzby"
					v-bind="fieldAttrs('typ_sluzby')"
					:options="typySluzby"
					valueKey="id"
					labelKey="nazev"
					:allTools="false"
					:columns="1"
				/>
			</div>


			<div class="form__field mt-4">
				<label for="datum_realizace_od" class="form__label">
					Služba realizována od
				</label>

				<BaseInput
					v-model="data.datum_realizace_od"
					v-bind="fieldAttrs('datum_realizace_od')"
					type="date"
					class="form__input--size20"
				/>
			</div>


			<div class="form__field mt-3">
				<label for="typ_registrace_socialni_sluzby" class="form__label">
					<p class="form__label-title">Typ registrace sociální služby</p>
          <p class="form__label-description">Vyplňte typ registrace nebo vyberte, že sociální služba není registrovaná.</p>
				</label>

				<div class="w100">
					<BaseInput
						v-model="data.typ_registrace_socialni_sluzby"
						v-bind="fieldAttrs('typ_registrace_socialni_sluzby')"
						type="text"
						class="w100"
						@input="switchCheckboxField('field', 'typ_registrace_socialni_sluzby', 'registrace_socialni_sluzby')"
					/>

					<BaseCheckbox
						v-model="data.registrace_socialni_sluzby"
						v-bind="fieldAttrs('registrace_socialni_sluzby')"
						label="není registrovaná"
						class="mt-2"
						@input="switchCheckboxField('checkbox', 'typ_registrace_socialni_sluzby', 'registrace_socialni_sluzby')"
					/>
				</div>
			</div>


			<div class="form__field mt-3">
				<label for="typ_registrace_zdravotni_sluzby" class="form__label">
					<p class="form__label-title">Typ registrace zdravotní služby</p>
          <p class="form__label-description">Vyplňte typ registrace nebo vyberte, že zdravotní služba není registrovaná.</p>
				</label>

				<div class="w100">
					<BaseInput
						v-model="data.typ_registrace_zdravotni_sluzby"
						v-bind="fieldAttrs('typ_registrace_zdravotni_sluzby')"
						type="text"
						class="w100"
						@input="switchCheckboxField('field', 'typ_registrace_zdravotni_sluzby', 'registrace_zdravotni_sluzby')"
					/>

					<BaseCheckbox
						v-model="data.registrace_zdravotni_sluzby"
						v-bind="fieldAttrs('registrace_zdravotni_sluzby')"
						label="není registrovaná"
						class="mt-2"
						@input="switchCheckboxField('checkbox', 'typ_registrace_zdravotni_sluzby', 'registrace_zdravotni_sluzby')"
					/>
				</div>
			</div>


			<div class="form__field mt-3">
				<label class="form__label">
					<p class="form__label-title">Forma poskytování služby</p>
          <p class="form__label-description">Nevyplňujte všechny možné formy poskytování vaší služby, ale převažující formu (nejvíce charakteristickou pro danou službu). Je možné zaškrtnout více možností, typicky např. služba následné péče (ambulantní i pobytová forma), sloučené programy kontaktního centra a terénních programů (terénní i ambulantní forma).</p>
          <p class="form__label-description">Tato část slouží pro základní rozlišení služeb, která slouží i pro jiné účely než dotační řízení. Výběr z možností nesouvisí s prioritizací služby v rámci dotačního řízení.</p>
					<p class="form__label-description">* ICT = poskytování služeb výhradně prostřednictvím informačních a komunikačních technologii</p>
				</label>

				<div class="w100">
          <Message type="error" class="mb-5" v-if="!schema.forma_terenni.valid">
            Vyberte alespoň jednu možnost
          </Message>

					<BaseCheckbox
						v-model="data.forma_terenni"
						v-bind="fieldAttrs('forma_terenni')"
						label="terénní (např. terénní programy)"
						class="mt-2"
					/>

					<BaseCheckbox
						v-model="data.forma_ambulantni"
						v-bind="fieldAttrs('forma_ambulantni')"
						label="ambulantní (např. kontaktní centra, ambulatní následná péče, ambulantní léčba)"
						class="mt-2"
					/>

					<BaseCheckbox
						v-model="data.forma_pobytova"
						v-bind="fieldAttrs('forma_pobytova')"
						label="pobytová (např. terapeutické komunity, následná péče s chráněným bydlením)"
						class="mt-2"
					/>

					<BaseCheckbox
						v-model="data.forma_ict"
						v-bind="fieldAttrs('forma_ict')"
						label="ICT * (např. specializované služby, které probíhají výlučně v prostředí informačních a komunikačních technologií)"
						class="mt-2"
					/>
				</div>
			</div>


      <div class="form__field mt-4">
        <label for="forma_upresneni" class="form__label">
          <p class="form__label-title">Upřesnění/komentář k formě poskytování služby</p>
          <p class="form__label-description">Popište další formy poskytování služby, v případě, že s klienty pracujete i jiným způsobem, než je uvedeno výše (tj. jiná než převažující forma poskytování služby).</p>
        </label>

        <BaseTextarea
            v-model="data.forma_upresneni"
            v-bind="fieldAttrs('forma_upresneni')"
            :rows="8"
            class="w100"
        />
      </div>


			<h3 class="subsection-title mt-6">1.4 Vedoucí služby</h3>

			<div class="form__field">
				<label for="osoba_jmeno" class="form__label">
					Jméno, příjmení, titul
				</label>

				<BaseInput
					v-model="data.osoba_jmeno"
					v-bind="fieldAttrs('osoba_jmeno')"
					type="text"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="osoba_funkce" class="form__label">
					Funkce
				</label>

				<BaseInput
					v-model="data.osoba_funkce"
					v-bind="fieldAttrs('osoba_funkce')"
					type="text"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="osoba_telefon" class="form__label">
					Telefon
				</label>

				<BaseInput
					v-model="data.osoba_telefon"
					v-bind="fieldAttrs('osoba_telefon')"
					type="text"
				/>
			</div>


			<div class="form__field mt-2">
				<label for="osoba_email" class="form__label">
					E-mail
				</label>

				<BaseInput
					v-model="data.osoba_email"
					v-bind="fieldAttrs('osoba_email')"
					type="text"
				/>
			</div>



			<SectionHeader title="2. CÍLOVÁ SKUPINA" class="mt-6" />

			<div class="form__field">
				<label for="id_cilovka_uzivatele" class="form__label">
          <p class="form__label-title">Převažující cílová skupina služby</p>
					<p class="form__label-description">Uvádějte pouze převažující cílovou skupinu, tj. neuvádějte všechny cílové skupiny, kterým můžete poskytovat službu, ale pouze cílovou skupinu, se kterou převážně pracujete.
            V případě, že máte např. dvě převažující cílové skupiny, uveďte obě. Pokud klienty vaší služby tvoří různé skupiny klientů, které jsou rovnoměrně zastoupeny (typicky např. služba ambulantní léčby), můžete uvést možnost „bez převažující cílové skupiny“.
            Cílová skupina „jiné bez závislostního problému“ je typická pro adiktologické preventivní služby a programy zaměřené na osoby blízké.</p>
          <p class="form__label-description">Tato část slouží pro orientaci v síti služeb a převážně pro jiné účely než dotační řízení. Výběr z možností nesouvisí s prioritizací služby v rámci dotačního řízení.</p>
				</label>

				<!-- <BaseSelect
					v-model="data.id_cilovka_uzivatele"
					v-bind="fieldAttrs('id_cilovka_uzivatele')"
					:options="cilovkyUzivatelu"
					valueKey="id"
					optionKey="nazev"
					:emptyValue="true"
				/> -->

        <div>
          <Message type="error" class="mb-5" v-if="!schema.id_cilovka_uzivatele.valid">
            Vyberte alespoň jednu možnost
          </Message>

          <BaseCheckboxArray
              v-model="data.id_cilovka_uzivatele"
              v-bind="fieldAttrs('id_cilovka_uzivatele')"
              :options="cilovkyUzivatelu"
              valueKey="id"
              labelKey="nazev"
              :allTools="false"
              :columns="1"
          />
        </div>
			</div>

			<div class="form__field mt-2">
				<label for="cilovka_uzivatele_upresneni" class="form__label">
          <p class="form__label-title">Upřesnění/komentář k cílové skupině</p>
					<p class="form__label-description">Prostor pro bližší vysvětlení a uvedení dalších cílových skupin. V případě, že pracujete i s dalšími skupinami klientů, než je uvedeno výše, popište tyto cílové skupiny.</p>
				</label>

				<BaseTextarea
					v-model="data.cilovka_uzivatele_upresneni"
					v-bind="fieldAttrs('cilovka_uzivatele_upresneni')"
					:rows="5"
					class="w100"
				/>
			</div>


			<div class="form__field mt-4">
				<label class="form__label">
          <p class="form__label-title">Poskytujete v rámci služby specializovaný program  pro některou z těchto cílových skupin?</p>
					<p class="form__label-description">Tato část slouží pro rychlou orientaci v síti služeb a převážně pro jiné účely než dotační řízení. Výběr z možností nesouvisí s prioritizací služby v rámci dotačního řízení.</p>
					<p class="form__label-description">Termín SPECIALIZOVANÝ PROGRAM označuje činnosti, které jsou odděleny personálně, časově, místně a/nebo metodami pro práci s touto cílovou skupinou. Pokud je celá služba specializovaná na specifickou cílovou skupinu, je považována za specializovaný program. <strong>Většina adiktologických služeb v ČR nemá specializovaný program.</strong></p>
				</label>

        <div>
          <Message type="error" class="mb-5" v-if="!schema.cilovka_program.valid">
            Vyberte alespoň jednu možnost
          </Message>

          <BaseCheckboxArray
              v-model="data.cilovka_program"
              v-bind="fieldAttrs('cilovka_program')"
              :options="cilovkyProgramu"
              valueKey="id"
              labelKey="nazev"
              :allTools="false"
              :columns="1"
          />
        </div>
			</div>

			<div class="form__field mt-4">
				<label for="cilovka_program_upresneni" class="form__label">
          <p class="form__label-title">Upřesnění/komentář ke specializovaným programům</p>
          <p class="form__label-description">Pokud služba nemá specializovaný program, ale na práci s některou z výše uvedených cílových skupin se více zaměřuje, prosím uveďte bližší informace.</p>
          <p class="form__label-description">Pokud služba má specializovaný program, uveďte bližší informace, zejména o tom, zda je celá služba specializovaný program (např. ambulance pro děti a mladistvé), nebo jde pouze o část služby (např. v rámci standardní služby se jeden pracovník věnuje specifické cílové skupině, několik lůžek v rámci služby vyčleněno pro specifickou cílovou skupinu).</p>
        </label>

				<BaseTextarea
					v-model="data.cilovka_program_upresneni"
					v-bind="fieldAttrs('cilovka_program_upresneni')"
					:rows="5"
					class="w100"
				/>
			</div>




			<SectionHeader title="3. PROGRAM PRIMÁRNÍ PREVENCE" class="mt-6" />

			<div class="form__field">
				<label for="primarni_prevence" class="form__label">
          Adiktologická preventivní služba (prevence)
        </label>

				<BaseCheckbox
					v-model="data.primarni_prevence"
					v-bind="fieldAttrs('primarni_prevence')"
				/>
			</div>


			<div class="form__field" v-if="data.primarni_prevence">
				<label class="form__label">
					Typ a témata poskytovaných programů primární prevence
				</label>

        <div>
          <Message type="error" class="mb-5" v-if="!schema.typy_primarni_prevence.valid">
            Vyberte alespoň jednu možnost
          </Message>

          <CastB2PrimarniPrevence
              v-model="data.typy_primarni_prevence"
              :disabled="jeProjektUzamcen"
          />
        </div>
			</div>


			<div class="form__field mt-4" v-if="data.primarni_prevence">
				<label for="primarni_prevence_upresneni" class="form__label">
					Upřesnění/komentář k programu primární prevence
				</label>

				<BaseTextarea
					v-model="data.primarni_prevence_upresneni"
					v-bind="fieldAttrs('primarni_prevence_upresneni')"
					:rows="5"
					class="w100"
				/>
			</div>





			<SectionHeader title="4. ZÁZEMÍ A MÍSTA REALIZACE SLUŽBY" class="mt-6" />

			<div class="form__field">
				<label for="provozni_zazemi" class="form__label">
					Stručný popis provozního zázemí služby
					<p class="form__label-description">Popište stručně prostorové řešení služby, uveďte, zda jsou např. vyhrazeny prostory pro práci s klienty, pro výměnu injekčního materiálu a základní vybavení služby.</p>
				</label>

				<BaseTextarea
					v-model="data.provozni_zazemi"
					v-bind="fieldAttrs('provozni_zazemi')"
					:rows="5"
					class="w100"
				/>
			</div>

			<div class="form__field mt-4">
				<label for="adresy_pracovist" class="form__label">
					Adresy detašovaných pracovišť (jsou-li)
				</label>

				<BaseTextarea
					v-model="data.adresy_pracovist"
					v-bind="fieldAttrs('adresy_pracovist')"
					:rows="5"
					class="w100"
				/>
			</div>

			<div class="form__field mt-4">
				<label for="mista_realizace_sluzby" class="form__label">
					Místa realizace služby
					<p class="form__label-description">
            Relevantní pro adiktologické služby minimalizace rizik poskytované terénní formou (kam služba zajíždí), adiktologické služby ambulantní léčby a poradenství se specifickým programem pro osoby ve vazbě / výkonu trestu odnětí svobody / po výkonu trestu odnětí svobody (ve kterých věznicích působí), adiktologické preventivní služby (kde realizují programy).
          </p>
				</label>

				<BaseTextarea
					v-model="data.mista_realizace_sluzby"
					v-bind="fieldAttrs('mista_realizace_sluzby')"
					:rows="5"
					class="w100"
				/>
			</div>


			<SectionHeader title="5. PROVOZNÍ DOBA REALIZACE SLUŽBY" class="mt-6" />

			<p>
        Uveďte, které dny a ve kterých hodinách je služba poskytovaná klientům. Pokud je služba poskytována současně na několika místech – např. adiktologické služby minimalizace rizik poskytované terénní formou (terénní programy) – využijte možnosti nakopírovat tabulku pro každé místo poskytování zvlášť. Informace slouží k přehledu o službách.
      </p>
			<p><em>(nerelevantní pro adiktologické preventivní služby)</em></p>


			<div class="form__field mt-5">
				<label for="pobytova_forma_sluzby" class="form__label">
					Pobytová forma služby
				</label>

				<BaseCheckbox
					v-model="data.pobytova_forma_sluzby"
					v-bind="fieldAttrs('pobytova_forma_sluzby')"
					label="Jde o pobytovou formu poskytování služby. Služba je poskytována nepřetržitě."
					class="mt-2"
				/>
			</div>

			<div class="form__field mt-2" v-if="data.pobytova_forma_sluzby">
				<label for="pocet_luzek" class="form__label">
					Kapacita programu
					<span class="form__label-description">(počet lůžek)</span>
				</label>

				<BaseInputNumber
					v-model="data.pocet_luzek"
					v-bind="fieldAttrs('pocet_luzek')"
					type="number"
          min="0"
					class="form__input--size20"
				/>
			</div>


			<div class="form__field mt-5">
				<label class="form__label">
					Ambulantní, terénní, ICT forma služby
				</label>

				<CastB2ProvozniDoba
					v-model="data.provozni_doba"
					:disabled="jeProjektUzamcen"
				/>
			</div>

			<SectionHeader title="6. POPIS SLUŽBY" class="mt-6" />

			<div class="form__field">
				<label for="popis" class="form__label">
					<p class="form__label-title">Základní popis služby/projektu</p>
					<p class="form__label-description">Stručně charakterizujte službu/projekt.</p>
				</label>

				<BaseTextarea
					v-model="data.popis"
					v-bind="fieldAttrs('popis')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div class="form__field mt-4">
				<label for="testovani_infekcni_nemoci" class="form__label">
					<p class="form__label-title">Testování na infekční onemocnění (např. HIV, VHC, VHB, syfilis)</p>
					<p class="form__label-description">Uveďte, zda služba poskytuje testování na infekční onemocnění (např. HIV, VHC, VHB, syfilis, tuberkulóza). Pokud ano, stručně uveďte, jakým způsobem je testování poskytováno, a na jaká onemocnění je testování zajištěno. Rovněž uveďte, zda poskytujete diagnostiku a návaznou léčbu a komplexní péči.</p>
				</label>

				<BaseTextarea
					v-model="data.testovani_infekcni_nemoci"
					v-bind="fieldAttrs('testovani_infekcni_nemoci')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div class="form__field mt-4">
				<label for="specifika" class="form__label">
					<p class="form__label-title">Specifika, popište čím je služba/projekt specifický</p>
					<p class="form__label-description">V případě, že jste <strong>certifikovanou</strong> službou, <strong>nepopisujte</strong> činnosti, které jsou součástí daného typu služby např. dle Seznamu a definic výkonů adiktologických služeb, nebo dle Standardů odborné způsobilosti adiktologických služeb – zaměřte se hlavně na specifika služby. Prosím neuvádějte a nepopisujte základní intervence typické pro Váš typ služby (např. popis procesu distribuce zdravotnického materiálu).</p>
				</label>

				<BaseTextarea
					v-model="data.specifika"
					v-bind="fieldAttrs('specifika')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div class="form__field mt-4">
				<label for="odborne_zajisteni" class="form__label">
					<p class="form__label-title">Odborné zajištění služby</p>
					<p class="form__label-description">Stručná informace o odborném zajištění služby a supervize. Zejména u nových služeb v dotačním řízení ÚV ČR uveďte stručný popis odborného zajištění služby, tj. odbornou praxi jednotlivých členů týmu.</p>
				</label>

				<BaseTextarea
					v-model="data.odborne_zajisteni"
					v-bind="fieldAttrs('odborne_zajisteni')"
					:rows="8"
					class="w100"
				/>
			</div>

			<SectionHeader title="7. ZMĚNY OPROTI PŘEDCHOZÍMU ROKU" class="mt-6" />

			<div class="form__field">
				<label for="oduvodneni_zmen" class="form__label">
          <p class="form__label-title">Odůvodnění (vysvětlení) změn služby</p>
          <p class="form__label-description">Uveďte konkrétní uskutečněné změny oproti stavu a informacím uvedeným v Části "Charakteristika a popis služby" (dříve Část "Žádost o dotaci / B2") v předchozím roce.</p>
				</label>

				<BaseTextarea
					v-model="data.oduvodneni_zmen"
					v-bind="fieldAttrs('oduvodneni_zmen')"
					:rows="8"
					class="w100"
				/>
			</div>


			<template v-if="!jeProjektUzamcen">
				<hr class="form__divider">

				<Loading title="Ukládám data&hellip;" v-if="isSaving" />

        <Message type="error" class="mb-5" v-if="error">
          {{ error }}
        </Message>

				<div class="form__buttons">
					<button
						class="button"
						:disabled="isSaving"
						@click.prevent="saveData"
					>
						<font-awesome-icon icon="save" class="button__icon" />
						Uložit formulář
					</button>
				</div>
			</template>

		</form>
	</div>
</template>

<script>
import { validateFields } from '@/utils/validator'
import {
	TYP_PROJEKTU,
	STAV_PROJEKTU,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'
import CiselnikyService from '@/services/CiselnikyService'

import Modal from '@/components/Common/Modal.vue'
import Message from '@/components/Common/Message.vue'
import Loading from '@/components/Common/Loading.vue'
import SectionHeader from '@/components/Common/SectionHeader.vue'
import BaseInput from '@/components/Forms/Base/BaseInput.vue'
import BaseInputNumber from '@/components/Forms/Base/BaseInputNumber.vue'
import BaseTextarea from '@/components/Forms/Base/BaseTextarea.vue'
import BaseSelect from '@/components/Forms/Base/BaseSelect.vue'
import BaseCheckbox from '@/components/Forms/Base/BaseCheckbox.vue'
import BaseCheckboxArray from '@/components/Forms/Base/BaseCheckboxArray.vue'

import CastB2PrimarniPrevence from '@/components/Projekty/CastB/2024/CastB2PrimarniPrevence.vue'
import CastB2ProvozniDoba from '@/components/Projekty/CastB/2024/CastB2ProvozniDoba.vue'
import CastB2TypologieSluzeb from '@/components/Projekty/CastB/2024/CastB2TypologieSluzeb.vue'
import PrevodniTabulkaTypuSluzebDialog from '@/components/Projekty/Dialog/PrevodniTabulkaTypuSluzebDialog.vue'

export default {
	name: 'CastB1',

	components: {
		Modal,
		Message,
		Loading,
		SectionHeader,
		BaseInput,
    BaseInputNumber,
		BaseTextarea,
		BaseSelect,
		BaseCheckbox,
		BaseCheckboxArray,
		CastB2PrimarniPrevence,
		CastB2ProvozniDoba,
		CastB2TypologieSluzeb,
		PrevodniTabulkaTypuSluzebDialog
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			error: '',
			message: '',
			isSaving: false,
			//dataLoaded: false,

			modalOpened: false,

			data: {},
			formLoaded: false,

			kraje: [],
			krajeLoaded: false,
			typySluzby: [],
			typySluzbyLoaded: false,
			cilovkyUzivatelu: [],
			cilovkyUzivateluLoaded: false,
			cilovkyProgramu: [],
			cilovkyProgramuLoaded: false,

			formChanged: false,
			schema: {
				kod_projektu: { required: true, },
				nazev_projektu: { required: true, },
        forma_upresneni: { required: true, },

				nazev_organizace: { required: true, },
				nazev_zarizeni: { required: true, },
				adresa: { required: true, },
				kraj: { required: true, },
				datum_realizace_od: { required: true, },
				typ_sluzby: { required: true, skip_validation_for_ids: [26,27] },
				registrace_socialni_sluzby: { required: true, },
				typ_registrace_socialni_sluzby: { required: false, },
				registrace_zdravotni_sluzby: { required: true, },
				typ_registrace_zdravotni_sluzby: { required: false, },

				forma_terenni: { required: true, valid: true, group_validation: ['forma_terenni', 'forma_ambulantni', 'forma_pobytova', 'forma_ict']},
				forma_ambulantni: { required: true, valid: true, group_validation: ['forma_terenni', 'forma_ambulantni', 'forma_pobytova', 'forma_ict']},
				forma_pobytova: { required: true, valid: true, group_validation: ['forma_terenni', 'forma_ambulantni', 'forma_pobytova', 'forma_ict']},
				forma_ict: { required: true, valid: true, group_validation: ['forma_terenni', 'forma_ambulantni', 'forma_pobytova', 'forma_ict']},

				osoba_jmeno: { required: true, },
				osoba_funkce: { required: true, },
				osoba_telefon: { required: true, },
				osoba_email: { required: true, },
				id_cilovka_uzivatele: { required: true, valid: true},
				cilovka_uzivatele_upresneni: { required: true, },
				cilovka_program: { required: true, valid: true},
				cilovka_program_upresneni: { required: true, },

        primarni_prevence: { required: true, },
				typy_primarni_prevence: { required: false, valid: true},
				primarni_prevence_upresneni: { required: false, },

				provozni_zazemi: { required: true, },
				adresy_pracovist: { required: true, },
				mista_realizace_sluzby: { required: true, },

				pobytova_forma_sluzby: { required: true, },
				pocet_luzek: { required: false, },

				provozni_doba: { required: false, },
				provozni_doba_upresneni: { required: false, },

				popis: { required: true, },
				testovani_infekcni_nemoci: { required: true, },
				specifika: { required: true, },
				odborne_zajisteni: { required: true, },
				oduvodneni_zmen: { required: true, },
			},
		}
	},

	computed: {
		...authComputed,

		jeMoznePodatZadost() {
			// UPRAVIT UPRAVIT UPRAVIT !!!
			// return Date.now() < Date.parse('2021-11-11')
			return true
		},

		jeProjektUzamcen() {
			return (
				this.projekt.stav === STAV_PROJEKTU.UZAMCENO
				|| this.projekt.stav === STAV_PROJEKTU.SCHVALENO
				|| this.projekt.stav === STAV_PROJEKTU.ZAMITNUTO
				|| this.isGov
				|| !this.jeMoznePodatZadost
			)
		},

		dataLoaded() {
			return (
				this.formLoaded
				&& this.krajeLoaded
				&& this.typySluzbyLoaded
				&& this.cilovkyUzivateluLoaded
				&& this.cilovkyProgramuLoaded
			)
		},
	},

	methods: {
    validateFields,

		openModal() {
			this.modalOpened = true
		},

		closeModal() {
			this.modalOpened = false
		},

		saveData() {
			this.error = ''
			this.message = ''

      if (!this.validateFields(this.data, this.schema)) {
        this.error = 'Zkontrolujte povinná pole'
        return
      }

			this.isSaving = true

			ProjektyService
			.updateProjektB2(this.projekt.id, this.data)
			.then(response => {
				if (response.data.success) {
					this.message = "Formulář byl v pořádku uložen"
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				// vyscrollujeme nahoru na strance, aby byla videt chyba nebo message
				this.isSaving = false
				window.scrollTo(0, 0)
			})
		},

		switchCheckboxField(type, fieldName, checkboxName) {
			if (type === 'field') {
				// psaní do pole = vypnout checkbox
				this.data[checkboxName] = false
			}

			if (type === 'checkbox' && this.data[checkboxName]) {
				// kliklo se na checkbox a ten je zapnuty = vymazeme obsah pole
				this.data[fieldName] = ''
			}
		},

		fieldChanged(event) {
			// const field = event.target.name
			// this.schema[field].valid = this.isValid(field)
			this.formChanged = true
		},

		fieldAttrs(name) {
			return {
				name: name,
				required: this.schema[name].required,
				valid: this.schema[name]?.valid,
				disabled: this.jeProjektUzamcen,
				placeholder: this.schema[name]?.placeholder,
				onInput: this.fieldChanged,
			}
		},

		loadData() {
			ProjektyService
			.getProjektB2(this.projekt.id)
			.then(response => {
				if (response.data.success) {
					this.data = response.data.data

					// !!!!!!!!!!!
					// !!!!!!!!!!!
					this.data.kod_projektu = this.projekt.kod
					this.data.nazev_projektu = this.projekt.nazev
					this.data.nazev_organizace = this.userOrg.organizace
					// !!!!!!!!!!!
					// !!!!!!!!!!!

					this.formLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

		loadKraje() {
			CiselnikyService.getKraje()
			.then(response => {
				if (response.data.success) {
					this.kraje = response.data.data
					this.krajeLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

		loadTypySluzby() {
			CiselnikyService.getTypySluzby()
			.then(response => {
				if (response.data.success) {
					this.typySluzby = response.data.data
					.filter(item => item.dotacni_rok === this.projekt.dotacni_rok) // vyfiltrujeme typy sluzeb pro konretni rok
					.map(item => ({
						id: item.id,
						nazev: item.zkratka ? item.zkratka + ' - ' + item.nazev : item.nazev,
						rodic: item.rodic
					}))
					this.typySluzbyLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

		loadCilovkyUzivatelu() {
			CiselnikyService.getCilovkyUzivatelu()
			.then(response => {
				if (response.data.success) {
					this.cilovkyUzivatelu = response.data.data
					this.cilovkyUzivateluLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

		loadCilovkyProgramu() {
			CiselnikyService.getCilovkyProgramu()
			.then(response => {
				if (response.data.success) {
					this.cilovkyProgramu = response.data.data
					this.cilovkyProgramuLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

	},

	created() {
		// constants
		this.TYP_PROJEKTU = TYP_PROJEKTU
		this.STAV_PROJEKTU = STAV_PROJEKTU

		this.loadData()
		this.loadKraje()
		this.loadTypySluzby()
		this.loadCilovkyUzivatelu()
		this.loadCilovkyProgramu()
	}

}
</script>