<template>
	<input
		class="form__input form__input--smallest"
		:type="type"
		:disabled="disabled"
		:value="value"
		@input="$emit('update', $event.target.value)"
	>
</template>

<script>

export default {
	name: 'SimpleInput',

	props: {
		value: {
			type: [Number, String],
			default: '',
		},
		type: {
			type: String,
			default: 'text',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},

}
</script>
