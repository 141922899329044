<template>
	<div class="panelgrid__panel">
		<div class="panelgrid__content">
			<h2 class="panelgrid__title">Části A</h2>
			<p>ZIP obsahující části A všech projektů ve vybraném dotačním roce.</p>
		</div>
		<div class="panelgrid__buttons">
			<select
				class="form__input form__input--small mr-2"
				v-model="dotacniRok"
			>
				<option
					v-for="rok in dotacniRoky"
					:key="rok"
				>
					{{ rok }}
				</option>
			</select>

			<button
				class="button button--outline button--small"
				:disabled="isLoading"
				@click="download"
			>
				Stáhnout ZIP
			</button>
			<span v-if="isLoading" class="spinner-text ml-2"><span class="spinner mr-1"></span>Načítá se</span>
			<!-- <a
				class="button button--outline button--small"
				:href="`https://api.dotace-protidrogovapolitika.cz/export-files/export-casti-A-${dotacniRok}.zip`"
			>
				Stáhnout ZIP
			</a> -->
		</div>
	</div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'

import ExportyService from '@/services/ExportyService'
import { saveAs } from 'file-saver'

import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
	name: 'HromadnyExportCastiA',

	components: {
		Message,
		Loading,
	},

	props: {
		dotacniRoky: {
			type: Array,
			default: [],
		},
		aktualniRok: {
			type: [Number, String],
			default: null,
		},
	},

	data() {
		return {
			error: '',
			isLoading: false,
			dotacniRok: null,
		}
	},

	computed: {
		...authComputed,
	},

	methods: {
		async download() {
			this.isLoading = true

			try {
				const response = await ExportyService.exportZipCastiA(this.dotacniRok)
				saveAs(new Blob([response.data]), `export-casti-A-${this.dotacniRok}.zip`)
			}
			catch(error) {
				this.error = error.message
			}

			this.isLoading = false
		},
	},


	created() {
		this.dotacniRok = this.aktualniRok
	},
}
</script>
