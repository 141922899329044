<template>
  <div>
    <SectionHeader title="Obsah a průběh projektu" />

    <template v-if="projekt.typ !== 2">
      <label class="form__label">
        <p class="form__label-title">Vyplňují služby</p>
        <p class="form__label-description">jejíchž předmětem je přímé poskytování služeb klientům, včetně poskytování služeb prostřednictvím nových technologii</p>
      </label>

      <div class="form__field form__field--full mt-4">
        <label for="vyvoj_sluzby" class="form__label">
          <p class="form__label-title">Vývoj služby a dosažení plánovaných změn/rozvoje služby</p>
          <p class="form__label-description">Popište vývoj služby a změny oproti popisu uvedenému v části Charakteristika a popis služby. <br>Uveďte, zda se vám podařila plánovaná změna/rozvoj služby, který jste popisovali v Žádosti (viz část Předmět žádosti).</p>
        </label>

        <BaseTextarea name="vyvoj_sluzby" v-model="data.vyvoj_sluzby" :rows="8" class="w100" />
      </div>

      <div class="form__field form__field--full mt-4">
        <label for="vyvoj_cilovky" class="form__label">
          <p class="form__label-title">Vývoj/změny cílové skupiny</p>
          <p class="form__label-description">Popište případný vývoj v cílové skupině oproti stavu uvedenému v části Charakteristika a popis služby.</p>
        </label>

        <BaseTextarea name="vyvoj_cilovky" v-model="data.vyvoj_cilovky" :rows="8" class="w100" />
      </div>

      <div class="form__field form__field--full mt-4">
        <label for="kriticke_zhodnoceni" class="form__label">
          <p class="form__label-title">Kritické zhodnocení úspěšnosti služby</p>
        </label>

        <BaseTextarea name="kriticke_zhodnoceni" v-model="data.kriticke_zhodnoceni" :rows="8" class="w100" />
      </div>

      <div class="form__field form__field--full mt-4">
        <label for="komentar2" class="form__label">
          <p class="form__label-title">Jakýkoliv jiný komentář</p>
          <p class="form__label-description">(není nutné vyplnit)</p>
        </label>

        <BaseTextarea name="komentar2" v-model="data.komentar2" :rows="8" class="w100" />
      </div>
    </template>


    <!-- IVH -->
    <template v-else>
      <label class="form__label">
        <p class="form__label-title">Vyplňují projekty (IVH)</p>
        <p class="form__label-description">jejichž předmětem je získání informací formou studií, výzkumu, analýz, šíření informací, vzdělávání, konference, nebo vývoj nového produktu, atd.</p>
      </label>

      <div class="form__field form__field--full mt-4">
        <label for="harmonogram_projektu" class="form__label">
          <p class="form__label-title">Harmonogram projektu</p>
          <p class="form__label-description">Komentujte dodržení harmonogramu projektu</p>
        </label>

        <BaseTextarea name="harmonogram_projektu" v-model="data.harmonogram_projektu" :rows="8" class="w100" />
      </div>

      <div class="form__field form__field--full mt-4">
        <label for="dosazeni_vystupu" class="form__label">
          <p class="form__label-title">Dosažení výstupů</p>
          <p class="form__label-description">Popište, jak se podařilo dosáhnout výstupů projektu, které jste uvedli v části Charakteristika a popis projektu. Dosažené výstupy popište. Vyjádřete se ke všem výstupům, které jste v této části uvedli, berte přitom v potaz informace, které jste uvedli v komentáři ke Konečné podobě rozpočtu dotace.</p>
        </label>

        <BaseTextarea name="dosazeni_vystupu" v-model="data.dosazeni_vystupu" :rows="8" class="w100" />
      </div>

      <div class="form__field form__field--full mt-4">
        <label for="dostupnost_vystupu" class="form__label">
          <p class="form__label-title">Dostupnost výstupů</p>
          <p class="form__label-description">Jak je/bude s výstupy projektu naloženo dále? <br>Pokud je to relevantní, uveďte, kde jsou/budou zveřejněny, jak a komu byly/budou distribuovány.</p>
        </label>

        <BaseTextarea name="dostupnost_vystupu" v-model="data.dostupnost_vystupu" :rows="8" class="w100" />
      </div>

      <div class="form__field form__field--full mt-4">
        <label for="kriticke_zhodnoceni" class="form__label">
          <p class="form__label-title">Kritické zhodnocení úspěšnosti projektu</p>
        </label>

        <BaseTextarea name="kriticke_zhodnoceni" v-model="data.kriticke_zhodnoceni" :rows="8" class="w100" />
      </div>

      <div class="form__field form__field--full mt-4">
        <label for="komentar2" class="form__label">
          <p class="form__label-title">Jakýkoliv jiný komentář</p>
          <p class="form__label-description">(není nutné vyplnit)</p>
        </label>

        <BaseTextarea name="komentar2" v-model="data.komentar2" :rows="8" class="w100" />
      </div>
    </template>

  </div>
</template>

<script>
import SectionHeader from '@/components/Common/SectionHeader'
import BaseInput from '@/components/Forms/Base/BaseInput'
import TableText from "@/components/Table/TableText.vue"
import TableInput from "@/components/Table/TableInputNumber.vue"
import BaseTextarea from "@/components/Forms/Base/BaseTextarea.vue";

export default {
  name: 'ZaverecnaZpravaObsah',
  components: {
    BaseTextarea,
    SectionHeader,
    BaseInput,
    TableText,
    TableInput,
  },
  props: {
    projekt: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isDiabled: true,
      dataset: {},
    }
  }
}
</script>

<style>
.form__field--full .form__label {
  flex: 0 0 30%;
}
</style>