import { mapGetters } from 'vuex'

export const authComputed = {
	...mapGetters([
		'loggedIn',
		'userId',
		'userName',
		'userInitials',
		'userOrg',
		'userRole',
		'isGov',
		'isOrg',
		'isSuperAdmin',
		'isAdmin',
		'isKontrolor',
		'isStatutar',
		'isClenDotacnihoVyboru',
		'isKrajskyKoordinator',
		'isExterniHodnotitel',
	])
}

export const optionsComputed = {
	...mapGetters([
		'getOptions',
		'getOptionsDotacniRok',
	])
}
