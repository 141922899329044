<template>
	<li class="menu__item">
		<router-link :to="to" class="menu__link">
			<span class="menu__link-title">{{ title }}</span>
			{{ subtitle }}
		</router-link>
	</li>
</template>

<script>
export default {
	name: 'MenuItem',

	props: {
		to: Object,
		title: String,
		subtitle: String,
	}

}
</script>
