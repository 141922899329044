<template>
	<Modal
		v-if="modalOpened"
		:overlay="true"
	>
		<RozpocetOdeslatDialog
			v-if="modalDialog ==='odeslat'"
			@submit="submit"
			@cancel="closeModal"
		/>
	</Modal>

	<SectionHeader :title="`Konečná podoba rozpočtu projektu na rok ${projekt.dotacni_rok}`" />

	<Message type="info" class="mb-3">
		Projektu byla schválena dotace ve výši <strong>{{ formatNumberCZ(projekt.celkova_dotace) }}</strong> Kč.<br>
		Upravte položky v rozpočtu tak, aby celkový požadavek na dotaci ÚV ČR odpovídal této částce.
	</Message>

	<form
		novalidate
		@submit.prevent=""
	>

		<table class="table table--has-inputs w100 table--rozpocet">
			<thead>
				<tr>
					<th class="w25">Nákladová položka</th>
					<th class="w10">Požadavek na<br>dotaci ÚV ČR</th>
					<th class="w25">Specifikace položek k rozpočtu<br>dotace ÚV ČR</th>
					<th class="w10">Upravený požadavek<br>na dotaci ÚV ČR</th>
					<th class="w30">Upravená specifikace položek<br>k rozpočtu dotace ÚV ČR</th>
				</tr>
			</thead>
			<tbody>
				<tr class="table__sum-row">
					<td>1. PROVOZNÍ NÁKLADY celkem</td>
					<td class="alr">{{ formatNumberCZ(puvodni.pd1_provozni_naklady_celkem) }}</td>
					<td>&nbsp;</td>
					<td class="alr">{{ formatNumberCZ(rozpocet.pd1_provozni_naklady_celkem) }}</td>
					<td>&nbsp;</td>
				</tr>
				<tr class="table__sub-sum-row">
					<td class="pl-3">1.1 Materiálové náklady celkem</td>
					<td class="alr">{{ formatNumberCZ(puvodni.pd11_materialove_naklady_celkem) }}</td>
					<td>&nbsp;</td>
					<td class="alr">{{ formatNumberCZ(rozpocet.pd11_materialove_naklady_celkem) }}</td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-4">1.1.1 potraviny</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd111_potraviny) }}</td>
					<td class="vali pre">{{puvodni.pd111_potraviny_upresneni}}</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd111_potraviny" v-bind="fieldAttrs('pd111_potraviny')" /></td>
					<td class="table__input"><BaseTextarea v-model="rozpocet.pd111_potraviny_upresneni" v-bind="fieldAttrs('pd111_potraviny_upresneni')" /></td>
				</tr>
				<tr>
					<td class="vali pl-4">1.1.2 zdravotnický materiál</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd112_zdravotnicky_material) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd112_zdravotnicky_material" v-bind="fieldAttrs('pd112_zdravotnicky_material')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-4">1.1.3 hygienický materiál</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd113_hygienicky_material) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd113_hygienicky_material" v-bind="fieldAttrs('pd113_hygienicky_material')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-4">1.1.4 kancelářské potřeby</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd114_kancelarske_potreby) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd114_kancelarske_potreby" v-bind="fieldAttrs('pd114_kancelarske_potreby')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-4">1.1.5 DDHM</td>
					<td class="vali alr" >{{ formatNumberCZ(puvodni.pd115_ddhm) }}</td>
					<td class="vali pre">{{ puvodni.pd115_ddhm_upresneni }}</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd115_ddhm" v-bind="fieldAttrs('pd115_ddhm')" /></td>
					<td class="table__input"><BaseTextarea v-model="rozpocet.pd115_ddhm_upresneni" v-bind="fieldAttrs('pd115_ddhm_upresneni')" /></td>
				</tr>
				<tr>
					<td class="vali pl-4">1.1.6 pohonné hmoty</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd116_pohonne_hmoty) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd116_pohonne_hmoty" v-bind="fieldAttrs('pd116_pohonne_hmoty')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-4">1.1.7 ostatní materiálové náklady</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd117_ostatni_materialove_naklady) }}</td>
					<td class="vali pre">{{puvodni.pd117_ostatni_materialove_naklady_upresneni}}</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd117_ostatni_materialove_naklady" v-bind="fieldAttrs('pd117_ostatni_materialove_naklady')" /></td>
					<td class="table__input"><BaseTextarea v-model="rozpocet.pd117_ostatni_materialove_naklady_upresneni" v-bind="fieldAttrs('pd117_ostatni_materialove_naklady_upresneni')" /></td>
				</tr>


				<tr class="table__sub-sum-row">
					<td class="vali pl-3">1.2 Opravy a udržování</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd12_opravy_udrzovani) }}</td>
					<td class="vali pre">{{puvodni.pd12_opravy_udrzovani_upresneni}}</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd12_opravy_udrzovani" v-bind="fieldAttrs('pd12_opravy_udrzovani')" /></td>
					<td class="table__input"><BaseTextarea v-model="rozpocet.pd12_opravy_udrzovani_upresneni" v-bind="fieldAttrs('pd12_opravy_udrzovani_upresneni')" /></td>
				</tr>


				<tr class="table__sub-sum-row">
					<td class="pl-3">1.3 Cestovné celkem</td>
					<td class="alr">{{ formatNumberCZ(puvodni.pd13_cestovne_celkem) }}</td>
					<td>&nbsp;</td>
					<td class="alr">{{ formatNumberCZ(rozpocet.pd13_cestovne_celkem) }}</td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-4">1.3.1 tuzemské cestovné</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd131_tuzemske_cestovne) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd131_tuzemske_cestovne" v-bind="fieldAttrs('pd131_tuzemske_cestovne')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-4">1.3.2 zahraniční cestovné</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd132_zahranicni_cestovne) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd132_zahranicni_cestovne" v-bind="fieldAttrs('pd132_zahranicni_cestovne')" /></td>
					<td>&nbsp;</td>
				</tr>


				<tr class="table__sub-sum-row">
					<td class="pl-3">1.4 Služby celkem</td>
					<td class="alr">{{ formatNumberCZ(puvodni.pd14_sluzby_celkem) }}</td>
					<td>&nbsp;</td>
					<td class="alr">{{ formatNumberCZ(rozpocet.pd14_sluzby_celkem) }}</td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-4">1.4.1 energie</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd141_energie) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd141_energie" v-bind="fieldAttrs('pd141_energie')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr class="table__subsub-sum-row">
					<td class="pl-4">1.4.2 ostatní služby:</td>
					<td class="alr">{{ formatNumberCZ(puvodni.pd142_pstatni_sluzby) }}</td>
					<td>&nbsp;</td>
					<td class="alr">{{ formatNumberCZ(rozpocet.pd142_pstatni_sluzby) }}</td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-5">- spoje (telefony, internet, poštovné apod.)</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd1421_spoje) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd1421_spoje" v-bind="fieldAttrs('pd1421_spoje')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-5">- nájemné</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd1422_najemne) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd1422_najemne" v-bind="fieldAttrs('pd1422_najemne')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-5">- operativní leasing</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd1423_operativni_leasing) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd1423_operativni_leasing" v-bind="fieldAttrs('pd1423_operativni_leasing')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-5">- školení a vzdělávání</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd1424_skoleni_vzdelavani) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd1424_skoleni_vzdelavani" v-bind="fieldAttrs('pd1424_skoleni_vzdelavani')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-5">- ekonomické, účetní a právní služby</td>
					<td class="vali alr" >{{ formatNumberCZ(puvodni.pd1425_ekonomicke_ucetni_pravni_sluzby) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd1425_ekonomicke_ucetni_pravni_sluzby" v-bind="fieldAttrs('pd1425_ekonomicke_ucetni_pravni_sluzby')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-5">- DDNM</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd1426_ddnm) }}</td>
					<td class="vali pre">{{puvodni.pd1426_ddnm_upresneni}}</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd1426_ddnm" v-bind="fieldAttrs('pd1426_ddnm')" /></td>
					<td class="table__input"><BaseTextarea v-model="rozpocet.pd1426_ddnm_upresneni" v-bind="fieldAttrs('pd1426_ddnm_upresneni')" /></td>
				</tr>
				<tr>
					<td class="vali pl-5">- jiné ostatní služby</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd1427_ostatni_sluzby) }}</td>
					<td class="vali pre">{{puvodni.pd1427_ostatni_sluzby_upresneni}}</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd1427_ostatni_sluzby" v-bind="fieldAttrs('pd1427_ostatni_sluzby')" /></td>
					<td class="table__input"><BaseTextarea v-model="rozpocet.pd1427_ostatni_sluzby_upresneni" v-bind="fieldAttrs('pd1427_ostatni_sluzby_upresneni')" /></td>
				</tr>

				<tr class="table__sub-sum-row">
					<td class="vali pl-3">1.5 Jiné provozní náklady celkem</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd15_provozni_naklady_celkem) }}</td>
					<td class="vali pre">{{puvodni.pd15_provozni_naklady_celkem_upresneni}}</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd15_provozni_naklady_celkem" v-bind="fieldAttrs('pd15_provozni_naklady_celkem')" /></td>
					<td class="table__input"><BaseTextarea v-model="rozpocet.pd15_provozni_naklady_celkem_upresneni" v-bind="fieldAttrs('pd15_provozni_naklady_celkem_upresneni')" /></td>
				</tr>

				<tr>
					<td colspan="5" class="table__no-cell">&nbsp;</td>
				</tr>

				<tr class="table__sum-row">
					<td>2. OSOBNÍ NÁKLADY celkem</td>
					<td class="alr">{{ formatNumberCZ(puvodni.pd2_osobni_naklady_celkem) }}</td>
					<td>&nbsp;</td>
					<td class="alr">{{ formatNumberCZ(rozpocet.pd2_osobni_naklady_celkem) }}</td>
					<td>&nbsp;</td>
				</tr>

				<tr class="table__sub-sum-row">
					<td class="pl-3">2.1 Mzdové náklady</td>
					<td class="alr">{{ formatNumberCZ(puvodni.pd21_mzdove_naklady) }}</td>
					<td>&nbsp;</td>
					<td class="alr">{{ formatNumberCZ(rozpocet.pd21_mzdove_naklady) }}</td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-4">2.1.1 hrubé mzdy/platy</td>
					<td class="vali alr" >{{ formatNumberCZ(puvodni.pd211_hrube_mzdy_platy) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd211_hrube_mzdy_platy" v-bind="fieldAttrs('pd211_hrube_mzdy_platy')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-4">2.1.2 ON na DPČ/DPP</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd212_on) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd212_on" v-bind="fieldAttrs('pd212_on')" /></td>
					<td>&nbsp;</td>
				</tr>
				<tr>
					<td class="vali pl-4">2.1.3 odvody zaměstnavatele na sociální a zdravotní pojištění</td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd213_odvody_socialni_zdravotni) }}</td>
					<td>&nbsp;</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd213_odvody_socialni_zdravotni" v-bind="fieldAttrs('pd213_odvody_socialni_zdravotni')" /></td>
					<td>&nbsp;</td>
				</tr>


				<tr class="table__sub-sum-row">
					<td class="vali pl-3">2.2 Sociální náklady <em>(např. zákonné pojištění odpovědnosti zaměstnavatele…)</em></td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd22_socialni_naklady) }}</td>
					<td class="vali pre">{{puvodni.pd22_socialni_naklady_upresneni}}</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd22_socialni_naklady" v-bind="fieldAttrs('pd22_socialni_naklady')" /></td>
					<td class="table__input"><BaseTextarea v-model="rozpocet.pd22_socialni_naklady_upresneni" v-bind="fieldAttrs('pd22_socialni_naklady_upresneni')" /></td>
				</tr>


				<tr class="table__sub-sum-row">
					<td class="vali pl-3">2.3  Ostatní osobní náklady <em>(např. práce dobrovolníků, ...)</em></td>
					<td class="vali alr">{{ formatNumberCZ(puvodni.pd23_osobni_naklady) }}</td>
					<td class="vali pre">{{puvodni.pd23_osobni_naklady_upresneni}}</td>
					<td class="table__input"><BaseInput v-model="rozpocet.pd23_osobni_naklady" v-bind="fieldAttrs('pd23_osobni_naklady')" /></td>
					<td class="table__input"><BaseTextarea v-model="rozpocet.pd23_osobni_naklady_upresneni" v-bind="fieldAttrs('pd23_osobni_naklady_upresneni')" /></td>
				</tr>

				<tr>
					<td colspan="5" class="table__no-cell">&nbsp;</td>
				</tr>

				<tr class="table__sum-row">
					<td>CELKOVÉ NÁKLADY DOTACE</td>
					<td class="alr">{{ formatNumberCZ(puvodni.pd_naklady_projektu_celkem) }}</td>
					<td>&nbsp;</td>
					<td
						class="alr"
						:class="{
							'bg-success': jeRozpocetOK,
							'bg-danger': !jeRozpocetOK,
						}"
					>
						<strong>{{ formatNumberCZ(rozpocet.pd_naklady_projektu_celkem) }}</strong>
					</td>
					<td
						rowspan="2"
						class="table__no-cell"
						:class="{
							'color-success': jeRozpocetOK,
							'color-danger': !jeRozpocetOK,
						}"
					>
						<span v-if="jeRozpocetOK">
							Vše v pořádku.<br>
							<span v-if="prevyseniDotace < 0">Ještě máte k dispozici <strong>{{ formatNumberCZ(-prevyseniDotace) }} &nbsp;Kč</strong>.</span>
						</span>
						<span v-else>
							Váš rozpočet převyšuje schválenou dotaci.<br>
							Snižte celkové náklady ještě o <strong>{{ formatNumberCZ(prevyseniDotace) }} &nbsp;Kč</strong>.
						</span>
					</td>
				</tr>
				<tr class="table__sum-row">
					<td colspan="3">SCHVÁLENÁ DOTACE</td>
					<td class="alr">{{ formatNumberCZ(projekt.celkova_dotace) }}</td>
				</tr>
			</tbody>

		</table>
	</form>

		<Message type="info" class="mt-5" v-if="jeRozpocetOK">
			<strong>Odesláním</strong> rozpočet zamknete a odešlete ke schválení.<br>
			<strong>Uložením</strong> si uložíte rozpracovaný rozpočet a můžete se k němu vrátit. Rozpočet se zatím neodešle na ÚV ČR ke schválení.
		</Message>

		<Message type="error" class="mt-5" v-if="!jeRozpocetOK">
			Před odesláním musíte rozpočet upravit tak, aby celkové náklady projektu nepřesahovaly výši schválené dotace.
		</Message>

		<Message type="error" class="mt-5" v-if="error">
			{{ error }}
		</Message>

		<Message type="info" class="mt-5" v-if="info">
			{{ info }}
		</Message>

		<div class="form__buttons mt-3">
			<button
				class="button button--success"
				:disabled="!jeRozpocetOK"
				@click.prevent.enter="confirmSubmit"
			>
				<font-awesome-icon icon="check" class="button__icon" />
				Odeslat konečnou podobu rozpočtu
			</button>

			<button
				class="button"
				@click.prevent.enter="save"
			>
				<font-awesome-icon icon="save" class="button__icon" />
				Uložit rozpracovaný rozpočet
			</button>

			<button
				class="button button--outline"
				@click.prevent.enter="cancel"
			>
				Storno / zpět na hlavní stranu projektu
			</button>
		</div>

		<Loading title="Odesílání rozpočtu" v-if="isSaving" />


</template>

<script>
import {
	STAV_PROJEKTU,
} from '@/constants'

import ProjektyService from '@/services/ProjektyService'

import { formatNumberCZ } from '@/utils/format.js'
import { toNumberCZ } from '@/utils/convert.js'

import Modal from '@/components/Common/Modal'
import RozpocetOdeslatDialog from '@/components/Projekty/Rozpocet/RozpocetOdeslatDialog'

import SectionHeader from '@/components/Common/SectionHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import BaseInput from  '@/components/Forms/Base/BaseInput'
import BaseTextarea from  '@/components/Forms/Base/BaseTextarea'

export default {
	name: 'RozpocetEdit',

	components: {
		SectionHeader,
		Message,
		Loading,
		BaseInput,
		BaseTextarea,
		Modal,
		RozpocetOdeslatDialog,
	},

	emits: ['update'],

	props: {
		projekt: {
			type: Object,
			required: true,
		},
		idRozpocet: {
			type: [String, Number],
			required: false,
		},
		typ: {
			type: [String, Number],
			required: true,
		},
		puvodni: {
			type: Object,
			required: true,
		},
		rozpocet: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			error: '',
			info: '',
			isSaving: false,

			modalOpened: false,
			modalDialog: '',

			schema: [
				{ name: 'pd1_provozni_naklady_celkem', type: 'number' },
				{ name: 'pd11_materialove_naklady_celkem', type: 'number' },
				{ name: 'pd111_potraviny', type: 'number' },
				{ name: 'pd111_potraviny_upresneni', type: 'text' },
				{ name: 'pd112_zdravotnicky_material', type: 'number' },
				{ name: 'pd113_hygienicky_material', type: 'number' },
				{ name: 'pd114_kancelarske_potreby', type: 'number' },
				{ name: 'pd115_ddhm', type: 'number' },
				{ name: 'pd115_ddhm_upresneni', type: 'text' },
				{ name: 'pd116_pohonne_hmoty', type: 'number' },
				{ name: 'pd117_ostatni_materialove_naklady', type: 'number' },
				{ name: 'pd117_ostatni_materialove_naklady_upresneni', type: 'text' },
				{ name: 'pd12_opravy_udrzovani', type: 'number' },
				{ name: 'pd12_opravy_udrzovani_upresneni', type: 'text' },
				{ name: 'pd13_cestovne_celkem', type: 'number' },
				{ name: 'pd131_tuzemske_cestovne', type: 'number' },
				{ name: 'pd132_zahranicni_cestovne', type: 'number' },
				{ name: 'pd14_sluzby_celkem', type: 'number' },
				{ name: 'pd141_energie', type: 'number' },
				{ name: 'pd142_pstatni_sluzby', type: 'number' },
				{ name: 'pd1421_spoje', type: 'number' },
				{ name: 'pd1422_najemne', type: 'number' },
				{ name: 'pd1423_operativni_leasing', type: 'number' },
				{ name: 'pd1424_skoleni_vzdelavani', type: 'number' },
				{ name: 'pd1425_ekonomicke_ucetni_pravni_sluzby', type: 'number' },
				{ name: 'pd1426_ddnm', type: 'number' },
				{ name: 'pd1426_ddnm_upresneni', type: 'text' },
				{ name: 'pd1427_ostatni_sluzby', type: 'number' },
				{ name: 'pd1427_ostatni_sluzby_upresneni', type: 'text' },
				{ name: 'pd15_provozni_naklady_celkem', type: 'number' },
				{ name: 'pd15_provozni_naklady_celkem_upresneni', type: 'text' },
				{ name: 'pd2_osobni_naklady_celkem', type: 'number' },
				{ name: 'pd21_mzdove_naklady', type: 'number' },
				{ name: 'pd211_hrube_mzdy_platy', type: 'number' },
				{ name: 'pd212_on', type: 'number' },
				{ name: 'pd213_odvody_socialni_zdravotni', type: 'number' },
				{ name: 'pd22_socialni_naklady', type: 'number' },
				{ name: 'pd22_socialni_naklady_upresneni', type: 'text' },
				{ name: 'pd23_osobni_naklady', type: 'number' },
				{ name: 'pd23_osobni_naklady_upresneni', type: 'text' },
				{ name: 'pd_naklady_projektu_celkem', type: 'number' },
			]
		}
	},

	computed: {
		jeRozpocetOK() {
			// zaokrouhlujeme na 5 desetinných míst
			return Math.round(this.rozpocet.pd_naklady_projektu_celkem * 100000) <= Math.round(this.projekt.celkova_dotace * 100000)
		},

		prevyseniDotace() {
			return this.rozpocet.pd_naklady_projektu_celkem - this.projekt.celkova_dotace
		}
	},

	methods: {
		formatNumberCZ,

		convertFieldsToValidValues() {
			this.schema
			.filter(field => field.type === 'number')
			.forEach(field => {
				this.rozpocet[field.name] = toNumberCZ(String(this.rozpocet[field.name])) || 0
			})
		},

		closeModal() {
			this.modalOpened = false
			this.modalDialog = ''
		},

		confirmSubmit() {
			this.modalOpened = true
			this.modalDialog = 'odeslat'
		},

		cancel() {
			this.$router.push({ name: 'Projekt', params: { id: this.projekt.id } })
		},

		async submit(e) {

			this.closeModal();

			this.isSaving = true
			this.error = ''
			this.info = ''

			this.convertFieldsToValidValues()

			const data = {
				draft: false,
				typ_rozpoctu: this.typ,
				...this.rozpocet,
			}

			tryblock:
			try {
				// uložíme rozpočet
				let response = await ProjektyService.updateRozpocet(this.projekt.id, data)
				if (!response.data.success) {
					this.error = response.data.error
					break tryblock
				}

				// nastavime stav projektu
				response = await ProjektyService.nastavitStavProjektu(this.projekt.id, {
					stav: STAV_PROJEKTU.KONECNY_ROZPOCET
				})
				if (!response.data.success) {
					this.error = response.data.error
					break tryblock
				}

				// posleme do rodice informaci, že se aktualizoval rozpočet
				this.$emit('update');

			}	catch(error) {
				this.error = error.message

			} finally {
				this.isSaving = false
			}

		},


		async save() {
			this.isSaving = true
			this.error = ''
			this.info = ''

			this.convertFieldsToValidValues()

			const data = {
				draft: true,
				typ_rozpoctu: this.typ,
				...this.rozpocet,
			}

			tryblock:
			try {
				// uložíme rozpočet
				const response = await ProjektyService.updateRozpocet(this.projekt.id, data)
				if (!response.data.success) {
					this.error = response.data.error
					break tryblock
				}

				this.info = 'Rozpočet byl v pořádku uložen.'

			}	catch(error) {
				this.error = error.message

			} finally {
				this.isSaving = false
			}

		},


		sumTable(...names) {
			return names.reduce((prev, current) => {
				const value = toNumberCZ(String(this.rozpocet[current]))
				return (
					typeof value === 'number'
					? prev + value
					: prev
				)
			}, 0)
		},

		recalculate() {
			this.rozpocet.pd11_materialove_naklady_celkem = this.sumTable(
				'pd111_potraviny',
				'pd112_zdravotnicky_material',
				'pd113_hygienicky_material',
				'pd114_kancelarske_potreby',
				'pd115_ddhm',
				'pd116_pohonne_hmoty',
				'pd117_ostatni_materialove_naklady',
			)

			this.rozpocet.pd13_cestovne_celkem = this.sumTable(
				'pd131_tuzemske_cestovne',
				'pd132_zahranicni_cestovne',
			)

			this.rozpocet.pd142_pstatni_sluzby = this.sumTable(
				'pd1421_spoje',
				'pd1422_najemne',
				'pd1423_operativni_leasing',
				'pd1424_skoleni_vzdelavani',
				'pd1425_ekonomicke_ucetni_pravni_sluzby',
				'pd1426_ddnm',
				'pd1427_ostatni_sluzby',
			)

			this.rozpocet.pd14_sluzby_celkem = this.sumTable(
				'pd141_energie',
				'pd142_pstatni_sluzby',
			)

			this.rozpocet.pd1_provozni_naklady_celkem = this.sumTable(
				'pd11_materialove_naklady_celkem',
				'pd12_opravy_udrzovani',
				'pd13_cestovne_celkem',
				'pd14_sluzby_celkem',
				'pd15_provozni_naklady_celkem',
			)

			this.rozpocet.pd21_mzdove_naklady = this.sumTable(
				'pd211_hrube_mzdy_platy',
				'pd212_on',
				'pd213_odvody_socialni_zdravotni',
			)

			this.rozpocet.pd2_osobni_naklady_celkem = this.sumTable(
				'pd21_mzdove_naklady',
				'pd22_socialni_naklady',
				'pd23_osobni_naklady',
			)

			this.rozpocet.pd_naklady_projektu_celkem = this.sumTable(
				'pd1_provozni_naklady_celkem',
				'pd2_osobni_naklady_celkem',
			)
		},

		fieldChanged(event) {
			// const field = event.target.name
			// this.schema[field].valid = this.isValid(field)
			this.recalculate()
		},

		fieldAttrs(name) {
			return {
				name: name,
				small: true,
				align: 'right',
				onInput: this.fieldChanged,
			}
		},
	},

	created() {
		/* constants */
		this.STAV_PROJEKTU = STAV_PROJEKTU
	}

}
</script>