<template>
	<PageHeader :title="nazevProjektu" />

	<Tabs
		:items="tabItems"
		:selected="tabSelected"
		@changeTab="changeTab($event)"
	/>

	<Modal
		v-if="modalOpened"
		:overlay="true"
	>
		<RozpocetVratitDialog
			v-if="modalDialog ==='vratit'"
			:idRozpocet="idRozpocet"
			@submit="vratitRozpocet"
			@cancel="closeModal"
		/>
	</Modal>

	<div class="content">

		<PageHeader>
			<div class="buttons">
				<button
					v-if="projekt.stav === STAV_PROJEKTU.KONECNY_ROZPOCET"
					class="button button--small button--outline"
					@click="exportRozpoctuDoDocx"
				>
					<font-awesome-icon icon="file-word" class="button__icon" />
					Export rozpočtu do DOCX
				</button>

				<button
					v-if="projekt.stav === STAV_PROJEKTU.KONECNY_ROZPOCET && ( isSuperAdmin || isAdmin)"
					class="button button--small button--outline button--danger"
					@click="dialogVraceni"
				>
					<font-awesome-icon icon="recycle" class="button__icon" />
					Vrátit rozpočet k přepracování
				</button>

			</div>
		</PageHeader>

		<Message type="error" v-if="error">
			{{ error }}
		</Message>

		<Loading v-if="!dataLoaded" />

		<template v-if="dataLoaded">

			<RozpocetEdit
				v-if="jeRozpocetEditovatelny"
				:projekt="projekt"
				:idRozpocet="idRozpocet"
				:typ="1"
				:puvodni="puvodniRozpocet"
				:rozpocet="rozpocet"
				@update="loadData"
			/>

			<RozpocetZobrazit
				v-else
				:projekt="projekt"
				:idRozpocet="idRozpocet"
				:typ="1"
				:puvodni="puvodniRozpocet"
				:rozpocet="rozpocet"
			/>

		</template>
	</div>
</template>

<script>
import {
	STAV_PROJEKTU,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import Tabs from '@/components/Common/Tabs'

import Modal from '@/components/Common/Modal'
import RozpocetVratitDialog from '@/components/Projekty/Rozpocet/RozpocetVratitDialog'


import RozpocetEdit from '@/components/Projekty/Rozpocet/RozpocetEdit'
import RozpocetZobrazit from '@/components/Projekty/Rozpocet/RozpocetZobrazit'

export default {
	name: 'Projekt-Rozpocet',

	components: {
		PageHeader,
		Message,
		Loading,
		Tabs,
		Modal,
		RozpocetVratitDialog,
		RozpocetEdit,
		RozpocetZobrazit,
	},

	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},

	data() {
		return {
			error: '',

			modalOpened: false,
			modalDialog: '',

			projekt: {},
			idRozpocet: null,
			rozpocet: {},
			puvodniRozpocet: {},

			projektLoaded: false,
			rozpocetLoaded: false,

			tabSelected: 1,
			tabItems: [
				{
					id: 0,
					title: 'Zpět na projekt',
					icon: 'arrow-left',
				},
				{
					id: 1,
					title: 'Konečná podoba rozpočtu',
				},
			],
		}
	},

	computed: {
		...authComputed,

		dataLoaded() {
			return this.projektLoaded	&& this.rozpocetLoaded
		},

		nazevProjektu() {
			return (
				this.dataLoaded
				? `${this.projekt.kod}: ${this.projekt.nazev}`
				: ''
			)
		},

		jeRozpocetEditovatelny() {
			return (
				!this.isGov
				&& (
					this.projekt.stav === STAV_PROJEKTU.UPRAVA_ROZPOCTU
					|| this.projekt.stav === STAV_PROJEKTU.VRACENI_ROZPOCTU
				)
			)
		},
	},

	methods: {

		closeModal() {
			this.modalOpened = false
			this.modalDialog = ''
		},

		vratitRozpocet() {
			this.closeModal()
			this.loadData()
		},

		dialogVraceni() {
			this.modalDialog = 'vratit'
			this.modalOpened = true
		},

		changeTab(tabId) {
			if (tabId === 0) {
				// zpět na projekt
				this.$router.push({ name: 'Projekt', params: { id: this.id } })
			}
		},

		exportRozpoctuDoDocx() {
			ProjektyService
			.exportRozpocetDocx(this.idRozpocet)
			.then((response) => {
				saveAs(new Blob([response.data]), `${this.projekt.kod}_Konecny rozpocet_${this.projekt.dotacni_rok}.docx`)
			})
			.catch(error => {
				console.error(error)
			})
		},

		loadRozpocet() {
			this.rozpocetLoaded = false

			ProjektyService
			.getRozpocet(this.id, 1)
			.then(response => {
				if (response.data.success) {
					this.idRozpocet = response.data.data.id
					this.puvodniRozpocet = response.data.data.puvodni_rozpocet
					this.rozpocet = (
						this.idRozpocet === null
						? {...response.data.data.puvodni_rozpocet}
						: response.data.data.rozpocet
					)
					this.rozpocetLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

		loadProjekt() {
			this.projektLoaded = false

			ProjektyService
			.getProjekt(this.id)
			.then(response => {
				if (response.data.success) {
					this.projekt = response.data.data.projekt
					this.projektLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},


		loadData() {
			this.loadProjekt()
			this.loadRozpocet()
		}

	},


	created() {
		/* constants */
		this.STAV_PROJEKTU = STAV_PROJEKTU

		this.loadData();
	},

}
</script>
