<template>
	<Dialog title="Smazat projekt" class="dialog--danger">

		<h3 class="color-danger">Opravdu chcete projekt smazat?</h3>
		<p>Toto je <strong>nevratná akce</strong>, která odstraní projekt z žádosti a smaže všechny jeho přílohy.</p>

		<hr class="form__divider">

		<div class="buttons">
			<button
				class="button button--outline button--danger"
				@click="$emit('submit')"
			>
				<font-awesome-icon icon="trash" class="button__icon" />
				Smazat projekt
			</button>

			<button
				class="button button--outline button--neutral"
				@click="$emit('cancel')"
			>
				Storno
			</button>

		</div>

	</Dialog>
</template>

<script>
import Dialog from '@/components/Common/Dialog'

export default {
	name: 'ProjektSmazatDialog',

	components: {
		Dialog,
	},

	emits: ['submit', 'cancel'],

}
</script>
