<template>

	<SectionHeader title="Část C4 - Pracovníci na dohody o pracovní činnosti (DPČ)" />

	<table class="table w100">
		<thead>
			<tr>
				<th>Pracovní pozice</th>
				<th>Specifikace</th>
				<th>Ukončený<br>psycho&shy;terapeutický<br>výcvik</th>
				<th>Dosažené<br>vzdělání</th>
				<th>Úvazek</th>
				<th>Platová<br>třída</th>
				<th>Měsíční<br>mzda</th>
				<th>Počet<br>měsíců<br>v&nbsp;rámci<br>projektu</th>
				<th>Celková<br>mzda pro<br>rok {{projekt.dotacni_rok}}</th>
				<th>Požadavek<br>dotace<br>na mzdu<br>od ÚV ČR<br>na rok {{projekt.dotacni_rok}}</th>
			</tr>
		</thead>
		<tbody>

			<tr class="table__sum-row" v-if="data.pracovnici_pp.length !== 0">
				<td class="alc" colspan="10">Pracovníci v přímé péči</td>
			</tr>
			<tr
				v-for="(radek, index) in data.pracovnici_pp"
				:key="`pp-${index}`"
			>
				<td v-tippy="chybaTooltip('pracovni_pozice', 'pp', index)" v-bind="chyba('pracovni_pozice', 'pp', index)">{{ pracovniPozice("primapece", radek.pracovni_pozice, radek.pracovni_pozice_poznamka) }}</td>
				<td v-tippy="chybaTooltip('specifikace', 'pp', index)" v-bind="chyba('specifikace', 'pp', index)">{{ specifikace("primapece", radek.specifikace, radek.specifikace_poznamka) }}</td>
				<td class="alc" v-tippy="chybaTooltip('psycho_vycvik', 'pp', index)" v-bind="chyba('psycho_vycvik', 'pp', index)">{{ radek.psycho_vycvik === 1 ? 'Ano' : 'Ne' }}</td>
				<td class="alc" v-tippy="chybaTooltip('dosazene_vzdelani', 'pp', index)" v-bind="chyba('dosazene_vzdelani', 'pp', index)">{{ vzdelani(radek.dosazene_vzdelani) }}</td>
				<td class="alc" v-tippy="chybaTooltip('uvazek', 'pp', index)" v-bind="chyba('uvazek', 'pp', index)">{{ radek.uvazek }}</td>
				<td class="alc" v-tippy="chybaTooltip('platova_trida', 'pp', index)" v-bind="chyba('platova_trida', 'pp', index)">{{ radek.platova_trida }}</td>
				<td class="alr" v-tippy="chybaTooltip('mesicni_mzda', 'pp', index)" v-bind="chyba('mesicni_mzda', 'pp', index)">{{ formatNumberCZ(radek.mesicni_mzda) }}</td>
				<td class="alc" v-tippy="chybaTooltip('pocet_mesicu', 'pp', index)" v-bind="chyba('pocet_mesicu', 'pp', index)">{{ radek.pocet_mesicu }}</td>
				<td class="alr" v-tippy="chybaTooltip('celkova_mzda', 'pp', index)" v-bind="chyba('celkova_mzda', 'pp', index)">{{ formatNumberCZ(radek.celkova_mzda) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pozadavek_dotace', 'pp', index)" v-bind="chyba('pozadavek_dotace', 'pp', index)">{{ formatNumberCZ(radek.pozadavek_dotace) }}</td>
			</tr>

			<tr class="table__sum-row" v-if="data.ostatni_pracovnici.length !== 0">
				<td class="alc" colspan="10">Ostatní pracovníci</td>
			</tr>
			<tr
				v-for="(radek, index) in data.ostatni_pracovnici"
				:key="`op-${index}`"
			>
				<td v-tippy="chybaTooltip('pracovni_pozice', 'op', index)" v-bind="chyba('pracovni_pozice', 'op', index)">{{ pracovniPozice("ostatni", radek.pracovni_pozice, radek.pracovni_pozice_poznamka) }}</td>
				<td v-tippy="chybaTooltip('specifikace', 'op', index)" v-bind="chyba('specifikace', 'op', index)">{{ specifikace("ostatni", radek.specifikace, radek.specifikace_poznamka) }}</td>
				<td>&nbsp;</td>
				<td class="alc" v-tippy="chybaTooltip('dosazene_vzdelani', 'op', index)" v-bind="chyba('dosazene_vzdelani', 'op', index)">{{ vzdelani(radek.dosazene_vzdelani) }}</td>
				<td class="alc" v-tippy="chybaTooltip('uvazek', 'op', index)" v-bind="chyba('uvazek', 'op', index)">{{ radek.uvazek }}</td>
				<td class="alc" v-tippy="chybaTooltip('platova_trida', 'op', index)" v-bind="chyba('platova_trida', 'op', index)">{{ radek.platova_trida }}</td>
				<td class="alr" v-tippy="chybaTooltip('mesicni_mzda', 'op', index)" v-bind="chyba('mesicni_mzda', 'op', index)">{{ formatNumberCZ(radek.mesicni_mzda) }}</td>
				<td class="alc" v-tippy="chybaTooltip('pocet_mesicu', 'op', index)" v-bind="chyba('pocet_mesicu', 'op', index)">{{ radek.pocet_mesicu }}</td>
				<td class="alr" v-tippy="chybaTooltip('celkova_mzda', 'op', index)" v-bind="chyba('celkova_mzda', 'op', index)">{{ formatNumberCZ(radek.celkova_mzda) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pozadavek_dotace', 'op', index)" v-bind="chyba('pozadavek_dotace', 'op', index)">{{ formatNumberCZ(radek.pozadavek_dotace) }}</td>
			</tr>

			<tr class="table__sum-row">
				<td colspan="4">Souhrn pracovníci v přímé péči</td>
				<td class="alc" v-tippy="chybaTooltip('pracovnici_pp_uvazek')" v-bind="chyba('pracovnici_pp_uvazek')">{{ data.pracovnici_pp_uvazek }}</td>
				<td colspan="3">&nbsp;</td>
				<td class="alr" v-tippy="chybaTooltip('pracovnici_pp_mzda')" v-bind="chyba('pracovnici_pp_mzda')">{{ formatNumberCZ(data.pracovnici_pp_mzda) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pracovnici_pp_dotace')" v-bind="chyba('pracovnici_pp_dotace')">{{ formatNumberCZ(data.pracovnici_pp_dotace) }}</td>
			</tr>
			<tr class="table__sum-row">
				<td colspan="4">Souhrn ostatní pracovníci</td>
				<td class="alc" v-tippy="chybaTooltip('ostatni_pracovnici_uvazek')" v-bind="chyba('ostatni_pracovnici_uvazek')">{{ data.ostatni_pracovnici_uvazek }}</td>
				<td colspan="3">&nbsp;</td>
				<td class="alr" v-tippy="chybaTooltip('ostatni_pracovnici_mzda')" v-bind="chyba('ostatni_pracovnici_mzda')">{{ formatNumberCZ(data.ostatni_pracovnici_mzda) }}</td>
				<td class="alr" v-tippy="chybaTooltip('ostatni_pracovnici_dotace')" v-bind="chyba('ostatni_pracovnici_dotace')">{{ formatNumberCZ(data.ostatni_pracovnici_dotace) }}</td>
			</tr>
			<tr class="table__sum-row">
				<td colspan="4">Celkové mzdové náklady</td>
				<td class="alc" v-tippy="chybaTooltip('mzdove_naklady_uvazek')" v-bind="chyba('mzdove_naklady_uvazek')">{{ data.mzdove_naklady_uvazek }}</td>
				<td colspan="3">&nbsp;</td>
				<td class="alr" v-tippy="chybaTooltip('mzdove_naklady_mzda')" v-bind="chyba('mzdove_naklady_mzda')">{{ formatNumberCZ(data.mzdove_naklady_mzda) }}</td>
				<td class="alr" v-tippy="chybaTooltip('mzdove_naklady_dotace')" v-bind="chyba('mzdove_naklady_dotace')">{{ formatNumberCZ(data.mzdove_naklady_dotace) }}</td>
			</tr>

		</tbody>
	</table>

	<h3 class="subsection-title mt-5">Komentář k personálnímu obsazení</h3>

	<div class="form__input w100">
		{{ data.personalni_obsazeni }}
	</div>

</template>

<script>
import { formatNumberCZ } from '@/utils/format.js'

import SectionHeader from '@/components/Common/SectionHeader'

export default {
	name: 'CastC4',

	components: {
		SectionHeader,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		pozicePrimaPece: {
			type: Array,
			required: true,
		},
		specifikacePrimaPece: {
			type: Array,
			required: true,
		},
		poziceNeprimaPece: {
			type: Array,
			required: true,
		},
		specifikaceNeprimaPece: {
			type: Array,
			required: true,
		},
		dosazeneVzdelani: {
			type: Array,
			required: true,
		},
	},

	methods: {
		formatNumberCZ,

		hodnotaCiselniku(arr, id) {
			return arr.find(item => item.id == id)?.nazev
		},

		nahraditSpecifikujte(value, replaceWith) {
			return value ? value.replace('– specifikujte', replaceWith) : ''
		},

		pracovniPozice(typ, pozice, poznamka) {
			const ciselnik = (
				typ === 'primapece'
				? this.pozicePrimaPece
				: this.poziceNeprimaPece
			)

			return (
				this.nahraditSpecifikujte(
					this.hodnotaCiselniku(ciselnik, pozice),
					`: ${poznamka}`
				)
			)
		},

		specifikace(typ, specifikace, poznamka) {
			const ciselnik = (
				typ === 'primapece'
				? this.specifikacePrimaPece
				: this.specifikaceNeprimaPece
			)

			return (
				this.nahraditSpecifikujte(
					this.hodnotaCiselniku(ciselnik, specifikace),
					`: ${poznamka}`
				)
			)
		},

		vzdelani(vzdelani) {
			return this.hodnotaCiselniku(this.dosazeneVzdelani, vzdelani)
		},



		importError(field, cast, radek) {
			if (!Array.isArray(this.projekt.kontrola_c)) return undefined
			return (
				cast
				? this.projekt.kontrola_c.find(err => err.pole === field && err.cast === cast && err.radek === radek && err.list === 'C4')
				: this.projekt.kontrola_c.find(err => err.pole === field && err.list === 'C4')
			)
		},

		chyba(field, cast = undefined, radek = undefined) {
			const importError = this.importError(field, cast, radek)
			return (
				importError
				? {
					class: 'table__error'
				}
				: {}
			)
		},

		chybaTooltip(field, cast = undefined, radek = undefined) {
			const importError = this.importError(field, cast, radek)
			return (
				importError
				? {
					content: `<strong>Chyba na listu ${importError.list} v buňce ${importError.bunka}</strong>` + (importError.chyba ? `<br>${importError.chyba}` : '' )
				}
				: {}
			)
		},

	},

}
</script>