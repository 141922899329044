<template>
	<div>

		<Message type="info" class="mb-5" v-if="message">
			{{ message }}
		</Message>

		<Message type="error" class="mb-5" v-if="error">
			{{ error }}
		</Message>

		<Loading v-if="!dataLoaded" />


		<form
			novalidate
			v-if="dataLoaded"
			@submit.prevent=""
			class="form--size40"
		>

			<SectionHeader title="1. PŘEDMĚT ŽÁDOSTI" />

			<div class="form__field">

				<label for="cinnosti" class="form__label">

					Popište, na jaké {{ projekt.typ === TYP_PROJEKTU.SLUZBA ? 'činnosti' : 'aktivity projektu' }} si žádáte dotaci od ÚV ČR
					<p class="form__label-description">např. <em>Žádám o spolufinancování služby/projektu jako celku popsaného v Části B2 „Charakteristika a popis služby/projektu“.</em> Dále uveďte i jiné nestandardní činnosti, pokud si na ně žádáte z dotace ÚV ČR.</p>
				</label>

				<BaseTextarea
					v-model="data.cinnosti"
					v-bind="fieldAttrs('cinnosti')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div class="form__field mt-5">
				<label for="planovane_zmeny" class="form__label">
					<p class="form__label-title">Plánované změny/rozvoj projektu v roce, na který si žádáte dotaci (nerelevantní pro jednoleté projekty, např. IVH)</p>
				</label>

				<BaseTextarea
					v-model="data.planovane_zmeny"
					v-bind="fieldAttrs('planovane_zmeny')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div
				class="form__field mt-5"
				v-if="projekt.typ === TYP_PROJEKTU.SLUZBA"
			>
				<label for="klienti_planovany_pocet" class="form__label">
					Plánovaný počet klientů služby/podpořených osob projektem v roce, na který si žádáte dotaci
				</label>

				<BaseInputNumber
					v-model="data.klienti_planovany_pocet"
					v-bind="fieldAttrs('klienti_planovany_pocet')"
					type="number"
          min="0"
					class="form__input--size20"
				/>
			</div>

			<div
				class="form__field mt-2"
				v-if="projekt.typ === TYP_PROJEKTU.SLUZBA"
			>
				<label for="klienti_komentar" class="form__label">
					Uveďte bližší specifikaci u odhadu počtu klientely/podpořených osob
				</label>

				<BaseTextarea
					v-model="data.klienti_komentar"
					v-bind="fieldAttrs('klienti_komentar')"
					:rows="5"
					class="w100"
				/>
			</div>




			<SectionHeader title="2. PERSONÁLNÍ OBSAZENÍ" class="mt-6" />

			<p>
				Uveďte personální zajištění služby/projektu na přepočtené úvazky. Uveďte včetně DPČ a DPP.
				<strong>Konkrétní složení týmu se uvádí do tabulky C3 až C5.</strong>
			</p>

			<CastB1Uvazky
				:typ="projekt.typ"
				:dotacniRok="projekt.dotacni_rok"
				:disabled="jeProjektUzamcen"
				v-model="data.prepoctene_uvazky"
			/>


			<div class="form__field mt-5">
				<label for="prepoctene_uvazky_komentar" class="form__label">
					Upřesnění/komentář k počtu úvazků nebo rozšíření týmu
				</label>

				<BaseTextarea
					v-model="data.prepoctene_uvazky_komentar"
					v-bind="fieldAttrs('prepoctene_uvazky_komentar')"
					:rows="5"
					class="w100"
				/>
			</div>



			<SectionHeader title="3. KOMENTÁŘ K ROZPOČTU" class="mt-6" />

<!--
			<h3 class="subsection-title">3.1 Memorandum o dlouhodobé spolupráci s ÚV ČR</h3>

			<div class="form__field">
				<label for="memorandum" class="form__label">
					Memorandum o dlouhodobé spolupráci s ÚV ČR:
				</label>

				<BaseSelect
					v-model="data.memorandum"
					v-bind="fieldAttrs('memorandum')"
					:options="[
						{ value: 1, option: 'žádám' },
						{ value: 2, option: 'nežádám' },
					]"
					valueKey="value"
					optionKey="option"
					:emptyValue="true"
					class="form__input--size20"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="memorandum_od" class="form__label">
					Uzavřeno na období od:
				</label>

				<BaseInput
					v-model="data.memorandum_od"
					v-bind="fieldAttrs('memorandum_od')"
					type="date"
					class="form__input--size20"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="memorandum_do" class="form__label">
					Uzavřeno na období do:
				</label>

				<BaseInput
					v-model="data.memorandum_do"
					v-bind="fieldAttrs('memorandum_do')"
					type="date"
					class="form__input--size20"
				/>
			</div>
 -->


			<h3 class="subsection-title">3.1 Smlouva se zdravotní pojišťovnou</h3>

			<div class="form__field">
				<label for="pojistovna_smlouva" class="form__label">
					Smlouva se zdravotní pojišťovnou
				</label>

				<BaseSelect
					v-model="data.pojistovna_smlouva"
					v-bind="fieldAttrs('pojistovna_smlouva')"
					:options="[
						{ value: 1, option: 'ano' },
						{ value: 2, option: 'ne' },
						{ value: 3, option: 'v jednání' },
					]"
					valueKey="value"
					optionKey="option"
					:emptyValue="true"
					class="form__input--size20"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="pojistovna_komentar" class="form__label">
					Upřesnění/komentář ke smlouvě se zdravotní pojišťovnou
				</label>

				<BaseTextarea
					v-model="data.pojistovna_komentar"
					v-bind="fieldAttrs('pojistovna_komentar')"
					:rows="5"
					class="w100"
				/>
			</div>




			<h3 class="subsection-title mt-6">3.2 Zdroje financování</h3>

			<div class="form__field">
				<label for="zdroje_financovani" class="form__label">
					Zdroje financování
					<p class="form__label-description">Zdroje financování služby se uvádí v části C1. V případě, že služba/projekt je financován z jednoho nebo dvou zdrojů, vysvětlete, proč není do financování zapojeno více zdrojů financování. Dále zde komentujte změny ve zdrojích financování a v jejich výši, pokud k nim dochází.</p>
				</label>

				<BaseTextarea
					v-model="data.zdroje_financovani"
					v-bind="fieldAttrs('zdroje_financovani')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="prekroceni_podilu" class="form__label">
					Zdůvodněte překročení 70% podílu dotace
					<p class="form__label-description">Dotace může být poskytnuta až do výše 70 % celkových nákladů/výdajů projektu uvedených v části C1, s výjimkou případů viz čl. 1, odst. 2, písm. i) Podmínek pro poskytnutí a čerpání dotace v rámci dotačního titulu „Protidrogová politika“ Úřadu vlády ČR.</p>
				</label>

				<BaseTextarea
					v-model="data.prekroceni_podilu"
					v-bind="fieldAttrs('prekroceni_podilu')"
					:rows="8"
					class="w100"
				/>
			</div>



			<h3 class="subsection-title mt-6">3.3 Rozpočet služby/projektu</h3>

			<div class="form__field">
				<label for="rozpocet" class="form__label">
					<p class="form__label-title">Rozpočet služby/projektu</p>
					<p class="form__label-description">Rozpočet služby/projektu se uvádí v části C2. Zde uveďte komentář a vysvětlení k rozpočtu služby/projektu.</p>
				</label>

				<BaseTextarea
					v-model="data.rozpocet"
					v-bind="fieldAttrs('rozpocet')"
					:rows="8"
					class="w100"
				/>
			</div>



			<h3 class="subsection-title mt-6">3.4 Zdůvodnění žádosti o příspěvek na operativní leasing vozidla</h3>

			<div class="form__field mt-2">
				<label for="zduvodneni_leasingu" class="form__label">
					<p class="form__label-title">Zdůvodnění žádosti o příspěvek na operativní leasing vozidla</p>
					<p class="form__label-description">Náklady/výdaje spojené s pořízením majetku formou leasingu viz čl. 3, písm. l) Podmínek pro poskytnutí a čerpání dotace v rámci dotačního titulu „Protidrogová politika“ Úřadu vlády ČR.</p>
				</label>

				<BaseTextarea
					v-model="data.zduvodneni_leasingu"
					v-bind="fieldAttrs('zduvodneni_leasingu')"
					:rows="8"
					class="w100"
				/>
			</div>



			<template v-if="!jeProjektUzamcen">
				<hr class="form__divider">

				<Loading title="Ukládám data&hellip;" v-if="isSaving" />

        <Message type="error" class="mb-5" v-if="error">
          {{ error }}
        </Message>

				<div class="form__buttons">
					<button
						class="button"
						:disabled="isSaving"
						@click.prevent="saveData"
					>
						<font-awesome-icon icon="save" class="button__icon" />
						Uložit formulář
					</button>
				</div>
			</template>

		</form>
	</div>
</template>

<script>
import { validateFields } from '@/utils/validator'
import {
	TYP_PROJEKTU,
	STAV_PROJEKTU,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'

import Message from '@/components/Common/Message.vue'
import Loading from '@/components/Common/Loading.vue'
import SectionHeader from '@/components/Common/SectionHeader.vue'
import BaseInput from '@/components/Forms/Base/BaseInput.vue'
import BaseInputNumber from '@/components/Forms/Base/BaseInputNumber.vue'
import BaseTextarea from '@/components/Forms/Base/BaseTextarea.vue'
import BaseSelect from '@/components/Forms/Base/BaseSelect.vue'

import CastB1Uvazky from '@/components/Projekty/CastB/2022/CastB1Uvazky.vue'

export default {
	name: 'CastB1',

	components: {
		Message,
		Loading,
		SectionHeader,
		BaseInput,
    BaseInputNumber,
		BaseTextarea,
		BaseSelect,
		CastB1Uvazky,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			error: '',
			message: '',
			dataLoaded: false,
			isSaving: false,

			data: {},

			formChanged: false,
			schema: {
				cinnosti: { required: true, },
				planovane_zmeny: { required: true, },
				klienti_planovany_pocet: { required: true, validator: "int", },
				klienti_komentar: { required: true, },
				prepoctene_uvazky: { required: true, },
				prepoctene_uvazky_komentar: { required: true, },
				memorandum: { required: true, },
				memorandum_od: { required: true, validator: "date", },
				memorandum_do: { required: true, validator: "date", },
				pojistovna_smlouva: { required: true, },
				pojistovna_komentar: { required: true, },
				zdroje_financovani: { required: true, },
				prekroceni_podilu: { required: true, },
				rozpocet: { required: true, },
				zduvodneni_leasingu: { required: true, },
				poznamka_poskytovatele: { required: true, },
			},
		}
	},

	computed: {
		...authComputed,

		jeMoznePodatZadost() {
			// UPRAVIT UPRAVIT UPRAVIT !!!
			// return Date.now() < Date.parse('2021-11-11')
			return true
		},

		jeProjektUzamcen() {
			return (
				this.projekt.stav === STAV_PROJEKTU.UZAMCENO
				|| this.projekt.stav === STAV_PROJEKTU.SCHVALENO
				|| this.projekt.stav === STAV_PROJEKTU.ZAMITNUTO
				|| this.isGov
				|| !this.jeMoznePodatZadost
			)
		},
	},

	methods: {
    validateFields,

		saveData() {
			this.error = ''
			this.message = ''

      if (!this.validateFields(this.data, this.schema)) {
        this.error = 'Zkontrolujte povinná pole'
        return
      }

			this.isSaving = true

			ProjektyService
			.updateProjektB1(this.projekt.id, this.data)
			.then(response => {
				if (response.data.success) {
					this.message = "Formulář byl v pořádku uložen"
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				// vyscrollujeme nahoru na strance, aby byla videt chyba nebo message
				this.isSaving = false
				window.scrollTo(0, 0)
			})
		},


		fieldChanged(event) {
			// const field = event.target.name
			// this.schema[field].valid = this.isValid(field)
			this.formChanged = true
		},

		fieldAttrs(name) {
			return {
				name: name,
				required: this.schema[name].required,
				valid: this.schema[name]?.valid,
				disabled: this.jeProjektUzamcen,
				placeholder: this.schema[name]?.placeholder,
				onInput: this.fieldChanged,
			}
		},

		loadData() {
			ProjektyService
			.getProjektB1(this.projekt.id)
			.then(response => {
				if (response.data.success) {
					this.data = response.data.data
					this.dataLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},
	},

	created() {
		// constants
		this.TYP_PROJEKTU = TYP_PROJEKTU
		this.STAV_PROJEKTU = STAV_PROJEKTU

		this.loadData()
	}

}
</script>