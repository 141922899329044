import ApiClient from './ApiClient'

export default {

	getDotacniRoky() {
		return ApiClient.get('/dotacni-roky')
	},

	getDotacniRok(id) {
		return ApiClient.get('/dotacni-roky/' + id)
	},

	createDotacniRok(data) {
		return ApiClient
			.post('/dotacni-roky', data)
	},

	updateDotacniRok(data) {
		return ApiClient
			.put('/dotacni-roky/' + data.rok, data)
	},

	vyuctovaniStart(rok) {
		return ApiClient
			.get(`/vyuctovani-start/${rok}`)
	}

}
