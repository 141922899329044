<template>
  <PageHeader title="HODNOCENÍ PROJEKTŮ">
    <button
        class="button button--outline"
        @click="exportXLS"
      >
        <font-awesome-icon icon="file-excel" class="button__icon" />
        Export hodnocení do Excelu
    </button>
  </PageHeader>

	<Message type="error" v-if="error">
    {{ error }}
	</Message>

	<Message type="info" v-if="info">
    {{ info }}
	</Message>

	<Loading v-if="!dataLoaded" />

  <template v-if="dataLoaded">

    <template v-if="projektyHodnoceni.length > 0">

      <table class="table w100 mt-4">
        <thead>
          <tr>
            <th class="all">Kód projektu</th>
            <th class="all">Název projektu</th>
            <th class="all">Žadatel</th>
            <th class="alc w100px">Body DŘ<br>{{ dotacniRok }} I. výzva</th>
            <th class="alc w150px">Požadovaná dotace ÚV&nbsp;ČR<br>I.&nbsp;výzva {{ dotacniRok }} (Kč)</th>
            <th class="alc w150px">Přidělená dotace ÚV&nbsp;ČR<br>{{ dotacniRok - 1 }} celkem (Kč)</th>
            <th class="alc w150px">Přidělená dotace ÚV&nbsp;ČR<br>{{ dotacniRok - 2 }} celkem (Kč)</th>
            <!-- <th class="alc w150px">Jde o nový<br>projekt v&nbsp;rámci<br>I.&nbsp;výzvy {{ dotacniRok }}</th> -->
            <th class="all">Komentář/stanovisko KPK zejména novým projektům v&nbsp;této výzvě k&nbsp;požadované dotaci</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="projekt in projektyHodnoceni"
            :key="projekt.id"
          >
            <td class="nowrap"><strong>{{ projekt.kod }}</strong></td>
            <td><strong>{{ projekt.nazev }}</strong></td>
            <td>{{ projekt.organizace }}</td>
            <td>
              <BaseInput
                v-model="projekt.body"
                :name="`body${projekt.id}`"
                :disabled="disabled"
                type="number"
                step="10"
                min="0"
                max="40"
              />
            </td>
            <td class="alr">{{ formatNumberCZ(projekt.pozadovana_dotace, '-') }}</td>
            <td class="alr">{{ formatNumberCZ(projekt.pridelena_dotace_r1, '-') }}</td>
            <td class="alr">{{ formatNumberCZ(projekt.pridelena_dotace_r2, '-') }}</td>
            <!-- <td class="alc">{{ projekt.novy_projekt ? 'ano' : 'ne' }}</td> -->
            <td>
              <BaseTextarea
                class="w100"
                v-model="projekt.komentar"
                :name="`komentar${projekt.id}`"
                :rows="2"
                :disabled="disabled"
              />
            </td>
          </tr>

          <tr>
            <td colspan="2" class="table__no-cell"></td>
            <td class="alr">Bodů celkem</td>
            <td class="alc">{{ boduCelkem }}</td>
            <td colspan="4" class="table__no-cell"></td>
          </tr>
          <tr>
            <td colspan="2" class="table__no-cell"></td>
            <td class="alr"><strong>Zbývá bodů</strong></td>
            <td class="alc"><strong>{{ boduZbyva }}</strong></td>
            <td colspan="4" class="table__no-cell"></td>
          </tr>
        </tbody>
      </table>

      <section class="content form--size30  mt-5">
        <h3 class="form__section-title">Komentář k hodnocení</h3>

        <div class="form__field form__field--required">
          <label for="komentar-situace" class="form__label">
            Souhrnný komentář k aktuální situaci v kraji ohledně sítě služeb (potřebnosti a dostupnosti služeb v kraji)
          </label>

          <BaseTextarea
            v-model="komentarSituace"
            name="komentar-situace"
            :rows="8"
            class="w100"
            :disabled="disabled"
          />
        </div>

        <div class="form__field form__field--required mt-4">
          <label for="komentar-financovani" class="form__label">
            Komentář k financování služeb z prostředků kraje - aktuální výhled financování na r. {{ dotacniRok }}
          </label>

          <BaseTextarea
            v-model="komentarFinancovani"
            name="komentar-financovani"
            :rows="8"
            class="w100"
            :disabled="disabled"
          />
        </div>
      </section>

    </template>

    <template v-if="projektyVyjadreni.length > 0">
      <h2 class="section-header mt-5">Vyjádření k nadregionálním projektům</h2>

      <table class="table w100 mt-2">
        <thead>
          <tr>
            <th class="all">Kód projektu</th>
            <th class="all">Název projektu</th>
            <th class="all">Žadatel</th>
            <th class="alc w150px">Požadovaná dotace ÚV&nbsp;ČR<br>I.&nbsp;výzva {{ dotacniRok }} (Kč)</th>
            <th class="alc w150px">Přidělená dotace ÚV&nbsp;ČR<br>{{ dotacniRok - 1 }} celkem (Kč)</th>
            <th class="alc w150px">Přidělená dotace ÚV&nbsp;ČR<br>{{ dotacniRok - 2 }} celkem (Kč)</th>
            <!-- <th class="alc w150px">Jde o nový<br>projekt v&nbsp;rámci<br>I.&nbsp;výzvy {{ dotacniRok }}</th> -->
            <th class="all">Komentář/stanovisko KPK</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="projekt in projektyVyjadreni"
            :key="projekt.id"
          >
            <td class="nowrap"><strong>{{ projekt.kod }}</strong></td>
            <td><strong>{{ projekt.nazev }}</strong></td>
            <td>{{ projekt.organizace }}</td>
            <td class="alr">{{ formatNumberCZ(projekt.pozadovana_dotace, '-') }}</td>
            <td class="alr">{{ formatNumberCZ(projekt.pridelena_dotace_r1, '-') }}</td>
            <td class="alr">{{ formatNumberCZ(projekt.pridelena_dotace_r2, '-') }}</td>
            <!-- <td class="alc">{{ projekt.novy_projekt ? 'ano' : 'ne' }}</td> -->
            <td>
              <BaseTextarea
                v-model="projekt.komentar"
                :name="`komentar${projekt.id}`"
                :rows="2"
                class="w100"
                :disabled="disabled"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </template>


		<div class="buttons mt-5">
			<button
        v-if="!disabled && isKrajskyKoordinator"
				class="button"
				@click="odeslatHodnoceni"
			>
				<font-awesome-icon icon="clipboard-check" class="button__icon" />
				Uložit hodnocení
			</button>

      <button
        v-if="isSuperAdmin || isAdmin || isKontrolor"
				class="button button--outline"
				@click="$router.push( {name: 'Hodnoceni'} )"
			>
				Zpět na seznam KPK
			</button>
		</div>

  </template>

</template>

<script>

import { authComputed } from '@/store/helpers.js'
import { formatNumberCZ, formatDateCZ } from '@/utils/format.js'

import { saveAs } from 'file-saver'

import ProjektyService from '@/services/ProjektyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

import BaseInput from '@/components/Forms/Base/BaseInput'
import BaseTextarea from '@/components/Forms/Base/BaseTextarea'

export default {
  name: 'HodnoceniDetail',

  components: {
    PageHeader,
    Message,
    Loading,
    BaseInput,
    BaseTextarea,
  },

  props: {
    koordinatorId: {
      type: [Number, String],
      required: true,
    },
    dotacniRok: {
      type: [Number, String],
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      error: '',
      info: '',
      dataLoaded: false,

      komentarSituace: '',
      komentarFinancovani: '',
      projektyHodnoceni: [],
      projektyVyjadreni: [],
    }
  },

  computed: {
    ...authComputed,

    boduCelkem() {
      return this.projektyHodnoceni.length * 20;
    },

    boduPrideleno() {
      return this.projektyHodnoceni.reduce(
        (sum, projekt) => sum + ( Number.parseInt(projekt.body) ? Number.parseInt(projekt.body) : 0 ),
        0
      )
    },

    boduZbyva() {
      return this.boduCelkem - this.boduPrideleno
    },

  },

  methods: {
    formatNumberCZ,
    formatDateCZ,

    exportXLS() {
			ProjektyService
			.exportHodnoceniXLS(this.koordinatorId, this.dotacniRok)
			.then((response) => {
				saveAs(new Blob([response.data]), `hodnoceni-projektu-${this.dotacniRok}-${this.koordinatorId}.xlsx`)
			})
    },

    odeslatHodnoceni() {
      const data = {
        komentar_situace: this.komentarSituace,
        komentar_financovani: this.komentarFinancovani,
        projekty_hodnoceni: this.projektyHodnoceni.map( projekt => {
          return {
            id: projekt.id,
            body: projekt.body ? projekt.body : 0,
            komentar: projekt.komentar
          }
        }),
        projekty_vyjadreni: this.projektyVyjadreni.map( projekt => {
          return {
            id: projekt.id,
            komentar: projekt.komentar
          }
        }),
      }

      ProjektyService
      .updateHodnoceni(this.koordinatorId, this.dotacniRok, data)
      .then(response => {
        if (response.data.success) {
          this.info = 'Hodnocení bylo v pořádku uloženo'
        } else {
          this.error = response.data.error
        }
      })
      .catch(error => {
        this.error = error.message
      })
    },

    loadData() {
      this.dataLoaded = false
      ProjektyService
      .getHodnoceni(this.koordinatorId, this.dotacniRok)
      .then(response => {
        if (response.data.success) {
          this.komentarSituace = response.data.data.komentar_situace
          this.komentarFinancovani = response.data.data.komentar_financovani
          this.projektyHodnoceni = response.data.data.projekty_hodnoceni
          this.projektyVyjadreni = response.data.data.projekty_vyjadreni

          this.dataLoaded = true
        } else {
          this.error = response.data.error
        }
      })
      .catch(error => {
        this.error = error.message
      })
    },
  },


  created() {
    this.loadData()
  }
}
</script>