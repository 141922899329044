<template>
	<Dialog title="Odeslat konečnou podobu rozpočtu" >

		<p class="color-danger"><strong>Opravdu chcete odeslat konečnou podobu rozpočtu na ÚV ČR?</strong></p>
		<p>Rozpočet se zamkne a vy ho již nebudete moci upravovat.</p>

		<hr class="form__divider">

		<div class="buttons">
			<button
				class="button"
				@click="$emit('submit')"
			>
				<font-awesome-icon icon="check" class="button__icon" />
				Odeslat rozpočet
			</button>

			<button
				class="button button--outline button--neutral"
				@click="$emit('cancel')"
			>
				Storno
			</button>

		</div>

	</Dialog>
</template>

<script>
import Dialog from '@/components/Common/Dialog'

export default {
	name: 'RozpocetOdeslatDialog',

	components: {
		Dialog,
	},

	emits: ['submit', 'cancel'],

}
</script>
