<template>
  <div>

    <PageHeader title="Informace o organizaci pro dotační rok" />

    <FormularAKontrola :id="id" />

  </div>
</template>

<script>
import PageHeader from '@/components/Common/PageHeader'
import FormularAKontrola from '@/components/Organizace/FormularAKontrola'

export default {
  name: 'OrganizaceKontrola',

  components: {
    PageHeader,
    FormularAKontrola,
  },

  props: {
    id: {
      type: [Number, String],
      required: true,
    }
  }

}
</script>
