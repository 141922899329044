<template>
	<div
		class="form__field form__field--vertical"
		:class="{
			'form__field--required': required,
			'form__field--invalid': valid === false,
		}"
	>

		<div class="form__field-line" v-if="label">
			<font-awesome-icon
				class="form__help"
				icon="info-circle"
				v-if="help"
				v-tippy="{ content: help }"
			/>

			<label
				v-if="label"
				:for="name"
				class="form__label"
			>{{ label }}</label>
		</div>

		<div class="form__field-line">
			<textarea
				class="form__input"
				:name="name"
				:id="name"
				:placeholder="placeholder"
				:disabled="disabled"
				:rows="rows"
				:value="modelValue"
				@input="$emit('update:modelValue', $event.target.value)"
			/>

			<FieldStatusIcon
				v-if="statusOptions.type === 'icon'"
				:status="status"
				:options="statusOptions"
			/>
			<FieldStatusControl
				v-if="statusOptions.type === 'control'"
				:status="status"
				@updateStatus="updateStatus($event)"
			/>
		</div>

	</div>
</template>

<script>
import FieldStatusIcon from '@/components/Forms/FieldStatusIcon'
import FieldStatusControl from '@/components/Forms/FieldStatusControl'

export default {
	name: 'FormTextarea',

	components: {
		FieldStatusIcon,
		FieldStatusControl,
	},

	props: {
		modelValue: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			default: 'text',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		valid: {
			type: Boolean,
			default: undefined,
		},
		label: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: null,
		},
		help: {											// pokud neni prazdna, tak je to napoveda nad ikonou s otaznickem
			type: String,
			default: null,
		},
		rows: {
			type: Number,
			default: 4,
		},
		status: {										// stav schvaleni pole
			type: Number,
			default: null,
		},
		statusOptions: {
			type: Object,
			default: {
				type: 'none',						// typ stavove ikony - 'none', 'icon', 'controls'
				showAsEmpty: false,			// zobrazit prazdné místo, kde by normálně byla ikona?
				showApproved: true,			// zobrazovat zelenou ikonu schvaleneho pole?
			},
		},
	},

	methods: {
		updateStatus(status) {
			this.$emit('updateStatus', {
				field: this.name,
				status: status,
			})
		}
	},

}
</script>
