<template>
  <div>

    <PageHeader title="Informace o organizaci pro dotační rok" />

    <FormularA />

  </div>
</template>

<script>
import PageHeader from '@/components/Common/PageHeader'
import FormularA from '@/components/Organizace/FormularA'

export default {
  name: 'OrganizaceInformace',

  components: {
    PageHeader,
    FormularA,
  },

}
</script>
