<template>
	<Dialog title="Upravit název a kód projektu">

		<Message class="mb-3"	type="error"	v-if="error">
			{{ error }}
		</Message>

		<Loading v-if="isSaving" />

		<form
			class="form form--size20"
			novalidate
			@submit.prevent="submit"
		>

			<div class="form__field form__field--required">
				<label for="kod" class="form__label">Kód projektu</label>
				<input
					class="form__input"
					type="text"
					name="kod"
					id="kod"
					v-model="kod"
				>
			</div>

			<div class="form__field form__field--required">
				<label for="nazev" class="form__label">Název</label>
				<input
					class="form__input"
					type="text"
					name="nazev"
					id="nazev"
					v-model="nazev"
				>
			</div>







			<hr class="form__divider">

			<div class="buttons">
				<button
					class="button button--outline"
					@click.prevent="saveData"
				>
					<font-awesome-icon icon="save" class="button__icon" />
					Uložit změny
				</button>

				<button
					class="button button--outline button--neutral"
					@click.prevent="cancel"
				>
					Storno
				</button>

			</div>

		</form>

	</Dialog>
</template>

<script>
import ProjektyService from '@/services/ProjektyService'

import Dialog from '@/components/Common/Dialog'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
	name: 'ProjektPrejmenovatDialog',

	components: {
		Dialog,
		Message,
		Loading,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
	},

	emits: ['submit', 'cancel'],

	data() {
		return {
			error: '',
			isSaving: false,

			nazev: '',
			kod: '',
		}
	},

	methods: {
		cancel() {
			this.$emit('cancel')
		},

		saveData() {
			this.isSaving = true
			const data = {
				kod: this.kod,
				nazev: this.nazev,
			}

			ProjektyService
			.updateProjekt(this.projekt.id, data)
			.then(response => {
				if (response.data.success) {
					// uloženo, odešleme nové jméno a kód zpět na stránku projektu
					this.$emit('submit', data)
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				this.isSaving = false
			})
		},
	},

	created() {
		this.nazev = this.projekt.nazev
		this.kod = this.projekt.kod
	}
}
</script>
