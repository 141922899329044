<template>

	<SectionHeader :title="`Konečná podoba rozpočtu projektu na rok ${projekt.dotacni_rok}`" />

	<Message type="info" class="mb-3" v-if="!isGov">
		Konečná podoba rozpočtu byla odeslána na ÚV ČR a nelze ji nadále měnit.<br>
	</Message>

	<Message type="info" class="mb-3" v-if="isGov">
		V tabulce jsou zvýrazněna pole změněná od původního požadavku na dotaci.
	</Message>

	<table class="table table--has-inputs w100 table--rozpocet">
		<thead>
			<tr>
				<th class="w25">Nákladová položka</th>
				<th v-if="zobrazit" class="w10">Požadavek na<br>dotaci ÚV ČR</th>
				<th v-if="zobrazit" class="w25">Specifikace položek k rozpočtu<br>dotace ÚV ČR</th>
				<th class="w10">Upravený požadavek<br>na dotaci ÚV ČR</th>
				<th class="w30">Upravená specifikace položek<br>k rozpočtu dotace ÚV ČR</th>
			</tr>
		</thead>
		<tbody>
			<tr class="table__sum-row">
				<td>1. PROVOZNÍ NÁKLADY celkem</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd1_provozni_naklady_celkem) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd1_provozni_naklady_celkem) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr class="table__sub-sum-row">
				<td class="pl-3">1.1 Materiálové náklady celkem</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd11_materialove_naklady_celkem) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd11_materialove_naklady_celkem) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.1 potraviny</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd111_potraviny) }}</td>
				<td v-if="zobrazit" class="pre">{{puvodni.pd111_potraviny_upresneni}}</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd111_potraviny) }}</td>
				<td class="pre" :class="rozdil('xxx')">{{rozpocet.pd111_potraviny_upresneni}}</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.2 zdravotnický materiál</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd112_zdravotnicky_material) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd112_zdravotnicky_material) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.3 hygienický materiál</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd113_hygienicky_material) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd113_hygienicky_material) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.4 kancelářské potřeby</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd114_kancelarske_potreby) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd114_kancelarske_potreby) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.5 DDHM</td>
				<td v-if="zobrazit" class="alr" >{{ formatNumberCZ(puvodni.pd115_ddhm) }}</td>
				<td v-if="zobrazit" class="pre">{{ puvodni.pd115_ddhm_upresneni }}</td>
				<td class="alr" :class="rozdil('xxx')" >{{ formatNumberCZ(rozpocet.pd115_ddhm) }}</td>
				<td class="pre" :class="rozdil('xxx')">{{ rozpocet.pd115_ddhm_upresneni }}</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.6 pohonné hmoty</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd116_pohonne_hmoty) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd116_pohonne_hmoty) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.7 ostatní materiálové náklady</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd117_ostatni_materialove_naklady) }}</td>
				<td v-if="zobrazit" class="pre">{{puvodni.pd117_ostatni_materialove_naklady_upresneni}}</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd117_ostatni_materialove_naklady) }}</td>
				<td class="pre" :class="rozdil('xxx')">{{rozpocet.pd117_ostatni_materialove_naklady_upresneni}}</td>
			</tr>


			<tr class="table__sub-sum-row">
				<td class="pl-3">1.2 Opravy a udržování</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd12_opravy_udrzovani) }}</td>
				<td v-if="zobrazit" class="pre">{{puvodni.pd12_opravy_udrzovani_upresneni}}</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd12_opravy_udrzovani) }}</td>
				<td class="pre" :class="rozdil('xxx')">{{rozpocet.pd12_opravy_udrzovani_upresneni}}</td>
			</tr>


			<tr class="table__sub-sum-row">
				<td class="pl-3">1.3 Cestovné celkem</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd13_cestovne_celkem) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd13_cestovne_celkem) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.3.1 tuzemské cestovné</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd131_tuzemske_cestovne) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd131_tuzemske_cestovne) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.3.2 zahraniční cestovné</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd132_zahranicni_cestovne) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd132_zahranicni_cestovne) }}</td>
				<td>&nbsp;</td>
			</tr>


			<tr class="table__sub-sum-row">
				<td class="pl-3">1.4 Služby celkem</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd14_sluzby_celkem) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd14_sluzby_celkem) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.4.1 energie</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd141_energie) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd141_energie) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr class="table__subsub-sum-row">
				<td class="pl-4">1.4.2 ostatní služby:</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd142_pstatni_sluzby) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd142_pstatni_sluzby) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-5">- spoje (telefony, internet, poštovné apod.)</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd1421_spoje) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd1421_spoje) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-5">- nájemné</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd1422_najemne) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd1422_najemne) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-5">- operativní leasing</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd1423_operativni_leasing) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd1423_operativni_leasing) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-5">- školení a vzdělávání</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd1424_skoleni_vzdelavani) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd1424_skoleni_vzdelavani) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-5">- ekonomické, účetní a právní služby</td>
				<td v-if="zobrazit" class="alr" >{{ formatNumberCZ(puvodni.pd1425_ekonomicke_ucetni_pravni_sluzby) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('xxx')" >{{ formatNumberCZ(rozpocet.pd1425_ekonomicke_ucetni_pravni_sluzby) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-5">- DDNM</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd1426_ddnm) }}</td>
				<td v-if="zobrazit" class="pre">{{puvodni.pd1426_ddnm_upresneni}}</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd1426_ddnm) }}</td>
				<td class="pre" :class="rozdil('xxx')">{{rozpocet.pd1426_ddnm_upresneni}}</td>
			</tr>
			<tr>
				<td class="pl-5">- jiné ostatní služby</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd1427_ostatni_sluzby) }}</td>
				<td v-if="zobrazit" class="pre">{{puvodni.pd1427_ostatni_sluzby_upresneni}}</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd1427_ostatni_sluzby) }}</td>
				<td class="pre" :class="rozdil('xxx')">{{rozpocet.pd1427_ostatni_sluzby_upresneni}}</td>
			</tr>

			<tr class="table__sub-sum-row">
				<td class="pl-3">1.5 Jiné provozní náklady celkem</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd15_provozni_naklady_celkem) }}</td>
				<td v-if="zobrazit" class="pre">{{puvodni.pd15_provozni_naklady_celkem_upresneni}}</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd15_provozni_naklady_celkem) }}</td>
				<td class="pre" :class="rozdil('xxx')">{{rozpocet.pd15_provozni_naklady_celkem_upresneni}}</td>
			</tr>

			<tr>
				<td colspan="5" class="table__no-cell">&nbsp;</td>
			</tr>

			<tr class="table__sum-row">
				<td>2. OSOBNÍ NÁKLADY celkem</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd2_osobni_naklady_celkem) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('pd2_osobni_naklady_celkem')">{{ formatNumberCZ(rozpocet.pd2_osobni_naklady_celkem) }}</td>
				<td>&nbsp;</td>
			</tr>

			<tr class="table__sub-sum-row">
				<td class="pl-3">2.1 Mzdové náklady</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd21_mzdove_naklady) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('pd21_mzdove_naklady')">{{ formatNumberCZ(rozpocet.pd21_mzdove_naklady) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">2.1.1 hrubé mzdy/platy</td>
				<td v-if="zobrazit" class="alr" >{{ formatNumberCZ(puvodni.pd211_hrube_mzdy_platy) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('pd211_hrube_mzdy_platy')">{{ formatNumberCZ(rozpocet.pd211_hrube_mzdy_platy) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">2.1.2 ON na DPČ/DPP</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd212_on) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('pd212_on')">{{ formatNumberCZ(rozpocet.pd212_on) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">2.1.3 odvody zaměstnavatele na sociální a zdravotní pojištění</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd213_odvody_socialni_zdravotni) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td class="alr" :class="rozdil('pd213_odvody_socialni_zdravotni')">{{ formatNumberCZ(rozpocet.pd213_odvody_socialni_zdravotni) }}</td>
				<td>&nbsp;</td>
			</tr>


			<tr class="table__sub-sum-row">
				<td class="pl-3">2.2 Sociální náklady <em>(např. zákonné pojištění odpovědnosti zaměstnavatele…)</em></td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd22_socialni_naklady) }}</td>
				<td v-if="zobrazit" class="pre">{{puvodni.pd22_socialni_naklady_upresneni}}</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd22_socialni_naklady) }}</td>
				<td class="pre" :class="rozdil('xxx')">{{rozpocet.pd22_socialni_naklady_upresneni}}</td>
			</tr>


			<tr class="table__sub-sum-row">
				<td class="pl-3">2.3  Ostatní osobní náklady <em>(např. práce dobrovolníků, ...)</em></td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd23_osobni_naklady) }}</td>
				<td v-if="zobrazit" class="pre">{{puvodni.pd23_osobni_naklady_upresneni}}</td>
				<td class="alr" :class="rozdil('xxx')">{{ formatNumberCZ(rozpocet.pd23_osobni_naklady) }}</td>
				<td class="pre" :class="rozdil('xxx')">{{rozpocet.pd23_osobni_naklady_upresneni}}</td>
			</tr>

			<tr>
				<td colspan="5" class="table__no-cell">&nbsp;</td>
			</tr>

			<tr class="table__sum-row">
				<td>CELKOVÉ NÁKLADY DOTACE</td>
				<td v-if="zobrazit" class="alr">{{ formatNumberCZ(puvodni.pd_naklady_projektu_celkem) }}</td>
				<td v-if="zobrazit">&nbsp;</td>
				<td
					class="alr"
					:class="{
						'bg-success': jeRozpocetOK,
						'bg-danger': !jeRozpocetOK,
					}"
				>
					<strong>{{ formatNumberCZ(rozpocet.pd_naklady_projektu_celkem) }}</strong>
				</td>
				<td
					rowspan="2"
					class="table__no-cell"
					:class="{
						'color-success': jeRozpocetOK,
						'color-danger': !jeRozpocetOK,
					}"
				>
					<span  v-if="!jeRozpocetOK">
						Váš rozpočet převyšuje schválenou dotaci.<br>
						Snižte celkové náklady ještě o <strong>{{ formatNumberCZ(prevyseniDotace) }} &nbsp;Kč</strong>.
					</span>
				</td>
			</tr>
			<tr class="table__sum-row">
				<td :colspan="zobrazit ? 3 : 1">SCHVÁLENÁ DOTACE</td>
				<td class="alr">{{ formatNumberCZ(projekt.celkova_dotace) }}</td>
			</tr>
		</tbody>

	</table>

</template>

<script>
import { authComputed } from '@/store/helpers.js'
import { formatNumberCZ } from '@/utils/format.js'

import { saveAs } from 'file-saver'

import SectionHeader from '@/components/Common/SectionHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
	name: 'RozpocetZobrazit',

	components: {
		SectionHeader,
		Message,
		Loading,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
		idRozpocet: {
			type: [String, Number],
			required: false,
		},
		typ: {
			type: [String, Number],
			required: true,
		},
		puvodni: {
			type: Object,
			required: true,
		},
		rozpocet: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			zobrazit: true,
			zvyraznit: true,
		}
	},

	computed: {
		...authComputed,

		jeRozpocetOK() {
			return this.rozpocet.pd_naklady_projektu_celkem <= this.projekt.celkova_dotace
		},

		prevyseniDotace() {
			return this.rozpocet.pd_naklady_projektu_celkem - this.projekt.celkova_dotace
		},
	},

	methods: {
		formatNumberCZ,

		rozdil(key) {
			return (
				(this.puvodni[key] !== this.rozpocet[key]) && this.zvyraznit && this.isGov
				? 'table--highlight'
				: ''
			)
		},
	},

}
</script>