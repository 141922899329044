<template>
  <BaseInput
      class="w100"
      type="number"

  />
</template>

<script>
import BaseInput from "@/components/Forms/Base/BaseInput.vue";
export default {
  name: 'TableInputNumber',
  components: {
    BaseInput
  },
  props: {
    data: Object,
    required: true
  },
  created() {
    if (this.data.dataset) {
      this.value = this.data.dataset
    }
  },
  data() {
    return {
      value: 0
    }
  },
  methods: {

  },
  emits: ['update'],
}
</script>