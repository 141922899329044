<template>
	<div class="form__checkbox-array">

    <Message type="error" class="mb-5" v-if="valid === false">
      Vyberte alespoň jednu možnost
    </Message>

		<div
			class="form__checkbox-array-columns"
			:style="`column-count: ${columns};`"
		>

			<div
				class="form__input-element"
				v-for="item in items"
				:key="item.value"
			>
				<template v-if="!item.parent">
					<input
						type="checkbox"
						class="form__checkbox"
						:class="{'form__checkbox--checked': item.checked }"
						:name="`${name}-${item.value}`"
						:id="`${name}-${item.value}`"
						:checked="item.checked"
						:disabled="disabled"
						@input="changeValue(item.value, $event.target.checked)"
					/>
					<label
						class="form__checkbox-box"
						:for="`${name}-${item.value}`"
					>
						<font-awesome-icon
							class="form__checkbox-icon"
							icon="check"
						/>
					</label>
					<label
						class="form__checkbox-array-label"
						:for="`${name}-${item.value}`"
					>
						{{item.label}}
					</label>
				</template>

				<div
					v-if="optionHasChildren(item.value)"
					class="form__checkbox-array-children"
				>
					<div
						class="form__input-element"
						v-for="child in childrenOptions(item.value)"
						:key="child.value"
					>
						<p>{{child.label}}</p>
						<div class="form__checkbox-array-children-flex">

							<div>
								<input
									type="checkbox"
									class="form__checkbox"
									:class="{'form__checkbox--checked': child.checked }"
									:name="`a-${name}-${child.value}`"
									:id="`a-${name}-${child.value}`"
									:checked="child.checked"
									:disabled="disabled"
									@input="changeValue(child.value, $event.target.checked)"
								/>
								<label
									class="form__checkbox-box"
									:for="`a-${name}-${child.value}`"
								>
									<font-awesome-icon
										class="form__checkbox-icon"
										icon="check"
									/>
								</label>
								<label
									class="form__checkbox-array-label"
									:for="`a-${name}-${child.value}`"
								>
									Ano
								</label>
							</div>

							<div>
								<input
									type="checkbox"
									class="form__checkbox"
									:class="{'form__checkbox--checked': !child.checked }"
									:name="`n-${name}-${child.value}`"
									:id="`n-${name}-${child.value}`"
									:checked="!child.checked"
									:disabled="disabled"
									@input="changeValue(child.value, !$event.target.checked)"
								/>
								<label
									class="form__checkbox-box"
									:for="`n-${name}-${child.value}`"
								>
									<font-awesome-icon
										class="form__checkbox-icon"
										icon="check"
									/>
								</label>
								<label
									class="form__checkbox-array-label"
									:for="`n-${name}-${child.value}`"
								>
									Ne
								</label>
							</div>


						</div>

					</div>
				</div>
			</div>

		</div>

		<div
			class="form__checkbox-array-checkall"
			v-if="allTools && !disabled"
		>
			<a href="#" @click.prevent="checkAll(true)">Vybrat</a> /
			<a href="#" @click.prevent="checkAll(false)">Vymazat</a>
			všechny možnosti
		</div>

	</div>
</template>

<script>
import Message from '@/components/Common/Message.vue'

export default {
	name: 'BaseCheckboxArray',
  components: {
    Message
  },
	props: {
		modelValue: {
			type: [Number, String],
			default: '',
		},
		name: {
			type: String,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
		valueKey: {
			type: String,
			required: true,
		},
		labelKey: {
			type: String,
			required: true,
		},
		allTools: {
			type: Boolean,
			default: false,
		},
		columns: {
			type: Number,
			default: 1,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		valid: {
			type: Boolean,
			default: undefined,
		},
	},

	data() {
		return {
			items: [],
		}
	},

	methods: {

		changeValue(value, checked) {
			this.items.find(item => item.value === value).checked = checked
			this.updateModel()
		},

		updateModel() {
			const values = this.items.filter(item => item.checked).map(item => item.value).join(',')
			this.$emit('update:modelValue', values)
			console.log(values)
		},

		checkAll(value) {
			this.items.forEach(item => {
				item.checked = value
			})
			this.updateModel()
		},

		createItems(value) {
			const values = (
				value
				? value.toString().split(',')
				: []
			)

			this.items = this.options.map(option => ({
				value: option[this.valueKey],
				label: option[this.labelKey],
				checked: values.some(x => x == option[this.valueKey]),
				parent: option.rodic
			}))
		},

		optionHasChildren(id) {
			return this.items.some(item => item.parent === id)
		},

		childrenOptions(id) {
			return this.items.filter(item => item.parent === id)
		},

	},

	watch: {
		modelValue(newValue) {
			this.createItems(newValue)
		}
	},

	created() {
		this.createItems(this.modelValue)
	}
}
</script>
