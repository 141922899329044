<template>
  <div>
    <PageHeader :title="pageTitle" />

    <Loading v-if="!dataLoaded" />

    <div class="content" ref="content">
      <Message type="info" class="mb-4">
        Položky označené <span class="required">*</span> jsou povinné, tj. je nutné zadat pouze dotační rok. Ostatní data lze zadávat postupně a zpětně editovat.
      </Message>

      <Message v-if="message" type="info" class="mb-4">
        {{message}}
      </Message>

      <Message v-if="error" type="error" class="mb-4">
        {{error}}
      </Message>

      <form
        class="form form--size50"
        novalidate
        v-if="dataLoaded"
        @submit.prevent="submitForm"
      >

        <h3 class="form__section-title">DOTAČNÍ ROK (1. KOLO)</h3>

        <div style="width: 60%;">

          <div class="form__field form__field--required">
            <font-awesome-icon
              class="form__help"
              icon="info-circle"
              v-tippy="{ content: 'Vypsaný dotační rok' }"
            />
            <label for="dotacni-rok" class="form__label">Dotační rok</label>
            <input
              class="form__input"
              type="text"
              name="dotacni-rok"
              id="dotacni-rok"
              v-model.number="dotacniRok.rok"
              :disabled="!isNew"
            >
          </div>

          <div class="form__field form__field--single">
            <div class="form__label"></div>
            <div class="form__text">
              <template v-if="dotacniRok.aktivni">
                <font-awesome-icon
                    class="form__help"
                    icon="check-circle"
                    v-tippy="{ content: 'Dotační rok je nastavený jako aktivní.' }"
                />
                <strong>Dotační rok je nastavený jako aktivní.</strong>
              </template>
              <template v-else>
                <!--
                <button
                    class="button"
                    @click.prevent="handleSetAsActive()"
                >
                  Nastavit jako aktivní
                </button>
                -->
              </template>
            </div>
          </div>

          <div class="form__field">
            <font-awesome-icon
              class="form__help"
              icon="info-circle"
              v-tippy="{ content: 'Termín, do kdy je nutné vyplnit informace o organizaci a odeslat formulář ke schválení' }"
            />
            <label for="prihlaseni-dotacni-rizeni" class="form__label">Přihlášení organizace do dotačního roku</label>
            <input
              class="form__input"
              type="date"
              name="prihlaseni-dotacni-rizeni"
              id="prihlaseni-dotacni-rizeni"
              v-model="dotacniRok.prihlaseni_dotacni_rizeni_od"
            >
            <input
                class="form__input form__input--ml"
                type="date"
                name="prihlaseni-dotacni-rizeni-do"
                id="prihlaseni-dotacni-rizeni-do"
                v-model="dotacniRok.prihlaseni_dotacni_rizeni_do"
            >
          </div>

          <div class="form__field">
            <label for="podani-zadosti" class="form__label">Termín podávání dotačních projektů</label>
            <input
              class="form__input"
              type="date"
              name="podani-zadosti"
              id="podani-zadosti"
              v-model="dotacniRok.podani_zadosti_od"
            >
            <input
                class="form__input form__input--ml"
                type="date"
                name="podani-zadosti-do"
                id="podani-zadosti-do"
                v-model="dotacniRok.podani_zadosti_do"
            >
          </div>

          <div class="form__field">
            <label for="zadosti-o-zmeny-rozpoctu" class="form__label">Žádosti o změny konečné podoby rozpočtů</label>
            <input
              class="form__input"
              type="date"
              name="zadosti-o-zmeny-rozpoctu"
              id="zadosti-o-zmeny-rozpoctu"
              v-model="dotacniRok.zadosti_o_zmeny_rozpoctu_od"
            >
            <input
                class="form__input form__input--ml"
                type="date"
                name="zadosti-o-zmeny-rozpoctu-do"
                id="zadosti-o-zmeny-rozpoctu-do"
                v-model="dotacniRok.zadosti_o_zmeny_rozpoctu_do"
            >
          </div>

          <div class="form__field">
            <label for="zadosti-o-zmeny-podilu" class="form__label">Žádosti o změny spolupodílu</label>
            <input
              class="form__input"
              type="date"
              name="zadosti-o-zmeny-podilu"
              id="zadosti-o-zmeny-podilu"
              v-model="dotacniRok.zadosti_o_zmeny_podilu_od"
            >
            <input
                class="form__input form__input--ml"
                type="date"
                name="zadosti-o-zmeny-podilu-do"
                id="zadosti-o-zmeny-podilu-do"
                v-model="dotacniRok.zadosti_o_zmeny_podilu_do"
            >
          </div>

          <div class="form__field">
            <label for="predlozeni-vyuctovani-dotace" class="form__label">Termín předložení vyúčtování dotace</label>
            <input
              class="form__input"
              type="date"
              name="predlozeni-vyuctovani-dotace"
              id="predlozeni-vyuctovani-dotace"
              v-model="dotacniRok.predlozeni_vyuctovani_dotace_od"
            >
            <input
                class="form__input form__input--ml"
                type="date"
                name="predlozeni-vyuctovani-dotace-do"
                id="predlozeni-vyuctovani-dotace-do"
                v-model="dotacniRok.predlozeni_vyuctovani_dotace_do"
            >
            <div class="form__button">
              <button @click="handleSpustitVyuctovani()" class="button">Spustit vyúčtování</button>
            </div>
          </div>

          <div class="form__field">
            <label for="predlozeni-prubezne-zpravy" class="form__label">Termín předložení průběžné zprávy</label>
            <input
              class="form__input"
              type="date"
              name="predlozeni-prubezne-zpravy"
              id="predlozeni-prubezne-zpravy"
              v-model="dotacniRok.predlozeni_prubezne_zpravy_od"
            >
            <input
                class="form__input form__input--ml"
                type="date"
                name="predlozeni-prubezne-zpravy-do"
                id="predlozeni-prubezne-zpravy-do"
                v-model="dotacniRok.predlozeni_prubezne_zpravy_do"
            >
          </div>

          <div class="form__field">
            <label for="predlozeni-zaverecne-zpravy" class="form__label">Termín předložení závěrečné zprávy</label>
            <input
              class="form__input"
              type="date"
              name="predlozeni-zaverecne-zpravy"
              id="predlozeni-zaverecne-zpravy"
              v-model="dotacniRok.predlozeni_zaverecne_zpravy_od"
            >
            <input
                class="form__input form__input--ml"
                type="date"
                name="predlozeni-zaverecne-zpravy-do"
                id="predlozeni-zaverecne-zpravy-do"
                v-model="dotacniRok.predlozeni_zaverecne_zpravy_do"
            >
          </div>

        </div>

        <hr class="form__divider">

        <Loading title="Ukládám data&hellip;" v-if="isSaving" />

        <div class="form__buttons">
          <button
            class="button"
            :disabled="!canSave"
            @click.prevent="saveForm"
          >
            <font-awesome-icon icon="check" class="button__icon" />
            Uložit
          </button>
        </div>

      </form>

    </div>

  </div>
</template>

<script>
import { formatDateCZ } from '@/utils/format'
import DotacniRokyService from '@/services/DotacniRokyService'

import PageHeader from '@/components/Common/PageHeader.vue'
import Message from '@/components/Common/Message.vue'
import Loading from '@/components/Common/Loading.vue'
import dotacniRokyService from "@/services/DotacniRokyService";

export default {
  name: 'DotacniRokEdit',

  components: {
    PageHeader,
    Message,
    Loading,
  },

  props: {
    rok: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      error: '',
      message: null,
      dataLoaded: false,
      isSaving: false,
      isNew: true,
      dotacniRok: {
        rok: '',
        prihlaseni_dotacni_rizeni: '',
        podani_zadosti: '',
        zadosti_o_zmeny_rozpoctu: '',
        zadosti_o_zmeny_podilu: '',
        predlozeni_vyuctovani_dotace: '',
        predlozeni_prubezne_zpravy: '',
        predlozeni_zaverecne_zpravy: ''
      },
    }
  },

  computed: {
    pageTitle() {
      return this.isNew ? 'Přidat nový dotační rok' : 'Editovat dotační rok'
    },

    canSave() {
      return !!this.dotacniRok.rok
    },

  },

  methods: {
    async handleSpustitVyuctovani() {
      this.error = ''
      this.message = ''

      try {
        const response = await dotacniRokyService.vyuctovaniStart(this.rok)

        if (!response.data.success) {
          this.error = 'Spustit vyúčtování se nepodařilo.'
        } else {
          this.message = 'Vyúčtování spuštěno.'
        }

      } catch(error) {
        this.error = 'Spustit vyúčtování se nepodařilo.'
      }
    },

    handleSetAsActive() {
      alert("set as active");
    },

    formatDateCZ: formatDateCZ,

    validateForm() {
      // validace roku - 4 cislice mezi 1900 a 2099
      const dateReg = /^(19|20)\d{2}$/
      if (!dateReg.test(this.dotacniRok.rok)) {
        this.$refs.content.scrollIntoView()
        this.error = 'Musíte zadat platný dotační rok.'
        return false
      }

      this.error = ''
      return true
    },

    saveForm() {
      if (this.validateForm()) {
        this.saveData()
      }
    },

    saveData() {
      this.error = ''
			this.message = ''
			this.isSaving = true

      const data = {...this.dotacniRok}
      for (const property in data) {
        data[property] = data[property] === '' ? null : data[property]
      }

      const saveFn = this.isNew ? DotacniRokyService.createDotacniRok : DotacniRokyService.updateDotacniRok
      saveFn(this.dotacniRok)
			.then(response => {
				if (response.data.success) {
					this.message = "Dotační rok byl v pořádku uložen."
          this.isNew = false
          this.dotacniRok = response.data.data
				} else {
					this.error += response.data.error
				}
			})
			.catch(error => {
        this.error += error.message
			})
			.then(() => {
        // vyscrollujeme nahoru na strance, aby byla videt chyba nebo message
				this.isSaving = false
				this.$refs.content.scrollIntoView()
			})

    },

    loadData() {
      // pokud editujeme existujici rok, tak nacteme data ze serveru
      if (this.rok) {
        DotacniRokyService
        .getDotacniRok(this.rok)
        .then(response => {
            if (response.data.success) {
            this.dotacniRok = response.data.data
            this.dataLoaded = true
            this.isNew = false
          } else {
            this.error += response.data.error
          }
        })
        .catch(error => {
          this.error += error.message
        })
      } else {
        this.dataLoaded = true
        this.isNew = true
      }
    }
  },

  created() {
    this.loadData()
  },
}
</script>
