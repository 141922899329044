import { library } from "@fortawesome/fontawesome-svg-core"
import {
	faInfoCircle,
	faExclamationTriangle,
	faExclamationCircle,
	faPlus,
	faTimes,
	faCheck,
	faCheckCircle,
	faQuestion,
	faSignInAlt,
	faFolderOpen,
	faTrash,
	faRecycle,
	faUpload,
	faDownload,
	faSave,
	faFile,
	faFilePdf,
	faFileWord,
	faFileExcel,
	faFilePowerpoint,
	faFileCsv,
	faFileArchive,
	faFileImport,
	faUndo,
	faClipboardCheck,
	faLock,
	faUnlock,
	faEye,
	faEdit,
	faArrowLeft,
	faArrowRight,
	faChevronUp,
	faChevronDown,
} from "@fortawesome/free-solid-svg-icons"

library.add(
	faInfoCircle,
	faExclamationTriangle,
	faExclamationCircle,
	faPlus,
	faTimes,
	faCheck,
	faCheckCircle,
	faQuestion,
	faSignInAlt,
	faFolderOpen,
	faTrash,
	faRecycle,
	faUpload,
	faDownload,
	faSave,
	faFile,
	faFilePdf,
	faFileWord,
	faFileExcel,
	faFilePowerpoint,
	faFileCsv,
	faFileArchive,
	faFileImport,
	faUndo,
	faClipboardCheck,
	faLock,
	faUnlock,
	faEye,
	faEdit,
	faArrowLeft,
	faArrowRight,
	faChevronUp,
	faChevronDown,
)
