
<template>
	<ul
		class="tabs"
		:class="{
			'tabs--equal-widths': equalWidths,
		}"
	>
		<li
			class="tabs__item"
			v-for="item in tabItems"
			:key="item.id"
			:class="{ 'tabs__item--active': item.name[0] === selectedName }"
			@click="setRoute(item.name[0])"
		>
			<font-awesome-icon
				class="tabs__icon"
				v-if="item?.icon"
				:icon="item.icon"
			/>
			<div class="tabs__content">
				<span class="tabs__title">{{ item.title }}</span>
				<span v-if="item.subtitle" class="tabs__subtitle">{{item.subtitle}}</span>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'LinkTabs',
	props: {
		items: {
			type: Array,
			required: true,
		},
		equalWidths: {
			type: [Boolean],
			default: false,
		},
	},

	computed: {
		tabItems() {
			return this.items.filter(item => !item?.hidden)
		},
    selectedName() {
      for (const item of this.tabItems) {
        for (const name of item.name) {
          if (this.$route.fullPath === name) {
            return item.name[0];
          }
        }
      }

      return null;
    }
	},

	methods: {
    setRoute(routeName) {
      this.$router.push({path: routeName})
    },

	},
}
</script>