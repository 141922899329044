<template>
	<Dialog :title="title" style="width: 700px">

		<Loading v-if="id && !dataLoaded" />

		<form
			class="form form--size20"
			novalidate
			@submit.prevent="submit"
		>

			<Message type="info" class="mb-3">
				E-mailová adresa slouží jako uživatelské jméno (login).
			</Message>

			<Message
				class="mb-3"
				type="error"
				v-if="error"
			>
				{{ error }}
			</Message>

			<div class="form__field form__field--required">
				<label for="email" class="form__label">E-mail / login</label>
				<input
					class="form__input"
					type="text"
					name="email"
					id="email"
					v-model="email"
				>
			</div>

			<div class="form__field form__field--required">
				<label for="jmeno" class="form__label">Jméno</label>
				<input
					class="form__input"
					type="text"
					name="jmeno"
					id="jmeno"
					v-model="jmeno"
				>
			</div>

			<div class="form__field form__field--required">
				<label for="prijmeni" class="form__label">Příjmení</label>
				<input
					class="form__input"
					type="text"
					name="prijmeni"
					id="prijmeni"
					v-model="prijmeni"
				>
			</div>

			<div class="form__field">
				<label for="telefon" class="form__label">Telefon</label>
				<input
					class="form__input"
					type="text"
					name="telefon"
					id="telefon"
					v-model="telefon"
				>
			</div>

			<div class="form__field form__field--required">
				<label for="role" class="form__label">Role</label>
				<select
					class="form__input"
					name="role"
					id="role"
					v-model.number="role"
					:disabled="roleDisabled"
				>
					<option value=""></option>
					<option
						v-for="item in roleSelectable"
						:value="item.id"
						:key="item.id"
					>
						{{item.role}}
					</option>
				</select>
			</div>

			<div
				class="form__field form__field--required"
				v-if="role == 3 && krajeLoaded"
			>
				<label for="kraj" class="form__label">Kraj</label>
				<select
					class="form__input"
					name="kraj"
					id="kraj"
					v-model.number="kraj"
				>
					<option value=""></option>
					<option
						v-for="item in krajeSeznam"
						:value="item.id"
						:key="item.id"
					>
						{{item.kraj}}
					</option>
				</select>
			</div>

			<div
				class="form__field form__field--required"
				v-if="role == 4"
			>
				<label for="datumExpirace" class="form__label">Datum expirace</label>
				<input
					class="form__input"
					type="date"
					name="datumExpirace"
					id="datumExpirace"
					v-model="datumExpirace"
				>
			</div>

			<div class="form__buttons form__buttons--space-between">
				<div class="form__buttons-left">
					<button
						type="submit"
						class="button"
						@click.prevent="submit"
					>
						<font-awesome-icon icon="folder-open" class="button__icon" />
						Uložit uživatele
					</button>
					<button
						class="button button--outline"
						@click.prevent="cancel"
					>
						Storno
					</button>
				</div>
				<div class="form__buttons-right">
					<button
						class="button button--outline button--danger"
						@click.prevent="changePassword"
					>
						<font-awesome-icon icon="lock" class="button__icon" />
						Změnit heslo
					</button>

					<button
						class="button button--outline button--danger"
						v-if="id && aktivni"
						@click.prevent="submit({ aktivni: false })"
					>
						<font-awesome-icon icon="trash" class="button__icon" />
						Deaktivovat
					</button>

					<button
						class="button button--outline button--success"
						v-if="id && !aktivni"
						@click.prevent="submit({ aktivni: true })"
					>
						<font-awesome-icon icon="recycle" class="button__icon" />
						Aktivovat
					</button>
				</div>
			</div>

		</form>
	</Dialog>
</template>

<script>
import ApiClient from '@/services/ApiClient'
import UzivateleService from '@/services/UzivateleService'

import Dialog from '@/components/Common/Dialog'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
	name: 'UzivatelEditForm',

	props: {
		id: {
			type: Number,
			default: null,
		},
		onSubmit: {
			type: Function,
			default: null
		},
		onCancel: {
			type: Function,
			default: null
		},
		onPassword: {
			type: Function,
			default: null
		}
	},

	components: {
		Dialog,
		Message,
		Loading,
	},

	data() {
		return {
			title: 'Nový uživatel aplikace',
			email: '',
			jmeno: '',
			prijmeni: '',
			telefon: '',
			role: null,
			kraj: null,
			datumExpirace: null,
			aktivni: true,

			dataLoaded: false,
			roleSeznam: [],
			roleLoaded: false,
			krajeSeznam: [],
			krajeLoaded: false,

			error: '',
		}
	},

	computed: {
		roleDisabled() {
			if (this.role === null) return false

			return this.roleLoaded
				? !this.roleSeznam.find(item => item.id == this.role).edit
				: true
		},

		roleSelectable() {
			return this.roleSeznam.filter(item => this.roleDisabled || item.edit)
		}
	},

	methods: {
		submit(options) {
			if (!this.checkForm()) return

			const uzivatel = {
				email: this.email,
				jmeno: this.jmeno,
				prijmeni: this.prijmeni,
				id_organizace: null,
				telefon: this.telefon,
				role: this.role,
				id_kraj: this.role == 3 ? this.kraj : null,
				datum_expirace: this.role == 4 ? this.datumExpirace : null,
				aktivni: this.aktivni,
				...options,
			}

			let save;

			if (this.id) {
				uzivatel.id = this.id,
				save = UzivateleService.updateUzivatel
			} else {
				save = UzivateleService.createUzivatel
			}

			save(uzivatel)
			.then(response => {
				if (response.data.success) {
					if (this.onSubmit) this.onSubmit()
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.data?.error || 'Při pokusu o uložení došlo k chybě'
			})
		},

		cancel() {
			if (this.onCancel) this.onCancel()
		},

		changePassword() {
			if (this.onPassword) this.onPassword()
		},

		checkForm() {
			this.error = '';

			let errRequired = false
			if (!(this.email && this.jmeno && this.prijmeni && this.role)) errRequired = true
			if (this.role == 3 && !this.kraj) errRequired = true
			if (this.role == 4 && !this.datumExpirace) errRequired = true
			if (errRequired) {
				this.error = 'Vyplňte všechny poviné údaje'
				return false
			}

			if (!(this.email.includes('@') && this.email.includes('.'))) {
				this.error = 'Neplatná emailová adresa'
				return false
			}

			return true
		}
	},

	created() {
		if(this.id) {
			this.title = 'Editace uživatele'

			UzivateleService.getUzivatel(this.id)
				.then(response => {
					if (response.data.success) {
						this.email = response.data.data.email
						this.jmeno = response.data.data.jmeno
						this.prijmeni = response.data.data.prijmeni
						this.telefon = response.data.data.telefon
						this.role = response.data.data.role
						this.kraj = response.data.data.id_kraj
						this.datumExpirace = response.data.data.datum_expirace
						this.aktivni = response.data.data.aktivni

						this.dataLoaded = true
					} else {
						this.error = response.data.error
					}
				})
				.catch(error => {
					this.error = error.response.data.error || 'Chyba při načítání uživatele'
				})
		}


		ApiClient.get('/role')
			.then(response => {
				this.roleSeznam = response.data.data
				this.roleLoaded = true
			})
			.catch(error => {
				this.error('Nepodařilo se načíst seznam uživatelských rolí')
			})

		ApiClient.get('/kraje')
			.then(response => {
				this.krajeSeznam = response.data.data
				this.krajeLoaded = true
			})
			.catch(error => {
				this.error('Nepodařilo se načíst seznam krajů')
			})
	}

}
</script>
