<template>
	<ul
		class="tabs"
		:class="{
			'tabs--equal-widths': equalWidths,
		}"
	>
		<li
			class="tabs__item"
			v-for="item in tabItems"
			:key="item.id"
			:class="{ 'tabs__item--active': item.id == selected }"
			@click="changeTab(item.id)"
		>
			<font-awesome-icon
				class="tabs__icon"
				v-if="item?.icon"
				:icon="item.icon"
			/>
			<div class="tabs__content">
				<span class="tabs__title">{{ item.title }}</span>
				<span v-if="item.subtitle" class="tabs__subtitle">{{item.subtitle}}</span>
			</div>
		</li>
	</ul>
</template>

<script>
export default {
	name: 'Tabs',

	/*
	Očekává se pole objektů v následujícím tvaru:
	{
		id: '2023',
		title: '2023',
		subtitle: 'schváleno',
		hidden: false,
	}

	selected je ID aktivní záložky
	*/
	props: {
		items: {
			type: Array,
			required: true,
		},
		selected: {
			type: [Number, String],
			default: null,
		},
		equalWidths: {
			type: [Boolean],
			default: false,
		},
	},

	computed: {
		tabItems() {
			return this.items.filter(item => !item?.hidden)
		},
	},

	methods: {
		changeTab(id) {
			if (id != this.selected) {
				this.$emit('changeTab', id)
			}
		}
	},
}
</script>

<style>

</style>