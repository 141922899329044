<template>
	<section v-if="zadost.datum_prvniho_odeslani_zadosti || zadost.datum_odeslani_zadosti">
		<div class="content">

			<table>
				<tr>
					<td class="all">Datum prvního odeslání žádosti</td>
					<td class="all pl-3"><strong>{{ formatDateCZ(zadost.datum_prvniho_odeslani_zadosti) }}</strong></td>
				</tr>
				<tr>
					<td class="all">Datum poslední uzamčení žádosti</td>
					<td class="all pl-3"><strong>{{ formatDateCZ(zadost.datum_odeslani_zadosti) }}</strong></td>
				</tr>
			</table>

		</div>
	</section>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import { formatDateCZ } from '@/utils/format.js'

export default {
	name: 'ZadostMeta',

	props: {
		zadost: {
			type: Object,
			required: true,
		},
	},

  computed: {
    ...authComputed,
  },

  methods: {
    formatDateCZ,
	},

}
</script>
