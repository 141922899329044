<template>
  <div>
    <SectionHeader title="Výkony za rok 2022" />

    <label class="form__label">
      <p class="form__label-title">(pro všechny služby mimo programů primární prevence)</p>
      <p class="form__label-description">Pozn.: Pokud některé z požadovaných údajů neevidujete, uveďte „N“, pokud některé intervence/výkony neposkytujete, ponechte políčko prázdné.
        Před vyplněním si pečlivě prostudujte pokyny.</p>
    </label>

    <div class="table-container">
      <table class="inputtable" style="width: 100%">
        <thead>
          <tr>
            <th colspan="3">&nbsp;</th>
            <th colspan="5">Z toho</th>
          </tr>
          <tr>
            <th>Výkon</th>
            <th>Počet osob, kterým byl výkon poskytnut</th>
            <th>Počet výkonů<sup>1</sup> celkem</th>
            <th>Osobní (face to face)</th>
            <th>Osobní přes telefon</th>
            <th>Osobní přes internet</th>
            <th>Korespondenčně</th>
          </tr>
        </thead>
        <tbody>
        <tr v-for="(row, indexRow) in dataset" :key="indexRow">
          <th v-html="rowsTitle[indexRow]"></th>
          <td v-for="(item, index) in row" :key="index">
            <span v-if="disabledFields[indexRow].includes(index)">{{ item }}</span>
            <BaseInput
                v-else
                class="w-100"
                :name="index"
                :error="isErrorInput(dataset[indexRow][index])"
                v-model="dataset[indexRow][index]"
            />
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <div class="form__field mt-4">
      <label style="min-width: 30%" for="vykony_ostatni_komentar" class="form__label">
        <p class="form__label-title">Poznámky, komentáře k tabulce výkony:</p>
        <p class="form__label-description">Popište zejména nárůst či pokles výkonů a jejich strukturu ve srovnání s totožným obdobím minulého roku; popište jakékoliv skutečnosti (lokální a jiná kontextová specifika), která mohou mít vliv na uváděná data.</p>
      </label>
      <BaseTextarea name="vykony_ostatni_komentar" class="w-100" :rows="8" v-model="data.vykony_ostatni_komentar" />
    </div>

    <ol class="mt-4">
      <li>
        Počet výkonů celkem - tj. kolikrát byla daná služba poskytnuta (např. počet ošetření, počet individuálních sezení, skupin, apod.).
        Přepočítáno na standardní výkony dle časové dotace uvedené v Seznamu a definicích výkonů adiktologických odborných služeb.
        Uživatelé aplikace UniData Lite výkony přepočítávat nemusí, aplikace je přepočítává automaticky.
      </li>
      <li>
        Zde se jedná o součet všech výkonů nepřímé práce – tedy: výkony nepřímé práce ve prospěch klienta + monitoring + zpráva o klientovi
        podle vymezení ve specifikaci výkonu 2.42 Zpráva o klientovi (nikdy se nejedná o běžný zápis dokumentace).
      </li>
      <li>
        Celkový počet všech výkonů poskytnutých klientům a ve prospěch klienta.
      </li>
      <li>
        Součet času všech výkonů poskytnutých klientům a ve prospěch klienta. Celkový čas všech výkonů nemusí být roven součtu hodnot
        ve sloupcích podle způsobu poskytnutí intervence.
      </li>
    </ol>

  </div>
</template>

<script>
import {formatNumberCZ} from '@/utils/format.js'
import {isNumericCZ, toNumberCZ} from '@/utils/convert.js'

import SectionHeader from '@/components/Common/SectionHeader'
import BaseInput from '@/components/Forms/Base/BaseInput'
import TableText from "@/components/Table/TableText.vue"
import TableInput from "@/components/Table/TableInputNumber.vue"
import BaseTextarea from "@/components/Forms/Base/BaseTextarea.vue";

export default {
  name: 'ZaverecnaZpravaVykonyMimoPrimarniPrevenci',
  components: {
    BaseTextarea,
    SectionHeader,
    BaseInput,
    TableText,
    TableInput,
  },
  props: {
    projekt: {
      type: Object,
      required: true,
    },
    data: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.dataset = JSON.parse(this.data.tab_vykony_ostatni)
  },
  computed: {
    uvazkyCelkem() {
      return 0
    }
  },
  data() {
    return {
      disabledFields: {
        individualni_prace: [],
        prace_s_rodinou: [],
        prace_se_skupinou: [],
        kratky_rozhovor: [],
        vysetreni: [],
        test_infekcni_nemoci: ['telefon', 'internet', 'pisemne'],
        test_hiv: ['telefon', 'internet', 'pisemne'],
        test_hcv: ['telefon', 'internet', 'pisemne'],
        test_hbv: ['telefon', 'internet', 'pisemne'],
        test_syfilis: ['telefon', 'internet', 'pisemne'],
        test_ostatni: ['telefon', 'internet', 'pisemne'],
        hr_vykony: ['telefon', 'internet', 'pisemne'],
        hr_materialy: ['telefon', 'internet', 'pisemne'],
        hr_vydane_jehly: ['pocet_osob', 'telefon', 'internet', 'pisemne'],
        hr_prijate_jehly: ['pocet_osob', 'telefon', 'internet', 'pisemne'],
        hr_nalezene_jehly: ['pocet_osob', 'telefon', 'internet', 'pisemne'],
        hr_peer_distribuce: ['telefon', 'internet', 'pisemne'],
        hr_potravinovy_servis: ['telefon', 'internet', 'pisemne'],
        hr_osetreni: ['telefon', 'internet', 'pisemne'],
        hr_intoxikace: ['telefon', 'internet', 'pisemne'],
        hr_hygiena: ['telefon', 'internet', 'pisemne'],
        farmakoterapie: [],
        zazitkove_programy: ['telefon', 'internet', 'pisemne'],
        podpora_prace: ['telefon', 'internet', 'pisemne'],
        np_celkem: ['osobne', 'telefon', 'internet', 'pisemne'],
        np_monitoring: [],
        np_zprava_o_klientovi: ['osobne', 'telefon', 'internet', 'pisemne'],
        celkem_pocet_vykonu: ['pocet_osob'],
        celkem_cas_vykonu: ['pocet_osob']
      },
      rowsTitle: {
        individualni_prace: 'Individuální práce',
        prace_s_rodinou: 'Práce s rodinou',
        prace_se_skupinou: 'Práce se skupinou',
        kratky_rozhovor: 'Krátký rozhovor s klientem',
        vysetreni: 'Vyšetření',
        test_infekcni_nemoci: 'Testování infekčních nemocí',
        test_hiv: '- počet testů na HIV ',
        test_hcv: '- počet testů na HCV',
        test_hbv: '- počet testů na HBV',
        test_syfilis: '- počet testů na syfilis',
        test_ostatni: 'Testování ostatní',
        hr_vykony: 'Výkony v oblasti harm reduction',
        hr_materialy: '- Distribuce HR materiálu',
        hr_vydane_jehly: '- Počet vydaných inj. jehel a stříkaček (ks)',
        hr_prijate_jehly: '- Počet přijatých inj. jehel a stříkaček (ks)',
        hr_nalezene_jehly: '- Počet nalezených inj. jehel a stříkaček (ks)',
        hr_peer_distribuce: '- Vrstevnická (peer) distribuce HR materiálu',
        hr_potravinovy_servis: '- Potravinový servis',
        hr_osetreni: '- Základní zdravotní ošetření',
        hr_intoxikace: '- Řešení akutní intoxikace',
        hr_hygiena: '- Hygienický servis',
        farmakoterapie: 'Farmakoterapie',
        zazitkove_programy: 'Zážitkové programy',
        podpora_prace: 'Podpora pracovních dovedností a zaměstnávání',
        np_celkem: 'Výkony nepřímé práce ve prospěch klienta – celkem<sup>2</sup>',
        np_monitoring: '- Monitoring',
        np_zprava_o_klientovi: '- Zpráva o klientovi',
        celkem_pocet_vykonu: 'Celkový počet všech výkonů podle způsobu poskytnutí intervence<sup>3</sup>',
        celkem_cas_vykonu: 'Celkový čas všech poskytnutných výkonů podle způsobu poskytnutí intervence <sup>4</sup>',
      },
      dataset: {},
    }
  },
  methods: {
    isNumericCZ,
    toNumberCZ,
    formatNumberCZ,
    isErrorInput(value) {
      return !(this.isNumericCZ(value) || value === '' || value === 'N' || value === 'n' || value === null)
    },
  },
  watch: {
    dataset: {
      handler () {
        this.data.tab_vykony_ostatni = JSON.stringify(this.dataset)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
  table{
    border-collapse: separate;
    border-spacing: 0;
  }

  thead{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 20;
    background-color: hsl(0, 0%, 98%);
  }

  thead th {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1;
    border: 1px solid hsl(0, 0%, 88%);
    background-color: hsl(0, 0%, 98%);
    padding: 10px;
  }

  table tbody th,
  table tbody td {
    border: 1px solid hsl(0, 0%, 88%);
  }
</style>