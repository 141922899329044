export const ROLE = {
	SUPERADMIN						: 1,
	ADMIN									: 2,
	KRAJSKY_KOORDINATOR		: 3,
	CLEN_DOTACNIHO_VYBORU	: 4,
	STATUTAR							: 5,
	PRACOVNIK_ORGANIZACE	: 6,
	EXTERNI_HODNOTITEL		: 7,
	KONTROLOR							: 8,
}
