<template>

<div>
  <SectionHeader title="Klienti za rok 2022" />

  <label class="form__label">
    <p class="form__label-title">(pro všechny služby mimo programů primární prevence)</p>
  </label>

  <div class="table-container">
    <table class="inputtable" style="width: 100%">
      <tbody>
        <tr v-for="(row, indexRow) in schema" :key="indexRow">
          <th style="width: 10%">{{ row.no }}</th>
          <td style="width: 70%">
            <p v-html="row.title"></p>
          </td>
          <td style="width: 20%">
            <BaseInput
                v-if="row.dataset"
                class="w-100"
                :disabled="!row.editable"
                :name="indexRow"
                :error="isErrorInput(dataset[indexRow])"
                v-model="dataset[indexRow]"
            />
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="form__field mt-4">
    <label style="min-width: 30%" for="specifika" class="form__label">
      <p class="form__label-title">Poznámky, komentáře k tabulce klienti:</p>
      <p class="form__label-description">Popište zejména nárůst či pokles klientů a jejich strukturu ve srovnání s totožným obdobím minulého roku;<br>
        popište jakékoliv skutečnosti (lokální a jiná kontextová specifika), které mohou mít vliv na uváděná data.</p>
    </label>

    <BaseTextarea name="klienti_komentar" class="w-100" :rows="8" v-model="data.klienti_komentar" />
  </div>

  <ol class="mt-4">
    <li>Klient - jakákoli osoba, která v daném období využila některou z nabízených služeb programu. </li>
    <li>Pokud jde o službu, v rámci které je realizován ještě samostatný projekt pro oblast nelátkových závislostí, počet     klientů – hazardních
      hráčů se zde nevyplňuje. V případě samostatného projektu pro oblast nelátkových závislostí musí být vedena oddělená evidence nejenom
      klientů, ale i kontaktů a výkonů a s tím spojených nákladů na rozpočet. V této položce se jedná pouze o hazardní hráče. Klienty s jinou
      nelátkovou závislostí, kteří nesázejí při hře peníze, vykazujte ve 4.2.</li>
    <li>První kontakt – první návštěva klienta v zařízení/programu či první interakce s klientem v terénu. Součástí prvního kontaktu je poskytnutí
      základních informací o zařízení/programu a o službách, které nabízí.</li>
    <li>Neidentifikovaní klienti - klienti programu/zařízení, které v evidenci klientů a poskytovaných služeb nelze vzájemně odlišit
      (tzv. "no-codes"). Tato situace se týká zejména velkých nízkoprahových programů.</li>
    <li>Klienti ve zprostředkovaném kontaktu - jedná se o klienty, kteří nejsou v přímém kontaktu se zařízením/programem,
      ale jsou jim zprostředkovány některé služby. Jedná se zejména o osoby v dosahu "peer pracovníků" programu, typicky
      v případě tzv. sekundární výměny injekčního materiálu.</li>
    <li>Týká se pouze pobytových služeb (tj. adiktologické služby krátkodobé stabilizace, adiktologické služby následné péče (s chráněným
      bydlením), adiktologické služby rezidenční léčby) a dlouhodobých strukturovaných ambulantních (stacionárních) programů.</li>
    <li>Součet všech dnů, které všichni klienti v daném období strávili v programu. U pobytových programů jde o počet lůžkodnů.</li>
    <li>Jak dlouho obvykle trvá jedna léčba/program.</li>
    <li>Počet klientů, kteří ukončili program předčasně před dosažením cílů léčby/služby. Jde např. o nespolupráci ze strany klienta,
      porušení léčebného režimu apod.</li>
    <li>Počet ubytovacích míst v zařízení nebo míst ve strukturovaném stacionárním programu.</li>
  </ol>

</div>
</template>

<script>
import {formatNumberCZ} from '@/utils/format.js'
import {isNumericCZ, toNumberCZ} from '@/utils/convert.js'

import SectionHeader from '@/components/Common/SectionHeader'
import BaseInput from '@/components/Forms/Base/BaseInput'
import TableText from "@/components/Table/TableText.vue"
import TableInput from "@/components/Table/TableInputNumber.vue"
import BaseTextarea from "@/components/Forms/Base/BaseTextarea.vue";

export default {
	name: 'ZaverecnaZpravaKlienti',
	components: {
    BaseTextarea,
		SectionHeader,
		BaseInput,
    TableText,
    TableInput,
	},
	props: {
		projekt: {
			type: Object,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: true,
		},
	},
  created() {
      this.dataset = JSON.parse(this.data.tab_klienti)
  },
  computed: {
      uvazkyCelkem() {
        return 0
      }
  },
	data() {
		return {
      schema: {
        title: {
          no: '1',
          title: 'Klienti<sup>1</sup> – lidé užívající drogy, kromě alkoholu (sk. 2) a tabáku (sk. 3)',
          editable: true,
          dataset: false,
        },
        k11_heroin: {
          no: '1.1',
          title: 'základní droga heroin',
          editable: true,
          dataset: true
        },
        k12_buprenorfin: {
          no: '1.2',
          title: 'základní droga buprenorfin - zneužívaný (non lege artis, injekčně, bez indikace lékařem, z černého trhu atd.)',
          editable: true,
          dataset: true
        },
        k13_metadon: {
          no: '1.3',
          title: 'základní droga metadon - zneužívaný (non lege artis, injekčně, bez indikace lékařem, z černého trhu atd.)',
          editable: true,
          dataset: true
        },
        k14_opiaty: {
          no: '1.4',
          title: 'základní droga jiné opioidy (opium, morfium, fentanyl, tramadol, další opioidní analgetika, etc.)',
          editable: true,
          dataset: true
        },
        k15_pervitin: {
          no: '1.5',
          title: 'základní droga pervitin',
          editable: true,
          dataset: true
        },
        k16_kokain: {
          no: '1.6',
          title: 'základní droga kokain/crack',
          editable: true,
          dataset: true
        },
        k17_thc: {
          no: '1.7',
          title: 'základní droga konopné drogy s obsahem THC',
          editable: true,
          dataset: true
        },
        k18_extaze: {
          no: '1.8',
          title: 'základní droga extáze',
          editable: true,
          dataset: true
        },
        k19_halucinogeny: {
          no: '1.9',
          title: 'základní droga halucinogeny',
          editable: true,
          dataset: true
        },
        k110_tekave_latky: {
          no: '1.10',
          title: 'základní droga těkavé látky',
          editable: true,
          dataset: true
        },
        k111_jina_droga: {
          no: '1.11',
          title: 'jiná základní droga, kromě alkoholu a tabáku',
          editable: true,
          dataset: true
        },
        k112_celkem_klientu: {
          no: '1.12',
          title: 'celkem klientů – lidí užívajících drogy (pozn. součet 1.1 až 1.11)',
          editable: false,
          dataset: true
        },
        k1121_muzi: {
          no: '1.12.1',
          title: '- z toho mužů',
          editable: true,
          dataset: true
        },
        k1122_injekcne: {
          no: '1.12.2',
          title: '- z toho osob užívajících injekčně',
          editable: true,
          dataset: true
        },
        k1123_do17let: {
          no: '1.12.3',
          title: '- z toho dětí do 17 let (včetně)',
          editable: true,
          dataset: true
        },
        k1124_nad50let: {
          no: '1.12.4',
          title: '- z toho osob nad 50 let (včetně)',
          editable: true,
          dataset: true
        },
        k13_prumerny_vek: {
          no: '1.13',
          title: 'průměrný věk klientů – lidí užívajících drogy',
          editable: true,
          dataset: true
        },
        title_2: {
          no: '2',
          title: 'Klienti se základní drogou alkohol',
          editable: false,
          dataset: false
        },
        a_klientu_celkem: {
          no: '2.1',
          title: 'celkem klientů se základní drogou alkohol',
          editable: true,
          dataset: true
        },
        a_muzi: {
          no: '2.1.1',
          title: '- z toho mužů',
          editable: true,
          dataset: true
        },
        a_do17let: {
          no: '2.1.2',
          title: '- z toho dětí do 17 let (včetně)',
          editable: true,
          dataset: true
        },
        a_nad50let: {
          no: '2.1.3',
          title: '- z toho osob nad 50 let (včetně)',
          editable: true,
          dataset: true
        },
        a_prumerny_vek: {
          no: '2.2',
          title: 'průměrný věk klientů se základní drogou alkohol',
          editable: true,
          dataset: true
        },
        title_3: {
          no: '3',
          title: 'Klienti se základní drogou tabák',
          editable: false,
          dataset: false
        },
        t_klientu_celkem: {
          no: '3.1',
          title: 'celkem klientů se základní drogou tabák',
          editable: true,
          dataset: true
        },
        t_muzi: {
          no: '3.1.1',
          title: '- z toho mužů',
          editable: true,
          dataset: true
        },
        t_do17let: {
          no: '3.1.2',
          title: '- z toho dětí do 17 let (včetně)',
          editable: true,
          dataset: true
        },
        t_nad50let: {
          no: '3.1.3',
          title: '- z toho osob nad 50 let (včetně)',
          editable: true,
          dataset: true
        },
        t_prumerny_vek: {
          no: '3.2',
          title: 'průměrný věk klientů se základní drogou tabák',
          editable: true,
          dataset: true
        },
        title_4: {
          no: '4',
          title: 'Klienti s problémem z oblasti nelátkových závislostí',
          editable: false,
          dataset: false
        },
        nz_hazardni_hraci: {
          no: '4.1',
          title: 'počet klientů - hazardních hráčů<sup>2</sup>',
          editable: true,
          dataset: true
        },
        nz_jina_nz: {
          no: '4.2',
          title: 'počet klientů s jinou nelátkovou závislostí',
          editable: true,
          dataset: true
        },
        nz_klientu_celkem: {
          no: '4.3',
          title: 'celkem klientů s problémem z oblasti nelátkových závislostí',
          editable: false,
          dataset: true
        },
        nz_muzi: {
          no: '4.3.1',
          title: '- z toho mužů',
          editable: true,
          dataset: true
        },
        nz_do17let: {
          no: '4.3.2',
          title: '- z toho dětí do 17 let (včetně)',
          editable: true,
          dataset: true
        },
        nz_nad50let: {
          no: '4.3.3',
          title: '- z toho osob nad 50 let (včetně)',
          editable: true,
          dataset: true
        },
        nz_prumerny_vek: {
          no: '4.4',
          title: 'průměrný věk klientů s problémem z oblasti nelátkových závislostí',
          editable: true,
          dataset: true
        },
        title_5: {
          no: '5',
          title: 'Identifikovaní klienti programu celkem',
          editable: false,
          dataset: false
        },
        ik_zavislost: {
          no: '5.1',
          title: 'celkem všech klientů se závislostním problémem (pozn. součet 1.12 + 2.1 + 3.1 + 4.3)',
          editable: false,
          dataset: true
        },
        ik_prvni_kontakt: {
          no: '5.1.1',
          title: '- z toho prvních kontaktů<sup>3</sup>',
          editable: true,
          dataset: true
        },
        ik_ostatni: {
          no: '5.2',
          title: 'celkem ostatních klientů (neuživatelé, rodinní příslušníci, blízcí osob se závislostním problémem)',
          editable: true,
          dataset: true
        },
        ik_klientu_celkem: {
          no: '5.3',
          title: 'celkem všech klientů (uživatelů i neuživatelů) (pozn. součet 5.1 + 5.2)',
          editable: false,
          dataset: true
        },
        title_6: {
          no: '6',
          title: 'Neidentifikovaní klienti<sup>4</sup>',
          editable: false,
          dataset: false
        },
        nk_opiaty: {
          no: '6.1',
          title: 'odhad počtu neidentifikovaných klientů se základní drogou opiáty',
          editable: true,
          dataset: true
        },
        nk_pervitin: {
          no: '6.2',
          title: 'odhad počtu neidentifikovaných klientů s základní drogou pervitin',
          editable: true,
          dataset: true
        },
        nk_injekcni_drogy: {
          no: '6.3',
          title: 'odhad počtu neidentifikovaných klientů - lidí užívajících drogy injekčně',
          editable: true,
          dataset: true
        },
        nk_muzi: {
          no: '6.4',
          title: 'odhad počtu neidentifikovaných klientů - mužů',
          editable: true,
          dataset: true
        },
        title_7: {
          no: '7',
          title: 'Klienti ve zprostředkovaném kontaktu<sup>5</sup>',
          editable: false,
          dataset: false
        },
        zk_odhad_poctu: {
          no: '7.1',
          title: 'odhad počtu klientů ve zprostředkovaném kontaktu',
          editable: true,
          dataset: true
        },
        title_8: {
          no: '8',
          title: 'Klienti v pobytových a dlouhodobých strukturovaných programech<sup>6</sup>',
          editable: false,
          dataset: false
        },
        sp_pobyt: {
          no: '8.1',
          title: 'počet klientů v pobytovém/dlouhodobém strukturovaném programu',
          editable: true,
          dataset: true
        },
        sp_klientodny: {
          no: '8.2',
          title: 'počet klientodnů<sup>7</sup>',
          editable: true,
          dataset: true
        },
        sp_delka: {
          no: '8.3',
          title: 'průměrná délka programu<sup>8</sup>',
          editable: true,
          dataset: true
        },
        sp_predcasne: {
          no: '8.4',
          title: 'počet klientů, kteří ukončili program předčasně<sup>9</sup>',
          editable: true,
          dataset: true
        },
        sp_kapacita: {
          no: '8.5',
          title: 'kapacita zařízení/programu<sup>10</sup>',
          editable: true,
          dataset: true
        },
      },
      dataset: {},
		}
	},
	methods: {
		isNumericCZ,
		toNumberCZ,
		formatNumberCZ,
		isErrorInput(value) {
			return !(this.isNumericCZ(value) || value === '' || value === 'N' || value === 'n')
		},
	},
  watch: {
    dataset: {
      handler () {
        let kTotalCount = 0
        const kTotalKey = 'k112_celkem_klientu'
        const kTotalItems = ['k11_heroin', 'k12_buprenorfin', 'k13_metadon', 'k14_opiaty', 'k15_pervitin', 'k16_kokain', 'k17_thc', 'k18_extaze', 'k19_halucinogeny', 'k110_tekave_latky', 'k111_jina_droga']

        let nzTotalCount = 0
        const nzTotalKey = 'nz_klientu_celkem'
        const nzTotalItems = ['nz_hazardni_hraci', 'nz_jina_nz']

        let ikTotalCount = 0
        const ikTotalKey = 'ik_zavislost'
        const ikTotalItems = ['k112_celkem_klientu', 'a_klientu_celkem', 't_klientu_celkem', 'nz_klientu_celkem']

        let ikTotalCount2 = 0
        const ikTotalKey2 = 'ik_klientu_celkem'
        const ikTotalItems2 = ['ik_zavislost', 'ik_ostatni']

        for (const itemKey in this.dataset) {
          const item = this.dataset[itemKey]

          if (kTotalItems.includes(itemKey)) {
            if(!isNaN(item) && item !== '') {
              kTotalCount += parseInt(item)
            }
          }

          if (nzTotalItems.includes(itemKey)) {
            if(!isNaN(item) && item !== '') {
              nzTotalCount += parseInt(item)
            }
          }

          if (ikTotalItems.includes(itemKey)) {
            if(!isNaN(item) && item !== '') {
              ikTotalCount += parseInt(item)
            }
          }

          if (ikTotalItems2.includes(itemKey)) {
            if(!isNaN(item) && item !== '') {
              ikTotalCount2 += parseInt(item)
            }
          }
        }

        if (kTotalCount) {
          this.dataset[ kTotalKey ] = kTotalCount
        }

        if (nzTotalCount) {
          this.dataset[ nzTotalKey ] = nzTotalCount
        }

        if (ikTotalCount) {
          this.dataset[ ikTotalKey ] = ikTotalCount
        }

        if (ikTotalCount2) {
          this.dataset[ ikTotalKey2 ] = ikTotalCount2
        }

        this.data.tab_klienti = JSON.stringify(this.dataset)
      },
      deep: true
    }
  }
}
</script>
