<template>

<div>
  <SectionHeader title="Výkony" />

  <label class="form__label">
    <p class="form__label-description">Pozn.: Pokud některé z požadovaných údajů neevidujete, uveďte „N“, pokud některé výkony neposkytujete, ponechte políčko prázdné.
      Před vyplněním si pečlivě prostudujte pokyny. Uživatelé aplikace UniData Lite výkony přepočítávat nemusí, aplikace je přepočítává automaticky. </p>
  </label>

  <div v-if="dataset" class="table-container">
    <table>
      <thead>
        <tr>
          <th rowspan="0">Výkon</th>
          <th colspan="4">Počet výkonů<sup>1</sup></th>
          <th rowspan="0">Celkový čas<sup>2</sup></th>
          <th rowspan="0">Počet institucí<sup>3</sup></th>
          <th rowspan="0">Počet klientů<br>(osob/tříd. skupin)<sup>4</sup></th>
          <th colspan="16">Počet témat prevence rizikového chování v rámci výkonu (tématická specifikace výkonu) </th>
        </tr>
        <tr>
          <th>Face to Face</th>
          <th>Telefon</th>
          <th>Internet</th>
          <th>Písemně</th>
          <th>Závislosti<br>-alkohol</th>
          <th>Závislosti<br>-tabák</th>
          <th>Závislosti<br>-nelegální<br>NL</th>
          <th>Závislosti<br>-nelátkové závislosti</th>
          <th>Záškoláctví</th>
          <th>Šikana<br>/agresivita<br>/kyberšikana</th>
          <th>Rizikové<br>sporty</th>
          <th>Rizikové chování v dopravě</th>
          <th>Rasismus a xenofobie</th>
          <th>Sekty</th>
          <th>Sexuální rizikové chování</th>
          <th>Poruchy příjmu potravy</th>
          <th>Problémy spojené se syndromem CAN</th>
          <th>Prevence prekriminálního a kriminálního chování</th>
          <th>Základní vzorce bezpečného chování napříč typy RCH</th>
          <th>Právní vědomí napříč typy RCH</th>
        </tr>
      </thead>
      <tbody>
      <tr v-for="(row, indexRow) in dataset" :key="indexRow">
        <th>{{ translateRowName[indexRow] }}</th>
        <td v-for="(item, index) in row" :key="index">
          <span v-if="['ip_celkem', 'sp_celkem', 'pr_celkem', 'rk_celkem'].includes(indexRow)">{{ item }}</span>
          <BaseInput
              v-else
              :name="`input-${index}`"
              :error="isErrorInput(dataset[indexRow][index])"
              v-model="dataset[indexRow][index]"
          />
        </td>
      </tr>
      </tbody>
    </table>
  </div>

  <div class="form__field form__field--full mt-4">
    <label for="vykony_komentar" class="form__label">
      <p class="form__label-title">Poznámky, komentáře k výkonům programů primární prevence:</p>
      <p class="form__label-description">Popište zejména nárůst či pokles klientů a výkonů a jejich strukturu ve srovnání s totožným obdobím minulého roku; popište jakékoliv skutečnosti (lokální a jiná kontextová specifika), která mohou mít vliv na uváděná data.</p>
    </label>

    <BaseTextarea name="vykony_komentar" v-model="data.vykony_komentar" :rows="8" class="w100" />
  </div>

  <ol class="mt-4">
    <li>
      Počet výkonů – součet výkonů realizovaných s klientem nebo v jeho prospěch s ostaními osobami (např. s rodičem, třídním učitelem, metodikem prevence) v daném období.<br>
      Výkon - časově ohraničená jednotka práce s cílovou skupinou, která má jasně definovaný proces a zároveň splňuje další kritéria stanovená Standardy odborné způsobilosti poskytovatelů programů školské primární prevence rizikového chování MŠMT či Standardy odborné způsobilosti adiktologických služeb v části Adiktologické preventivní služby.
    </li>"
    <li>
      Téma je povinnou specifikací výkonů v oblasti primární prevence. Specifikace tématu rozlišuje, jaké je hlavní téma / zaměření výkonu. Výkon se může sekundárně dotýkat více témat, ale uvádí se pouze hlavní téma. Počty specifikací se nepřepočítávají na časovou dotaci (počty výkonů). V případě, kdy je výkon zaměřen na více hlavních témat, uvádí se tato témata všechna, přičemž nedochází k dělení výkonu. Tématická specifikace se týká všech výkonů – pokud výkon má téma, potom uvádějte tematickou specifikaci. Může se však stát, že výkon téma nemá – v takovém případě se tematická specifikace neuvádí.
    </li>"
    <li>
      Celkový čas – součet časů, po které trvala realizace výkonů (uvedeno v hodinách ve formátu hh:mm).
    </li>
    <li>
      Počet institucí – počet škol či jiných institucí, jejichž „podřízené subjekty“ (např. žáci, pedagogové) byly klienty výkonů.
    </li>
    <li>
      Počet klientů (osob / tříd / skupin) – počet klientů, s kterými byly realizovány, nebo v jejichž prospěch byly realizovány, výkony. V případě skupinové práce u všeobecné primární prevence uvádějte počet tříd/skupin. V případě ostatních výkonů uvádějte počet osob.
    </li>
  </ol>

</div>
</template>

<script>
import {formatNumberCZ} from '@/utils/format.js'
import {isNumericCZ, toNumberCZ} from '@/utils/convert.js'

import SectionHeader from '@/components/Common/SectionHeader'
import BaseInput from '@/components/Forms/Base/BaseInput'
import TableText from "@/components/Table/TableText.vue"
import TableInput from "@/components/Table/TableInputNumber.vue"
import BaseTextarea from "@/components/Forms/Base/BaseTextarea.vue";

export default {
	name: 'ZaverecnaZpravaVykony',
	components: {
    BaseTextarea,
		SectionHeader,
		BaseInput,
    TableText,
    TableInput,
	},
	props: {
		projekt: {
			type: Object,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: true,
		},
	},
  created() {
      this.dataset = JSON.parse(this.data.tab_vykony)
  },
  computed: {
      uvazkyCelkem() {
        return 0
      }
  },
	data() {
		return {
      dataset: {},
      translateRowName: {
        ip_celkem: 'Individuální práce (celkem)',
        ip_vpp: 'VPP',
        ip_spp: 'SPP',
        ip_ipp: 'IPP',
        ip_vzdelavani: 'Vzdělávání',
        sp_celkem: 'Skupinová práce (celkem)',
        sp_vpp: 'VPP',
        sp_spp: 'SPP',
        sp_ipp: 'IPP',
        sp_vzdelavani: 'Vzdělávání',
        pr_celkem: 'Práce s rodinou (celkem)',
        pr_ipp: 'IPP',
        rk_celkem: 'Krátký rozhovor s klientem (celkem)',
        rk_vpp: 'VPP',
        rk_spp: 'SPP',
        rk_ipp: 'IPP',
        rk_vzdelavani: 'Vzdělávání',
      }
		}
	},
	methods: {
		isNumericCZ,
		toNumberCZ,
		formatNumberCZ,
		isErrorInput(value) {
			return !(this.isNumericCZ(value) || value === '' || value === 'N' || value === 'n' || value === null)
		},
	},
  watch: {
    dataset: {
      handler () {
        if (!this.dataset) {
          return
        }

        const sums = {
          'ip_celkem': ['ip_vpp', 'ip_spp', 'ip_ipp', 'ip_vzdelavani'],
          'sp_celkem': ['sp_vpp', 'sp_spp', 'sp_ipp', 'sp_vzdelavani'],
          'pr_celkem': ['pr_ipp'],
          'rk_celkem': ['rk_vpp', 'rk_spp', 'rk_ipp', 'rk_vzdelavani']
        }

        for (const sumKey in sums) {
          const sum = sums[sumKey]
          const totals = []

          for (const areaKey in sum) {
            const area = sum[areaKey]

            for (const key in this.dataset[area]) {
              const item = this.dataset[area][key]

              if (!totals.hasOwnProperty(key)) {
                totals[key] = 0
              }

              if (!isNaN(item) && item !== '' && item !== null) {
                totals[key] += parseFloat(item)
              }
            }
          }

          for (const totalKey in totals) {
            this.dataset[sumKey][totalKey] = totals[totalKey]
          }
        }

        this.data.tab_vykony = JSON.stringify(this.dataset)
      },
      deep: true
    }
  }
}
</script>

<style scoped>
.table-container{
  overflow: auto;
}

table{
  border-collapse: separate;
  border-spacing: 0;
}

thead {
  background-color: hsl(0, 0%, 98%);
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}

table thead th {
  border: 1px solid hsl(0, 0%, 88%);
  background-color: hsl(0, 0%, 98%);
  padding: 10px;
}

tbody th{
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  background-color: hsl(0, 0%, 98%);
  padding: 10px;
}

table tbody tr{
  border: 1px solid hsl(0, 0%, 88%);
}

table tbody tr th,
table tbody tr td {
  border: 1px solid hsl(0, 0%, 88%);
  padding: 4px;
}

table td{
  text-align: center;
}

table input{
  width: 100px;
  text-align: center;
}
</style>