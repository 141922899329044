<template>
  <div class="app__container">
    <Header />
    <div class="app__content">
      <router-view />
    </div>
  </div>
</template>

<script>
import Header from '@/components/Header/Header'
import axios from 'axios'

export default {
  name: 'App',

  components: {
    'Header': Header
  },

}
</script>

<style>

</style>
