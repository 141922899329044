<template>
  <Dialog title="Změna hesla" class="dialog">
    <div v-if="passwordChanged">

      <p>Vaše heslo bylo změněno.</p>

      <div class="buttons">
        <button
            class="button button--outline button--outline"
            @click="$emit('cancel')"
        >
          Ok
        </button>

      </div>
    </div>

    <div v-else>

      <form class="form form--size20 form--column">

        <Message
            class="mb-3"
            type="error"
            v-if="error"
        >
          {{ error }}
        </Message>

        <Message
            class="mb-3"
            type="info"
            v-if="info"
        >
          {{ info }}
        </Message>

        <div class="form__field form__field--required">
          <label for="email" class="form__label">Stávající heslo</label>
          <input
              class="form__input"
              type="password"
              name="current_password"
              id="current-password"
              v-model="currentPassword"
          >
        </div>

        <div class="form__field form__field--required">
          <label for="email" class="form__label">Nové heslo</label>
          <input
              class="form__input"
              type="password"
              name="current_email"
              id="current-email"
              v-model="newPassword"
          >
        </div>

        <div class="form__field form__field--required">
          <label for="email" class="form__label">Nové heslo <br>(pro kontrolu)</label>
          <input
              class="form__input"
              type="password"
              name="current_email"
              id="current-email"
              v-model="repeatPassword"
          >
        </div>
      </form>

      <hr class="form__divider">

      <div class="buttons">
        <button
            class="button button--outline"
            @click="handleChangePassword"
        >
          Změnit heslo
        </button>

        <button
            class="button button--outline button--neutral"
            @click="$emit('cancel')"
        >
          Storno
        </button>

      </div>
    </div>


  </Dialog>
</template>

<script>
import bcrypt from 'bcryptjs'
import { authComputed } from '@/store/helpers.js'
import Dialog from '@/components/Common/Dialog'
import LoginService from '@/services/LoginService'

export default {
  name: 'ChangePasswordDialog',
  components: {
    Dialog,
  },
  emits: ['submit', 'cancel'],
  props: {
    dotacniRok: {
      type: String,
      required: true,
    }
  },
  computed: {
    ...authComputed
  },
  data() {
    return {
      currentPassword: '',
      newPassword: '',
      repeatPassword: '',
      error: '',
      info: '',
      passwordChanged: false,
    }
  },
  methods: {
    async handleChangePassword() {
      this.error = ''

      if (this.currentPassword === '') {
        this.error += 'Stávající heslo je povinné. '
      }

      if (this.newPassword === '') {
        this.error += 'Nové heslo je povinné. '
      }

      if (this.repeatPassword === '') {
        this.error += 'Heslo pro kontrolu je povinné. '
      }

      if (this.newPassword !== this.currentPassword) {
        this.error += 'Hesla se neshodují. '
      }

      if (this.error === '') {
        return
      }

      let hashNewPassword = ''
      try {
        hashNewPassword = await bcrypt.hash(this.newPassword, 10)
      } catch(error) {
        this.error = error
        return
      }

      try {
        const data = {
          'heslo': hashNewPassword,
          'overeni': this.currentPassword
        }
        const response = await LoginService.updatePassword(this.userId, data)

        if (response.data.success) {
          this.info = 'Heslo bylo změněno.'
          this.currentPassword = ''
          this.newPassword = ''
          this.repeatPassword = ''
          this.passwordChanged = true
        } else {
          this.error = response.data.error
        }

      } catch (error) {
        this.error += error.message
      }
    }
  }
}
</script>
