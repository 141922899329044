<template>
	<input
		class="form__input form__input--smallest"
		:type="type"
		:disabled="disabled"
		:value="value"
		@input="updateValue($event.target.value)"
	>
</template>

<script>
import { separateThousands } from '@/utils/convert.js';

export default {
	name: 'SimpleNumber',

	props: {
		value: {
			type: [Number, String],
			default: '',
		},
		type: {
			type: String,
			default: 'text',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		updateValue(value) {
			const formatedValue = separateThousands(value)
			this.$emit('update', formatedValue)
		}
	},

}
</script>
