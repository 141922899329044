<template>
	<PageHeader :title="nazevProjektu">
		<div class="buttons" v-if="!projektDeleted">
			<button
				v-if="projekt.stav === STAV_PROJEKTU.UZAMCENO && ( isSuperAdmin || isAdmin)"
				class="button button--small button--outline"
				@click="dialogVraceni"
			>
				<font-awesome-icon icon="recycle" class="button__icon" />
				Vrátit projekt k přepracování
			</button>

			<button
				class="button button--small button--outline"
				@click="exportProjektuDoPdf"
			>
				<font-awesome-icon icon="file-pdf" class="button__icon" />
				Export projektu do PDF
			</button>

			<button
				v-if="projekt.stav === STAV_PROJEKTU.PODAVANI && !isGov"
				class="button button--small button--outline"
				@click="dialogPrejmenovani"
			>
				<font-awesome-icon icon="edit" class="button__icon" />
				Přejmenovat projekt
			</button>

			<button
				v-if="projekt.stav === STAV_PROJEKTU.PODAVANI || isSuperAdmin || isAdmin"
				class="button button--small button--danger button--outline"
				@click="dialogSmazani"
			>
				<font-awesome-icon icon="trash" class="button__icon" />
				Smazat projekt
			</button>
		</div>
	</PageHeader>

	<Modal
		v-if="modalOpened"
		:overlay="true"
	>
		<ProjektSmazatDialog
			v-if="modalDialog ==='smazat'"
			@submit="smazatProjekt"
			@cancel="closeModal"
		/>

		<ProjektPrejmenovatDialog
			v-if="modalDialog ==='prejmenovat'"
			:projekt="projekt"
			@submit="prejmenovatProjekt($event)"
			@cancel="closeModal"
		/>

		<ProjektVratitDialog
			v-if="modalDialog ==='vratit'"
			:projekt="projekt"
			@submit="vratitProjekt"
			@cancel="closeModal"
		/>

	</Modal>


	<Message type="info" v-if="message">
		{{ message }}
	</Message>

	<Message type="error" v-if="error">
		{{ error }}
	</Message>

	<Loading v-if="!dataLoaded || isDeleting" />

	<template v-if="dataLoaded && !projektDeleted">
    <section class="content">
      <div class="infogrid">
        <table class="infogrid__table">
          <tr>
            <th class="infogrid__label">Kód projektu:</th>
            <td>{{ projekt.kod }}</td>
          </tr>
          <tr>
            <th class="infogrid__label">Název projektu:</th>
            <td>{{ projekt.nazev }}</td>
          </tr>
          <tr>
            <th class="infogrid__label">Dotační rok:</th>
            <td>{{ projekt.dotacni_rok }}</td>
          </tr>
        </table>

        <table class="infogrid__table">
          <tr>
            <th class="infogrid__label">Stav projektu:</th>
            <td>{{ projekt.stav_nazev }}</td>
          </tr>
          <tr>
            <th class="infogrid__label">Požadované dotace:</th>
            <td>{{ dataC2.pd_naklady_projektu_celkem }}</td>
          </tr>
          <tr>
            <th class="infogrid__label">Přidělené dotace:</th>
            <td>{{ projekt.celkova_dotace }}</td>
          </tr>
        </table>

      </div>
    </section>

		<div class="panelgrid mt-5" v-if="projekt.stav === STAV_PROJEKTU.VRACENO">
			<div class="panelgrid__panel panelgrid__panel--danger">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">Projekt vrácen k přepracování</h2>
					<p>Projekt Vám byl vrácen k přepracování. Zdůvodnění najdete v popisu vpravo.</p>
					<p>Opravte nedostatky, vraťte se na <router-link :to="{ name: 'Zadost' }">hlavní stranu žádosti</router-link> a žádost znovu odešlete ke kontrole.</p>
				</div>
			</div>

			<div class="panelgrid__panel panelgrid__panel--danger panelgrid__panel--twocolumns">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">Důvod vrácení</h2>
					<pre>{{ projekt.poznamka_vraceni_projektu }}</pre>
				</div>
			</div>
		</div>


		<div class="panelgrid mt-5" v-if="projekt.stav === STAV_PROJEKTU.UPRAVA_ROZPOCTU">
			<div class="panelgrid__panel panelgrid__panel--danger">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">Schválená dotace - upravte konečnou podobu rozpočtu</h2>
					<p>Projektu byla schválena dotace ve výši <strong>{{ formatNumberCZ(projekt.celkova_dotace, '-') }} Kč</strong></p>
					<p>Upravte <router-link :to="{ name: 'Projekt-Rozpocet', params: { id: id }}">konečnou podobu rozpočtu</router-link>.</p>
				</div>
			</div>
		</div>


		<div class="panelgrid mt-5" v-if="projekt.stav === STAV_PROJEKTU.VRACENI_ROZPOCTU">
			<div class="panelgrid__panel panelgrid__panel--danger">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">Konečná podoba rozpočtu vrácena k přepracování</h2>
					<p>Opravte <router-link :to="{ name: 'Projekt-Rozpocet', params: { id: id }}">konečnou podobu rozpočtu</router-link> a znovu ji odešlete ke schválení.</p>
				</div>
			</div>

			<div class="panelgrid__panel panelgrid__panel--danger panelgrid__panel--twocolumns">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">Důvod vrácení konečné podoby rozpočtu</h2>
					<pre>{{ projekt.poznamka_vraceni_projektu }}</pre>
				</div>
			</div>
		</div>


		<div class="panelgrid mt-5">

			<div class="panelgrid__panel">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">ŽÁDOST O DOTACI (část B1)</h2>
					<ul class="panelgrid__list">
						<li>Předmět žádosti</li>
						<li>Personální obsazení</li>
						<li>Komentář k rozpočtu</li>
					</ul>
				</div>
				<div class="panelgrid__buttons">
					<button
						class="button button--outline"
						@click="$router.push({ name: 'Projekt-B1', params: { id: id } })"
					>
						<font-awesome-icon :icon="jeProjektZamceny ? 'eye' : 'edit'" class="button__icon" />
						{{ jeProjektZamceny ? 'Zobrazit' : 'Upravit' }}
					</button>
				</div>
			</div>

			<div class="panelgrid__panel">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">CHARAKTERISTIKA A POPIS {{ projekt.typ == TYP_PROJEKTU.SLUZBA ? 'SLUŽBY' : 'PROJEKTU' }} (část B2)</h2>
					<ul class="panelgrid__list" v-if="projekt.typ == TYP_PROJEKTU.SLUZBA">
						<li>Informace o zařízení</li>
						<li>Cílová skupina</li>
						<li>Program primární prevence</li>
						<li>Zázemí a místa realizace</li>
						<li>Provozní doba</li>
						<li>Popis služby</li>
						<li>Změny oproti předchozímu roku</li>
					</ul>
					<ul class="panelgrid__list" v-if="projekt.typ == TYP_PROJEKTU.IVH">
						<li>Informace o zařízení</li>
						<li>Popis projektu</li>
						<li>Změny oproti předchozímu roku</li>
					</ul>
				</div>
				<div class="panelgrid__buttons">
					<button
						class="button button--outline"
						@click="$router.push({ name: 'Projekt-B2', params: { id: id } })"
					>
						<font-awesome-icon :icon="jeProjektZamceny ? 'eye' : 'edit'" class="button__icon" />
						{{ jeProjektZamceny ? 'Zobrazit' : 'Upravit' }}
					</button>
				</div>
			</div>

			<div class="panelgrid__panel">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">ROZPOČTY PROJEKTU (část C)</h2>
					<ul class="panelgrid__list">
						<li>Část C1 - Zdroje</li>
						<li>Část C2 - Rozpočet</li>
						<li>Část C3 - Pracovníci na HPP</li>
						<li>Část C4 - Pracovníci na DPČ</li>
						<li>Část C5 - Pracovníci na DPP</li>
					</ul>
				</div>
				<div class="panelgrid__buttons">
					<button
						class="button button--outline"
						@click="$router.push({ name: 'Projekt-C', params: { id: id } })"
					>
						<font-awesome-icon :icon="jeProjektZamceny ? 'eye' : 'edit'" class="button__icon" />
						{{ jeProjektZamceny ? 'Zobrazit' : 'Upravit' }}
					</button>
				</div>
			</div>


			<div class="panelgrid__panel" v-if="isGov">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">PŘÍLOHA ŽÁDOSTI</h2>
					<p v-if="zadost.id_priloha"><strong>{{ zadost.priloha_puvodni_nazev }}</strong></p>
					<p v-else>Žádost nemá žádnou přílohu.</p>
				</div>
				<div class="panelgrid__buttons">
					<button
						v-if="zadost.id_priloha"
						class="button button--outline"
						@click="stahnoutPrilohu"
					>
						<font-awesome-icon icon="download" class="button__icon" />
						Stáhnout přílohu
					</button>
				</div>
			</div>

			<div class="panelgrid__panel" v-if="jeMozneZobrazitRozpocet">
				<div class="panelgrid__content">
					<h2 class="panelgrid__title">KONEČNÁ PODOBA ROZPOČTU</h2>
					<p>Konečná podoba rozpočtu vzhledem k přidělené dotaci.</p>
				</div>
				<div class="panelgrid__buttons">
					<button
						class="button button--outline"
						@click="$router.push({ name: 'Projekt-Rozpocet', params: { id: id } })"
					>
						<font-awesome-icon :icon="jeRozpocetEditovatelny ? 'edit' : 'eye'" class="button__icon" />
						{{ jeRozpocetEditovatelny ? 'Upravit' : 'Zobrazit' }}
					</button>
				</div>
			</div>
		</div>


    <template v-if="jeMozneZobrazitVyuctovani">

      <h3 class="mt-5">Vyúčtování dotace</h3>
      <div class="panelgrid mt-2">

          <div class="panelgrid__panel">
            <div class="panelgrid__content">
              <h2 class="panelgrid__title">VYÚČTOVÁNÍ DOTACE</h2>
              <p>Vyúčtování dotace přidělené projektu.</p>
            </div>
            <div class="panelgrid__buttons">
              <button
                class="button button--outline"
                @click="$router.push({ name: 'Projekt-Vyuctovani', params: { id: id } })"
              >
                <font-awesome-icon :icon="!isGov ? 'edit' : 'eye'" class="button__icon" />
                {{ !isGov ? 'Upravit' : 'Zobrazit' }}
              </button>
            </div>
          </div>

          <div class="panelgrid__panel">
            <div class="panelgrid__content">
              <h2 class="panelgrid__title">VÝKAZ ZISKŮ A ZTRÁT</h2>
              <p>Výkaz zisků a ztrát a další přílohy.</p>
            </div>
            <div class="panelgrid__buttons">
              <button
                class="button button--outline"
                @click="$router.push({ name: 'Projekt-Vyuctovani-prilohy', params: { id: id } })"
              >
                <font-awesome-icon :icon="!isGov ? 'edit' : 'eye'" class="button__icon" />
                {{ !isGov ? 'Upravit' : 'Zobrazit' }}
              </button>
            </div>
          </div>


          <template  v-if="jeMozneZobrazitZaverecnaZprava">
              <div class="panelgrid__panel">
                <div class="panelgrid__content">
                  <h2 class="panelgrid__title">ZÁVĚREČNÁ ZPRÁVA</h2>
                  <p>Závěrečná zpráva projektu.</p>
                </div>
                <div class="panelgrid__buttons">
                  <button
                    class="button button--outline"
                    @click="$router.push({ name: 'Projekt-Zaverecna-zprava', params: { id: id } })"
                  >
                    <font-awesome-icon :icon="!isGov ? 'edit' : 'eye'" class="button__icon" />
                    {{ !isGov ? 'Upravit' : 'Zobrazit' }}
                  </button>
                </div>
              </div>
          </template>
        </div>
      </template>

	  </template>

	<button
		v-if="!isGov"
		class="button button--outline mt-5"
		@click="$router.push({ name: 'Zadost' , query: {dotacniRok: projekt.dotacni_rok}})"
	>
		<font-awesome-icon icon="arrow-left" class="button__icon" />
		Zpět na hlavní stranu žádosti
	</button>

</template>

<script>
import {
	TYP_PROJEKTU,
	STAV_PROJEKTU,
} from '@/constants'

import { authComputed, optionsComputed } from '@/store/helpers.js'
import { formatNumberCZ, formatDateCZ } from '@/utils/format.js'

import ProjektyService from '@/services/ProjektyService'

import { saveAs } from 'file-saver'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import Modal from '@/components/Common/Modal'

import ProjektSmazatDialog from '@/components/Projekty/Dialog/ProjektSmazatDialog'
import ProjektPrejmenovatDialog from '@/components/Projekty/Dialog/ProjektPrejmenovatDialog'
import ProjektVratitDialog from '@/components/Projekty/Dialog/ProjektVratitDialog'

export default {
	name: 'Projekt',

	components: {
		PageHeader,
		Message,
		Loading,
		Modal,
		ProjektSmazatDialog,
		ProjektPrejmenovatDialog,
		ProjektVratitDialog,
	},

	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},

	data() {
		return {
      dataC2: '',
			modalOpened: false,
			modalDialog: '',

			error: '',
			message: '',
			dataLoaded: false,

			rozpocetDuvodVraceni: '',
			rozpocetLoaded: false,

			isDeleting: false,
			projektDeleted: false,

			projekt: {},
			zadost: {},
		}
	},

	computed: {
		...authComputed,
		...optionsComputed,

		nazevProjektu() {
			return (
				this.dataLoaded
				? this.projekt.kod + ': ' + this.projekt.nazev
				: 'PROJEKT'
			)
		},

    optionsDotacniRok() {
      return this.getOptionsDotacniRok(this.projekt.dotacni_rok)
    },

		jeMoznePodatZadost() {
      return (Date.now() > Date.parse(this.optionsDotacniRok.podani_zadosti_od)) && (Date.now() < Date.parse(this.optionsDotacniRok.podani_zadosti_do))
		},

		jeProjektZamceny() {
			return (
				this.projekt.stav === STAV_PROJEKTU.UZAMCENO
				|| this.projekt.stav === STAV_PROJEKTU.SCHVALENO
				|| this.projekt.stav === STAV_PROJEKTU.ZAMITNUTO
				|| this.projekt.stav === STAV_PROJEKTU.UPRAVA_ROZPOCTU
				|| this.projekt.stav === STAV_PROJEKTU.VRACENI_ROZPOCTU
				|| this.projekt.stav === STAV_PROJEKTU.KONECNY_ROZPOCET
				|| this.isGov
				|| !this.jeMoznePodatZadost
			)
		},

		jeMozneZobrazitRozpocet() {
			return (
				this.projekt.stav === STAV_PROJEKTU.UPRAVA_ROZPOCTU
				|| this.projekt.stav === STAV_PROJEKTU.VRACENI_ROZPOCTU
				|| this.projekt.stav === STAV_PROJEKTU.KONECNY_ROZPOCET
			)
		},

		jeRozpocetEditovatelny() {
			return (
				this.projekt.stav === STAV_PROJEKTU.UPRAVA_ROZPOCTU
				|| this.projekt.stav === STAV_PROJEKTU.VRACENI_ROZPOCTU
			)
		},



		jeMozneZobrazitVyuctovani() {
			return (
          (
				    this.projekt.stav === STAV_PROJEKTU.CEKA_NA_VYUCTOVANI
            || this.projekt.stav === STAV_PROJEKTU.VRACENI_VYUCTOVANI
            || this.projekt.stav === STAV_PROJEKTU.VYUCTOVANI_ODESLANO
          )

            && (Date.now() >= Date.parse(this.optionsDotacniRok.predlozeni_vyuctovani_dotace_od))
			)
		},

    jeMozneZobrazitPrilohuVykazZiskuAZtrat() {
      return (
          this.projekt.stav === STAV_PROJEKTU.CEKA_NA_VYUCTOVANI
          || this.projekt.stav === STAV_PROJEKTU.VRACENI_VYUCTOVANI
          || this.projekt.stav === STAV_PROJEKTU.VYUCTOVANI_ODESLANO
      )
    },

    jeMozneZobrazitZaverecnaZprava() {
      return (
          (
            this.projekt.stav === STAV_PROJEKTU.CEKA_NA_VYUCTOVANI
            || this.projekt.stav === STAV_PROJEKTU.VRACENI_VYUCTOVANI
            || this.projekt.stav === STAV_PROJEKTU.VYUCTOVANI_ODESLANO
          ) &&
          (Date.now() >= Date.parse(this.optionsDotacniRok.predlozeni_zaverecne_zpravy_od) && (Date.now() <= Date.parse(this.optionsDotacniRok.predlozeni_zaverecne_zpravy_do)))
      )
    },

		jeVyuctovaniEditovatelne() {
			return true
		},

	},

	methods: {
		formatNumberCZ,
		formatDateCZ,

		closeModal() {
			this.modalOpened = false
			this.modalDialog = ''
		},

		vratitProjekt() {
			this.closeModal()
			this.loadData()
		},

		prejmenovatProjekt(data) {
			this.closeModal()
			this.projekt.nazev = data.nazev
			this.projekt.kod = data.kod
		},

		dialogVraceni() {
			this.modalDialog = 'vratit'
			this.modalOpened = true
		},

		dialogPrejmenovani() {
			this.modalDialog = 'prejmenovat'
			this.modalOpened = true
		},

		dialogSmazani() {
			this.modalDialog = 'smazat'
			this.modalOpened = true
		},

		smazatProjekt() {
			this.closeModal()
			this.isDeleting = true

			ProjektyService
			.deleteProjekt(this.id)
			.then(response => {
				if (response.data.success) {
					this.projektDeleted = true
					this.message = 'Projekt byl úspěšně smazán.'
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				this.isDeleting = false
			})
		},


		stahnoutPrilohu() {
			ProjektyService
			.stahnoutPrilohuZadosti(this.zadost.id_priloha)
			.then((response) => {
				saveAs(new Blob([response.data]), this.zadost.priloha_puvodni_nazev)
			})
		},


		exportProjektuDoPdf() {
			ProjektyService
			.exportPDF(this.id)
			.then((response) => {
				saveAs(new Blob([response.data]), `projekt-${this.projekt.kod}.pdf`)
			})
		},


		loadRozpocet() {
			this.rozpocetLoaded = false

			ProjektyService
			.getRozpocet(this.id, 1)
			.then(response => {
				if (response.data.success) {
					this.rozpocetDuvodVraceni = response.data.data.duvod_vraceni
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				this.rozpocetLoaded = true
			})
		},

		loadData() {
			this.dataLoaded = false

			ProjektyService
			.getProjekt(this.id)
			.then(response => {
				if (response.data.success) {
					this.projekt = response.data.data.projekt
					this.zadost = response.data.data.zadost
          this.dataC2 = response.data.data.c2
					if (this.projekt.stav === this.STAV_PROJEKTU.VRACENI_ROZPOCTU) {
						this.loadRozpocet()
					}
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				this.dataLoaded = true
			})
		},

	},


	created() {
		// constants
		this.TYP_PROJEKTU = TYP_PROJEKTU
		this.STAV_PROJEKTU = STAV_PROJEKTU

		this.loadData()
	},

}
</script>
