<template>
	<PageHeader
		:title="`PŘÍLOHY VYÚČTOVÁNÍ ${nazevProjektu}`"
		:subtitle="`Dotační rok ${dotacniRok}`"
	/>

	<Tabs
		:items="tabItems"
		:selected="tabSelected"
		@changeTab="changeTab($event)"
	/>

	<div class="content">

		<Message type="error" v-if="error" class="mb-5">
			{{ error }}
		</Message>

		<Loading v-if="!dataLoaded" />

		<div
			v-if="dataLoaded"
			class="grid grid--double-gap"
		>
			<div class="grid-column">

				<VyuctovaniPrilohyVykaz
					:fileId="vyuctovani.id_priloha_vykaz"
					:fileName="vyuctovani.priloha_vykaz_nazev"
					:disabled="!jeMoznePodatVyuctovani"
					@uploaded="updateFile('vykaz', $event)"
				/>

			</div>
			<div class="grid-column">

				<VyuctovaniPrilohyDalsi
					:fileId="vyuctovani.id_priloha_dalsi"
					:fileName="vyuctovani.priloha_dalsi_nazev"
					:disabled="!jeMoznePodatVyuctovani"
					@uploaded="updateFile('dalsi', $event)"
				/>

			</div>
		</div>
	</div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import Tabs from '@/components/Common/Tabs'

import VyuctovaniPrilohyVykaz from '@/components/Projekty/Vyuctovani/VyuctovaniPrilohyVykaz'
import VyuctovaniPrilohyDalsi from '@/components/Projekty/Vyuctovani/VyuctovaniPrilohyDalsi'


export default {
	name: 'ProjektVyuctovaniPrilohy',

	components: {
		PageHeader,
		Message,
		Loading,
		Tabs,
		VyuctovaniPrilohyVykaz,
		VyuctovaniPrilohyDalsi,
	},

	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},

	data() {
		return {
			error: '',
			savingError: '',

			vyuctovaniLoaded: false,
			projektLoaded: false,
			isSaving: false,

			vyuctovani: null,
			projekt: null,

			tabSelected: 1,
			tabItems: [
				{
					id: 0,
					title: 'Zpět na projekt',
					icon: 'arrow-left',
				},
				{
					id: 1,
					title: 'Výkaz zisků a ztrát',
					subtitle: 'a další přílohy',
				},
			],
		}
	},

	computed: {
		...authComputed,

		dataLoaded() {
			return this.vyuctovaniLoaded && this.projektLoaded
		},

		nazevProjektu() {
			return (
				this.projektLoaded
				? ` - ${this.projekt.kod}: ${this.projekt.nazev}`
				: ''
			)
		},

		dotacniRok() {
			return this.projektLoaded ? this.projekt.dotacni_rok	: ''
		},

		jeMoznePodatVyuctovani() {
			return true
		}
	},

	methods: {
		changeTab(tabId) {
			if (tabId === 0) {
				// zpět na projekt
				this.$router.push({ name: 'Projekt', params: { id: this.id } })
			}
			this.tabSelected = tabId
		},

		updateFile(typ, uploadData) {

			console.log(typ, uploadData)
			// probehl upload souboru, nacteme nova data
			this.vyuctovani[`id_priloha_${typ}`] = uploadData.nazev
			this.vyuctovani[`priloha_${typ}_nazev`] = uploadData.puvodni_nazev

			this.updateVyuctovani();
		},

		async updateVyuctovani() {
			this.isSaving = true
			this.savingError = ''

			const data = {
				...this.vyuctovani,
				...this.vyuctovani.tab1,
				...this.vyuctovani.tab2,
				...this.vyuctovani.tab3,
			}
			delete data.tab1
			delete data.tab2
			delete data.tab3

			try {
				const response = await ProjektyService.updateVyuctovani(this.projekt.id, data)
				if (!response.data.success) {
					this.savingError += response.data.error
				} else {
					this.vyuctovani.uzamceno = uzamceno
				}
			} catch(err) {
				this.savingError += err.message
			}

			this.isSaving = false
		},

		async loadVyuctovani() {
			this.vyuctovaniLoaded = false

			try {
				const response = await ProjektyService.getVyuctovani(this.id)
				if (!response.data.success) {
					this.error += response.data.error
					return
				}
				this.vyuctovani = response.data.data
			} catch(err) {
				this.error += err.message
				return
			}

			this.vyuctovaniLoaded = true
		},

		async loadProjekt() {
			this.projektLoaded = false

			try {
				const response = await ProjektyService.getProjekt(this.id)
				if (!response.data.success) {
					this.error += response.data.error
					return
				}
				this.projekt = response.data.data.projekt
			} catch(err) {
				this.error += err.message
				return
			}

			this.projektLoaded = true
		},

	},


	created() {
		this.loadVyuctovani()
		this.loadProjekt()
	},

}
</script>
