<template>
	<Dialog
		title="Převodní tabulka typologie adiktologických služeb"
		:wide="true"
		:closeButton="true"
		@close="close"
	>

		<Message class="mb-3"	type="info">
			<p>Typy služeb podle Standardů odborné způsobilosti adiktologických služeb (nová typologie adiktologických služeb platná od listopadu 2021).</p>
			<p>Níže uvádíme k možnému využití zjednodušený převodník mezi původní a novou typologií služeb. Nicméně doporučujeme vybrat typ služby dle Standardů odborné způsobilosti adiktologických služeb.</p>
		</Message>

		<table class="table">
			<thead>
				<tr>
					<th>Stará typologie</th>
					<th>Nová typologie</th>
				</tr>
			</thead>
			<tbody>
				<tr>
					<td>PP - Specifická primární prevence	</td>
					<td>P - Adiktologické preventivní služby (prevence)</td>
				</tr>
				<tr>
					<td>KPS - Kontaktní a poradenské služby	</td>
					<td>HR - Adiktologické služby minimalizace rizik (minimalizace rizik)</td>
				</tr>
				<tr>
					<td>TP - Terénní programy</td>
					<td>HR - Adiktologické služby minimalizace rizik (minimalizace rizik)</td>
				</tr>
				<tr>
					<td>AL - Ambulantní léčba	</td>
					<td>AL - Adiktologické služby ambulantní léčby a poradenství (ambulantní léčba)</td>
				</tr>
				<tr>
					<td>SP - Ambulantní stacionární péče</td>
					<td>AL - Adiktologické služby ambulantní léčby a poradenství (ambulantní léčba)</td>
				</tr>
				<tr>
					<td>RPTK - Rezidenční péče v terapeutických komunitách</td>
					<td>RL - Adiktologické služby rezidenční léčby (rezidenční léčba)</td>
				</tr>
				<tr>
					<td>DP - Doléčovací programy</td>
					<td>NP - Adiktologické služby následné péče (následná péče)</td>
				</tr>
				<tr>
					<td>SL - Substituční léčba</td>
					<td>AL - Adiktologické služby ambulantní léčby a poradenství (ambulantní léčba)</td>
				</tr>
				<tr>
					<td>SV - Adiktologické služby ve vězení</td>
					<td>AL - Adiktologické služby ambulantní léčby a poradenství (ambulantní léčba)</td>
				</tr>
				<tr>
					<td>DTX – Detoxifikace</td>
					<td>KS - Adiktologické služby krátkodobé stabilizace (krátkodobá stabilizace)</td>
				</tr>
				<tr>
					<td>KSUL - Krátkodobá a střednědobá lůžková péče</td>
					<td>RL - Adiktologické služby rezidenční léčby (rezidenční léčba)</td>
				</tr>
				<tr>
					<td colspan="2"><strong>Další typy projektů v dotačním řízení:</strong></td>
				</tr>
				<tr>
					<td colspan="2">POR - Specializované služby poradenství pro osoby se závislostním chováním a/nebo osoby ohrožené závislostním chováním a jejich blízké</td>
				</tr>
				<tr>
					<td colspan="2">IVH - Projekty zaměřené na poskytování informací, výzkum hodnocení a další projekty, které nelze zařadit do výše uvedených kategorií</td>
				</tr>
			</tbody>
		</table>
	</Dialog>
</template>

<script>
import Dialog from '@/components/Common/Dialog'
import Message from '@/components/Common/Message'

export default {
	name: 'PrevodniTabulkaTypuSluzebDialog',

	components: {
		Dialog,
		Message,
	},

	emits: ['close'],


	methods: {
		close() {
			this.$emit('close')
		},
	},
}
</script>
