<template>
	<Dialog	title="Žádost je zamčená">
		<p><strong>Žádost je úspěšně zamčená a odeslaná ke kontrole.</strong></p>
		<p>V zamknuté žádosti nelze dělat změny nebo upravovat projekty.</p>

		<p>Nezapomeňte část A opatřit podpisem statutárního zástupce (elektronicky nebo fyzicky) a odeslat datovou schránkou, nebo v listinné podobě na adresu Úřadu vlády ČR, nebo osobně odevzdat na podatelně Úřadu vlády ČR.</p>

		<hr class="form__divider">

		<div class="buttons">
			<button
				class="button"
				@click="$emit('confirm')"
			>
				<font-awesome-icon icon="check" class="button__icon" />
				OK, rozumím
			</button>
		</div>

	</Dialog>
</template>

<script>
import Dialog from '@/components/Common/Dialog'

export default {
	name: 'ZadostUzamceniReportDialog',

	components: {
		Dialog
	},

	emits: ['confirm'],

}
</script>
