<template>
	<PageHeader :title="'Žádost o dotaci (část B)' + nazevProjektu" />
	<Tabs
		:items="tabItems"
		:selected="tabSelected"
		@changeTab="changeTab($event)"
	/>

	<div class="content">

		<Message type="error" v-if="error">
			{{ error }}
		</Message>

		<Loading v-if="!dataLoaded" />

		<template v-if="dataLoaded">
      <template v-if="projekt.dotacni_rok < 2024">
        <CastB12022
            v-if="tabSelected === 1"
            :projekt="projekt"
        />

        <CastB2Sluzba2022
            v-if="tabSelected === 2 & projekt.typ === 1"
            :projekt="projekt"
        />

        <CastB2Projekt2022
            v-if="tabSelected === 2 & projekt.typ === 2"
            :projekt="projekt"
        />
      </template>

      <template v-if="projekt.dotacni_rok >= 2024">
        <CastB1Sluzba2024
            v-if="tabSelected === 1 & projekt.typ === 1"
            :projekt="projekt"
        />

        <CastB1Projekt2024
            v-if="tabSelected === 1 & projekt.typ === 2"
            :projekt="projekt"
        />

        <CastB2Sluzba2024
            v-if="tabSelected === 2 & projekt.typ === 1"
            :projekt="projekt"
        />

        <CastB2Projekt2024
            v-if="tabSelected === 2 & projekt.typ === 2"
            :projekt="projekt"
        />
      </template>
		</template>

	</div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import Tabs from '@/components/Common/Tabs'

import CastB12022 from '@/components/Projekty/CastB/2022/CastB1.vue'
import CastB2Sluzba2022 from '@/components/Projekty/CastB/2022/CastB2Sluzba.vue'
import CastB2Projekt2022 from '@/components/Projekty/CastB/2022/CastB2Projekt.vue'

import CastB1Sluzba2024 from '@/components/Projekty/CastB/2024/CastB1Sluzba.vue'
import CastB1Projekt2024 from '@/components/Projekty/CastB/2024/CastB1Projekt.vue'
import CastB2Sluzba2024 from '@/components/Projekty/CastB/2024/CastB2Sluzba.vue'
import CastB2Projekt2024 from '@/components/Projekty/CastB/2024/CastB2Projekt.vue'

export default {
	name: 'Projekt-B',

	components: {
		PageHeader,
		Message,
		Loading,
		Tabs,
    CastB12022,
    CastB2Sluzba2022,
    CastB2Projekt2022,
    CastB1Sluzba2024,
    CastB1Projekt2024,
    CastB2Sluzba2024,
    CastB2Projekt2024
	},

	props: {
		id: {
			type: [Number, String],
			required: true,
		},
		zalozka: {
			type: Number,
			default: 1,
		},
	},

	data() {
		return {
			error: '',
			dataLoaded: false,

			projekt: {},

			tabSelected: 1,
			tabItems: [
				{
					id: 0,
					title: 'Zpět na projekt',
					icon: 'arrow-left',
				},
				{
					id: 1,
					title: 'Žádost o dotaci',
					subtitle: 'Část B1',
				},
				{
					id: 2,
					title: 'Charakteristika a popis služby/projektu',
					subtitle: 'Část B2',
				}
			]
		}
	},

	computed: {
		...authComputed,

		nazevProjektu() {
			return (
				this.dataLoaded
				? ` - ${this.projekt.kod}: ${this.projekt.nazev}`
				: ''
			)
		},
	},

	watch: {
		zalozka(newVal, oldVal) {
			this.tabSelected = newVal
		},
	},

	methods: {

		changeTab(tabId) {
			switch (tabId) {
				case 0:
					// zpět na projekt
					this.$router.push({ name: 'Projekt', params: { id: this.id } })
					break
				case 1:
					// B1
					this.$router.push({ name: 'Projekt-B1', params: { id: this.id } })
					break
				case 2:
					// B2
					this.$router.push({ name: 'Projekt-B2', params: { id: this.id } })
					break
			}
		},

		loadData() {
			ProjektyService
			.getProjekt(this.id)
			.then(response => {
				if (response.data.success) {
					this.projekt = response.data.data.projekt
					this.b1 = response.data.data.b1
					this.b2 = response.data.data.b2
					this.dataLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

	},


	created() {
		this.tabSelected = this.zalozka;
		this.loadData()
	},

}
</script>
