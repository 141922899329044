<template>
	<section>

		<SectionHeader title="PROJEKTY PŘEDKLÁDANÉ ŽADATELEM" />

		<div class="content">
			<Loading v-if="!dataLoaded" />

			<template v-if="dataLoaded">
				<Message type="info">
					<p>Zatím v žádosti nemáte žádné projekty.</p>
					<p><strong>Do žádosti můžete naimportovat projekty, které byly součástí vaší žádosti v dotačním roce {{importZRoku}}.</strong> Vybrané projekty naimportujte kliknutím na tlačítko pod tabulkou.</p>
					<p><strong class="color-danger">POZOR:</strong> U projektů se přenese textová část B1 a B2, ale ne rozpočtové části C1-C5.</p>
					<p>Pokud nechcete importovat existující projekty, můžete <router-link :to="{ name: 'ProjektNovy', params: { idZadosti: zadost.id } }">přidat zcela nový projekt</router-link>.</p>
				</Message>


				<div class="small mt-4 mb-2">
					<a href="#" @click.prevent="vybratVse">Vybrat všechny</a>
					| <a href="#" @click.prevent="vybratNic">Zrušit výběr</a>
					| Označeno projektů: <strong>{{ vybraneProjekty.length }}</strong>
				</div>

				<table class="datagrid mt-2 mb-4">
					<thead>
						<tr>
							<th>&nbsp;</th>
							<th>Kód</th>
							<th>Název projektu</th>
							<th class="alc">Typ</th>
						</tr>
					</thead>
					<tbody>
						<tr
							class="datagrid__row"
							v-for="projekt in projekty"
							:key="projekt.projekt.id"
						>
							<td>
								<SimpleCheckbox
									:checked="jeVybrany(projekt.projekt.id)"
									@click="vybrat(projekt.projekt.id)"
								/>
							</td>
							<td><strong>{{ projekt.projekt.kod }}</strong></td>
							<td>{{ projekt.projekt.nazev }}</td>
							<td class="alc">{{ typProjektu(projekt.projekt.typ) }}</td>
						</tr>
					</tbody>
				</table>

				<Loading v-if="isImporting" class="mb-2"/>

				<Message v-if="importError" type="error" class="mt-4 mb-4">
					{{importError}}
				</Message>

				<button
					class="button"
					:disabled="vybraneProjekty.length === 0"
					@click="importovatProjekty"
				>
					<font-awesome-icon icon="file-import" class="button__icon" />
					Přenést vybrané projekty do aktuální žádosti
				</button>
			</template>
		</div>

	</section>
</template>

<script>
import {
	TYP_PROJEKTU,
	STAV_PROJEKTU,
} from '@/constants'

import ZadostiService from '@/services/ZadostiService'

import { formatNumberCZ } from '@/utils/format.js'

import Loading from '@/components/Common/Loading'
import Message from '@/components/Common/Message'
import SectionHeader from '@/components/Common/SectionHeader'

import SimpleCheckbox from '@/components/Forms/Simple/SimpleCheckbox';

export default {
	name: 'ZadostProjektyImport',

	components: {
		Loading,
		Message,
		SectionHeader,
		SimpleCheckbox,
	},

	props: {
		// cílová žádost, kam se mají projekty naimportovat
		zadost: {
			type: Object,
			required: true,
		},
		// žádosti/roky, ve kterých mohla organizace podavat zadost
		zadostiOrganizace: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			errors: [],
			dataLoaded: false,

			projekty: [],
			importZRoku: null,
			vybraneProjekty: [],

			isImporting: false,
			importError: null,
		}
	},

	methods: {
		formatNumberCZ,

		typProjektu(typ) {
			switch (typ) {
				case this.TYP_PROJEKTU.SLUZBA:
					return 'služba'
				case this.TYP_PROJEKTU.IVH:
					return 'IVH'
			}
			return '-'
		},

		vybratVse() {
			this.vybraneProjekty = this.projekty.map(projekt => projekt.projekt.id)
		},

		vybratNic() {
			this.vybraneProjekty = []
		},

		vybrat(id) {
			if (!this.jeVybrany(id)) {
				this.vybraneProjekty.push(id)
				return
			}

			const idx = this.vybraneProjekty.findIndex(idProjektu => idProjektu === id)
			this.vybraneProjekty.splice(idx, 1)
		},

		jeVybrany(id) {
			return !!this.vybraneProjekty.find(idProjektu => idProjektu === id)
		},

		async importovatProjekty() {
			if (this.vybraneProjekty.length === 0) {
				this.importError = 'Musíte vybrat alespoň 1 projekt'
				return
			}

			this.isImporting = true
			try {
				const response = await ZadostiService.importovatProjekty({
					zadost: this.zadost.id,
					projekty: this.vybraneProjekty
				})
				if (!response.data.success) {
					this.importError = response.data.error
					this.isImporting = false
					return
				}
				// refresh stránky žádosti
				this.$router.go(0)
			} catch(err) {
				this.importError = err.message
				this.isImporting = false
				return
			}
			this.isImporting = false
		},

		async loadProjekty(idZadosti) {
			// nacte projekty obsazene v zadosti s predanym id
			try {
				const response = await ZadostiService.getZadostProjekty(idZadosti)
				if (!response.data.success) {
					this.errors.push(response.data.error)
					return
				}
				return response.data.data
			} catch(err) {
				this.errors.push(err.message)
				return
			}
		},

		async loadData() {
			this.dataLoaded = false
			this.projekty = []
			this.vybraneProjekty = []

			// projdeme postupne všechny žádosti organizace,
			// až narazíme na první, která má nějaké projekty
			// ty zobrazíme pro import
			for (let i = 0; i < this.zadostiOrganizace.length; i++) {
				if (this.zadostiOrganizace[i].dotacni_rok !== this.zadost.dotacni_rok) {
					const projekty = await this.loadProjekty(this.zadostiOrganizace[i].id)
					if (projekty) {
						this.importZRoku = this.zadostiOrganizace[i].dotacni_rok
						this.projekty = projekty
						this.vybratVse()
						break
					}
				}
			}
			this.dataLoaded = true
		},

	},


	created() {
		// constants
		this.TYP_PROJEKTU = TYP_PROJEKTU
		this.STAV_PROJEKTU = STAV_PROJEKTU

		this.loadData()
	}
}
</script>
