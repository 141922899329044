<template>
	<PageHeader :title="`Žádost ${dotacniRok ? '- dotační rok ' + dotacniRok : ''}`" />

	<Tabs
		:items="tabItems"
		:selected="dotacniRok"
		@changeTab="changeTab"
	/>

	<section class="content">

		<Message v-if="error" type="error">
			{{error}}
		</Message>

		<Loading v-if="!dataLoaded" />

		<template v-if="dataLoaded">
			<Message type="info">
				Žádost je ve stavu <strong>{{ zadost.stav_nazev }}</strong>.
			</Message>

			<ZadostZadatelODotaci :organizace="informaceOrganizace" class="mt-4" />
		</template>

	</section>

	<template v-if="dataLoaded">

		<ZadostProjekty
			class="mt-5"
			:idZadosti="zadost.id"
			:projekty="projekty"
			:disabled="true"
		/>

		<ZadostPriloha
			class="mt-5"
			:idZadosti="zadost.id"
			:file="priloha"
			:disabled="true"
		/>

		<ZadostMeta
			class="mt-5"
			:zadost="zadost"
		/>

	</template>

</template>


<script>
import {
	STAV_ZADOSTI,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import ZadostiService from '@/services/ZadostiService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import Tabs from '@/components/Common/Tabs'

import ZadostZadatelODotaci from '@/components/Zadost/ZadostZadatelODotaci'
import ZadostProjekty from '@/components/Zadost/ZadostProjekty'
import ZadostPriloha from '@/components/Zadost/ZadostPriloha'
import ZadostMeta from '@/components/Zadost/ZadostMeta'

export default {
	name: 'ZadostAdmin',

	components: {
		PageHeader,
		Message,
		Loading,
		Tabs,
		ZadostZadatelODotaci,
		ZadostProjekty,
		ZadostPriloha,
		ZadostMeta,
	},

	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},

	data() {
		return {
      error: '',
			dataLoaded: false,

			zadost: {},
			informaceOrganizace: {},
			projekty: [],

			tabItems: [],
			dotacniRok: null,
		}
	},

  computed: {
    ...authComputed,

		jeZadostUzamcena() {
			return (
				this.zadost.id_stav === STAV_ZADOSTI.UZAMCENO
				|| this.zadost.id_stav === STAV_ZADOSTI.SCHVALENO
				|| this.zadost.id_stav === STAV_ZADOSTI.ZAMITNUTO
			)
		},

		priloha() {
			return (
				this.zadost.id_priloha
				? {
					nazev: this.zadost.id_priloha,
					puvodni_nazev: this.zadost.priloha_puvodni_nazev,
				}
				: null
			)
		},
  },

	methods: {
		changeTab(id) {
			this.tabSelected = id;
		},

		loadData() {
			this.dataLoaded = false

			ZadostiService
			.getZadostById(this.id)
			.then(response => {
				if (response.data.success) {
					this.zadost = response.data.data.zadost
					this.informaceOrganizace = response.data.data.informace_organizace
					this.projekty = response.data.data.projekty

					// vytvoříme záložku s roke a stavem žádosti
					this.tabItems = [{
						id: this.zadost.dotacni_rok,
						title: this.zadost.dotacni_rok,
						subtitle: this.zadost.stav_nazev,
					}]
					this.dotacniRok = this.zadost.dotacni_rok

				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				this.dataLoaded = true
			})
		},

	},

	created() {
		// constants
		this.STAV_ZADOSTI = STAV_ZADOSTI

		this.loadData()
	}
}
</script>
