<template>
  <div>
    <Modal
      v-if="editFormOpened"
      :overlay="true"
    >
      <StatutarEditForm
        :onSubmit="organizationUpdated"
        :onCancel="closeEditForm"
      />
    </Modal>

    <PageHeader title="Přehled organizací">
      <button
        class="button"
        @click="createOrganization"
      >
        <font-awesome-icon icon="plus" class="button__icon" />
        Přidat organizaci
      </button>
    </PageHeader>

    <Message type="info">
      Přehled organizací můžete filtrovat nebo řadit pomocí prvků v záhlaví tabulky. Najetím myši na zkrácený text v tabulce se zobrazí celý. Kliknutím kamkoliv na řádek v tabulkce se otevře dialogové okno s možností editace.
    </Message>

    <Message v-if="error" type="error">
      {{error}}
    </Message>

    <Loading v-if="!dataLoaded" />

    <FiltrOrganizace
      class="mt-4"
      :filterValues="datagridFilter"
      @updateFilter="updateDatagridFilter($event)"
    />

    <table v-if="dataLoaded" class="datagrid mt-4">
      <thead>
        <tr>
          <th>
            Dotační rok
            <SortArrows field="dotacni_rok" :current="datagridSort" @sort="sort" />
          </th>
          <th>
            Název organizace
            <SortArrows field="organizace" :current="datagridSort" @sort="sort" />
          </th>
          <th>
            Stav
            <SortArrows field="stav_nazev" :current="datagridSort" @sort="sort" />
          </th>
          <th>
            IČ
            <SortArrows field="ic" :current="datagridSort" @sort="sort" />
          </th>
          <th>
            DIČ
            <SortArrows field="dic" :current="datagridSort" @sort="sort" />
          </th>
          <th>
            Statutární zástupce
            <SortArrows field="statutar_prijmeni" :current="datagridSort" @sort="sort" />
          </th>
          <th>
            Telefon
            <SortArrows field="statutar_telefon" :current="datagridSort" @sort="sort" />
          </th>
          <th>
            E-mail
            <SortArrows field="statutar_email" :current="datagridSort" @sort="sort" />
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="organizaceResult.length === 0">
          <td colspan="8" class="alc p-5 color-danger">Nenalezeny žádné organizace.</td>
        </tr>

        <tr
          class="datagrid__row datagrid__row--non-hoverable"
          v-for="org in organizaceResult"
          :key="org.id"
        >
          <td>{{ org.dotacni_rok }}</td>
          <td><a @click.prevent="editOrganization(org.id_zadosti, org.stav_formulare_a)">{{ org.organizace }}</a></td>
          <td>{{ org.stav_nazev || 'Nevyplněno' }}</td>
          <td>{{ org.ic }}</td>
          <td>{{ org.dic }}</td>
          <td>{{ org.statutar_jmeno + ' ' + org.statutar_prijmeni }}</td>
          <td>{{ org.statutar_telefon }}</td>
          <td>{{ org.statutar_email }}</td>
        </tr>
      </tbody>
    </table>

  </div>
</template>

<script>
import OrganizaceService from '@/services/OrganizaceService'

import Modal from '@/components/Common/Modal'
import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import SortArrows from '@/components/DataGrid/SortArrows'
import FiltrOrganizace from '@/components/Toolbar/FiltrOrganizace'

import StatutarEditForm from '@/components/Organizace/StatutarEditForm'


export default {
  name: 'Organizace',

  components: {
    Modal,
    PageHeader,
    Message,
    Loading,
    SortArrows,
    FiltrOrganizace,
    StatutarEditForm,
  },

  data() {
    return {
      error: '',
      organizace: [],
      dataLoaded: false,
      editFormOpened: false,

      datagridFilter: {},
      datagridSort: {
        field: 'organizace',
        dir: 'asc',
      },
    }
  },


  computed: {
    organizaceResult() {
      return this.organizace.filter(org => {
        let result = true

        // dotacni rok
        if (this.datagridFilter?.dotacniRok) {
          result = (
            result
            && org.dotacni_rok
            && org.dotacni_rok == this.datagridFilter.dotacniRok
          )
        }

        // nazev organizace
        if (this.datagridFilter?.organizace) {
          result = (
            result
            && org.organizace
            && org.organizace.toLowerCase().includes(this.datagridFilter.organizace.toLowerCase())
          )
        }

        // ic
        if (this.datagridFilter?.ic) {
          result = (
            result
            && org.ic
            && org.ic.toLowerCase().includes(this.datagridFilter.ic.toLowerCase())
          )
        }

        // dic
        if (this.datagridFilter?.dic) {
          result = (
            result
            && org.dic
            && org.dic.toLowerCase().includes(this.datagridFilter.dic.toLowerCase())
          )
        }

        // statutar
        if (this.datagridFilter?.statutar) {
          result = (
            result
            && (org.statutar_jmeno + org.statutar_prijmeni)
            && (org.statutar_jmeno + ' ' + org.statutar_prijmeni).toLowerCase().includes(this.datagridFilter.statutar.toLowerCase())
          )
        }

        // telefon
        if (this.datagridFilter?.telefon) {
          result = (
            result
            && org.statutar_telefon
            && org.statutar_telefon.toLowerCase().includes(this.datagridFilter.telefon.toLowerCase())
          )
        }

        // email
        if (this.datagridFilter?.email) {
          result = (
            result
            && org.statutar_email
            && org.statutar_email.toLowerCase().includes(this.datagridFilter.email.toLowerCase())
          )
        }



        // vratime, zda organizace splnuje kriteria
        return result
      })
      .sort((itemA, itemB) => {
        if (!this.datagridSort.field) return 0

        const dir = this.datagridSort.dir === 'desc' ? 1 : -1

        let a = itemA[this.datagridSort.field]
        let b = itemB[this.datagridSort.field]

        if (typeof a === 'string') a = a.toLowerCase()
        if (typeof b === 'string') b = b.toLowerCase()

        if (a == b) return 0
        if (a == null || a == undefined) return dir
        if (b == null || b == undefined) return -dir

        return (
          typeof a === 'string'
          ? a.localeCompare(b) * -dir
          : a < b ? dir : -dir
        )
      })
    }
  },

  methods: {
    sort({field, dir}) {
      this.datagridSort.field = field
      this.datagridSort.dir = dir
      this.saveDatagridSettings()
    },

    updateDatagridFilter(filterValues) {
      this.datagridFilter = filterValues
      this.saveDatagridSettings()
    },

    saveDatagridSettings() {
			const data = JSON.stringify({
				filter: this.datagridFilter,
				sort: this.datagridSort,
			})
			localStorage.setItem('cadros-organizace-settings', data)
		},

		loadDatagridSettings() {
			const data = JSON.parse(localStorage.getItem('cadros-organizace-settings'))
			this.datagridFilter = data?.filter || {}
			this.datagridSort = data?.sort || {
				field: 'organizace',
				dir: 'asc',
			}
		},

    closeEditForm() {
      this.editFormOpened = false
    },

    organizationUpdated() {
      this.closeEditForm()
      this.organizace = []
      this.dataLoaded = false
      this.loadData()
    },

    createOrganization() {
      this.editFormOpened = true
    },

    editOrganization(idZadosti, idStav) {
      if (idStav && idStav !== 1) {
        // presmerujeme na editaci formulare A,
        // ale jen pokud je formular alespon ve stavu "Odeslano ke kontrole" nebo dalsim
        this.$router.push({ name: 'OrganizaceKontrola', params: { id: idZadosti } })
      }
    },

    loadData() {
      OrganizaceService.getOrganizace()
        .then(response => {
          this.organizace = response.data.data
          this.dataLoaded = true
        })
        .catch(error => {
          this.error += error.response.data.error
        })
    }
  },

  created() {
    this.loadDatagridSettings()
    this.loadData()
  }
}
</script>
