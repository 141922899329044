<template>
	<Dialog title="Přidání nové organizace">

		<form
			class="form form--size40"
			novalidate
			@submit.prevent="submit"
		>

			<Message type="info" class="mb-3">
				E-mailová adresa slouží jako uživatelské jméno (login).
			</Message>

			<Message v-if="error" type="error" class="mb-3">
				{{ error }}
			</Message>

			<div class="form__field form__field--required">
				<label for="email" class="form__label">E-mail stat. zástupce</label>
				<input
					class="form__input"
					type="text"
					name="email"
					id="email"
					v-model="email"
				>
			</div>

			<div class="form__field form__field--required">
				<label for="jmeno" class="form__label">Jméno</label>
				<input
					class="form__input"
					type="text"
					name="jmeno"
					id="jmeno"
					v-model="jmeno"
				>
			</div>

			<div class="form__field form__field--required">
				<label for="prijmeni" class="form__label">Příjmení</label>
				<input
					class="form__input"
					type="text"
					name="prijmeni"
					id="prijmeni"
					v-model="prijmeni"
				>
			</div>

			<div class="form__field">
				<label for="telefon" class="form__label">Telefon</label>
				<input
					class="form__input"
					type="text"
					name="telefon"
					id="telefon"
					v-model="telefon"
				>
			</div>


			<div class="form__buttons">
				<button
					type="submit"
					class="button"
					@click.prevent="submit"
				>
					<font-awesome-icon icon="folder-open" class="button__icon" />
					Uložit a odeslat přístupy do aplikace
				</button>
				<button
					class="button button--outline"
					@click.prevent="cancel"
				>
					Storno
				</button>
			</div>

		</form>
	</Dialog>
</template>

<script>
import OrganizaceService from '@/services/OrganizaceService'

import Dialog from '@/components/Common/Dialog'
import Message from '@/components/Common/Message'

export default {
	name: 'StatutarEditForm',

	props: {
		onSubmit: {
			type: Function,
			default: null
		},
		onCancel: {
			type: Function,
			default: null
		}
	},

	components: {
		Dialog,
		Message,
	},

	data() {
		return {
			email: '',
			jmeno: '',
			prijmeni: '',
			telefon: '',

			error: '',
		}
	},

	methods: {
		submit() {
			if (!this.checkForm()) return

			const uzivatel = {
				email: this.email,
				jmeno: this.jmeno,
				prijmeni: this.prijmeni,
				telefon: this.telefon,
				id_organizace: null,
				role: 5, // statutarni zastupce
				id_kraj: null,
				datum_expirace: null,
				aktivni: this.aktivni
			}

			OrganizaceService.createOrganizace(uzivatel)
				.then(response => {
					if (response.data.success) {
						if (this.onSubmit) this.onSubmit()
					} else {
						this.error = response.data?.error
					}
				})
				.catch(error => {
					this.error = error.data?.error || 'Při pokusu o uložení došlo k chybě'
				})
		},

		cancel() {
			if (this.onCancel) this.onCancel()
		},

		checkForm() {
			this.error = '';

			if (!(this.email && this.jmeno && this.prijmeni)) {
				this.error = 'Vyplňte všechny poviné údaje'
				return false
			}

			if (!(this.email.includes('@') && this.email.includes('.'))) {
				this.error = 'Neplatná emailová adresa'
				return false
			}

			return true
		}
	},

}
</script>
