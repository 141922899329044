import ApiClient from './ApiClient'

export default {

	getZadosti() {
		return ApiClient.get(`/zadosti/`)
	},

	getZadostiOrganizace(idOrganizace) {
		return ApiClient.get(`/zadosti-organizace/${idOrganizace}`)
	},

	getZadostById(id) {
		return ApiClient.get(`/zadosti/${id}`)
	},

	getZadostProjekty(id) {
		return ApiClient.get(`/zadost-projekty/${id}`)
	},

	zamknoutZadost(id) {
		return ApiClient.put(`/zadost-zamknout/${id}`)
	},

	stahnoutPDF(id) {
		return ApiClient.get(
			`/export-pdf-a/${id}`,
			{	responseType: 'blob' }
		)
	},

	stahnoutCestneProhlaseniBezdluznost(id) {
		return ApiClient.get(
			`/cestne-prohlaseni/${id}`,
			{	responseType: 'blob' }
		)
	},

	importovatProjekty(data) {
		return ApiClient.post(`/projekty-import`, data);
	},

	getVyuctovaniPrehled(id) {
		return ApiClient.get(`/vyuctovani-prehled/${id}`);
	},

	odeslatVyuctovani(id, ids) {
		return ApiClient.put(`/vyuctovani-odeslat/${id}`, {projekty: ids});
	}
}
