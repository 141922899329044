<template>
  <div>
    <Modal
        v-if="modalOpened"
        :overlay="true"
    >
      <EmailConfirmDialog
          @submit="submitEmail"
          @cancel="closeModal"
          :dotacniRok="dotacniRok"
      />
    </Modal>

    <Message
        class="mb-3"
        type="error"
        v-if="error"
    >
      {{ error }}
    </Message>

    <Message
        class="mb-3"
        type="info"
        v-if="info"
    >
      {{ info }}
    </Message>

    <div class="year">
      <label
          for="message_subject"
          class="form__label"
      >Dotační rok</label>

      <select
          class="form__input"
          v-model="dotacniRok"
      >
        <option
            v-for="item in dotacniRoky"
            :key="item.rok"
        >
          {{ item.rok }}
        </option>
      </select>
    </div>

    <div class="subject">
      <label
          for="message_subject"
          class="form__label"
      >Předmět</label>

      <input
          type="text"
          class="form__input"
          name="message_subject"
          id="message-subject"
          ref="message_subject"
          required
          v-model="subject"
      />
    </div>

    <ckeditor :editor="editor" v-model="editorData" :config="editorConfig"></ckeditor>

    <div class="submit-button">
      <button
        class="button"
        @click.prevent="sendEmail"
      >
        Odeslat e-mail
      </button>
    </div>
  </div>
</template>

<script>
import '@ckeditor/ckeditor5-build-classic/build/translations/cs'
import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import Message from '@/components/Common/Message.vue'
import Modal from '@/components/Common/Modal.vue'
import EmailConfirmDialog from '@/components/Nastaveni/Dialog/EmailConfirmDialog.vue'

import SettingsService from '@/services/SettingsService'
import DotacniRokyService from '@/services/DotacniRokyService'

export default {
  name: 'app',
  components: {
    EmailConfirmDialog,
    Message,
    Modal
  },
  data() {
    return {
      modalOpened: false,
      error: '',
      info: '',
      dotacniRoky: [],
      dotacniRok: '',
      editor: ClassicEditor,
      editorData: '',
      subject: '',
      editorConfig: {
        language: 'cs',
        toolbar: [ 'undo', 'redo', '|', 'heading', '|', 'bold', 'italic', 'NumberedList', 'BulletedList', '|', 'link']
      }
    };
  },
  methods: {
    loadData() {
      DotacniRokyService
          .getDotacniRoky()
          .then(response => {
            if (response.data.success) {
              this.dotacniRoky = response.data.data
            } else {
              this.error += response.data.error
            }
          })
          .catch(error => {
            this.error += error.message
          })
    },
    sendEmail() {
      this.error = ''
      this.info = ''

      if (this.dotacniRok === '') {
        this.error += 'Dotační rok je povinný. '
      }

      if (this.subject === '') {
        this.error += 'Subjekt je povinný. '
      }

      if (this.editorData === '') {
        this.error += 'Obsah e-mailu je povinný. '
      }

      if (this.error !== '') {
        return
      }

      this.modalOpened = true
    },
    closeModal() {
      this.modalOpened = false
    },
    async submitEmail() {
      this.modalOpened = false

      const data = {
        'predmet': this.subject,
        'zprava': this.editorData,
        'rok': this.dotacniRok
      };

      try {
        const response = await SettingsService.sendEmail(data)
        if (response.data.success) {
          this.info = 'E-mail odeslán.'
          this.subject = ''
          this.editorData = ''
          this.dotacniRok = ''
        } else {
          this.error = response.data.error
        }
      } catch(error) {
        this.error = error.message
      }
    },
  },
  created() {
    this.loadData()
  },
}
</script>

<style>
  .ck-editor__editable {
    min-height: 500px;
  }

  .ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
  .ck.ck-editor__top .ck-sticky-panel .ck-toolbar.ck-rounded-corners {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  .ck.ck-editor__main>.ck-editor__editable:not(.ck-focused) {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }
</style>

<style scoped>
  .submit-button {
    margin-top: 15px;
    display: flex;
    justify-content: flex-end;
  }

  .subject, .year {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 20px;
  }

  .subject .form__label,
  .year .form__label{
    flex: 0 0 0;
  }
</style>