<template>

  <UzivateleAdmin v-if="isGov" />

  <UzivateleOrganizace v-if="isOrg" />

</template>

<script>
import { authComputed } from '@/store/helpers.js'
import UzivateleAdmin from '@/components/Uzivatele/UzivateleAdmin'
import UzivateleOrganizace from '@/components/Uzivatele/UzivateleOrganizace'


export default {
  name: 'Uzivatele',

  components: {
    UzivateleAdmin,
    UzivateleOrganizace,
  },

  computed: {
    ...authComputed,
  },
}
</script>