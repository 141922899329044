<template>
	<div class="form__checkbox-array">
		<div
			class="form__checkbox-array-columns"
			:style="`column-count: ${columns};`"
		>

			<div
				class="form__input-element"
				v-for="item in items"
				:key="item.value"
			>
				<input
					type="checkbox"
					class="form__checkbox"
					:class="{'form__checkbox--checked': item.checked }"
					:name="`${name}`"
					:id="`${name}-${item.value}`"
					:checked="item.checked"
					:disabled="disabled"
					@input="changeValue(item.value, $event.target.checked)"
				/>
				<label
					class="form__checkbox-box"
					:for="`${name}-${item.value}`"
				>
					<font-awesome-icon
						class="form__checkbox-icon"
						icon="check"
					/>

				</label>
				<label
					class="form__checkbox-array-label"
					:for="`${name}-${item.value}`"
				>
					{{ item.label }}
				</label>
			</div>

		</div>

		<div
			class="form__checkbox-array-checkall"
			v-if="allTools && !disabled"
		>
			<a href="#" @click.prevent="checkAll(true)">Vybrat</a> /
			<a href="#" @click.prevent="checkAll(false)">Vymazat</a>
			všechny možnosti
		</div>

	</div>
</template>

<script>
export default {
	name: 'BaseCheckboxArray',

	props: {
		modelValue: {
			type: [Number, String],
			default: '',
		},
		name: {
			type: String,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
		valueKey: {
			type: String,
			required: true,
		},
		labelKey: {
			type: String,
			required: true,
		},
		allTools: {
			type: Boolean,
			default: false,
		},
		columns: {
			type: Number,
			default: 1,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		valid: {
			type: Boolean,
			default: undefined,
		},
	},

	data() {
		return {
			items: [],
		}
	},

	methods: {

		changeValue(value, checked) {
			this.items.find(item => item.value === value).checked = checked
			this.updateModel()
		},

		updateModel() {
			const values = this.items.filter(item => item.checked).map(item => item.value).join(',')
			this.$emit('update:modelValue', values)
		},

		checkAll(value) {
			this.items.forEach(item => {
				item.checked = value
			})
			this.updateModel()
		},

		createItems(value) {
			const values = (
				value
				? value.toString().split(',')
				: []
			)

			this.items = this.options.map(option => ({
				value: option[this.valueKey],
				label: option[this.labelKey],
				checked: values.some(x => x == option[this.valueKey]),
			}))
		},

	},

	watch: {
		modelValue(newValue) {
			this.createItems(newValue)
		}
	},

	created() {
		this.createItems(this.modelValue)
	}
}
</script>
