<template>
	<div
		class="field-status-control"
	>
		<div
			class="field-status-control__icon"
			:class="{ 'field-status-control__icon--approved': status === 1 }"
			@click="$emit('updateStatus', 1)"
		>
			<font-awesome-icon icon="check" />
		</div>

		<div
			class="field-status-control__icon"
			:class="{ 'field-status-control__icon--declined': status === 2 }"
			@click="$emit('updateStatus', 2)"
		>
			<font-awesome-icon icon="times" />
		</div>
	</div>
</template>

<script>
export default {
	name: 'FieldStatusControl',

	props: {
		status: {
			type: Number,
			default: 0,
		}
	},

	computed: {
		statusIcon() {
			switch (this.status) {
				case 1:
					return {
						name: 'approved',
						icon: 'check',
						tooltip: 'Schválený údaj',
					}
				case 2:
					return {
						name: 'declined',
						icon: 'times',
						tooltip: 'Údaj vrácený k opravě',
					}
				default:
					return {
						name: 'none',
						icon: 'question',
						tooltip: 'Změněný údaj ke schválení',
					}
				}
		},
	},

}
</script>
