<template>
	<section>

		<SectionHeader title="PROJEKTY PŘEDKLÁDANÉ ŽADATELEM">
      <button
				v-if="!disabled"
        class="button button--outline"
        @click="novyProjekt(idZadosti)"
      >
        <font-awesome-icon icon="plus" class="button__icon" />
        Přidat nový projekt
      </button>
		</SectionHeader>

		<div v-if="projekty.length === 0" class="content">
			<p>
				V žádosti zatím není žádný projekt.
				<router-link :to="{ name: 'ProjektNovy', params: { idZadosti } }">Přidat nový projekt</router-link>
			</p>
		</div>

		<template v-else>
			<table class="datagrid">
				<thead>
					<tr>
						<th>Kód</th>
						<th>Název projektu</th>
						<th class="alc">Typ</th>
						<th class="alc">Uzamčen</th>
						<th class="alr">Celkové náklady</th>
						<th class="alr">Požadovaná dotace</th>
						<th class="alr">Celková dotace</th>
					</tr>
				</thead>
				<tbody>
					<tr
						class="datagrid__row datagrid__row--non-hoverable"
						v-for="projekt in projekty"
						:key="projekt.id"
					>
						<td>{{ projekt.kod }}</td>
            <td><a @click.prevent="editProjekt(projekt.id)" href="#">{{ projekt.nazev }}</a></td>
						<td class="alc">{{ typProjektu(projekt.typ) }}</td>
						<td class="alc"><font-awesome-icon icon="lock" v-if="jeProjektZamceny(projekt.stav)" /></td>
						<td class="alr">{{ formatNumberCZ(projekt.naklady_celkem, '-') }}</td>
						<td class="alr">{{ formatNumberCZ(projekt.pozadovana_dotace, '-') }}</td>
						<td class="alr">{{ formatNumberCZ(projekt.celkova_dotace, '-') }}</td>
					</tr>
				</tbody>
			</table>

			<Message type="info" class="mt-3">
				Kliknutím na řádek v tabulce můžete otevřít detail projektu a neuzamčené projekty editovat.
			</Message>

		</template>

	</section>
</template>

<script>
import {
	TYP_PROJEKTU,
	STAV_PROJEKTU,
} from '@/constants'

import { formatNumberCZ } from '@/utils/format.js'

import Message from '@/components/Common/Message'
import SectionHeader from '@/components/Common/SectionHeader'


export default {
	name: 'ZadostProjekty',

	components: {
		Message,
		SectionHeader,
	},

	props: {
		idZadosti: {
			type: Number,
			required: true,
		},
		projekty: {
			type: Array,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		formatNumberCZ,

		typProjektu(typ) {
			switch (typ) {
				case this.TYP_PROJEKTU.SLUZBA:
					return 'služba'
				case this.TYP_PROJEKTU.IVH:
					return 'IVH'
			}
			return '-'
		},

		jeProjektZamceny(stav) {
			return (
				stav === STAV_PROJEKTU.UZAMCENO
				|| stav === STAV_PROJEKTU.SCHVALENO
				|| stav === STAV_PROJEKTU.ZAMITNUTO
				|| stav === STAV_PROJEKTU.UPRAVA_ROZPOCTU
				|| stav === STAV_PROJEKTU.VRACENI_ROZPOCTU
				|| stav === STAV_PROJEKTU.KONECNY_ROZPOCET
			)
		},

		novyProjekt(idZadosti) {
			this.$router.push({ name: 'ProjektNovy', params: { idZadosti } })
		},

		editProjekt(id) {
			this.$router.push({ name: 'Projekt', params: { id } })
		}
	},


	created() {
		// constants
		this.TYP_PROJEKTU = TYP_PROJEKTU
		this.STAV_PROJEKTU = STAV_PROJEKTU
	}
}
</script>
