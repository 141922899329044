<template>
	<Dialog title="Kontrola žádosti" class="dialog--wide">

		<Message type="error" class="mb-4" v-if="!kontrolaVysledek">
			Žádost obsahuje chyby. Dokud je neopravíte, nebude možné žádost uzamknout a odeslat.
		</Message>

		<Message type="info" class="mb-4" v-if="kontrolaVysledek">
			Žádost je bez chyb a je možné ji uzamknout a odeslat ke kontrole.
		</Message>

		<table class="table w100">
			<thead>
				<tr>
					<th colspan="2">Projekt</th>
					<th>Stav kontroly</th>
				</tr>
			</thead>
			<tbody>
				<template
					v-for="projekt in kontrola"
					:key="projekt.id"
				>
					<tr>
						<td v-if="projekt.kod !== null">{{ projekt.kod }}</td>
						<td	:colspan="projekt.kod === null ? 2 : 1">{{ projekt.nazev }}</td>
						<td class="alc">
							<font-awesome-icon
								:icon="projekt.status ? 'check' : 'times'"
								:class="{
									'color-success': projekt.status,
									'color-danger': !projekt.status,
								}"
							/>
						</td>
					</tr>
					<tr v-if="!projekt.status">
						<td colspan="3">
							<p class="mt-0"><strong>Obsahuje chyby:</strong></p>
							<ul class="color-danger">
								<li
									v-for="(chyba, index) in projekt.chyby"
									:key="index"
								>
									{{ chyba }}
								</li>
							</ul>
						</td>
					</tr>
				</template>
			</tbody>
		</table>

		<div class="buttons mt-4">
			<button
				class="button  button--outline"
				@click="closeDialog"
			>
				Ano, rozumím
			</button>
		</div>

	</Dialog>
</template>

<script>
import Dialog from '@/components/Common/Dialog'
import Message from '@/components/Common/Message'

export default {
	name: 'ZadostKontrolaDialog',

	components: {
		Dialog,
		Message,
	},

	props: {
		kontrola: {
			type: Array,
			required: true,
		},
		kontrolaVysledek: {
			type: Boolean,
			required: true,
		}
	},

	emits: ['confirm'],

	data() {
		return {
			error: '',
			message: '',
		}
	},

	methods: {
		closeDialog() {
			this.$emit('confirm')
		},
	},


}
</script>
