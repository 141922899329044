<template>

  <Modal
      v-if="modalOpened"
      :overlay="true"
  >
    <ChangePasswordDialog
        @submit="changePassword"
        @cancel="closeModal"
    />
  </Modal>

	<div class="current-user__menu" v-show="open">
		<div class="current-user__name">
			Přihlášený uživatel: <strong>{{ name }}</strong>
		</div>
		<div class="current-user__options">
      <button
          class="button button--elastic"
          @click="handleChangePassword"
      >
        <font-awesome-icon icon="lock" class="button__icon" />
        Změna hesla
      </button>
			<button
				class="button button--elastic"
				@click="logout"
			>
				<font-awesome-icon icon="times" class="button__icon" />
				Odhlásit
			</button>
		</div>
	</div>
</template>

<script>
import Modal from '@/components/Common/Modal.vue'
import ChangePasswordDialog from "@/components/CurrentUser/ChangePasswordDialog.vue";

export default {
	name: 'CurrentUserMenu',

  components: {
    ChangePasswordDialog,
    Modal,
  },

	props: {
		open: Boolean,
		name: String
	},

  data() {
    return {
      modalOpened: false,
    }
  },

	methods: {
		logout() {
			this.$store.dispatch('logout')
			.then(() => {
			 	this.$router.push({ name: 'Logout' });
			})
		},
    handleChangePassword() {
      this.modalOpened = true
    },
    closeModal() {
      this.modalOpened = false
    },
    changePassword() {
      this.modalOpened = false
    }
	}
}
</script>
