<template>
	<table class="inputtable w100">
		<thead>
			<tr>
				<th style="width: 55%;">&nbsp;</th>
				<th style="width: 15%;">Všeobecná<br>prevence</th>
				<th style="width: 15%;">Selektivní<br>prevence</th>
				<th style="width: 15%;">Indikovaná<br>prevence</th>
			</tr>
		</thead>
		<tbody>
			<tr
				v-for="radek in data"
				:key="radek.id"
			>
				<th>
						{{ nazevProgramu(radek.id) }}
				</th>
				<td style="text-align: center;">
					<BaseCheckbox
						@input="updateModel"
						v-model="radek.vseobecna"
						:name="`radek${radek.id}_vseobecna`"
						:disabled="disabled"
					/>
				</td>
				<td style="text-align: center;">
					<BaseCheckbox
						@input="updateModel"
						v-model="radek.selektivni"
						:name="`radek${radek.id}_selektivni`"
						:disabled="disabled"
					/>
				</td>
				<td style="text-align: center;">
					<BaseCheckbox
						@input="updateModel"
						v-model="radek.indikovana"
						:name="`radek${radek.id}_indikovana`"
						:disabled="disabled"
					/>
				</td>
			</tr>
		</tbody>
	</table>

</template>

<script>
import BaseCheckbox from '@/components/Forms/Base/BaseCheckbox.vue'

export default {
	name: 'CastB2PrimarniPrevence',

	components: {
		BaseCheckbox,
	},

	props: {
		modelValue: {
			type: [Number, String, Array],
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false
		},
	},

	data() {
		return {
			data: null,

			nazvy: [
				{ id: 1, nazev: 'prevence v adiktologii – prevence kouření', },
				{ id: 2, nazev: 'prevence v adiktologii – prevence užívání alkoholu', },
				{ id: 3, nazev: 'prevence v adiktologii – prevence patologického hráčství', },
				{ id: 4, nazev: 'prevence v adiktologii – prevence užívání nelegálních drog', },
				{ id: 5, nazev: 'záškoláctví', },
				{ id: 6, nazev: 'šikana a extrémní projevy agrese, kyberšikana', },
				{ id: 7, nazev: 'rizikové sporty', },
				{ id: 8, nazev: 'rizikové chování v dopravě', },
				{ id: 9, nazev: 'rasismus a xenofobie', },
				{ id: 10, nazev: 'negativní působení sekt', },
				{ id: 11, nazev: 'sexuální rizikové chování', },
				{ id: 12, nazev: 'spektrum poruch příjmu potravy', },
				{ id: 13, nazev: 'okruh poruch a problémů spojených se syndromem CAN', },
				{ id: 14, nazev: 'základní vzorce bezpečného chování napříč typy rizikového chování', },
				{ id: 15, nazev: 'právní vědomí napříč typy rizikového chování', },
				{ id: 16, nazev: 'prekriminální a kriminální chování napříč typy rizikového chování', },
			]

		}
	},

	methods: {
		nazevProgramu(id) {
			return this.nazvy.find(x => x.id === id).nazev
		},

		updateModel() {
			this.$emit('update:modelValue', JSON.stringify(this.data))
		},
	},

	created() {
		if (this.modelValue) {
			this.data = JSON.parse(this.modelValue)
			return
		}

		// hodnota je zatím prázdná, vytvoříme výchozí pole
		this.data = [
			{ id: 1, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 2, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 3, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 4, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 5, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 6, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 7, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 8, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 9, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 10, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 11, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 12, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 13, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 14, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 15, vseobecna: false, selektivni: false, indikovana: false, },
			{ id: 16, vseobecna: false, selektivni: false, indikovana: false, },
		]

		this.updateModel()
	}

}
</script>