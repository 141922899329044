import { createRouter, createWebHistory } from 'vue-router'

import store from '../store'
import CiselnikyService from '@/services/CiselnikyService'

import Login from '../views/Login'
import Logout from '../views/Logout'
import Dashboard from '../views/Dashboard'
import Organizace from '../views/Organizace'
import Uzivatele from '../views/Uzivatele'
import OrganizaceInformace from '../views/OrganizaceInformace'
import OrganizaceKontrola from '../views/OrganizaceKontrola'
import DotacniRoky from '../views/NastaveniAplikace/DotacniRoky'
import DotacniRokEdit from '../views/NastaveniAplikace/DotacniRokEdit'
import Emaily from '../views/NastaveniAplikace/Email.vue'

import Zadost from '../views/Zadost'
import ZadostRok from '../views/Zadost/ZadostRok'

import ZadostAdmin from '../views/ZadostAdmin'
import ProjektNovy from '../views/ProjektNovy'
import Projekt from '../views/Projekt'
import ProjektC from '../views/Projekt-C'
import ProjektB from '../views/Projekt-B'
import ProjektRozpocet from '../views/ProjektRozpocet'
import Zadosti from '../views/Zadosti'
import Projekty from '../views/Projekty'
import Exporty from '../views/Exporty'
import Hodnoceni from '../views/Hodnoceni'
import HodnoceniDetail from '../components/Hodnoceni/HodnoceniDetail'

import ProjektVyuctovani from '../views/ProjektVyuctovani'
import ProjektVyuctovaniPrilohy from '../views/ProjektVyuctovaniPrilohy'
import ProjektZaverecnaZprava from '../views/ProjektZaverecnaZprava'
import NastaveniAplikace from '../views/NastaveniAplikace'
import Rozhodnuti from '../views/NastaveniAplikace/Rozhodnuti'
import ForgotPassword from '@/views/ForgotPassword'
import ForgotPasswordSet from '@/views/ForgotPasswordSet'
import Email from '@/views/NastaveniAplikace/Email'

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: Dashboard,
    meta: { requiresLogin: true },
  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/logout',
    name: 'Logout',
    component: Logout,
  },
  {
    path: '/zapomenute-heslo',
    name: 'ForgotPassword',
    component: ForgotPassword,
  },
  {
    path: '/reset-hesla/:hash',
    name: 'ForgotPasswordSet',
    component: ForgotPasswordSet,
  },
  {
    path: '/organizace',
    name: 'Organizace',
    component: Organizace,
    meta: { requiresLogin: true },
  },
  {
    path: '/uzivatele',
    name: 'Uzivatele',
    component: Uzivatele,
    meta: { requiresLogin: true },
  },
  {
    path: '/organizace-informace',
    name: 'OrganizaceInformace',
    component: OrganizaceInformace,
    meta: { requiresLogin: true },
  },
  {
    path: '/organizace-kontrola/:id',
    name: 'OrganizaceKontrola',
    component: OrganizaceKontrola,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/zadost',
    name: 'Zadost',
    component: Zadost,
    props: true,
    meta: { requiresLogin: true },
    children: [
      {
        path: 'rok/:rok',
        name: 'ZadostRok',
        component: ZadostRok,
        meta: { requiresLogin: true },
      },
      {
        path: ':id',
        name: 'ZadostAdmin',
        component: ZadostAdmin,
        props: true,
        meta: { requiresLogin: true },
      },
    ]
  },
  {
    path: '/projekt-novy/:idZadosti',
    name: 'ProjektNovy',
    component: ProjektNovy,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt/:id',
    name: 'Projekt',
    component: Projekt,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt/:id/c',
    name: 'Projekt-C',
    component: ProjektC,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt/:id/b1',
    name: 'Projekt-B1',
    component: ProjektB,
    props: route => ({ id: route.params.id, zalozka: 1 }),
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt/:id/b2',
    name: 'Projekt-B2',
    component: ProjektB,
    props: route => ({ id: route.params.id, zalozka: 2 }),
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt/:id/rozpocet',
    name: 'Projekt-Rozpocet',
    component: ProjektRozpocet,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt/:id/vyuctovani',
    name: 'Projekt-Vyuctovani',
    component: ProjektVyuctovani,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt/:id/vyuctovani-prilohy',
    name: 'Projekt-Vyuctovani-prilohy',
    component: ProjektVyuctovaniPrilohy,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/projekt/:id/zaverecna-zprava',
    name: 'Projekt-Zaverecna-zprava',
    component: ProjektZaverecnaZprava,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/zadosti',
    name: 'Zadosti',
    component: Zadosti,
    meta: { requiresLogin: true },
  },
  {
    path: '/projekty',
    name: 'Projekty',
    component: Projekty,
    meta: { requiresLogin: true },
  },
  {
    path: '/exporty',
    name: 'Exporty',
    component: Exporty,
    meta: { requiresLogin: true },
  },
  {
    path: '/hodnoceni',
    name: 'Hodnoceni',
    component: Hodnoceni,
    meta: { requiresLogin: true },
  },
  {
    path: '/hodnoceni-detail/:koordinatorId/:dotacniRok',
    name: 'HodnoceniDetail',
    component: HodnoceniDetail,
    props: true,
    meta: { requiresLogin: true },
  },
  {
    path: '/nastaveni',
    name: 'NastaveniAplikace',
    component: NastaveniAplikace,
    props: true,
    meta: { requiresLogin: true },
    children: [
      {
        path: 'dotacni-roky',
        name: 'DotacniRoky',
        component: DotacniRoky,
        meta: { requiresLogin: true },
      },
      {
        path: 'dotacni-rok-novy',
        name: 'DotacniRokNovy',
        component: DotacniRokEdit,
        meta: { requiresLogin: true },
      },
      {
        path: 'dotacni-rok-edit/:rok',
        name: 'DotacniRokEdit',
        component: DotacniRokEdit,
        props: true,
        meta: { requiresLogin: true },
      },
      {
        path: 'email',
        name: 'Email',
        component: Email,
        props: true,
        meta: { requiresLogin: true },
      },
      {
        path: 'rozhodnuti',
        name: 'Rozhodnuti',
        component: Rozhodnuti,
        props: true,
        meta: { requiresLogin: true },
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach(async (to, from) => {
  let enableNavigation = false

  // cesta nevyzaduje login? dovolime navigaci
  if (!to.matched.some(record => record.meta?.requiresLogin)) return true

  // uzivatel je prihlaseny? dovolime navigaci
  if (store.getters.isAuthenticated) enableNavigation = true

  // cesta vyzaduje navigaci a uzivatel neni prihlaseny?
  // nacteme data ze sessionStorage
  const user = sessionStorage.getItem('c_user')

  if (user) {
    // v sessionStorage je uložený uživatel z posledního přihlášení
    // načteme data
    store.commit('SET_USER_DATA', JSON.parse(user))

    // a dovolime navigaci
    enableNavigation = true
  }

  if (enableNavigation) {
    try {
      const optionsResponse = await CiselnikyService.getOptions()
      store.commit('SET_OPTIONS', optionsResponse.data.data)

      return true
    } catch {
      return { name: 'Login' }
    }
  }

  // nepodařilo se načíst uživatele ze sessionStorage
  // přesměrujeme na Login
  return { name: 'Login' }
})


export default router
