<template>
  <div>
    <PageHeader title="Rozhodnutí" />
  </div>
</template>

<script>
import PageHeader from '@/components/Common/PageHeader.vue'

export default {
  name: 'Rozhodnutí',
  components: {
    PageHeader
  },
  data() {
    return {

    }
  }
}
</script>