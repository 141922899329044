<template>
	<div
		class="simple-checkbox"
		:class="{ 'simple-checkbox--checked': checked }"
	>
		<font-awesome-icon
			class="simple-checkbox__icon"
			icon="check"
		/>
	</div>
</template>

<script>
export default {
	name: 'VisualCheckbox',

	props: {
		checked: {
			type: Boolean,
			default: false,
		},
	},

}
</script>
