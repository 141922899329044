<template>

  <PageHeader title="Systém elektronických žádostí" />

  <Message type="info" class="mb-4">
    Kliknutím na řádek v tabulce se otevře detail s možností editace.
  </Message>


  <div class="grid">
    <div class="grid__column">

      <InformaceOrganizacePrehled />

    </div>
    <div class="grid__column">

      <ZadostiPrehled />

    </div>
  </div>

</template>

<script>
import { authComputed } from '@/store/helpers.js'
import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import InformaceOrganizacePrehled from '@/components/Dashboard/Organizace/InformaceOrganizacePrehled'
import ZadostiPrehled from '@/components/Dashboard/Organizace/ZadostiPrehled'

export default {
  name: 'DashboardOrganizace',

  components: {
    PageHeader,
    Message,
    InformaceOrganizacePrehled,
    ZadostiPrehled,
  },

  computed: {
    ...authComputed
  },
}
</script>
