<template>
  <div>
    <ForgotPasswordForm />
  </div>
</template>

<script>
import ForgotPasswordForm from '@/components/ForgotPassword/ForgotPasswordForm'

export default {
  name: 'ForgotPassword',

  components: {
    ForgotPasswordForm,
  },

}
</script>
