<template>
	<nav class="menu">
		<ul v-if="loggedIn" class="menu__list">
			<template v-if="isOrg">
				<!-- MENU UZIVATELU ORGANIZACE -->
				<MenuItem
					title="Dashboard"
					subtitle="Rychlý přehled"
					:to="{ name: 'Dashboard' }"
				/>
				<MenuItem
					title="Organizace"
					subtitle="Doplnění informací"
					:to="{ name: 'OrganizaceInformace' }"
				/>
				<MenuItem
					title="Hlavní strana žádosti"
					subtitle="Přehled projektů"
					:to="{ name: 'Zadost' }"
				/>
				<MenuItem
					v-if="isStatutar"
					title="Uživatelé"
					subtitle="Správa uživatelů"
					:to="{ name: 'Uzivatele' }"
				/>
			</template>

			<template v-if="isGov">
				<!-- ADMINISTRACNI MENU -->
				<MenuItem
					title="Dashboard"
					subtitle="Rychlý přehled"
					:to="{ name: 'Dashboard' }"
				/>
				<MenuItem
					v-if="isSuperAdmin || isAdmin || isKontrolor"
					title="Organizace"
					subtitle="Seznam organizací"
					:to="{ name: 'Organizace' }"
				/>
				<MenuItem
					v-if="isSuperAdmin || isAdmin"
					title="Uživatelé"
					subtitle="Aktivace uživatelů"
					:to="{ name: 'Uzivatele' }"
				/>
				<MenuItem
					v-if="isSuperAdmin || isAdmin || isKontrolor"
					title="Žádosti"
					subtitle="Přehled žádostí"
					:to="{ name: 'Zadosti' }"
				/>
				<MenuItem
					title="Projekty"
					subtitle="Přehled projektů"
					:to="{ name: 'Projekty' }"
				/>
				<MenuItem
					v-if="isSuperAdmin || isAdmin || isKontrolor"
					title="Export"
					subtitle="Exporty dat"
					:to="{ name: 'Exporty' }"
				/>
				<MenuItem
					v-if="isSuperAdmin || isAdmin || isKontrolor || isKrajskyKoordinator"
					title="Hodnocení"
					subtitle="Hodnocení projektů"
					:to="{ name: 'Hodnoceni' }"
				/>
				<MenuItem
					v-if="isSuperAdmin || isAdmin"
					title="Nastavení"
					subtitle="Nastavení aplikace"
					:to="{ name: 'DotacniRoky' }"
				/>
			</template>
		</ul>
	</nav>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import MenuItem from '@/components/Menu/MenuItem'

export default {
	name: 'Menu',

	components: {
		MenuItem
	},

	computed: {
    ...authComputed,
  },

	data() {
		return {
		}
	}
}
</script>
