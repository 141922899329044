<template>

	<SectionHeader :title="`Část C2 - Rozpočet projektu dle nákladových položek na rok ${projekt.dotacni_rok}`" />

	<table class="table">
		<thead>
			<tr>
				<th class="w40">Nákladová položka</th>
				<th class="w10">Celkové náklady<br>projektu<br>na {{projekt.dotacni_rok}}</th>
				<th class="w10">Požadavek na<br>dotaci ÚV ČR<br>na {{projekt.dotacni_rok}}</th>
				<th class="w30">Specifikace položek k rozpočtu<br>dotace ÚV ČR</th>
			</tr>
		</thead>
		<tbody>

			<tr class="table__sum-row">
				<td>1. PROVOZNÍ NÁKLADY celkem</td>
				<td class="alr" v-tippy="chybaTooltip('np1_provozni_naklady_celkem')" v-bind="chyba('np1_provozni_naklady_celkem')">{{ formatNumberCZ(data.np1_provozni_naklady_celkem) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd1_provozni_naklady_celkem')" v-bind="chyba('pd1_provozni_naklady_celkem')">{{ formatNumberCZ(data.pd1_provozni_naklady_celkem) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr class="table__sub-sum-row">
				<td class="pl-3">1.1 Materiálové náklady celkem</td>
				<td class="alr" v-tippy="chybaTooltip('np11_materialove_naklady_celkem')" v-bind="chyba('np11_materialove_naklady_celkem')">{{ formatNumberCZ(data.np11_materialove_naklady_celkem) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd11_materialove_naklady_celkem')" v-bind="chyba('pd11_materialove_naklady_celkem')">{{ formatNumberCZ(data.pd11_materialove_naklady_celkem) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.1 potraviny</td>
				<td class="alr" v-tippy="chybaTooltip('np111_potraviny')" v-bind="chyba('np111_potraviny')">{{ formatNumberCZ(data.np111_potraviny) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd111_potraviny')" v-bind="chyba('pd111_potraviny')">{{ formatNumberCZ(data.pd111_potraviny) }}</td>
				<td v-tippy="chybaTooltip('pd111_potraviny_upresneni')" v-bind="chyba('pd111_potraviny_upresneni')">{{data.pd111_potraviny_upresneni}}</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.2 zdravotnický materiál</td>
				<td class="alr" v-tippy="chybaTooltip('np112_zdravotnicky_material')" v-bind="chyba('np112_zdravotnicky_material')">{{ formatNumberCZ(data.np112_zdravotnicky_material) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd112_zdravotnicky_material')" v-bind="chyba('pd112_zdravotnicky_material')">{{ formatNumberCZ(data.pd112_zdravotnicky_material) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.3 hygienický materiál</td>
				<td class="alr" v-tippy="chybaTooltip('np113_hygienicky_material')" v-bind="chyba('np113_hygienicky_material')">{{ formatNumberCZ(data.np113_hygienicky_material) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd113_hygienicky_material')" v-bind="chyba('pd113_hygienicky_material')">{{ formatNumberCZ(data.pd113_hygienicky_material) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.4 kancelářské potřeby</td>
				<td class="alr" v-tippy="chybaTooltip('np114_kancelarske_potreby')" v-bind="chyba('np114_kancelarske_potreby')">{{ formatNumberCZ(data.np114_kancelarske_potreby) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd114_kancelarske_potreby')" v-bind="chyba('pd114_kancelarske_potreby')">{{ formatNumberCZ(data.pd114_kancelarske_potreby) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.5 DDHM</td>
				<td class="alr" v-tippy="chybaTooltip('np115_ddhm')" v-bind="chyba('np115_ddhm')">{{ formatNumberCZ(data.np115_ddhm) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd115_ddhm')" v-bind="chyba('pd115_ddhm')">{{ formatNumberCZ(data.pd115_ddhm) }}</td>
				<td v-tippy="chybaTooltip('pd115_ddhm_upresneni')" v-bind="chyba('pd115_ddhm_upresneni')">{{data.pd115_ddhm_upresneni}}</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.6 pohonné hmoty</td>
				<td class="alr" v-tippy="chybaTooltip('np116_pohonne_hmoty')" v-bind="chyba('np116_pohonne_hmoty')">{{ formatNumberCZ(data.np116_pohonne_hmoty) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd116_pohonne_hmoty')" v-bind="chyba('pd116_pohonne_hmoty')">{{ formatNumberCZ(data.pd116_pohonne_hmoty) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.1.7 ostatní materiálové náklady</td>
				<td class="alr" v-tippy="chybaTooltip('np117_ostatni_materialove_naklady')" v-bind="chyba('np117_ostatni_materialove_naklady')">{{ formatNumberCZ(data.np117_ostatni_materialove_naklady) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd117_ostatni_materialove_naklady')" v-bind="chyba('pd117_ostatni_materialove_naklady')">{{ formatNumberCZ(data.pd117_ostatni_materialove_naklady) }}</td>
				<td v-tippy="chybaTooltip('pd117_ostatni_materialove_naklady_upresneni')" v-bind="chyba('pd117_ostatni_materialove_naklady_upresneni')">{{data.pd117_ostatni_materialove_naklady_upresneni}}</td>
			</tr>

			<tr class="table__sub-sum-row">
				<td class="pl-3">1.2 Opravy a udržování</td>
				<td class="alr" v-tippy="chybaTooltip('np12_opravy_udrzovani')" v-bind="chyba('np12_opravy_udrzovani')">{{ formatNumberCZ(data.np12_opravy_udrzovani) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd12_opravy_udrzovani')" v-bind="chyba('pd12_opravy_udrzovani')">{{ formatNumberCZ(data.pd12_opravy_udrzovani) }}</td>
				<td v-tippy="chybaTooltip('pd12_opravy_udrzovani_upresneni')" v-bind="chyba('pd12_opravy_udrzovani_upresneni')">{{data.pd12_opravy_udrzovani_upresneni}}</td>
			</tr>

			<tr class="table__sub-sum-row">
				<td class="pl-3">1.3 Cestovné celkem</td>
				<td class="alr" v-tippy="chybaTooltip('np13_cestovne_celkem')" v-bind="chyba('np13_cestovne_celkem')">{{ formatNumberCZ(data.np13_cestovne_celkem) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd13_cestovne_celkem')" v-bind="chyba('pd13_cestovne_celkem')">{{ formatNumberCZ(data.pd13_cestovne_celkem) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.3.1 tuzemské cestovné</td>
				<td class="alr" v-tippy="chybaTooltip('np131_tuzemske_cestovne')" v-bind="chyba('np131_tuzemske_cestovne')">{{ formatNumberCZ(data.np131_tuzemske_cestovne) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd131_tuzemske_cestovne')" v-bind="chyba('pd131_tuzemske_cestovne')">{{ formatNumberCZ(data.pd131_tuzemske_cestovne) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.3.2 zahraniční cestovné</td>
				<td class="alr" v-tippy="chybaTooltip('np132_zahranicni_cestovne')" v-bind="chyba('np132_zahranicni_cestovne')">{{ formatNumberCZ(data.np132_zahranicni_cestovne) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd132_zahranicni_cestovne')" v-bind="chyba('pd132_zahranicni_cestovne')">{{ formatNumberCZ(data.pd132_zahranicni_cestovne) }}</td>
				<td>&nbsp;</td>
			</tr>

			<tr class="table__sub-sum-row">
				<td class="pl-3">1.4 Služby celkem</td>
				<td class="alr" v-tippy="chybaTooltip('np14_sluzby_celkem')" v-bind="chyba('np14_sluzby_celkem')">{{ formatNumberCZ(data.np14_sluzby_celkem) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd14_sluzby_celkem')" v-bind="chyba('pd14_sluzby_celkem')">{{ formatNumberCZ(data.pd14_sluzby_celkem) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">1.4.1 energie</td>
				<td class="alr" v-tippy="chybaTooltip('np141_energie')" v-bind="chyba('np141_energie')">{{ formatNumberCZ(data.np141_energie) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd141_energie')" v-bind="chyba('pd141_energie')">{{ formatNumberCZ(data.pd141_energie) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr class="table__subsub-sum-row">
				<td class="pl-4">1.4.2 ostatní služby:</td>
				<td class="alr" v-tippy="chybaTooltip('np142_pstatni_sluzby')" v-bind="chyba('np142_pstatni_sluzby')">{{ formatNumberCZ(data.np142_pstatni_sluzby) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd142_pstatni_sluzby')" v-bind="chyba('pd142_pstatni_sluzby')">{{ formatNumberCZ(data.pd142_pstatni_sluzby) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-5">- spoje (telefony, internet, poštovné apod.)</td>
				<td class="alr" v-tippy="chybaTooltip('np1421_spoje')" v-bind="chyba('np1421_spoje')">{{ formatNumberCZ(data.np1421_spoje) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd1421_spoje')" v-bind="chyba('pd1421_spoje')">{{ formatNumberCZ(data.pd1421_spoje) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-5">- nájemné</td>
				<td class="alr" v-tippy="chybaTooltip('np1422_najemne')" v-bind="chyba('np1422_najemne')">{{ formatNumberCZ(data.np1422_najemne) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd1422_najemne')" v-bind="chyba('pd1422_najemne')">{{ formatNumberCZ(data.pd1422_najemne) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-5">- operativní leasing</td>
				<td class="alr" v-tippy="chybaTooltip('np1423_operativni_leasing')" v-bind="chyba('np1423_operativni_leasing')">{{ formatNumberCZ(data.np1423_operativni_leasing) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd1423_operativni_leasing')" v-bind="chyba('pd1423_operativni_leasing')">{{ formatNumberCZ(data.pd1423_operativni_leasing) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-5">- školení a vzdělávání</td>
				<td class="alr" v-tippy="chybaTooltip('np1424_skoleni_vzdelavani')" v-bind="chyba('xxxxx')">{{ formatNumberCZ(data.np1424_skoleni_vzdelavani) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd1424_skoleni_vzdelavani')" v-bind="chyba('pd1424_skoleni_vzdelavani')">{{ formatNumberCZ(data.pd1424_skoleni_vzdelavani) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-5">- ekonomické, účetní a právní služby</td>
				<td class="alr" v-tippy="chybaTooltip('np1425_ekonomicke_ucetni_pravni_sluzby')" v-bind="chyba('np1425_ekonomicke_ucetni_pravni_sluzby')">{{ formatNumberCZ(data.np1425_ekonomicke_ucetni_pravni_sluzby) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd1425_ekonomicke_ucetni_pravni_sluzby')" v-bind="chyba('pd1425_ekonomicke_ucetni_pravni_sluzby')">{{ formatNumberCZ(data.pd1425_ekonomicke_ucetni_pravni_sluzby) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-5">- DDNM</td>
				<td class="alr" v-tippy="chybaTooltip('np1426_ddnm')" v-bind="chyba('np1426_ddnm')">{{ formatNumberCZ(data.np1426_ddnm) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd1426_ddnm')" v-bind="chyba('pd1426_ddnm')">{{ formatNumberCZ(data.pd1426_ddnm) }}</td>
				<td v-tippy="chybaTooltip('pd1426_ddnm_upresneni')" v-bind="chyba('pd1426_ddnm_upresneni')">{{data.pd1426_ddnm_upresneni}}</td>
			</tr>
			<tr>
				<td class="pl-5">- jiné ostatní služby</td>
				<td class="alr" v-tippy="chybaTooltip('np1427_ostatni_sluzby')" v-bind="chyba('np1427_ostatni_sluzby')">{{ formatNumberCZ(data.np1427_ostatni_sluzby) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd1427_ostatni_sluzby')" v-bind="chyba('pd1427_ostatni_sluzby')">{{ formatNumberCZ(data.pd1427_ostatni_sluzby) }}</td>
				<td v-tippy="chybaTooltip('pd1427_ostatni_sluzby_upresneni')" v-bind="chyba('pd1427_ostatni_sluzby_upresneni')">{{data.pd1427_ostatni_sluzby_upresneni}}</td>
			</tr>

			<tr class="table__sub-sum-row">
				<td class="pl-3">1.5 Jiné provozní náklady celkem</td>
				<td class="alr" v-tippy="chybaTooltip('np15_provozni_naklady_celkem')" v-bind="chyba('np15_provozni_naklady_celkem')">{{ formatNumberCZ(data.np15_provozni_naklady_celkem) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd15_provozni_naklady_celkem')" v-bind="chyba('pd15_provozni_naklady_celkem')">{{ formatNumberCZ(data.pd15_provozni_naklady_celkem) }}</td>
				<td v-tippy="chybaTooltip('pd15_provozni_naklady_celkem_upresneni')" v-bind="chyba('pd15_provozni_naklady_celkem_upresneni')">{{data.pd15_provozni_naklady_celkem_upresneni}}</td>
			</tr>


			<tr class="table__sum-row">
				<td>2. OSOBNÍ NÁKLADY celkem</td>
				<td class="alr" v-tippy="chybaTooltip('np2_osobni_naklady_celkem')" v-bind="chyba('np2_osobni_naklady_celkem')">{{ formatNumberCZ(data.np2_osobni_naklady_celkem) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd2_osobni_naklady_celkem')" v-bind="chyba('pd2_osobni_naklady_celkem')">{{ formatNumberCZ(data.pd2_osobni_naklady_celkem) }}</td>
				<td>&nbsp;</td>
			</tr>

			<tr class="table__sub-sum-row">
				<td class="pl-3">2.1 Mzdové náklady</td>
				<td class="alr" v-tippy="chybaTooltip('np21_mzdove_naklady')" v-bind="chyba('np21_mzdove_naklady')">{{ formatNumberCZ(data.np21_mzdove_naklady) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd21_mzdove_naklady')" v-bind="chyba('pd21_mzdove_naklady')">{{ formatNumberCZ(data.pd21_mzdove_naklady) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">2.1.1 hrubé mzdy/platy</td>
				<td class="alr" v-tippy="chybaTooltip('np211_hrube_mzdy_platy')" v-bind="chyba('np211_hrube_mzdy_platy')">{{ formatNumberCZ(data.np211_hrube_mzdy_platy) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd211_hrube_mzdy_platy')" v-bind="chyba('pd211_hrube_mzdy_platy')">{{ formatNumberCZ(data.pd211_hrube_mzdy_platy) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">2.1.2 ON na DPČ/DPP</td>
				<td class="alr" v-tippy="chybaTooltip('np212_on')" v-bind="chyba('np212_on')">{{ formatNumberCZ(data.np212_on) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd212_on')" v-bind="chyba('xxxxx')">{{ formatNumberCZ(data.pd212_on) }}</td>
				<td>&nbsp;</td>
			</tr>
			<tr>
				<td class="pl-4">2.1.3 odvody zaměstnavatele na sociální a zdravotní pojištění</td>
				<td class="alr" v-tippy="chybaTooltip('np213_odvody_socialni_zdravotni')" v-bind="chyba('np213_odvody_socialni_zdravotni')">{{ formatNumberCZ(data.np213_odvody_socialni_zdravotni) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd213_odvody_socialni_zdravotni')" v-bind="chyba('pd213_odvody_socialni_zdravotni')">{{ formatNumberCZ(data.pd213_odvody_socialni_zdravotni) }}</td>
				<td>&nbsp;</td>
			</tr>

			<tr class="table__sub-sum-row">
				<td class="pl-3">2.2 Sociální náklady <em>(např. zákonné pojištění odpovědnosti zaměstnavatele…)</em></td>
				<td class="alr" v-tippy="chybaTooltip('np22_socialni_naklady')" v-bind="chyba('np22_socialni_naklady')">{{ formatNumberCZ(data.np22_socialni_naklady) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd22_socialni_naklady')" v-bind="chyba('pd22_socialni_naklady')">{{ formatNumberCZ(data.pd22_socialni_naklady) }}</td>
				<td v-tippy="chybaTooltip('pd22_socialni_naklady_upresneni')" v-bind="chyba('pd22_socialni_naklady_upresneni')">{{data.pd22_socialni_naklady_upresneni}}</td>
			</tr>

			<tr class="table__sub-sum-row">
				<td class="pl-3">2.3  Ostatní osobní náklady <em>(např. práce dobrovolníků, ...)</em></td>
				<td class="alr" v-tippy="chybaTooltip('np23_osobni_naklady')" v-bind="chyba('np23_osobni_naklady')">{{ formatNumberCZ(data.np23_osobni_naklady) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd23_osobni_naklady')" v-bind="chyba('pd23_osobni_naklady')">{{ formatNumberCZ(data.pd23_osobni_naklady) }}</td>
				<td v-tippy="chybaTooltip('pd23_osobni_naklady_upresneni')" v-bind="chyba('pd23_osobni_naklady_upresneni')">{{data.pd23_osobni_naklady_upresneni}}</td>
			</tr>

			<tr class="table__sum-row">
				<td>CELKOVÉ NÁKLADY PROJEKTU</td>
				<td class="alr" v-tippy="chybaTooltip('np_naklady_projektu_celkem')" v-bind="chyba('np_naklady_projektu_celkem')">{{ formatNumberCZ(data.np_naklady_projektu_celkem) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pd_naklady_projektu_celkem')" v-bind="chyba('pd_naklady_projektu_celkem')">{{ formatNumberCZ(data.pd_naklady_projektu_celkem) }}</td>
				<td class="table__no-cell">&nbsp;</td>
			</tr>

		</tbody>
	</table>

</template>

<script>
import { formatNumberCZ } from '@/utils/format.js'

import SectionHeader from '@/components/Common/SectionHeader'

export default {
	name: 'CastC2',

	components: {
		SectionHeader,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		}
	},

	methods: {
		formatNumberCZ,

		importError(field) {
			if (!Array.isArray(this.projekt.kontrola_c)) return undefined
			return this.projekt.kontrola_c.find(err => err.pole === field && err.list === 'C2')
		},

		chyba(field) {
			const importError = this.importError(field)
			return (
				importError
				? {
					class: 'table__error'
				}
				: {}
			)
		},

		chybaTooltip(field) {
			const importError = this.importError(field)
			return (
				importError
				? {
					content: `<strong>Chyba na listu ${importError.list} v buňce ${importError.bunka}</strong>` + (importError.chyba ? `<br>${importError.chyba}` : '' )
				}
				: {}
			)
		},

	},

}
</script>