<template>
	<div class="page-header">
		<h1 class="page-header__title">
			{{ title }}
		</h1>

		<div v-if="!isEmptySlot" class="page-header__buttons">
			<slot />
		</div>

		<div v-if="subtitle" class="page-header__subtitle">
			{{ subtitle }}
		</div>
	</div>
</template>

<script>
export default {
	name: 'PageHeader',

	props: {
		title: {
			type: String,
			required: true,
		},
		subtitle: {
			type: String,
			required: false,
		},
	},

	computed: {
		isEmptySlot() {
			return !this.$slots.default
		}
	},
}
</script>
