<template>
	<PageHeader
      v-if="projekt"
		:title="`VYÚČTOVÁNÍ DOTACE ${nazevProjektu}`"
		:subtitle="`Dotační rok ${dotacniRok}`"
  >
      <div class="buttons" v-if="(isSuperAdmin || isAdmin) && projekt.stav === STAV_PROJEKTU.VYUCTOVANI_ODESLANO">
        <button @click="dialogVraceni()" class="button button--small button--danger button--outline">
          Vrátit k přepracování
        </button>
      </div>
  </PageHeader>

  <Modal
      v-if="modalOpened"
      :overlay="true"
  >
    <VyuctovaniVratitDialog
        v-if="modalDialog === 'vratit'"
        :id="vyuctovani.id"
        @submit="vratitVyuctovani"
        @cancel="closeModal"
    />
  </Modal>

  <div class="panelgrid mt-5" style="margin-bottom: 40px;" v-if="vyuctovani && vyuctovani.stav_vyuctovani === 2">
    <div class="panelgrid__panel panelgrid__panel--danger">
      <div class="panelgrid__content">
        <h2 class="panelgrid__title">Vyúčtování vráceno k přepracování</h2>
        <p>Vyúčtování Vám bylo vráceno k přepracování. Zdůvodnění najdete v popisu vpravo.</p>
        <p>Opravte nedostatky, vraťte se na <router-link :to="{ name: 'Zadost' }">hlavní stranu žádosti</router-link> a žádost znovu odešlete ke kontrole.</p>
      </div>
    </div>

    <div class="panelgrid__panel panelgrid__panel--danger panelgrid__panel--twocolumns">
      <div class="panelgrid__content">
        <h2 class="panelgrid__title">Důvod vrácení</h2>

      </div>
    </div>
  </div>

	<Tabs
		:items="tabItems"
		:selected="tabSelected"
		@changeTab="changeTab($event)"
	/>

	<div class="content">

		<Message type="error" v-if="error" class="mb-5">
			{{ error }}
		</Message>

		<Loading v-if="!dataLoaded" />

		<template v-if="dataLoaded">

			<VyuctovaniNavod
				v-if="tabSelected == 1"
				:projekt="projekt"
			/>

			<VyuctovaniTab1Dotace
				v-if="tabSelected === 2"
				:projekt="projekt"
				:data="vyuctovani.tab1"
				:disabled="!jeMoznePodatVyuctovani"
				@update="updateField($event)"
			/>

			<VyuctovaniTab2Zdroje
				v-if="tabSelected === 3"
				:projekt="projekt"
				:data="vyuctovani.tab2"
				:disabled="!jeMoznePodatVyuctovani"
				@update="updateField($event)"
			/>

			<VyuctovaniTab3Rozpocet
				v-if="tabSelected === 4"
				:projekt="projekt"
				:data="vyuctovani.tab3"
				:disabled="!jeMoznePodatVyuctovani"
				@update="updateField($event)"
			/>

			<VyuctovaniExcel
				v-if="tabSelected == 5"
				:vyuctovani="vyuctovani"
				:projekt="projekt"
				:disabled="!jeMoznePodatVyuctovani"
				@uploaded="updateFile($event)"
			/>

			<template v-if="jeMoznePodatVyuctovani && tabSelected != 5">
				<hr class="form__divider">

				<Loading title="Ukládám data&hellip;" v-if="isSaving" />

				<Message type="error" v-if="savingError" class="mt-3 mb-3">
					{{ savingError }}
				</Message>

				<Message type="info" v-if="jeMoznePodatVyuctovani && vyuctovani.uzamceno" class="mt-3 mb-3">
					Vyúčtování je uzamčeno a připraveno k odeslání. Potřebujete-li údaje upravit, můžete ho znovu odemknout.
				</Message>

				<div class="form__buttons">
					<button
					 	v-if="jeMoznePodatVyuctovani && !vyuctovani.uzamceno"
						class="button"
						:disabled="isSaving"
						@click.prevent="updateVyuctovani({uzamceno: 1})"
					>
						<font-awesome-icon icon="lock" class="button__icon" />
						Uložit a zamknout pro odeslání
					</button>

					<button
						v-if="jeMoznePodatVyuctovani && !vyuctovani.uzamceno"
						class="button button--outline"
						:disabled="isSaving"
						@click.prevent="updateVyuctovani({uzamceno: 0})"
					>
						<font-awesome-icon icon="save" class="button__icon" />
						Uložit koncept (k odeslání později)
					</button>

					<button
						v-if="jeMoznePodatVyuctovani && vyuctovani.uzamceno"
						class="button button--outline"
						:disabled="isSaving"
						@click.prevent="updateVyuctovani({uzamceno: 0})"
					>
						<font-awesome-icon icon="unlock" class="button__icon" />
						Odemknout vyúčtování pro úpravy
					</button>

				</div>
			</template>

		</template>
	</div>
</template>

<script>
import { STAV_PROJEKTU } from '@/constants'
import { toNumberCZ, isNumericCZ } from '@/utils/convert.js'
import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'

import Modal from '@/components/Common/Modal'
import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import Tabs from '@/components/Common/Tabs'

import VyuctovaniVratitDialog from '@/components/Projekty/Dialog/VyuctovaniVratitDialog'
import VyuctovaniNavod from '@/components/Projekty/Vyuctovani/VyuctovaniNavod'
import VyuctovaniTab1Dotace from '@/components/Projekty/Vyuctovani/VyuctovaniTab1Dotace'
import VyuctovaniTab2Zdroje from '@/components/Projekty/Vyuctovani/VyuctovaniTab2Zdroje'
import VyuctovaniTab3Rozpocet from '@/components/Projekty/Vyuctovani/VyuctovaniTab3Rozpocet'
import VyuctovaniExcel from '@/components/Projekty/Vyuctovani/VyuctovaniExcel'

export default {
	name: 'ProjektVyuctovani',

	components: {
		PageHeader,
		Message,
		Loading,
		Tabs,
    Modal,
		VyuctovaniNavod,
		VyuctovaniTab1Dotace,
		VyuctovaniTab2Zdroje,
		VyuctovaniTab3Rozpocet,
		VyuctovaniExcel,
    VyuctovaniVratitDialog
	},

	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},

	data() {
		return {
      STAV_PROJEKTU: '',
      modalOpened: false,
      modalDialog: '',

			error: '',
			savingError: '',

			vyuctovaniLoaded: false,
			projektLoaded: false,
			isSaving: false,

			vyuctovani: null,
			projekt: null,

			tabSelected: 1,
			tabItems: [
				{
					id: 0,
					title: 'Zpět na projekt',
					icon: 'arrow-left',
				},
				{
					id: 1,
					title: 'Návod',
				},
				{
					id: 2,
					title: 'Dotace',
					subtitle: 'Tab. 1',
				},
				{

					id: 3,
					title: 'Zdroje',
					subtitle: 'Tab. 2',
				},
				{
					id: 4,
					title: 'Rozpočet',
					subtitle: 'Tab. 3',
				},
				{
					id: 5,
					title: 'Export/import XLSX',
				},
			],
		}
	},

	computed: {
		...authComputed,

		dataLoaded() {
			return this.vyuctovaniLoaded && this.projektLoaded
		},

		nazevProjektu() {
			return (
				this.projektLoaded
				? ` - ${this.projekt.kod}: ${this.projekt.nazev}`
				: ''
			)
		},

		dotacniRok() {
			return this.projektLoaded ? this.projekt.dotacni_rok	: ''
		},

		jeMoznePodatVyuctovani() {
			return true
		}
	},

	methods: {
		isNumericCZ,
		toNumberCZ,

    dialogVraceni() {
      this.modalDialog = 'vratit'
      this.modalOpened = true
    },

		changeTab(tabId) {
			if (tabId === 0) {
				// zpět na projekt
				this.$router.push({ name: 'Projekt', params: { id: this.id } })
			}
			this.tabSelected = tabId
		},

		updateField({tab, prefix, field, value}) {
			this.vyuctovani[tab][prefix + field] = this.toNumberOnlyChars(value)
			switch(tab) {
				case 'tab3':
					this.recalculateTab3(prefix)
					break;
				case 'tab2':
					this.recalculateTab2(prefix)
					break;
			}
			this.recalculateTab1()
		},

		recalculateTab1() {
			this.vyuctovani.tab1.cerpane_provozni =
				this.vyuctovani.tab3.sc1_provozni_naklady_celkem

			this.vyuctovani.tab1.cerpane_osobni =
				this.vyuctovani.tab3.sc2_osobni_naklady_celkem

			this.vyuctovani.tab1.cerpane_celkem =
				this.vyuctovani.tab1.cerpane_provozni +
				this.vyuctovani.tab1.cerpane_osobni

			this.vyuctovani.tab1.vratka =
				this.vyuctovani.tab1.ziskane_celkem -
				this.vyuctovani.tab1.cerpane_celkem

		},

		recalculateTab2(prefix) {
			this.vyuctovani.tab2[`${prefix}1_statni_rozpocet`] =
				this.toNumber(this.vyuctovani.tab2[`${prefix}11_uvcr`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}12_mpsv`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}13_urady_prace`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}14_msmt`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}15_mz`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}16_ms`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}17_ostatni_zdroje`])

			this.vyuctovani.tab2[`${prefix}2_uzemni_rozpocty`] =
				this.toNumber(this.vyuctovani.tab2[`${prefix}21_kraj_prostredkympsv`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}22_kraj_prostredkykraje`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}23_mesta_obce`])

			this.vyuctovani.tab2[`${prefix}6_soukrome_zdroje`] =
				this.toNumber(this.vyuctovani.tab2[`${prefix}61_nadace_sbirky_dary`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}62_prijmy_od_klientu`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}63_ostatni`])

				console.log( this.toNumber(this.vyuctovani.tab2[`${prefix}1_statni_rozpocet`]) )
				console.log( this.toNumber(this.vyuctovani.tab2[`${prefix}2_uzemni_rozpocty`]) )
				console.log( this.toNumber(this.vyuctovani.tab2[`${prefix}3_esif`]) )
				console.log( this.toNumber(this.vyuctovani.tab2[`${prefix}4_zahranicni_zdroje`]) )
				console.log( this.toNumber(this.vyuctovani.tab2[`${prefix}5_uhrady_zp`]) )
				console.log( this.toNumber(this.vyuctovani.tab2[`${prefix}6_soukrome_zdroje`]) )

			this.vyuctovani.tab2[`${prefix}_celkove_naklady`] =
				this.toNumber(this.vyuctovani.tab2[`${prefix}1_statni_rozpocet`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}2_uzemni_rozpocty`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}3_esif`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}4_zahranicni_zdroje`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}5_uhrady_zp`]) +
				this.toNumber(this.vyuctovani.tab2[`${prefix}6_soukrome_zdroje`])

			if ( (this.toNumber(this.vyuctovani.tab2[`${prefix}_celkove_naklady`])) !== 0) {
				this.vyuctovani.tab2[`${prefix}_spoluucast_uvcr`] = Math.round(10000 *
					this.toNumber(this.vyuctovani.tab2[`${prefix}11_uvcr`]) /
					this.toNumber(this.vyuctovani.tab2[`${prefix}_celkove_naklady`])
				) / 100

				this.vyuctovani.tab2[`${prefix}_spoluucast_jinezdroje`] = Math.round(10000 *
					(
						this.toNumber(this.vyuctovani.tab2[`${prefix}_celkove_naklady`]) -
						this.toNumber(this.vyuctovani.tab2[`${prefix}11_uvcr`])
					) /
					this.toNumber(this.vyuctovani.tab2[`${prefix}_celkove_naklady`])
				) / 100;
			} else {
				this.vyuctovani.tab2[`${prefix}_spoluucast_uvcr`] = 0
				this.vyuctovani.tab2[`${prefix}_spoluucast_jinezdroje`] = 0
			}
		},

		recalculateTab3(prefix) {
			this.vyuctovani.tab3[`${prefix}11_materialove_naklady_celkem`] =
				this.toNumber(this.vyuctovani.tab3[`${prefix}111_potraviny`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}112_zdravotnicky_material`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}113_hygienicky_material`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}114_kancelarske_potreby`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}115_ddhm`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}116_pohonne_hmoty`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}117_ostatni_materialove_naklady`])

			this.vyuctovani.tab3[`${prefix}13_cestovne_celkem`] =
				this.toNumber(this.vyuctovani.tab3[`${prefix}131_tuzemske_cestovne`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}132_zahranicni_cestovne`])

			this.vyuctovani.tab3[`${prefix}142_pstatni_sluzby`] =
				this.toNumber(this.vyuctovani.tab3[`${prefix}1421_spoje`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}1422_najemne`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}1423_operativni_leasing`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}1424_skoleni_vzdelavani`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}1425_ekonomicke_ucetni_pravni_sluzby`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}1426_ddnm`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}1427_ostatni_sluzby`])

			this.vyuctovani.tab3[`${prefix}14_sluzby_celkem`] =
				this.toNumber(this.vyuctovani.tab3[`${prefix}141_energie`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}142_pstatni_sluzby`])

			this.vyuctovani.tab3[`${prefix}1_provozni_naklady_celkem`] =
				this.toNumber(this.vyuctovani.tab3[`${prefix}11_materialove_naklady_celkem`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}12_opravy_udrzovani`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}13_cestovne_celkem`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}14_sluzby_celkem`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}15_provozni_naklady_celkem`])

			this.vyuctovani.tab3[`${prefix}21_mzdove_naklady`] =
				this.toNumber(this.vyuctovani.tab3[`${prefix}211_hrube_mzdy_platy`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}212_on`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}213_odvody_socialni_zdravotni`])

			this.vyuctovani.tab3[`${prefix}2_osobni_naklady_celkem`] =
				this.toNumber(this.vyuctovani.tab3[`${prefix}21_mzdove_naklady`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}22_socialni_naklady`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}23_osobni_naklady`])

			this.vyuctovani.tab3[`${prefix}_naklady_projektu_celkem`] =
				this.toNumber(this.vyuctovani.tab3[`${prefix}1_provozni_naklady_celkem`]) +
				this.toNumber(this.vyuctovani.tab3[`${prefix}2_osobni_naklady_celkem`])
		},

		toNumberOnlyChars(value) {
			return typeof value === 'string'
				? value.replace(/[^0-9\-\,\.]*/gi, '')
				: value
		},

		toNumber(value) {
			const num = this.toNumberCZ(value)
			return num || 0;
		},

		updateFile(file) {
			// probehl upload souboru, nacteme nova data
			this.loadVyuctovani()
		},

		async updateVyuctovani({uzamceno}) {
			this.isSaving = true
			this.savingError = ''

			const data = {
				...this.vyuctovani,
				...this.vyuctovani.tab1,
				...this.vyuctovani.tab2,
				...this.vyuctovani.tab3,
				uzamceno,
			}
			delete data.tab1
			delete data.tab2
			delete data.tab3

			try {
				const response = await ProjektyService.updateVyuctovani(this.projekt.id, data)
				if (!response.data.success) {
					this.savingError += response.data.error
				} else {
					this.vyuctovani.uzamceno = uzamceno
				}
			} catch(err) {
				this.savingError += err.message
			}

			this.isSaving = false
		},

    closeModal() {
      this.modalOpened = false
      this.modalDialog = ''
    },

    vratitVyuctovani() {
      this.closeModal()
      this.loadVyuctovani()
    },

		async loadVyuctovani() {
			this.vyuctovaniLoaded = false

			try {
				const response = await ProjektyService.getVyuctovani(this.id)
				if (!response.data.success) {
					this.error += response.data.error
					return
				}
				this.vyuctovani = response.data.data
			} catch(err) {
				this.error += err.message
				return
			}

			this.vyuctovaniLoaded = true
		},

		async loadProjekt() {
			this.projektLoaded = false

			try {
				const response = await ProjektyService.getProjekt(this.id)
				if (!response.data.success) {
					this.error += response.data.error
					return
				}
				this.projekt = response.data.data.projekt
			} catch(err) {
				this.error += err.message
				return
			}

			this.projektLoaded = true
		},

	},


	created() {
		this.loadVyuctovani()
		this.loadProjekt()
    this.STAV_PROJEKTU = STAV_PROJEKTU
	},

}
</script>
