<template>
	<div class="filters">

		<div class="filter size4">
			<label class="filter__label">Jméno a příjmení</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': jmeno }"
				v-model="jmeno"
				@input="updateFilter"
			/>
		</div>
		<div class="filter size4">
			<label class="filter__label">Organizace</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': organizace }"
				v-model="organizace"
				@input="updateFilter"
			/>
		</div>

		<div class="filter size4">
			<label class="filter__label">Telefon</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': telefon}"
				v-model="telefon"
				@input="updateFilter"
			/>
		</div>

		<div class="filter size5">
			<label class="filter__label">E-mail</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': email}"
				v-model="email"
				@input="updateFilter"
			/>
		</div>

		<div class="filter" v-if="filterActive">
			<a
				href="#"
				class="filter__link"
				@click.prevent="clearFilter"
			>Zrušit filtr</a>
		</div>
	</div>
</template>

<script>
export default {
	name: 'FiltrUzivatele',

	props: {
		filterValues: {
			type: Object,
			default: {},
		},
	},

	data() {
		return {
			jmeno: this.filterValues?.jmeno || '',
			organizace: this.filterValues?.organizace || '',
			telefon: this.filterValues?.telefon || '',
			email: this.filterValues?.email || '',
			role: this.filterValues?.role || '',
			stav: this.filterValues?.stav || '',
		}
	},

	computed: {
		filterActive() {
			return (
				!!this.jmeno
				|| !!this.organizace
				|| !!this.telefon
				|| !!this.email
				|| !!this.role
				|| !!this.stav
			)
		},
	},

	methods: {
		updateFilter() {
			this.$emit('updateFilter', {
				jmeno: this.jmeno,
				organizace: this.organizace,
				telefon: this.telefon,
				email: this.email,
				role: this.role,
				stav: this.stav,
			})
		},

		clearFilter() {
			this.jmeno = ''
			this.organizace = ''
			this.telefon = ''
			this.email = ''
			this.role = ''
			this.stav = ''

			this.updateFilter()
		}
	},


	created() {

	}
}
</script>
