import isNumeric from 'validator/es/lib/isNumeric'

// odstrani z textu vsechny mezery
export const removeSpaces = (text) => {
	return text.replace(/\s/g, '')
}

// nahradi . nebo , za novy znak
export const replaceDecimalChar = (text, newChar) => {
	return text.replace(/(\.|\,)/g, newChar)
}

// odstrani . nebo , na konci textoveho retezce
export const removeTrailingDecimalChar = (text) => {
	return text.replace(/(\.|\,)*$/, '')
}

export const toNumberCZ = (value) => {
	if (typeof value === 'number') return value
	if (typeof value !== 'string') return undefined

	let newValue = removeSpaces(value)
	newValue = replaceDecimalChar(newValue, '.')
	newValue = removeTrailingDecimalChar(newValue)
	return (
		isNumeric(newValue, {locale: 'en-US'})
		? Number(newValue)
		: undefined
	)
}

export const isNumericCZ = (value) => {
	if (typeof value === 'number') return true
	if (typeof value === 'string' && typeof toNumberCZ(value) === 'number') return true
	return false
}

export const separateThousands = (value) => {
	let newValue = toNumberCZ(value)
	if (!newValue) {
		return value
	}

	return Intl.NumberFormat('cs-CZ').format(newValue)
}