import ApiClient from './ApiClient'

export default {

	login(credentials) {
		return ApiClient
			.post('/login', credentials)
	},

	hesloReset(data) {
		return ApiClient
			.put('/heslo-reset', data)
	},

	hesloObnova(data, hash) {
		return ApiClient
			.put(`/heslo-obnova/${hash}`, data)
	},

	updatePassword(userId, data) {
		return ApiClient
			.put(`/heslo/${userId}`, data)
	}

}
