<template>
	<div class="filters">

		<div class="filter">
			<label class="filter__label">Rok</label>
			<select
				class="filter__input w100"
				:class="{ 'filter__input--selected': dotacniRok }"
				v-model="dotacniRok"
				@change="updateFilter"
			>
				<option value=""></option>
        <option v-for="item in dotacniRoky" :key="item.rok" :value="item.rok">{{ item.rok }}</option>
			</select>
		</div>

		<div class="filter size4">
			<label class="filter__label">Organizace</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': organizace}"
				v-model="organizace"
				@input="updateFilter"
			/>
		</div>

		<div class="filter">
			<label class="filter__label">Stav</label>
			<select
				class="filter__input w100"
				:class="{ 'filter__input--selected': stav}"
				v-model="stav"
				@change="updateFilter"
			>
				<option value=""></option>
				<option :value="STAV_ZADOSTI.PODAVANI">podávání žádosti</option>
				<option :value="STAV_ZADOSTI.UZAMCENO">uzamčeno, probíhá kontrola</option>
				<option :value="STAV_ZADOSTI.VRACENO">vráceno k přepracování</option>
			</select>
		</div>

		<div class="filter" v-if="filterActive">
			<a
				href="#"
				class="filter__link"
				@click.prevent="clearFilter"
			>Zrušit filtr</a>
		</div>
	</div>
</template>

<script>
import {
	STAV_ZADOSTI,
} from '@/constants'
import DotacniRokyService from "@/services/DotacniRokyService";

export default {
	name: 'FiltrZadosti',

	props: {
		filterValues: {
			type: Object,
			default: {},
		},
	},

	data() {
		return {
      dotacniRoky: [],
			dotacniRok: this.filterValues?.dotacniRok || '',
			organizace: this.filterValues?.organizace || '',
			stav: this.filterValues?.stav || '',
		}
	},

	computed: {
		filterActive() {
			return (
				!!this.dotacniRok
				|| !!this.organizace
				|| !!this.stav
			)
		},
	},

	methods: {
    async loadDotacniRoky() {
      try {
        const dotacniRokyResponse = await DotacniRokyService.getDotacniRoky()
        if (dotacniRokyResponse.data.success) {
          this.dotacniRoky = dotacniRokyResponse.data.data
          this.dataLoaded = true
        } else {
          this.error += dotacniRokyResponse.data.error
        }

      } catch(error) {
        this.error += error.message
      }
    },

		updateFilter() {
			this.$emit('updateFilter', {
				dotacniRok: this.dotacniRok,
				organizace: this.organizace,
				stav: this.stav,
			})
		},

		clearFilter() {
			this.dotacniRok = ''
			this.organizace = ''
			this.stav = ''

			this.updateFilter()
		}
	},


	created() {
		// constants
		this.STAV_ZADOSTI  = STAV_ZADOSTI
    this.loadDotacniRoky()
	}
}
</script>
