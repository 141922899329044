import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import VueTippy from 'vue-tippy'
import 'tippy.js/dist/tippy.css'
import '@/assets/scss/tippy-theme-cadros.scss'

import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome"
import '@/icons.js'

import '@/assets/scss/style.scss'

import CKEditor from '@ckeditor/ckeditor5-vue';

import axios from 'axios'

axios.interceptors.response.use(
	response => response,
	error => {
		if (error.response.status === 401) {
			store.dispatch('logout')
			.then(
				router.push({ name: 'Login' })
			)
		}
		return Promise.reject(error)
	}
)


createApp(App)
	.use(store)
	.use(router)
	.use(CKEditor)
	.use(VueTippy, {
		defaultProps: {
			theme: 'cadros',
			placement: 'top',
			allowHTML: true,
		}
	})
	.component("font-awesome-icon", FontAwesomeIcon)
	.mount('#app')
