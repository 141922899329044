<template>

  <Dialog title="Zapomenuté heslo">

    <form class="form form--size20">

      <Message
          class="mb-3"
          type="error"
          v-if="error"
      >
        {{ error }}
      </Message>

      <Message
          class="mb-3"
          type="info"
          v-if="message"
      >
        {{ message }}
      </Message>

      <template v-if="!message">
        <div class="form__field form__field--required">
          <label for="email" class="form__label">E-mail</label>
          <input
              class="form__input"
              type="text"
              name="email"
              id="email"
              v-model="email"
          >
        </div>

        <p>Zadejte e-mail, kterým se přihlašujete do aplikace. Po kliknutí na tlačítko vám <br>e-mailem bude zaslán odkaz na vytvoření nového hesla.</p>

        <div class="form__buttons form__buttons--aligned">

          <button
              class="button"
              @click.prevent="login"
          >
            <font-awesome-icon icon="sign-in-alt" class="button__icon" />
            Obnovit heslo
          </button>

        </div>
      </template>

    </form>

  </Dialog>

</template>

<script>
import LoginService from '@/services/LoginService'

import Dialog from '@/components/Common/Dialog'
import Message from '@/components/Common/Message'

export default {
  name: 'ForgotPasswordForm',

  components: {
    Dialog,
    Message,
  },

  data() {
    return {
      email: '',
      error: '',
      message: '',
    }
  },

  methods: {
    login() {
      LoginService.hesloReset({
        email: this.email
      })
        .then(response => {

          if (response.data.success) {
            this.email = '';
            this.message = 'Na e-mail vám byl zaslán odkaz a instrukce pro vytvoření nového hesla.'
          } else {
            this.error = response.data.error
          }

        })
        .catch(error => {
          this.error = error.response.message || 'Při obnovení hesla došlo k chybě'
        })
    }
  }
}
</script>
