<template>
	<textarea
		class="form__input"
		:class="{
      'form__input--small-padding': small,
      'form__input--error': valid === false
    }"
		:name="name"
		:id="name"
		:placeholder="placeholder"
		:disabled="disabled"
		:rows="rows"
		:value="modelValue"
		@input="$emit('update:modelValue', $event.target.value)"
	/>
</template>

<script>
export default {
	name: 'BaseTextarea',

	props: {
		modelValue: {
			type: String,
			default: '',
		},
		name: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			default: 'text',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		valid: {
			type: Boolean,
			default: undefined,
		},
		placeholder: {
			type: String,
			default: null,
		},
		rows: {
			type: Number,
			default: 4,
		},
		small: {
			type: Boolean,
			default: false,
		},
	},

}
</script>
