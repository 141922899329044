<template>
  <template v-if="isOrg">
    <PageHeader :title="`Žádost ${tabSelected ? '- dotační rok ' + tabSelected : ''}`" />
    <LinkTabs v-if="isOrg" :items="tabItems" />

    <div class="mt-5">
      <router-view></router-view>
    </div>
  </template>

  <template v-else>
    <router-view></router-view>
  </template>

</template>


<script>
import {
	STAV_ZADOSTI,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import ZadostiService from '@/services/ZadostiService'
import CiselnikyService from '@/services/CiselnikyService'

import PageHeader from '@/components/Common/PageHeader'
import Loading from '@/components/Common/Loading'
import Tabs from '@/components/Common/Tabs'
import LinkTabs from '@/components/Common/LinkTabs.vue'

export default {
	name: 'Zadost',

	components: {
		PageHeader,
		Loading,
		Tabs,
    LinkTabs,
	},

	props: {
		dotacniRok: {
			type: [Number, String],
			default: undefined,
		},
	},

	data() {
		return {
      errors: [],
			zadostiLoaded: false,
			zadostLoaded: false,

			zadosti: [],

			zadost: {},
			informaceOrganizace: {},
			projekty: [],

			tabItems: [],
			tabSelected: null,

			aktivniRok: null,
		}
	},

  computed: {
    ...authComputed,

		dataLoaded() {
			return this.zadostiLoaded && this.zadostLoaded
		},
  },

	methods: {
		async loadZadosti() {
			this.zadostiLoaded = false

			if (!this.userOrg) {
				this.errors.push('Chybí ID organizace')
				return
			}

			try {
				const response = await ZadostiService.getZadostiOrganizace(this.userOrg.idOrganizace)
				if (response.data.success) {
					this.zadostiLoaded = true
					return response.data.data
				} else {
					this.errors.push(response.data.error)
					this.zadostiLoaded = true
					return []
				}
			}
			catch(error) {
				this.errors.push(error.message)
				return []
			}
		},

		async loadAktivniRok() {
			// nacteme dotacni roky, abychom zjistili, který rok je aktuální

			try {
				const response = await CiselnikyService.getDotacniRoky()
				if (!response.data.success) {
					this.errors.push(response.data.error)
					return
				}
				const roky = response.data.data

				// seředíme sestupně
				roky.sort((a, b) => b.rok - a.rok)
				// zjistime aktualni dotacni rok
				return roky.find(rok => rok.aktivni)?.rok;
			} catch(err) {
				this.errors.push(err.message)
			}
		},

		async loadData() {
			// zjistime aktivni dotacni rok
			this.aktivniRok = await this.loadAktivniRok();

      // Pokud je prazdny dotacni rok, presmerovat na aktivni dotacni rok
      if (!this.$route.params.rok && this.$route.name === 'Zadost') {
        // console.log("redirect zadost")
        this.$router.push({path: `/zadost/rok/${this.aktivniRok}`})
      }

			// nacteme seznam zadosti / roků, kdy se organizac mohla ucastnit dot. rizeni
			// tj. mela schvalene informace organizace
			this.zadosti = await this.loadZadosti()

			// přemapujeme žádosti organizace na záložky
			this.tabItems = this.zadosti.map(item => ({
				id: item.dotacni_rok,
        name: [`/zadost/rok/${item.dotacni_rok}`],
				title: item.dotacni_rok,
				subtitle: item.stav_nazev,
			}))

			// pokud v záložkách neni aktivni dotacni rok, tak ho tam pridame
			if (!this.tabItems.find(tab => tab.id === this.aktivniRok)) {
				this.tabItems = [
					{
						id: this.aktivniRok,
						title: this.aktivniRok,
            name: [`/zadost/rok/${this.aktivniRok}`],
						subtitle: 'Nevyplněno'
					},
					...this.tabItems
				]
			}
		}

	},

	created() {
		// constants
		this.STAV_ZADOSTI = STAV_ZADOSTI
		this.loadData()
	},

  watch: {
    $route() {
      this.loadData()
    },
  }
}
</script>
