import { createStore } from 'vuex'

import user from './modules/user'
import options from './modules/options'

export default createStore({
  modules: {
    user,
    options,
  },
})
