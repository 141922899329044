<template>
	<section>

		<SectionHeader title="PŘÍLOHA K ŽÁDOSTI" />

		<div class="content">

			<BaseFileUpload
				name="zadost-priloha"
				label="Příloha k žádosti (max. 10 MB)"
				buttonTextUpload="ULOŽIT PŘÍLOHU K ŽÁDOSTI"
				uploadType="priloha_zadosti"
				:uploadOptions="{
					id: idZadosti,
					pole: 'id_priloha'
				}"
				:file="file"
				:disabled="disabled"
				@uploaded="uploaded"
			/>

			<Message type="info" class="mt-3" v-if="!disabled">
				Přiložit jde maximálně jeden soubor. Při nahrání přílohy znovu bude původní přepsána. Chcete-li přiložit více souborů, zabalte je do archivu ZIP.
			</Message>
		</div>

	</section>
</template>

<script>
import Message from '@/components/Common/Message'
import SectionHeader from '@/components/Common/SectionHeader'
import BaseFileUpload from '@/components/Forms/BaseFileUpload'

export default {
	name: 'ZadostPriloha',

	components: {
		Message,
		SectionHeader,
		BaseFileUpload,
	},

	props: {
		idZadosti: {
			type: Number,
			required: true,
		},
		file: {
			type: Object,
			default: null,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
	},

	methods: {
		uploaded(payload) {
			this.$emit('uploaded', payload)
		},
	},

}
</script>
