<template>
	<div>
		<Message type="info" class="mb-5" v-if="message">
			{{ message }}
		</Message>

		<Message type="error" class="mb-5" v-if="error">
			{{ error }}
		</Message>

		<Loading v-if="!dataLoaded" />


		<form
			novalidate
			v-if="dataLoaded"
			@submit.prevent=""
			class="form--size40"
		>

			<SectionHeader title="1. INFORMACE O ZAŘÍZENÍ REALIZUJÍCÍ PROJEKT" />

			<h3 class="subsection-title">1.1 Identifikace projektu</h3>

			<div class="form__field">
				<label for="nazev_organizace" class="form__label">
					Název organizace
				</label>

				<BaseInput
					v-model="data.nazev_organizace"
					v-bind="fieldAttrs('nazev_organizace')"
					type="text"
					:disabled="true"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="kod_projektu" class="form__label">
					Kód projektu
				</label>

				<BaseInput
					v-model="data.kod_projektu"
					v-bind="fieldAttrs('kod_projektu')"
					type="text"
					class="form__input--size20"
					:disabled="true"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="nazev_projektu" class="form__label">
					Název projektu
				</label>

				<BaseInput
					v-model="data.nazev_projektu"
					v-bind="fieldAttrs('nazev_projektu')"
					type="text"
					:disabled="true"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="adresa" class="form__label">
					Adresa zázemí realizace projektu
				</label>

				<BaseTextarea
					v-model="data.adresa"
					v-bind="fieldAttrs('adresa')"
					:rows="5"
					class="w100"
				/>
			</div>


			<div class="form__field">
				<label for="kraj" class="form__label">
          <p class="form__label-title">Kraj realizace projektu</p>
          <p class="form__label-description">(Hlavní město  Praha, Jihočeský  kraj,  Jihomoravský  kraj,  Karlovarský kraj,  Kraj  Vysočina,  Královéhradecký  kraj,  Liberecký  kraj,  Moravskoslezský  kraj,  Olomoucký kraj, Pardubický kraj, Plzeňský kraj, Středočeský kraj, Ústecký kraj, Zlínský kraj, Celá ČR)</p>
				</label>

				<BaseSelect
					v-model="data.kraj"
					v-bind="fieldAttrs('kraj')"
					:options="kraje"
					valueKey="id"
					optionKey="kraj"
					:emptyValue="true"
					class="form__input--size20"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="datum_realizace_od" class="form__label">
					Projekt realizován od
				</label>

				<BaseInput
					v-model="data.datum_realizace_od"
					v-bind="fieldAttrs('datum_realizace_od')"
					type="date"
					class="form__input--size20"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="datum_predpokladane_ukonceni" class="form__label">
					Předpokládané ukončení projektu
				</label>

				<BaseInput
					v-model="data.datum_predpokladane_ukonceni"
					v-bind="fieldAttrs('datum_predpokladane_ukonceni')"
					type="date"
					class="form__input--size20"
				/>
			</div>



			<h3 class="subsection-title mt-6">1.2 Osoba zodpovědná za realizaci projektu</h3>

			<div class="form__field">
				<label for="osoba_jmeno" class="form__label">
					Jméno, příjmení, titul
				</label>

				<BaseInput
					v-model="data.osoba_jmeno"
					v-bind="fieldAttrs('osoba_jmeno')"
					type="text"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="osoba_funkce" class="form__label">
					Funkce
				</label>

				<BaseInput
					v-model="data.osoba_funkce"
					v-bind="fieldAttrs('osoba_funkce')"
					type="text"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="osoba_telefon" class="form__label">
					Telefon
				</label>

				<BaseInput
					v-model="data.osoba_telefon"
					v-bind="fieldAttrs('osoba_telefon')"
					type="text"
				/>
			</div>


			<div class="form__field mt-2">
				<label for="osoba_email" class="form__label">
					E-mail
				</label>

				<BaseInput
					v-model="data.osoba_email"
					v-bind="fieldAttrs('osoba_email')"
					type="text"
				/>
			</div>




			<SectionHeader title="2. POPIS PROJEKTU" class="mt-6" />

			<div class="form__field">
				<label for="vychodiska" class="form__label">
					<p class="form__label-title">Východiska projektu</p>
					<p class="form__label-description">Zdůvodnění, proč je předložený projekt důležitý a stručné představení problému. Jaké je teoretické ukotvení projektu - východiska, potřebnost, míra provedené rešerše k tématu atp.</p>
				</label>

				<BaseTextarea
					v-model="data.vychodiska"
					v-bind="fieldAttrs('vychodiska')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div class="form__field mt-4">
				<label for="popis" class="form__label">
					<p class="form__label-title">Popis projektu</p>
					<p class="form__label-description">Stručný popis projektu, včetně předmětu a hlavního cíle projektu.</p>
					<p class="form__label-description">Popis <strong>výzkumných projektů</strong> musí obsahovat a) stručné a jasné stanovení předmětu a cíle výzkumného projektu, stanovení výzkumných otázek/hypotéz, b) popis základního a výběrového souboru, včetně zdůvodnění a předpokládaného rozsahu výběru (pokud je výběrový soubor relevantní, v opačném případě zdůvodnění plánované rešerše), c) popis a zdůvodnění metod a nástrojů sběru dat, metod analýzy dat, způsobu řešení uvedené výzkumné otázky, d) etické aspekty, zejména jejich ošetření ve vztahu k účastníkům výzkumu.</p>
				</label>

				<BaseTextarea
					v-model="data.popis"
					v-bind="fieldAttrs('popis')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div class="form__field mt-4">
				<label for="casovy_harmonogram" class="form__label">
					<p class="form__label-title">Časový harmonogram projektu</p>
					<p class="form__label-description">Popište předpokládaný časový rozvrh a etapy projektu, včetně personálního a technického zajištění jednotlivých etap. Harmonogram jednotlivých etap projektu uvádějte minimálně po čtvrtletích.</p>
				</label>

				<BaseTextarea
					v-model="data.casovy_harmonogram"
					v-bind="fieldAttrs('casovy_harmonogram')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div class="form__field mt-4">
				<label for="vystupy" class="form__label">
					<p class="form__label-title">Výstupy projektu</p>
					<p class="form__label-description">Uveďte seznam konkrétních realizovaných výstupů projektu, např. výzkumné zprávy, knihy, články, webové stránky, uskutečněné vzdělávací akce, počet proškolených osob. U výzkumných projektů uveďte dále k jednotlivým výstupům, jak s nimi bude naloženo (např. jestli a jak bude výstup zveřejněn, komu a jak bude k dispozici).</p>
				</label>

				<BaseTextarea
					v-model="data.vystupy"
					v-bind="fieldAttrs('vystupy')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div class="form__field mt-4">
				<label for="seznam_zdroju" class="form__label">
					Seznam použitých zdrojů
				</label>

				<BaseTextarea
					v-model="data.seznam_zdroju"
					v-bind="fieldAttrs('seznam_zdroju')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div class="form__field mt-4">
				<label for="odborne_zajisteni" class="form__label">
					<p class="form__label-title">Odborné zajištění projektu</p>
					<p class="form__label-description">Uveďte stručný odborný životopis/medailonek řešitelů projektu, jejich nejvýznamnější realizované projekty a publikační činnost za posledních 5 let.</p>
				</label>

				<BaseTextarea
					v-model="data.odborne_zajisteni"
					v-bind="fieldAttrs('odborne_zajisteni')"
					:rows="8"
					class="w100"
				/>
			</div>



			<SectionHeader title="3. ZMĚNY OPROTI PŘEDCHOZÍMU ROKU" class="mt-6" />

			<div class="form__field">
				<label for="oduvodneni_zmen" class="form__label">
          <p class="form__label-title">Odůvodnění (vysvětlení) změn v projektu</p>
          <p class="form__label-description">Uveďte konkrétní uskutečněné změny oproti stavu a informacím uvedeným v Části "Charakteristika a popis projektu" v předchozím roce, pokud v něm byl projekt realizován (tj. netýká se jednoletých projektů).</p>
				</label>

				<BaseTextarea
					v-model="data.oduvodneni_zmen"
					v-bind="fieldAttrs('oduvodneni_zmen')"
					:rows="8"
					class="w100"
				/>
			</div>


			<template v-if="!jeProjektUzamcen">
				<hr class="form__divider">

				<Loading title="Ukládám data&hellip;" v-if="isSaving" />

        <Message type="error" class="mb-5" v-if="error">
          {{ error }}
        </Message>

				<div class="form__buttons">
					<button
						class="button"
						:disabled="isSaving"
						@click.prevent="saveData"
					>
						<font-awesome-icon icon="save" class="button__icon" />
						Uložit formulář
					</button>
				</div>
			</template>

		</form>
	</div>
</template>

<script>
import { validateFields } from '@/utils/validator'
import {
	TYP_PROJEKTU,
	STAV_PROJEKTU,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'
import CiselnikyService from '@/services/CiselnikyService'

import Message from '@/components/Common/Message.vue'
import Loading from '@/components/Common/Loading.vue'
import SectionHeader from '@/components/Common/SectionHeader.vue'
import BaseInput from '@/components/Forms/Base/BaseInput.vue'
import BaseTextarea from '@/components/Forms/Base/BaseTextarea.vue'
import BaseSelect from '@/components/Forms/Base/BaseSelect.vue'

export default {
	name: 'CastB1',

	components: {
		Message,
		Loading,
		SectionHeader,
		BaseInput,
		BaseTextarea,
		BaseSelect,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			error: '',
			message: '',
			isSaving: false,
			//dataLoaded: false,

			data: {},
			formLoaded: false,

			kraje: [],
			krajeLoaded: false,

			formChanged: false,
			schema: {
				kod_projektu: { required: true, },
				nazev_projektu: { required: true, },

				nazev_organizace: { required: true, },
				// nazev_zarizeni: { required: true, },
				adresa: { required: true, },
				kraj: { required: true, },
				datum_realizace_od: { required: true, },
				datum_predpokladane_ukonceni: { required: true, },
				osoba_jmeno: { required: true, },
				osoba_funkce: { required: true, },
				osoba_telefon: { required: true, },
				osoba_email: { required: true, },
				vychodiska: { required: true, },
				popis: { required: true, },
				casovy_harmonogram: { required: true, },
				vystupy: { required: true, },
				seznam_zdroju: { required: true, },
				odborne_zajisteni: { required: true, },
				oduvodneni_zmen: { required: true, },
			},
		}
	},

	computed: {
		...authComputed,

		jeMoznePodatZadost() {
			// UPRAVIT UPRAVIT UPRAVIT !!!
			// return Date.now() < Date.parse('2021-11-11')
			return true
		},

		jeProjektUzamcen() {
			return (
				this.projekt.stav === STAV_PROJEKTU.UZAMCENO
				|| this.projekt.stav === STAV_PROJEKTU.SCHVALENO
				|| this.projekt.stav === STAV_PROJEKTU.ZAMITNUTO
				|| this.isGov
				|| !this.jeMoznePodatZadost
			)
		},

		dataLoaded() {
			return this.formLoaded && this.krajeLoaded
		},
	},

	methods: {
    validateFields,

		saveData() {
			this.error = ''
			this.message = ''

      if (!this.validateFields(this.data, this.schema)) {
        this.error = 'Zkontrolujte povinná pole'
        return
      }

			this.isSaving = true

			ProjektyService
			.updateProjektB2(this.projekt.id, this.data)
			.then(response => {
				if (response.data.success) {
					this.message = "Formulář byl v pořádku uložen"
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				// vyscrollujeme nahoru na strance, aby byla videt chyba nebo message
				this.isSaving = false
				window.scrollTo(0, 0)
			})
		},


		fieldChanged(event) {
			// const field = event.target.name
			// this.schema[field].valid = this.isValid(field)
			this.formChanged = true
		},

		fieldAttrs(name) {
			return {
				name: name,
				required: this.schema[name].required,
				valid: this.schema[name]?.valid,
				disabled: this.jeProjektUzamcen,
				placeholder: this.schema[name]?.placeholder,
				onInput: this.fieldChanged,
			}
		},

		loadData() {
			ProjektyService
			.getProjektB2(this.projekt.id)
			.then(response => {
				if (response.data.success) {
					this.data = response.data.data

					// !!!!!!!!!!!
					// !!!!!!!!!!!
					this.data.kod_projektu = this.projekt.kod
					this.data.nazev_projektu = this.projekt.nazev
					this.data.nazev_organizace = this.userOrg.organizace
					// !!!!!!!!!!!
					// !!!!!!!!!!!

					this.formLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

		loadKraje() {
			CiselnikyService.getKraje()
			.then(response => {
				if (response.data.success) {
					this.kraje = response.data.data
					this.krajeLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

	},

	created() {
		// constants
		this.TYP_PROJEKTU = TYP_PROJEKTU
		this.STAV_PROJEKTU = STAV_PROJEKTU

		this.loadData()
		this.loadKraje()
	}

}
</script>