<template>
  <div>
    <section>
      <Message v-if="errors.length !== 0" type="error">
        <ul>
          <li v-for="(error, index) in errors" :key="index">
            {{error}}
          </li>
        </ul>
      </Message>

      <Loading v-if="!dataLoaded" />

      <template v-if="dataLoaded && zadost === null">
        <p>Vyplňovat žádost a přidávat projekty lze až po vyplnění a schválení <router-link :to="{ name: 'OrganizaceInformace' }">informací o organizaci</router-link>.</p>
        <p>Jakmile budou informace o vaší organizaci schváleny, vraťte se na tuto stránku a pokračujte ve vyplňování žádosti.</p>
      </template>

      <template v-if="dataLoaded && zadost !== null">

        <div class="content">
          <Message type="info">
            Žádost je ve stavu <strong>{{ zadost.stav_nazev }}</strong>.
          </Message>
          <ZadostZadatelODotaci :organizace="informaceOrganizace" class="mt-4" />
        </div>

        <Tabs
            class="mt-5"
            :items="tabItems"
            :selected="tabSelected"
            @changeTab="changeTab($event)"
        />

      </template>
    </section>
    <template v-if="dataLoaded && zadost !== null">
      <div class="content">
        <template v-if="tabSelected === 2">
          <ZadostPrehledVyuctovani
              class="mt-5"
              :zadost="zadost"
              @setError="setResponseError"
              @updated="loadZadost()"
          />
        </template>

        <template v-if="tabSelected === 1">
          <ZadostProjekty
              v-if="projekty.length > 0 || zadost.dotacni_rok !== aktivniRok && tabSelected === 1"
              class="mt-5"
              :idZadosti="zadost.id"
              :projekty="projekty"
              :disabled="zadost.id_stav !== STAV_ZADOSTI.PODAVANI || !jeMoznePodatZadost"
          />

          <ZadostStazeniPDF
              class="mt-5"
              v-if="dataLoaded && zadost.id_stav !== STAV_ZADOSTI.PODAVANI"
              :idZadosti="zadost.id"
          />

          <ZadostProjektyImport
              v-if="projekty.length === 0 && zadost.dotacni_rok === aktivniRok && jeMoznePodatZadost"
              :zadost="zadost"
              :zadostiOrganizace="zadosti"
              class="mt-5"
          />

          <ZadostPriloha
              class="mt-5"
              :idZadosti="zadost.id"
              :file="priloha"
              :disabled="zadost.id_stav !== STAV_ZADOSTI.PODAVANI || !jeMoznePodatZadost"
              @uploaded="prilohaUploaded"
          />

          <ZadostAkce
              v-if="!jeZadostUzamcena && jeMoznePodatZadost"
              class="mt-5"
              :idZadosti="zadost.id"
          />

          <ZadostMeta
              class="mt-5"
              :zadost="zadost"
          />
        </template>
      </div>
    </template>
  </div>
</template>

<script>
import {
  STAV_ZADOSTI,
} from '@/constants'

import { authComputed, optionsComputed } from '@/store/helpers.js'
import ZadostiService from '@/services/ZadostiService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import Tabs from '@/components/Common/Tabs'

import ZadostZadatelODotaci from '@/components/Zadost/ZadostZadatelODotaci'
import ZadostStazeniPDF from '@/components/Zadost/ZadostStazeniPDF'
import ZadostProjekty from '@/components/Zadost/ZadostProjekty'
import ZadostProjektyImport from '@/components/Zadost/ZadostProjektyImport'
import ZadostPriloha from '@/components/Zadost/ZadostPriloha'
import ZadostAkce from '@/components/Zadost/ZadostAkce'
import ZadostPrehledVyuctovani from "@/components/Zadost/ZadostPrehledVyuctovani.vue";
import DotacniRokyService from "@/services/DotacniRokyService";
import ZadostMeta from '@/components/Zadost/ZadostMeta'

export default {
  name: 'ZadostRok',

  components: {
    ZadostPrehledVyuctovani,
    PageHeader,
    Message,
    Loading,
    Tabs,
    ZadostZadatelODotaci,
    ZadostStazeniPDF,
    ZadostProjekty,
    ZadostProjektyImport,
    ZadostPriloha,
    ZadostAkce,
    ZadostMeta,
  },

  props: {
    dotacniRok: {
      type: [Number, String],
      default: undefined,
    },
  },

  data() {
    return {
      dotacniRoky: [],
      errors: [],
      zadostiLoaded: false,
      zadostLoaded: false,

      zadosti: [],

      zadost: {},
      informaceOrganizace: {},
      projekty: [],
      tabSelected: 1,
      tabItems: [
        {
          id: 2,
          title: 'Vyúčtování',
          hidden: false,
        },
        {
          id: 1,
          title: 'Žádost',
          hidden: false,
        },
      ]
    }
  },

  computed: {
    ...authComputed,
    ...optionsComputed,

    optionsDotacniRok() {
      return this.getOptionsDotacniRok(this.$route.params.rok)
    },

    aktivniRok() {
      const item = this.dotacniRoky.find(item => item.aktivni)
      if (item && item.hasOwnProperty('rok')) {
        return item.rok
      }
    },

    jeMoznePodatZadost() {
      const dateTo = new Date(Date.parse(this.optionsDotacniRok.podani_zadosti_do))
      dateTo.setHours(23, 59, 59)

      return (Date.now() >= Date.parse(this.optionsDotacniRok.podani_zadosti_od)) && (Date.now() <= dateTo.getTime())
    },

    jeZadostUzamcena() {
      return (
          this.zadost.id_stav === STAV_ZADOSTI.UZAMCENO
          || this.zadost.id_stav === STAV_ZADOSTI.SCHVALENO
          || this.zadost.id_stav === STAV_ZADOSTI.ZAMITNUTO
      )
    },

    dataLoaded() {
      return this.zadostiLoaded && this.zadostLoaded
    },

    priloha() {
      return (
          this.zadost.id_priloha
              ? {
                nazev: this.zadost.id_priloha,
                puvodni_nazev: this.zadost.priloha_puvodni_nazev,
              }
              : null
      )
    },
  },

  methods: {
    async loadDotacniRoky() {
      try {
        const dotacniRokyResponse = await DotacniRokyService.getDotacniRoky()
        if (dotacniRokyResponse.data.success) {
          this.dotacniRoky = dotacniRokyResponse.data.data
          this.dataLoaded = true
        } else {
          this.error += dotacniRokyResponse.data.error
        }

      } catch(error) {
        this.error += error.message
      }
    },

    setResponseError(error) {
      this.errors.push(error)
    },

    changeTab(index) {
      this.tabSelected = index;
    },

    prilohaUploaded(file) {
      this.zadost.id_priloha = file.nazev
      this.zadost.priloha_puvodni_nazev = file.puvodni_nazev
    },

    async loadZadosti() {
      this.zadostiLoaded = false

      if (!this.userOrg) {
        this.errors.push('Chybí ID organizace')
        return
      }

      try {
        const response = await ZadostiService.getZadostiOrganizace(this.userOrg.idOrganizace)
        if (response.data.success) {
          this.zadostiLoaded = true
          return response.data.data
        } else {
          this.errors.push(response.data.error)
          this.zadostiLoaded = true
          return []
        }
      }
      catch(error) {
        this.errors.push(error.message)
        return []
      }
    },

    async loadZadostById(id) {
      try {
        const response = await ZadostiService.getZadostById(id)
        if (response.data.success) {
          return response.data.data
        } else {
          this.errors.push(response.data.error)
          return {}
        }
      }
      catch(error) {
        this.errors.push(error.message)
        return {}
      }
    },

    async loadZadost() {
      this.tabSelected = 1;
      const rok = this.$route.params.rok;

      this.zadostLoaded = false
      this.zadost = null
      this.informaceOrganizace = null
      this.projekty = null
      this.zadosti = await this.loadZadosti()

      const vybranaZadost = this.zadosti.find((zadost) => parseInt(zadost.dotacni_rok) === parseInt(rok))

      if (vybranaZadost) {
        const data = await this.loadZadostById(vybranaZadost.id)
        this.zadost = data?.zadost
        this.informaceOrganizace = data?.informace_organizace
        this.projekty = data?.projekty

        // Opravit na základě skutečného stavu žádosti
        // --> /-byl podpořen (dostal peníze)-/ + žádost ve stavu čeká na vyúčtování +
        //

        this.tabItems[0].hidden = true;
        if (this.zadost.stav_zadosti === 2 && Date.now() >= Date.parse(this.optionsDotacniRok.predlozeni_vyuctovani_dotace_od)) {
          this.tabItems[0].hidden = false;
          this.tabSelected = 2;
        }
      }

      this.zadostLoaded = true
    },

  },

  created() {
    this.STAV_ZADOSTI = STAV_ZADOSTI // constants
    this.loadZadost()
    this.loadDotacniRoky()

    /*
    if (Date.now() >= Date.parse(this.optionsDotacniRok.predlozeni_vyuctovani_dotace_od)) {
      this.tabItems[0].hidden = false;
    }
     */
  },

  watch: {
    $route() {
      this.loadZadost()
    },
  }
}
</script>