
export const validateFields = (data, schema) => {
    let result = true

    for (const dataKey in data) {
        if (
            schema.hasOwnProperty(dataKey)
            && schema[dataKey].hasOwnProperty('required')
            && schema[dataKey].required
        ) {
            let validator = 'string'

            // TODO add validator based on data type
            // if (this.schema[dataKey].hasOwnProperty('validator')) {
            //   validator = this.schema[dataKey].validator
            // }


            if (dataKey === 'primarni_prevence') {
                let answers = []

                if (!data.typy_primarni_prevence) {
                    answers = []
                } else if (data.typy_primarni_prevence.length === 1) {
                    answers = JSON.parse(data.typy_primarni_prevence[0])
                } else if ( typeof data.typy_primarni_prevence === 'string') {
                    answers = JSON.parse(data.typy_primarni_prevence)
                } else {
                    answers = data.typy_primarni_prevence
                }

                let checkbox_result = false
                for(const key in answers) {
                    if(!!answers[key].indikovana || !!answers[key].selektivni || !!answers[key].vseobecna) {
                        checkbox_result = true
                    }
                }

                if(!checkbox_result && data.primarni_prevence) {
                    result = false
                    schema.typy_primarni_prevence.valid = false
                } else {
                    schema.typy_primarni_prevence.valid = true
                }

                if (!data.primarni_prevence_upresneni && data.primarni_prevence) {
                    result = false
                    schema.primarni_prevence_upresneni.valid = false
                } else {
                    schema.primarni_prevence_upresneni.valid = true
                }
            }

            else if (dataKey === 'pobytova_forma_sluzby') {
                if (!data.pocet_luzek && data.pobytova_forma_sluzby) {
                    result = false
                    schema.pocet_luzek.valid = false
                } else {
                    schema.pocet_luzek.valid = true
                }
            }


            else if (schema[dataKey].hasOwnProperty('group_validation')) {
                let groupResult = false

                for (const groupKey of schema[dataKey].group_validation) {
                    if (data[groupKey]) {
                        groupResult = true
                    }
                }

                schema[dataKey].valid = groupResult
            }


            // Custom fields validation
            else if (dataKey === 'registrace_socialni_sluzby' && data.hasOwnProperty('typ_registrace_socialni_sluzby') && schema.hasOwnProperty('typ_registrace_socialni_sluzby')) {
                if (!data.typ_registrace_socialni_sluzby && !data.registrace_socialni_sluzby) {
                    result = false
                    schema.typ_registrace_socialni_sluzby.valid = false
                } else {
                    schema.typ_registrace_socialni_sluzby.valid = true
                }
            }


            else if (dataKey === 'registrace_zdravotni_sluzby' && data.hasOwnProperty('typ_registrace_zdravotni_sluzby') && schema.hasOwnProperty('typ_registrace_zdravotni_sluzby')) {
                if (!data.typ_registrace_zdravotni_sluzby && !data.registrace_zdravotni_sluzby) {
                    result = false
                    schema.typ_registrace_zdravotni_sluzby.valid = false
                } else {
                    schema.typ_registrace_zdravotni_sluzby.valid = true
                }
            }


            // Validation checkboxes without specified ids
            else if (schema[dataKey] && schema[dataKey].hasOwnProperty('skip_validation_for_ids') && data[dataKey]) {
                const listOfIds = data[dataKey].split(',').filter((el) => !schema[dataKey].skip_validation_for_ids.includes(parseInt(el)))

                if (!listOfIds.length) {
                    schema[dataKey].valid = false
                    result = false
                } else {
                    schema[dataKey].valid = true
                }
            }



            // Validation without validation type specification
            else if (validator === 'string') {
                if (data[dataKey] === '' || !data[dataKey]) {
                    schema[dataKey].valid = false
                    result = false
                } else {
                    schema[dataKey].valid = true
                }
            }

        }
    }

    return result
}