<template>

	<SectionHeader :title="`Vyúčtování dotace od ÚV ČR za rok ${projekt.dotacni_rok}`" />

	<table class="table table--no-hover w70">
		<colgroup>
			<col class="w55">
			<col class="w15">
			<col class="w15">
			<col class="w15">
		</colgroup>
		<tbody>
			<tr class="table__subsub-sum-row">
				<td colspan="4" class="all"><strong>1. Požadovaná dotace - Žádost</strong></td>
			</tr>
			<tr>
				<td rowspan="3">Finanční prostředky žádané od ÚV ČR na realizaci projektu</td>
				<td rowspan="2" class="alc sub-sum">Celkem</td>
				<td colspan="2" class="alc sub-sum">Z toho</td>
			</tr>
			<tr>
				<td class="alc sub-sum">1. Provozní</td>
				<td class="alc sub-sum">2. Osobní</td>
			</tr>
			<tr>
				<td class="alc"><strong>{{ formatNumberCZ(data.zadane_celkem) }}</strong></td>
				<td class="alc">{{ formatNumberCZ(data.zadane_provozni) }}</td>
				<td class="alc">{{ formatNumberCZ(data.zadane_osobni) }}</td>
			</tr>

			<tr class="table__subsub-sum-row">
				<td colspan="4" class="all"><strong>2. Získaná dotace - Rozhodnutí</strong></td>
			</tr>
			<tr>
				<td rowspan="3">Finanční prostředky žádané od ÚV ČR na realizaci projektu</td>
				<td rowspan="2" class="alc sub-sum">Celkem</td>
				<td colspan="2" class="alc sub-sum">Z toho</td>
			</tr>
			<tr>
				<td class="alc sub-sum">1. Provozní</td>
				<td class="alc sub-sum">2. Osobní</td>
			</tr>
			<tr>
				<td class="alc"><strong>{{ formatNumberCZ(data.ziskane_celkem) }}</strong></td>
				<td class="alc">{{ formatNumberCZ(data.ziskane_provozni) }}</td>
				<td class="alc">{{ formatNumberCZ(data.ziskane_osobni) }}</td>
			</tr>

			<tr class="table__subsub-sum-row">
				<td colspan="4" class="all"><strong>3. Skutečné čerpání dotace poskytnuté ÚV ČR (viz tab. 3)</strong></td>
			</tr>
			<tr>
				<td rowspan="3">Čerpání finančních prostředků získaných od ÚV ČR na realizaci projektu</td>
				<td rowspan="2" class="alc sub-sum">Celkem</td>
				<td colspan="2" class="alc sub-sum">Z toho</td>
			</tr>
			<tr>
				<td class="alc sub-sum">1. Provozní</td>
				<td class="alc sub-sum">2. Osobní</td>
			</tr>
			<tr>
				<td class="alc"><strong>{{ formatNumberCZ(data.cerpane_celkem) }}</strong></td>
				<td class="alc">{{ formatNumberCZ(data.cerpane_provozni) }}</td>
				<td class="alc">{{ formatNumberCZ(data.cerpane_osobni) }}</td>
			</tr>

			<tr>
				<td class="all subsub-sum"><strong>4. Vratka</strong></td>
				<td class="alc"><strong>{{ formatNumberCZ(data.vratka) }}</strong></td>
				<td colspan="2" class="table__no-cell">&nbsp;</td>
			</tr>
			<tr>
				<td class="all">Byla vratka provedena v průběhu roku?</td>
				<td class="alc">{{ data.vratka_v_prubehu_roku === 0 ? 'ne' : 'ano' }}</td>
				<td colspan="2" class="table__no-cell">&nbsp;</td>
			</tr>

		</tbody>
	</table>
</template>

<script>
import { formatNumberCZ } from '@/utils/format.js'
import { toNumberCZ, isNumericCZ } from '@/utils/convert.js'

import SectionHeader from '@/components/Common/SectionHeader'
import BaseInput from '@/components/Forms/Base/BaseInput'

export default {
	name: 'VyuctovaniTab1Dotace',

	components: {
		SectionHeader,
		BaseInput,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: true,
		},
	},

	emits: ['update'],

	methods: {
		isNumericCZ,
		toNumberCZ,
		formatNumberCZ,
	},


}
</script>