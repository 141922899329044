<template>
	<div class="w100">
		<div
			class="panel mb-3"
			v-for="(sluzba, index) in data"
			:key="index"
		>
			<div class="panel__title d-flex align-items-center justify-content-space-between">
				<div>{{index + 1}}. služba</div>
				<button
					v-if="!disabled"
					class="button button--danger button--tiny button--outline"
					@click="odebratSluzbu(index)"
				>
					<font-awesome-icon icon="times" class="button__icon" />
					Odebrat
				</button>
			</div>

			<table class="inputtable inputtable--no-border w100 mt-3">
				<tr>
					<th class="w20 tal pl-0">Název služby</th>
					<td class="w80">
						<BaseInput
							class="w100"
							v-model="sluzba.nazev"
							:name="`pd_sl${index}_nazev`"
							:disabled="disabled"
							@input="updateModel"
						/>
					</td>
				</tr>
			</table>

			<table class="inputtable w100 mt-3">
				<thead>
					<tr>
						<th class="w20 tal">Den</th>
						<th class="w35">Provozní doba</th>
						<th class="w45">Poznámka</th>
					</tr>
				</thead>
				<tbody>
					<tr
						v-for="radek in sluzba.provozni_doba"
						:key="radek.id"
					>
						<th>
								{{ nazevProgramu(radek.id) }}
						</th>
						<td>
							<BaseInput
								class="w100"
								:name="`pd_sl${index}_r${radek.id}_doba`"
								:disabled="disabled"
								v-model="radek.provozni_doba"
								@input="updateModel"
							/>
						</td>
						<td style="text-align: center;">
							<BaseInput
								class="w100"
								:name="`pd_sl${index}_r${radek.id}_poznamka`"
								:disabled="disabled"
								v-model="radek.poznamka"
								@input="updateModel"
							/>
						</td>
					</tr>
				</tbody>
			</table>

			<table class="inputtable inputtable--no-border w100 mt-3">
				<tr>
					<th class="w20 tal pl-0">Upřesnění / pozn.</th>
					<td class="w80">
						<BaseTextarea
							class="w100"
							v-model="sluzba.upresneni"
							:name="`pd_sl${index}_upresneni`"
							:rows="3"
							:disabled="disabled"
							@input="updateModel"
						/>
					</td>
				</tr>
			</table>

		</div>

		<button
			v-if="!disabled"
			class="button button--small button--outline"
			@click="pridatSluzbu"
		>
			<font-awesome-icon icon="plus" class="button__icon" />
			Přidat další službu
		</button>

	</div>
</template>

<script>
import BaseInput from '@/components/Forms/Base/BaseInput.vue'
import BaseTextarea from '@/components/Forms/Base/BaseTextarea.vue'

export default {
	name: 'CastB2ProvozniDoba',

	components: {
		BaseInput,
		BaseTextarea,
	},

	props: {
		modelValue: {
			type: [Number, String, Array],
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false
		},
	},

	data() {
		return {
			data: null,

			dny: [
				{ id: 1, nazev: 'Pondělí', },
				{ id: 2, nazev: 'Úterý', },
				{ id: 3, nazev: 'Středa', },
				{ id: 4, nazev: 'Čtvrtek', },
				{ id: 5, nazev: 'Pátek', },
				{ id: 6, nazev: 'Sobota', },
				{ id: 7, nazev: 'Neděle', },
			],

		}
	},

	methods: {
		updateModel() {
			this.$emit('update:modelValue', JSON.stringify(this.data))
		},

		nazevProgramu(den) {
			return this.dny.find(x => x.id === den).nazev
		},

		pridatSluzbu() {
			this.data.push(this.novyZaznam())
		},

		odebratSluzbu(index) {
			this.data.splice(index, 1)
		},

		novyZaznam() {
			return {
				nazev: '',
				// forma_terenni: false,
				// forma_ambulantni: false,
				// forma_ict: false,
				provozni_doba: [
					{ id: 1, provozni_doba: '', poznamka: '', },
					{ id: 2, provozni_doba: '', poznamka: '', },
					{ id: 3, provozni_doba: '', poznamka: '', },
					{ id: 4, provozni_doba: '', poznamka: '', },
					{ id: 5, provozni_doba: '', poznamka: '', },
					{ id: 6, provozni_doba: '', poznamka: '', },
					{ id: 7, provozni_doba: '', poznamka: '', },
				],
				upresneni: '',
			}
		},

	},

	created() {
		if (this.modelValue) {
			this.data = JSON.parse(this.modelValue)
			return
		}

		// hodnota je zatím prázdná, vytvoříme výchozí pole
		this.data = [
			this.novyZaznam()
		]

		this.updateModel()
	}

}
</script>