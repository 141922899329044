<template>
  <div class="grid grid--double-gap">
    <div class="grid-column"><!-- v-if="!isGov" TODO what isGov? -->
      <ZaverecnaZpravaExcelDownload
          :idZadosti="data.id"
          :filename="nazevSouboru"
      />
    </div>
    <div class="grid-column">
      <ZaverecnaZpravaExcelImport
          :idZadosti="data.id"
          :fileId="data.id_soubor_zprava"
          :fileName="data.soubor_zprava_nazev"
          :importErrors="importObsahujeChyby"
          :disabled="disabled"
          @uploaded="updateFile($event)"
      />
    </div>
  </div>
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import Message from '@/components/Common/Message'
import ZaverecnaZpravaExcelDownload from '@/components/Projekty/ZaverecnaZprava/ZaverecnaZpravaExcelDownload'
import ZaverecnaZpravaExcelImport from '@/components/Projekty/ZaverecnaZprava/ZaverecnaZpravaExcelImport'

export default {
  name: 'ZaverecnaZpravaExcel',
  components: {
    Message,
    ZaverecnaZpravaExcelDownload,
    ZaverecnaZpravaExcelImport,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
    projekt: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  emits: ['uploaded'],
  computed: {
    ...authComputed,
    nazevSouboru() {
      return `${this.projekt.kod}-zaverecna-zprava.xlsx`
    },

    importObsahujeChyby() {
      // TODO ??? Returns only false
      // return Array.isArray(this.projekt.kontrola_c) && this.projekt.kontrola_c.length > 0
      return false
    },
  },

  methods: {
    updateFile(file) {
      this.$emit('uploaded', file)
    },
  },
}
</script>