<template>

	<SectionHeader title="Vyplnění v aplikaci" />

	<ul>
		<li>Vyúčtování můžete upravit/vyplnit přímo v aplikaci na záložkách <strong>Tab 1-3</strong>.</li>
		<li>Hodnoty, které nejdou upravit, se dopočítavají automaticky nebo jsou převzaty z žádosti a konečné podoby rozpočtu a již je nelze měnit.</li>
	</ul>

	<SectionHeader title="Vyplnění v Excelu" class="mt-5" />

	<ul>
		<li>Chcete-li data upravit mimo aplikaci, na záložce <strong>Export/Import XLSX</strong> si můžete stáhnout předvyplněný Excelový sešit.</li>
		<li>Sešit vyplňte a naimportujte zpět do aplikace.</li>
	</ul>

	<Message type="warning">
		<strong>POZOR:</strong> Import XLSX vždy přepíše jakékoliv změny, které jste ve vyúčtování v aplikaci udělali, daty ze sešitu.
	</Message>


	<SectionHeader title="Uložení a odeslání vyúčtování" class="mt-5" />

	<ul>
		<li>Data můžete průběžně ukládat tlačítkem <strong>Uložit koncept</strong>.</li>
		<li>Vyučtování a závěrečné zprávy se odesílají ke kontrole hromadně pro všechny projekty v žádosti.</li>
		<li>Tlačítkem <strong>Uložit a zamknout pro odeslání</strong> potvrdíte vyúčtování tohoto projektu a připravíte ho k hromadnému odeslání později.</li>
		<li>Odeslání všech vyúčtování, závěrečných zpráv a povinných příloh se provádí na hlavní kartě <strong>Žádosti</strong>.</li>
	</ul>
</template>

<script>

import SectionHeader from '@/components/Common/SectionHeader'
import Message from '@/components/Common/Message'

export default {
	name: 'VyuctovaniNavod',

	components: {
		SectionHeader,
		Message,
	},

	props: {
		projekt: {
			type: [Object],
			required: true,
		},
	},

}
</script>