<template>

	<div
		class="dialog dialog--center"
		:class="{
			'dialog--wide': wide
		}"
	>
		<div
			class="dialog__header"
			v-if="title || closeButton"
		>
			<h2 class="dialog__title">{{ title ? title : '' }}</h2>
			<div class="dialog__close">
				<font-awesome-icon
					v-if="closeButton"
					icon="times"
					class="button__icon"
					@click="close"
				/>
			</div>
		</div>
		<div
			class="dialog__content"
			:class="{
				'dialog__content--center': options?.centerContent
			}"
		>
			<slot />
		</div>
	</div>

</template>

<script>
export default {
	name: 'Dialog',

	emits: ['close'],

	props: {
		title: {
			type: String,
			default: null,
		},
		closeButton: {
			type: Boolean,
			default: false,
		},
		wide: {
			type: Boolean,
			default: false,
		},
		options: {
			type: Object,
			default: null,
		},
	},

	methods: {
		close() {
			this.$emit('close')
		}
	}

}
</script>
