const dateTimeFormat = new Intl.DateTimeFormat('cs-CZ')

const numberFormat = new Intl.NumberFormat('cs-CZ', {
	style: 'decimal',
	minimumFractionDigits: 2,
	maximumFractionDigits: 2,
})


export function formatDateCZ(isoDate, emptyString = '') {
	return isoDate ? dateTimeFormat.format(new Date(isoDate)) : emptyString
}

export function formatNumberCZ(number, emptyString = '') {
	return (
		number || number === 0
		? numberFormat.format(number).replace(' ', '&nbsp;')
		: emptyString
	)
}
