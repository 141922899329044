import ApiClient from './ApiClient'

export default {

	uploadFile(formData) {
		return ApiClient.post('/upload-souboru',
			formData,
			{
				headers: {
					'Content-Type': 'multipart/form-data',
				}
			}
		)
	},

	downloadFile(idSouboru) {
		return ApiClient.get(
			`/soubor/${idSouboru}`,
			{	responseType: 'blob' }
		)
	},

}
