<template>
	<div>
		<SectionHeader title="Stažení XLSX souboru k vyplnění" />

		<Message type="info">
			Stáhněte si XLSX soubor, do kterého doplňte údaje potřebné k&nbsp;vyúčtování projektu. Soubor je předvyplněný daty z&nbsp;žádosti a&nbsp;z&nbsp;konečné podoby rozpočtu. Soubor upravte a <strong>naimportujte zpět do aplikace</strong>.<br><br>Soubor je unikátní pro každý projekt. <strong>Nekopírujte a nepřepisujte</strong> soubor z jednoho projektu pro účely jiného projektu nebo služby.
		</Message>

		<button
			class="button button--success mt-4"
			@click="downloadFile"
		>
			<font-awesome-icon icon="file-excel" class="button__icon" />
			Stáhnout formulář pro vyúčtování
		</button>
	</div>
</template>

<script>
import ProjektyService from '@/services/ProjektyService'
import { saveAs } from 'file-saver'

import SectionHeader from '@/components/Common/SectionHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
	name: 'VyuctovaniExcelDownload',

	components: {
		SectionHeader,
		Message,
		Loading,
	},

	props: {
		idVyuctovani: {
			type: [Number, String],
			required: true,
		},
		filename: {
			type: String,
			default: null,
		},
	},

	methods: {
		downloadFile() {
			// saveAs = function of FileSaver.js library
			ProjektyService
			.downloadVzorVyuctovani(this.idVyuctovani)
			.then(response => {
				saveAs(new Blob([response.data]), this.filename)
			})
		},

	}

}
</script>