<template>
  <div>
	  <Dialog title="Odhlášení" :options="{ centerContent: true }">
      <p>Byli jste úspěšně odhlášení ze systému.</p>
      <div class="dialog__buttons">
        <button
          class="button button--outline"
          @click="gotoLogin"
        >
          Znovu se přihlásit
        </button>
      </div>
    </Dialog>
  </div>
</template>

<script>
import Dialog from '@/components/Common/Dialog'

export default {
  name: 'Logout',

  components: {
    Dialog
  },

  methods: {
    gotoLogin() {
      this.$router.push({ name: 'Login' });
    }
  }
}
</script>
