<template>
  <div>
    <!-- TODO Where isGov defined -->
    <SectionHeader :title="isGov ? 'Naimportovaný soubor XSLX se závěrečnou zprávou' : 'Import vyplněného XLSX zpět do aplikace'" />

    <Message type="info" v-if="!disabled">
      Vyplněný XLSX soubor naimportujte zpět do aplikace.
    </Message>

    <div v-if="fileId">
      <hr class="mt-3 mb-3">
      <p><strong>Původní naimportovaný soubor</strong>:</p>
      <a
          href=""
          @click.prevent="downloadFile"
      >{{ fileName }}</a>

      <Message v-if="importErrors" type="error" class="mt-3">
        Data se naimportovala, ale jsou v nich chyby. Chyby jsou označeny červeně. Opravte chyby v&nbsp;původním XLSX sešitu a&nbsp;naimportujte ho znovu nebo upravte data přímo v aplikaci.
      </Message>

      <hr class="mt-3 mb-3">

      <p v-if="!disabled">Dokud není odeslané vyúčtování, lze nahrát novou verzi souboru.</p>
      <p v-if="disabled && !isGov">Již není možné nahrávat novou verzi záverečné zprávy.</p>
    </div>

    <div class="form__field form__field--autosize mt-3">

      <input
          type="file"
          class="form__upload-input"
          name="file"
          id="file"
          ref="file"
          :disabled="disabled"
          @change="uploadFile($event)"
      />

      <label
          v-if="!disabled"
          class="button form__upload-button"
          for="file"
      >
        <font-awesome-icon icon="upload" class="button__icon" />
        Nahrát XLSX soubor
      </label>

      <div class="form__upload">
        <Loading v-if="uploading" title="Nahrávám soubor" class="m-0" />
        <Message v-if="error" type="error">{{error}}</Message>
      </div>

    </div>
  </div>

</template>

<script>
import { authComputed } from '@/store/helpers.js'

import ProjektyService from '@/services/ProjektyService'
import ApiClient from '@/services/ApiClient'
import { saveAs } from 'file-saver'

import SectionHeader from '@/components/Common/SectionHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
  name: 'ZaverecnaZpravaExcelImport',

  components: {
    SectionHeader,
    Message,
    Loading,
  },

  props: {
    idZadosti: {
      type: [Number, String],
      required: true,
    },
    fileId: {
      type: String,
      default: null,
    },
    fileName: {
      type: String,
      default: null,
    },
    importErrors: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: true,
    }
  },

  emits: ['uploaded'],

  data() {
    return {
      error: '',
      uploading: false,
    }
  },

  computed: {
    ...authComputed,
  },

  methods: {
    downloadFile() {
      // saveAs = function of FileSaver.js library
      ApiClient.get(
          '/soubor/' + this.fileId,
          {	responseType: 'blob' }, // important
      ).then((response) => {
        saveAs(new Blob([response.data]), this.fileName)
      })
    },

    uploadFile() {
      const fileRef = this.$refs.file
      const file = fileRef.files[0]

      let formData = new FormData()
      formData.append('file', file)
      formData.append('id', this.idZadosti)

      this.uploading = true
      this.error = ''


      ProjektyService
          .uploadZaverecnaZprava(formData)
          .then(response => {
            console.log(response.data)
            if (response.data.success) {
              this.$emit('uploaded', response.data.data)
            } else {
              this.error = response.data.error
            }
          })
          .catch(error => {
            this.error = error.message
          })
          .then(() => {
            this.uploading = false
            fileRef.value = ''
          })
    },

  },

}
</script>