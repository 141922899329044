<template>
	<div class="grid grid--double-gap">
		<div class="grid-column" v-if="!isGov">

			<CastCExcelDownload
				:id="projekt.id"
				:filename="nazevSouboru"
			/>

		</div>
		<div class="grid-column">

			<CastCExcelImport
				:id="projekt.id"
				:fileId="projekt.id_priloha_c"
				:fileName="projekt.priloha_c_puvodni_nazev"
				:importErrors="importObsahujeChyby"
				:disabled="jeProjektUzamcen"
				@uploaded="updateFile($event)"
			/>

		</div>
	</div>
</template>

<script>
import {
	TYP_PROJEKTU,
	STAV_PROJEKTU,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'

import CastCExcelDownload from './CastCExcelDownload.vue'
import CastCExcelImport from './CastCExcelImport.vue'

export default {
	name: 'CastCExcel',

	components: {
		CastCExcelDownload,
		CastCExcelImport,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
	},

	emits: ['uploaded'],

	computed: {
		...authComputed,

		importObsahujeChyby() {
			return Array.isArray(this.projekt.kontrola_c) && this.projekt.kontrola_c.length > 0
		},

		jeMoznePodatZadost() {
			// UPRAVIT UPRAVIT UPRAVIT !!!
			// return Date.now() < Date.parse('2021-11-11')
			return true
		},

		jeProjektUzamcen() {
			return (
				this.projekt.stav === STAV_PROJEKTU.UZAMCENO
				|| this.projekt.stav === STAV_PROJEKTU.SCHVALENO
				|| this.projekt.stav === STAV_PROJEKTU.ZAMITNUTO
				|| this.isGov
				|| !this.jeMoznePodatZadost
			)
		},

		nazevSouboru() {
			return `${this.projekt.kod}-cast-C-rozpocty.xlsx`
		},
	},

	methods: {
		updateFile(file) {
			this.$emit('uploaded', file)
		},
	},

	created() {
		// constants
		this.TYP_PROJEKTU = TYP_PROJEKTU
		this.STAV_PROJEKTU = STAV_PROJEKTU
	},
}
</script>