<template>

<div>
  <SectionHeader title="Personální a organizační zajištění" />

  <label class="form__label">
    <p class="form__label-title">Personální obsazení (množství úvazků)</p>
    <p class="form__label-description">Uveďte, zda se vám podařilo zajistit plánované personální obsazení, dle Žádosti (viz část 2. Personální obsazení), zejména co se týká požadovaného počtu úvazků. Popište případný vývoj v personálním obsazení.</p>
  </label>

  <template v-if="projekt.typ !== 2">
    <p>Tab. Personální složení služby a výše přepočtených úvazků (HPP a DPČ) (vyplní služby)</p>
    <table class="inputtable" style="width: 100%">
      <thead>
      <tr>
        <th style="width: 40%;">&nbsp;</th>
        <th style="width: 15%;">Plán<br>na rok {{projekt.dotacni_rok - 1}}</th>
        <th style="width: 15%;">Skutečný stav<br> v&nbsp;roce {{projekt.dotacni_rok - 1}}</th>
        <th style="width: 15%;">Plán<br>na rok {{projekt.dotacni_rok}}</th>
        <th style="width: 15%;">Stav ke<br>konci roku {{projekt.dotacni_rok}}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>
          Pracovníci v přímé péči
        </th>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_prima_pece.minuly_plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_prima_pece.minuly_plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_prima_pece.minula_skutecnost"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_prima_pece.minula_skutecnost"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_prima_pece.plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_prima_pece.plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="p_prima_pece"
              v-model="dataset['p_prima_pece']"
          />
        </td>
      </tr>
      <tr>
        <th>
          - zdravotnický personál
        </th>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_zdravotnicky.minuly_plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_zdravotnicky.minuly_plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_zdravotnicky.minula_skutecnost"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_zdravotnicky.minula_skutecnost"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_zdravotnicky.plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_zdravotnicky.plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="p_zdravotnicky"
              v-model="dataset['p_zdravotnicky']"
          />
        </td>
      </tr>
      <tr>
        <th>
          - sociální profese
        </th>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_socialni.minuly_plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_socialni.minuly_plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_socialni.minula_skutecnost"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_socialni.minula_skutecnost"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_socialni.plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_socialni.plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="p_socialni"
              v-model="dataset['p_socialni']"
          />
        </td>
      </tr>
      <tr>
        <th>
          - pedagogický personál
        </th>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_pedagogicky.minuly_plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_pedagogicky.minuly_plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_pedagogicky.minula_skutecnost"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_pedagogicky.minula_skutecnost"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_pedagogicky.plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_pedagogicky.plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="p_pedagogicky"
              v-model="dataset['p_pedagogicky']"
          />
        </td>
      </tr>
      <tr>
        <th>
          - jiné
        </th>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_jine.minuly_plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_jine.minuly_plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_jine.minula_skutecnost"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_jine.minula_skutecnost"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_jine.plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_jine.plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="p_jine"
              v-model="dataset['p_jine']"
          />
        </td>
      </tr>
      <tr>
        <th>
          Ostatní pracovníci (mimo přímou péči)
        </th>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_ostatni.minuly_plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_ostatni.minuly_plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_ostatni.minula_skutecnost"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_ostatni.minula_skutecnost"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_ostatni.plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_ostatni.plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="p_ostatni"
              v-model="dataset['p_ostatni']"
          />
        </td>
      </tr>
      <tr>
        <th>
          Úvazky celkem
        </th>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.uvazky_celkem.minuly_plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.uvazky_celkem.minuly_plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.uvazky_celkem.minula_skutecnost"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.uvazky_celkem.minula_skutecnost"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.uvazky_celkem.plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.uvazky_celkem.plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="uvazkyCelkem"
              :disabled="true"
              v-model="uvazkyCelkem"
          />
        </td>
      </tr>
      </tbody>
    </table>
  </template>

  <template v-else>
    <p>Tab. Personální složení projektu a výše přepočtených úvazků (HPP a DPČ) (vyplní neslužbové projekty)</p>
    <table class="inputtable" style="width: 100%">
      <thead>
      <tr>
        <th style="width: 40%;">&nbsp;</th>
        <th style="width: 15%;">Plán<br>na rok {{projekt.dotacni_rok - 1}}</th>
        <th style="width: 15%;">Skutečný stav<br> v&nbsp;roce {{projekt.dotacni_rok - 1}}</th>
        <th style="width: 15%;">Plán<br>na rok {{projekt.dotacni_rok}}</th>
        <th style="width: 15%;">Stav ke<br>konci roku {{projekt.dotacni_rok}}</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>
          Výzkumní pracovníci
        </th>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_vyzkumni.minuly_plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_vyzkumni.minuly_plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_vyzkumni.minula_skutecnost"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_vyzkumni.minula_skutecnost"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_vyzkumni.plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_vyzkumni.plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="p_vyzkumni"
              :error="isErrorInput(dataset['p_vyzkumni'])"
              v-model="dataset['p_vyzkumni']"
          />
        </td>
      </tr>
      <tr>
        <th>
          Ostatní pracovníci
        </th>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_ostatni.minuly_plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_ostatni.minuly_plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_ostatni.minula_skutecnost"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_ostatni.minula_skutecnost"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="data.tab_personalni_zajisteni_plan.p_ostatni.plan"
              :disabled="true"
              v-model="data.tab_personalni_zajisteni_plan.p_ostatni.plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="p_ostatni"
              :error="isErrorInput(dataset['p_ostatni'])"
              v-model="dataset['p_ostatni']"
          />
        </td>
      </tr>
      <tr>
        <th>
          Úvazky celkem
        </th>
        <td>
          <BaseInput
              class="w100"
              type="number"
              :disabled="true"
              name="data.tab_personalni_zajisteni_plan.uvazky_celkem.minuly_plan"
              v-model="data.tab_personalni_zajisteni_plan.uvazky_celkem.minuly_plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              :disabled="true"
              name="data.tab_personalni_zajisteni_plan.uvazky_celkem.minula_skutecnost"
              v-model="data.tab_personalni_zajisteni_plan.uvazky_celkem.minula_skutecnost"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              :disabled="true"
              name="data.tab_personalni_zajisteni_plan.uvazky_celkem.plan"
              v-model="data.tab_personalni_zajisteni_plan.uvazky_celkem.plan"
          />
        </td>
        <td>
          <BaseInput
              class="w100"
              type="number"
              name="uvazkyCelkem"
              :disabled="true"
              v-model="uvazkyCelkem"
          />
        </td>
      </tr>
      </tbody>
    </table>
  </template>

  <div class="form__field form__field--full mt-4">
    <label for="komentar1" class="form__label">
      <p class="form__label-title">Komentář</p>
    </label>

    <BaseTextarea name="komentar1" v-model="data.komentar1" :rows="8" class="w100" />
  </div>

  <div class="form__field form__field--full mt-4">
    <label for="odborne_zajisteni" class="form__label">
      <p class="form__label-title">Odborné zajištění</p>
      <p class="form__label-description">Popište případné změny v odborném zajištění služby/projektu (viz Charakteristika a popis služby/projektu)</p>
    </label>

    <BaseTextarea name="odborne_zajisteni" class="w-100" :rows="8" v-model="data.odborne_zajisteni" />
  </div>

  <div class="form__field form__field--full mt-4">
    <label for="komentar1_dalsi" class="form__label">
      <p class="form__label-title">Jakýkoliv jiný komentář</p>
      <p class="form__label-description">(není nutné vyplnit)</p>
    </label>

    <BaseTextarea name="komentar1_dalsi" class="w-100" :rows="8" v-model="data.komentar1_dalsi" />
  </div>

</div>
</template>

<script>
import { formatNumberCZ } from '@/utils/format.js'
import { toNumberCZ, isNumericCZ } from '@/utils/convert.js'

import SectionHeader from '@/components/Common/SectionHeader'
import BaseInput from '@/components/Forms/Base/BaseInput'
import TableText from "@/components/Table/TableText.vue"
import TableInput from "@/components/Table/TableInputNumber.vue"
import BaseTextarea from "@/components/Forms/Base/BaseTextarea.vue";

export default {
	name: 'ZaverecnaZpravaZajisteni',
	components: {
    BaseTextarea,
		SectionHeader,
		BaseInput,
    TableText,
    TableInput,
	},
	props: {
		projekt: {
			type: Object,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: true,
		},
	},

  created() {
      this.dataset = JSON.parse(this.data.tab_personalni_zajisteni)
  },

  computed: {
      uvazkyCelkem() {
        let total = 0

        if (this.projekt.typ !== 2) {
          total = parseFloat(this.dataset.p_prima_pece)
            + parseFloat(this.dataset.p_zdravotnicky)
            + parseFloat(this.dataset.p_socialni)
            + parseFloat(this.dataset.p_pedagogicky)
            + parseFloat(this.dataset.p_jine)
            + parseFloat(this.dataset.p_ostatni)
        } else {
            total = parseFloat(this.dataset.p_vyzkumni)
              + parseFloat(this.dataset.p_ostatni)
        }


        return Math.round(total * 100) / 100
      }
  },

	data() {
		return {
      dataset: {},
		}
	},

	methods: {
		isNumericCZ,
		toNumberCZ,
		formatNumberCZ,
		isErrorInput(value) {
			return !(this.isNumericCZ(value) || value === '')
		},
	},

  watch: {
    dataset: {
      handler () {
        this.data.tab_personalni_zajisteni = JSON.stringify(this.dataset)
      },
      deep: true
    }
  }
}
</script>