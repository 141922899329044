<template>
	<div class="filters">
		<div class="filter">
			<label class="filter__label">Rok</label>
			<select
				class="filter__input w100"
				:class="{ 'filter__input--selected': dotacniRok }"
				v-model="dotacniRok"
				@change="updateFilter"
			>
				<option value=""></option>
				<option v-for="item in dotacniRoky" :key="item.rok" :value="item.rok">{{ item.rok }}</option>
			</select>
		</div>

		<div class="filter size4">
			<label class="filter__label">Organizace</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': organizace}"
				v-model="organizace"
				@input="updateFilter"
			/>
		</div>

		<div class="filter size2">
			<label class="filter__label">Kód</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': kod}"
				v-model="kod"
				@input="updateFilter"
			/>
		</div>

		<div class="filter size5">
			<label class="filter__label">Název projektu</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': nazev}"
				v-model="nazev"
				@input="updateFilter"
			/>
		</div>

		<div class="filter">
			<label class="filter__label">Typ</label>
			<select
				class="filter__input w100"
				:class="{ 'filter__input--selected': typ}"
				v-model="typ"
				@change="updateFilter"
			>
				<option value=""></option>
				<option :value="TYP_PROJEKTU.SLUZBA">služba</option>
				<option :value="TYP_PROJEKTU.IVH">IVH</option>
			</select>
		</div>

		<div class="filter">
			<label class="filter__label">Stav</label>
			<select
				class="filter__input w100"
				:class="{ 'filter__input--selected': stav}"
				v-model="stav"
				@change="updateFilter"
			>
				<option value=""></option>
        <option v-for="item in stavy" :key="item.id" :value="item.id">{{ item.nazev }}</option>
			</select>
		</div>

		<div class="filter" v-if="filterActive">
			<a
				href="#"
				class="filter__link"
				@click.prevent="clearFilter"
			>Zrušit filtr</a>
		</div>
	</div>
</template>

<script>
import {
	TYP_PROJEKTU
} from '@/constants'
import DotacniRokyService from "@/services/DotacniRokyService";
import CiselnikyService from "@/services/CiselnikyService";

export default {
	name: 'FiltrProjekty',

	props: {
		filterValues: {
			type: Object,
			default: {},
		},
	},

	data() {
		return {
      dotacniRoky: [],
			dotacniRok: this.filterValues?.dotacniRok || '',
			organizace: this.filterValues?.organizace || '',
			kod: this.filterValues?.kod || '',
			nazev: this.filterValues?.nazev || '',
			typ: this.filterValues?.typ || '',
			stav: this.filterValues?.stav || '',
      stavy: ''
		}
	},

	computed: {
		filterActive() {
			return (
				!!this.dotacniRok
				|| !!this.organizace
				|| !!this.kod
				|| !!this.nazev
				|| !!this.typ
				|| !!this.stav
			)
		},
	},

	methods: {
    async loadStavuProjektu() {
      try {
        const stavyProjektuResponse = await CiselnikyService.getStavyProjektu()

        if (stavyProjektuResponse.data.success) {
          this.stavy = stavyProjektuResponse.data.data
        } else {
          this.error += stavyProjektuResponse.data.error
        }
      } catch (error) {
        this.error += error.message
      }
    },

    async loadDotacniRoky() {
      try {
        const dotacniRokyResponse = await DotacniRokyService.getDotacniRoky()
        if (dotacniRokyResponse.data.success) {
          this.dotacniRoky = dotacniRokyResponse.data.data
          this.dataLoaded = true
        } else {
          this.error += dotacniRokyResponse.data.error
        }

      } catch(error) {
        this.error += error.message
      }
    },

		updateFilter() {
			this.$emit('updateFilter', {
				dotacniRok: this.dotacniRok,
				organizace: this.organizace,
				kod: this.kod,
				nazev: this.nazev,
				typ: this.typ,
				stav: this.stav,
			})
		},

		clearFilter() {
			this.dotacniRok = ''
			this.organizace = ''
			this.kod = ''
			this.nazev = ''
			this.typ = ''
			this.stav = ''

			this.updateFilter()
		}
	},


	created() {
		// constants
		this.TYP_PROJEKTU = TYP_PROJEKTU

    // codelists
    this.loadStavuProjektu()
    this.loadDotacniRoky()
	}
}
</script>
