<template>
	<div class="form__input-element">
		<input
			type="checkbox"
			class="form__checkbox"
			:class="{'form__checkbox--checked': modelValue}"
			:name="name"
			:id="name"
			:checked="modelValue"
			:disabled="disabled"
			@input="$emit('update:modelValue', $event.target.checked)"
		/>
		<label
			:for="name"
			class="form__checkbox-box"
		>
			<font-awesome-icon
				class="form__checkbox-icon"
				icon="check"
			/>
		</label>
		<label
			v-if="label"
			:for="name"
			class="form__checkbox-label ml-2"
		>{{ label }}</label>
	</div>
</template>

<script>
export default {
	name: 'BaseCheckbox',

	props: {
		modelValue: {
			type: Boolean,
			default: false,
		},
		name: {
			type: String,
			required: true,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		valid: {
			type: Boolean,
			default: undefined,
		},
		label: {
			type: String,
			default: null,
		},
	},

}
</script>
