<template>
  <section>
      <SectionHeader class="mt-5" title="PŘEHLED VYÚČTOVÁNÍ" />

      <template v-if="items.length">
        <table class="datagrid">
          <thead>
            <tr>
              <th>Kód</th>
              <th>Název projektu</th>
              <th class="alc">Typ</th>
              <th class="alr">Celkové dotace</th>
              <th class="alc">Vyúčtování</th>
              <th class="alc">Závěrečná zpráva</th>
              <th class="alc">Výkaz zisků a ztrát</th>
            </tr>
          </thead>
          <tbody>
          <tr
              class="datagrid__row datagrid__row--non-hoverable"
              v-for="item in items"
              :key="item.id"
          >
            <td>{{ item.kod }}</td>
            <td><a @click.prevent="editProjekt(item.id)" href="#">{{ item.nazev }}</a></td>
            <td class="alc">{{ typProjektu(item.typ) }}</td>
            <td class="alr">{{ formatNumberCZ(item.celkova_dotace) }}</td>
            <td class="alc">
              <font-awesome-icon v-if="item.vyuctovani" class="valid" icon="check" />
              <font-awesome-icon v-else icon="times" class="invalid" />
            </td>
            <td class="alc">
              <font-awesome-icon v-if="item.zaverecna_zprava" class="valid" icon="check" />
              <font-awesome-icon v-else class="invalid" icon="times" />
            </td>
            <td class="alc">
              <font-awesome-icon v-if="item.vykaz" class="valid" icon="check" />
              <font-awesome-icon v-else class="invalid" icon="times" />
            </td>
          </tr>
          </tbody>
        </table>
      </template>

      <div v-else class="content">
        <Message type="info">
          Žádná data
        </Message>
      </div>

      <div v-if="!enabledOdeslatVyuctovani" class="content">
        <Message type="info">
          Vyúčtování lze odeslat ke kontrole, až jsou vyplněny všechny náležitosti u všech projektů v žádosti
        </Message>
      </div>

      <div v-if="zadost.id_stav_vyuctovani === 3" class="content">
        <Message type="info">
          Vyúčtování odesláno.
        </Message>
      </div>

      <Message type="error" class="pt-2" v-if="errorMessage">
        {{errorMessage }}
      </Message>

      <button v-if="zadost.id_stav_vyuctovani !== 3" :disabled="!enabledOdeslatVyuctovani" @click="handleOdeslatVyuctovani()" class="button mt-3">Odeslat vyúčtování</button>


      <ZadostStazeniCestneProhlaseniBezdluznost
          :idZadosti="zadost.id"
          class="mt-5"
      />
    </section>
</template>

<script>
import {
  TYP_PROJEKTU,
  STAV_PROJEKTU
} from '@/constants'
import { formatNumberCZ } from '@/utils/format.js'
import Message from '@/components/Common/Message'
import SectionHeader from '@/components/Common/SectionHeader'
import ZadostiService from "@/services/ZadostiService";
import ZadostStazeniCestneProhlaseniBezdluznost from "@/components/Zadost/ZadostStazeniCestneProhlaseniBezdluznost.vue";
export default {
  name: 'ZadostPrehledVyuctovani',
  components: {
    ZadostStazeniCestneProhlaseniBezdluznost,
    Message,
    SectionHeader,
  },
  props: {
    idZadosti: {
      type: Number,
      required: true,
    },
    zadost: {
      type: Object,
      required: true,
    },
  },
  computed: {
    projektIds() {
      let ids = []
      this.items.forEach((item) => {
        ids.push(item.id)
      });
      return ids
    },
    enabledOdeslatVyuctovani() {
      let result = true
      this.items.forEach((item) => {
        if (!item.vyuctovani || !item.zaverecna_zprava || !item.vykaz) {
          result = false
          return
        }
      });
      return result
    }
  },
  data() {
    return {
      STAV_PROJEKTU: '',
      items: [],
      errorMessage: ''
    }
  },
  async created() {
    this.TYP_PROJEKTU = TYP_PROJEKTU
    this.STAV_PROJEKTU = STAV_PROJEKTU

    await this.loadData()
  },
  methods: {
    formatNumberCZ,
    async loadData() {
      try {
        const response = await ZadostiService.getVyuctovaniPrehled(this.zadost.id)
        if (response.data.success) {
          this.items = response.data.data
        } else {
          this.$emit('setError', response.data.error);
        }
      }
      catch(error) {
        this.$emit('setError', error.message);
      }
    },
    typProjektu(typ) {
      switch (typ) {
        case this.TYP_PROJEKTU.SLUZBA:
          return 'služba'
        case this.TYP_PROJEKTU.IVH:
          return 'IVH'
      }
      return '-'
    },
    async handleOdeslatVyuctovani() {
      try {
        const response = await ZadostiService.odeslatVyuctovani(this.zadost.id, this.projektIds)
        await this.loadData();

        if (!response.data.success) {
          this.errorMessage = response.data.error
        }

        this.$emit('updated')

      } catch(e) { }
    },
    editProjekt(id) {
      this.$router.push({ name: 'Projekt', params: { id } })
    }
  }
}
</script>