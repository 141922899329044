<template>
  <h3>ZMĚNY KE SCHVÁLENÍ (formulář A)</h3>

  <Message v-if="error" type="error">
    {{error}}
  </Message>

  <Loading v-if="!dataLoaded" />

  <table v-if="dataLoaded && result.length !== 0" class="datagrid">
    <tbody>
      <tr
        class="datagrid__row--warning"
        v-for="zadost in result"
        :key="zadost.id"
        @click="editZadost(zadost.id)"
      >
        <td>{{ zadost.dotacni_rok }}</td>
        <td>{{ zadost.nazev }}</td>
        <td>{{ zadost.stav_nazev }}</td>
        <td>{{ zadost.ic }}</td>
        <td>{{ zadost.dic }}</td>
        <td>{{ zadost.statutar_cele_jmeno }}</td>
        <td>{{ zadost.statutar_telefon }}</td>
        <td>{{ zadost.statutar_email }}</td>
      </tr>
    </tbody>
  </table>

  <p v-if="dataLoaded && result.length == 0" >
    Žádná organizace momentálně nemá formulář A ke schválení.
  </p>

</template>

<script>
import InformaceOrganizaceService from '@/services/InformaceOrganizaceService'

import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
  name: 'ZmenyKeSchvaleni',

  components: {
    Message,
    Loading,
  },

  data() {
    return {
      error: '',
      zadosti: [],
      dataLoaded: false,
    }
  },

  computed: {
    result() {
      return this.zadosti
    }
  },

  methods: {
    editZadost(id) {
      this.$router.push({ name: 'OrganizaceKontrola', params: { id: id } })
    },

    loadData() {
			InformaceOrganizaceService
      .getZmenyKeSchvaleni()
			.then(response => {
				if (response.data.success) {
					this.zadosti = response.data.data
          this.dataLoaded = true
				} else {
          this.error += response.data.error
				}
			})
			.catch(error => {
        this.error += error.message
			})
      .then(
        // stane se vzdy
        // this.dataLoaded = true
      )
    }
  },

  created() {
    this.loadData()
  }
}
</script>
