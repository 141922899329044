<template>
	<div>
		<Message type="info" class="mb-5" v-if="message">
			{{ message }}
		</Message>

		<Message type="error" class="mb-5" v-if="error">
			{{ error }}
		</Message>

		<Loading v-if="!dataLoaded" />


		<form
			novalidate
			v-if="dataLoaded"
			@submit.prevent=""
			class="form--size40"
		>

			<SectionHeader title="1. PŘEDMĚT ŽÁDOSTI" />

			<div class="form__field">

				<label for="cinnosti" class="form__label">
          <p class="form__label-title">Popište, na jaké činnosti si žádáte dotaci od ÚV ČR</p>
					<p class="form__label-description">Uveďte, o financování jakých konkrétních aktivit/činností žádáte.</p>
				</label>

				<BaseTextarea
					v-model="data.cinnosti"
					v-bind="fieldAttrs('cinnosti')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div class="form__field mt-5">
				<label for="planovane_zmeny" class="form__label">
					<p class="form__label-title">Plánované změny/rozvoj projektu v roce, na který si žádáte dotaci (nerelevantní pro jednoleté projekty, např. IVH)</p>
          <p class="form__label-description">Uveďte plánované změny v případě, že se jedná o projekt, který byl realizován v předchozím roce.</p>
				</label>

				<BaseTextarea
					v-model="data.planovane_zmeny"
					v-bind="fieldAttrs('planovane_zmeny')"
					:rows="8"
					class="w100"
				/>
			</div>


			<SectionHeader title="2. PERSONÁLNÍ OBSAZENÍ ZA CELÝ PROJEKT" class="mt-6" />

			<p>
				Uveďte všechny přepočtené personální úvazky podílející se na zajištění celého projektu, včetně DPČ a DPP
				<strong>Konkrétní složení týmu se uvádí do tabulky C3 až C5.</strong>
			</p>

			<CastB1Uvazky
				:typ="projekt.typ"
				:dotacniRok="projekt.dotacni_rok"
				:disabled="jeProjektUzamcen"
				v-model="data.prepoctene_uvazky"
			/>


			<div class="form__field mt-5">
				<label for="prepoctene_uvazky_komentar" class="form__label">
					Upřesnění/komentář k počtu úvazků nebo rozšíření týmu
				</label>

				<BaseTextarea
					v-model="data.prepoctene_uvazky_komentar"
					v-bind="fieldAttrs('prepoctene_uvazky_komentar')"
					:rows="5"
					class="w100"
				/>
			</div>



			<SectionHeader title="3. KOMENTÁŘ K ROZPOČTU" class="mt-6" />

<!--
			<h3 class="subsection-title">3.1 Memorandum o dlouhodobé spolupráci s ÚV ČR</h3>

			<div class="form__field">
				<label for="memorandum" class="form__label">
					Memorandum o dlouhodobé spolupráci s ÚV ČR:
				</label>

				<BaseSelect
					v-model="data.memorandum"
					v-bind="fieldAttrs('memorandum')"
					:options="[
						{ value: 1, option: 'žádám' },
						{ value: 2, option: 'nežádám' },
					]"
					valueKey="value"
					optionKey="option"
					:emptyValue="true"
					class="form__input--size20"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="memorandum_od" class="form__label">
					Uzavřeno na období od:
				</label>

				<BaseInput
					v-model="data.memorandum_od"
					v-bind="fieldAttrs('memorandum_od')"
					type="date"
					class="form__input--size20"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="memorandum_do" class="form__label">
					Uzavřeno na období do:
				</label>

				<BaseInput
					v-model="data.memorandum_do"
					v-bind="fieldAttrs('memorandum_do')"
					type="date"
					class="form__input--size20"
				/>
			</div>
 -->


			<h3 class="subsection-title">3.1 Smlouva se zdravotní pojišťovnou</h3>

			<div class="form__field">
				<label for="pojistovna_smlouva" class="form__label">
					Smlouva se zdravotní pojišťovnou
				</label>

				<BaseSelect
					v-model="data.pojistovna_smlouva"
					v-bind="fieldAttrs('pojistovna_smlouva')"
					:options="[
						{ value: 1, option: 'ano' },
						{ value: 2, option: 'ne' },
						{ value: 3, option: 'v jednání' },
					]"
					valueKey="value"
					optionKey="option"
					:emptyValue="true"
					class="form__input--size20"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="pojistovna_komentar" class="form__label">
          <p class="form__label-title">Upřesnění/komentář ke smlouvě se zdravotní pojišťovnou</p>
          <p class="form__label-description">Uveďte např. s jakými pojišťovnami máte uzavřené smlouvy, s jakými pojišťovnami o uzavření smlouvy vyjednáváte, zda probíhala jednání se zdravotními pojišťovnami, s jakým výsledkem, a identifikujte překážky v uzavření smlouvy.</p>
				</label>

				<BaseTextarea
					v-model="data.pojistovna_komentar"
					v-bind="fieldAttrs('pojistovna_komentar')"
					:rows="5"
					class="w100"
				/>
			</div>




			<h3 class="subsection-title mt-6">3.2 Zdroje financování</h3>

			<div class="form__field">
				<label for="zdroje_financovani" class="form__label">
          <p class="form__label-title">Zdroje financování</p>
					<p class="form__label-description">Zdroje financování projektu se uvádí v části C1. V případě, že projekt je financován z jednoho zdroje, vysvětlete, proč není do financování zapojeno více zdrojů financování. Dále zde komentujte změny ve zdrojích financování a v jejich výši, pokud k nim dochází.</p>
				</label>

				<BaseTextarea
					v-model="data.zdroje_financovani"
					v-bind="fieldAttrs('zdroje_financovani')"
					:rows="8"
					class="w100"
				/>
			</div>

			<div class="form__field mt-2">
				<label for="prekroceni_podilu" class="form__label">
          <p class="form__label-title">Zdůvodněte překročení 70% podílu dotace</p>
					<p class="form__label-description">Dotace může být poskytnuta až do výše 70 % celkových nákladů/výdajů projektu uvedených v části C1, V případě překročení 70% podílu dotace je třeba řádné zdůvodnění, např. informace typu, s kým bylo o spolufinancování služby jednáno, s jakým výsledkem, důvody neúspěchu.</p>
				</label>

				<BaseTextarea
					v-model="data.prekroceni_podilu"
					v-bind="fieldAttrs('prekroceni_podilu')"
					:rows="8"
					class="w100"
				/>
			</div>



			<h3 class="subsection-title mt-6">3.3 Rozpočet projektu</h3>

			<div class="form__field">
				<label for="rozpocet" class="form__label">
					<p class="form__label-title">Rozpočet projektu</p>
					<p class="form__label-description">Rozpočet projektu se uvádí v části C2. Zde uveďte komentář a vysvětlení k rozpočtu služby/projektu.</p>
          <p class="form__label-description">V případě, že byl projekt realizován v předchozím roce a došlo k navýšení požadavku o dotaci oproti předchozímu roku, toto navýšení zdůvodněte.</p>
				</label>

				<BaseTextarea
					v-model="data.rozpocet"
					v-bind="fieldAttrs('rozpocet')"
					:rows="8"
					class="w100"
				/>
			</div>



			<h3 class="subsection-title mt-6">3.4 Zdůvodnění žádosti o příspěvek na operativního leasingu vozidla</h3>

			<div class="form__field mt-2">
				<label for="zduvodneni_leasingu" class="form__label">
					<p class="form__label-title">Zdůvodnění žádosti o příspěvek na operativní leasing vozidla</p>
					<p class="form__label-description">Náklady/výdaje spojené s pořízením majetku formou operativního leasingu řádně zdůvodněte</p>
          <p class="form__label-description">V případě, že zatím nedisponujete leasingovou smlouvou, pouze uvažujete o jejím uzavření, popište stávající stav (např. počet lokalit, kde je projekt realizován, vč. vzdálenosti v km, dopravní obslužnost v místě realizace projektu, počet vozidel využívaných projektem, popř. jejich technický stav) a informace o finanční podpoře úhrady nákladů na leasing z dalších zdrojů financování. V případě, že máte uzavřenou smlouvu s leasingovou společností, uveďte datum účinnosti smlouvy (datum předání vozidla), dobu trvání leasingového vztahu, příp. datum jeho ukončení, údaj o ceně pronajatého vozidla (tj. o splátkách nájemného v průběhu leasingového vztahu) a vyjádření k nákladům spojených s údržbou, opravami či servisními službami pronajatého vozu (povinnost hradit náklady může být smluvně přenesena na nájemce).</p>
				</label>

				<BaseTextarea
					v-model="data.zduvodneni_leasingu"
					v-bind="fieldAttrs('zduvodneni_leasingu')"
					:rows="8"
					class="w100"
				/>
			</div>



			<template v-if="!jeProjektUzamcen">
				<hr class="form__divider">

				<Loading title="Ukládám data&hellip;" v-if="isSaving" />

        <Message type="error" class="mb-5" v-if="error">
          {{ error }}
        </Message>

				<div class="form__buttons">
					<button
						class="button"
						:disabled="isSaving"
						@click.prevent="saveData"
					>
						<font-awesome-icon icon="save" class="button__icon" />
						Uložit formulář
					</button>
				</div>
			</template>

		</form>
	</div>
</template>

<script>
import { validateFields } from '@/utils/validator'
import {
	TYP_PROJEKTU,
	STAV_PROJEKTU,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'

import Message from '@/components/Common/Message.vue'
import Loading from '@/components/Common/Loading.vue'
import SectionHeader from '@/components/Common/SectionHeader.vue'
import BaseInput from '@/components/Forms/Base/BaseInput.vue'
import BaseInputNumber from '@/components/Forms/Base/BaseInputNumber.vue'
import BaseTextarea from '@/components/Forms/Base/BaseTextarea.vue'
import BaseSelect from '@/components/Forms/Base/BaseSelect.vue'

import CastB1Uvazky from '@/components/Projekty/CastB/2024/CastB1Uvazky.vue'

export default {
	name: 'CastB1',

	components: {
		Message,
		Loading,
		SectionHeader,
		BaseInput,
    BaseInputNumber,
		BaseTextarea,
		BaseSelect,
		CastB1Uvazky,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			error: '',
			message: '',
			dataLoaded: false,
			isSaving: false,

			data: {},

			formChanged: false,
			schema: {
				cinnosti: { required: true, },
				planovane_zmeny: { required: true, },
				// klienti_planovany_pocet: { required: true, validator: "int", },
				// klienti_komentar: { required: true, },
				prepoctene_uvazky: { required: true, },
				prepoctene_uvazky_komentar: { required: true, },
				// memorandum: { required: true, },
				// memorandum_od: { required: true, validator: "date", },
				// memorandum_do: { required: true, validator: "date", },
				pojistovna_smlouva: { required: true, },
				pojistovna_komentar: { required: true, },
				zdroje_financovani: { required: true, },
				prekroceni_podilu: { required: true, },
				rozpocet: { required: true, },
				zduvodneni_leasingu: { required: true, },
				// poznamka_poskytovatele: { required: true, },
			},
		}
	},

	computed: {
		...authComputed,

		jeMoznePodatZadost() {
			// UPRAVIT UPRAVIT UPRAVIT !!!
			// return Date.now() < Date.parse('2021-11-11')
			return true
		},

		jeProjektUzamcen() {
			return (
				this.projekt.stav === STAV_PROJEKTU.UZAMCENO
				|| this.projekt.stav === STAV_PROJEKTU.SCHVALENO
				|| this.projekt.stav === STAV_PROJEKTU.ZAMITNUTO
				|| this.isGov
				|| !this.jeMoznePodatZadost
			)
		},
	},

	methods: {
    validateFields,

		saveData() {
			this.error = ''
			this.message = ''

      if (!this.validateFields(this.data, this.schema)) {
        this.error = 'Zkontrolujte povinná pole'
        return
      }

			this.isSaving = true

			ProjektyService
			.updateProjektB1(this.projekt.id, this.data)
			.then(response => {
				if (response.data.success) {
					this.message = "Formulář byl v pořádku uložen"
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
			.then(() => {
				// vyscrollujeme nahoru na strance, aby byla videt chyba nebo message
				this.isSaving = false
				window.scrollTo(0, 0)
			})
		},


		fieldChanged(event) {
			// const field = event.target.name
			// this.schema[field].valid = this.isValid(field)
			this.formChanged = true
		},

		fieldAttrs(name) {
			return {
				name: name,
				required: this.schema[name].required,
				valid: this.schema[name]?.valid,
				disabled: this.jeProjektUzamcen,
				placeholder: this.schema[name]?.placeholder,
				onInput: this.fieldChanged,
			}
		},

		loadData() {
			ProjektyService
			.getProjektB1(this.projekt.id)
			.then(response => {
				if (response.data.success) {
					this.data = response.data.data
					this.dataLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},
	},

	created() {
		// constants
		this.TYP_PROJEKTU = TYP_PROJEKTU
		this.STAV_PROJEKTU = STAV_PROJEKTU

		this.loadData()
	}

}
</script>