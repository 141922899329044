<template>

	<PageHeader title="HODNOCENÍ KRAJSKÝCH KOORDINÁTORŮ" />

	<Message type="error" v-if="error">
    {{ error }}
	</Message>

	<Loading v-if="!dataLoaded" />

	<table v-if="dataLoaded" class="datagrid mt-4">
		<thead>
			<tr>
        <th>Krajský koordinátor</th>
        <th>Kraj</th>
        <th>Datum odeslání hodnocení</th>
			</tr>
		</thead>
		<tbody>
      <tr v-if="hodnotiteleResult.length === 0">
        <td colspan="3" class="alc p-5 color-danger">Nebyla nalezena žádná hodnocení projektů v tomto dotačním roce.</td>
      </tr>

			<tr
				v-for="hodnotitel in hodnotiteleResult"
				:key="hodnotitel.id_uzivatele"
				@click="zobrazitHodnoceni(hodnotitel.id_uzivatele)"
			>
				<td><strong>{{ hodnotitel.jmeno }}</strong></td>
				<td><strong>{{ hodnotitel.kod }}</strong> - {{ hodnotitel.kraj }}</td>
				<td>{{ formatDateCZ(hodnotitel.datum_odeslani) }}</td>
			</tr>
		</tbody>
	</table>


</template>

<script>
import { authComputed } from '@/store/helpers.js'
import { formatDateCZ } from '@/utils/format.js'

import ProjektyService from '@/services/ProjektyService'
import CiselnikyService from '@/services/CiselnikyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
  name: 'HodnoceniPrehled',

  components: {
    PageHeader,
    Message,
    Loading,
  },

  data() {
    return {
      error: '',

      dotacniRok: 2024,

      dataLoaded: false,
      hodnoceni: [],
    }
  },

  computed: {
    ...authComputed,

    hodnotiteleResult() {
      return this.hodnoceni
    }
  },

  methods: {
    formatDateCZ,

    zobrazitHodnoceni(koordinatorId) {
      this.$router.push({ name: 'HodnoceniDetail', params: { koordinatorId: koordinatorId, dotacniRok: this.dotacniRok } })
    },

    loadData() {
      ProjektyService
      .getHodnoceniPrehled(this.dotacniRok)
      .then(response => {
        if (response.data.success) {
          this.hodnoceni = response.data.data
          this.dataLoaded = true
        } else {
          this.error = response.data.error
        }
      })
      .catch(error => {
        this.error = error.message
      })
    },

  },

  created() {
    this.loadData()
  }
}
</script>