<template>
	<input
		class="form__input"
		:class="{
			'form__input--small-padding': small,
			'all': align === 'left',
			'alr': align === 'right',
			'alc': align === 'center',
			'form__input--error': error || valid === false
		}"
		:type="type"
		:name="name"
		:id="name"
		:placeholder="placeholder"
		:disabled="disabled"
		:value="modelValue"
    :min="min"
    :max="max"
		@input="$emit('update:modelValue', $event.target.value)"
	>
</template>

<script>
export default {
	name: 'BaseInputNumber',

	props: {
		modelValue: {
			type: [Number, String],
			default: '',
		},
		name: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			default: 'text',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		valid: {
			type: Boolean,
			default: undefined,
		},
		placeholder: {
			type: String,
			default: null,
		},
		error: {
			type: Boolean,
			default: false,
		},
		small: {
			type: Boolean,
			default: false,
		},
		align: {
			type: String,
			default: undefined,
		},
    min: {
      type: String,
      default: undefined
    },
    max: {
      type: String,
      default: undefined
    }
	},

}
</script>
