<template>
  <div>
    <SetPasswordForm />
  </div>
</template>

<script>
import SetPasswordForm from '@/components/ForgotPassword/SetPasswordForm'

export default {
  name: 'ForgotPasswordSet',

  components: {
    SetPasswordForm,
  },

}
</script>
