<template>

	<SectionHeader title="Část C5 - Pracovníci na dohody o provedení práce (DPP)" />

	<table class="table w100">
		<thead>
			<tr>
				<th>Pracovní pozice</th>
				<th>Specifikace</th>
				<th>Celkem<br>počet<br>hodin</th>
				<th>Honorář<br>za&nbsp;hodinu</th>
				<th>Odměna<br>celkem&nbsp;pro<br>rok&nbsp;{{projekt.dotacni_rok}}</th>
				<th>Požadavek<br>dotace<br>na mzdu<br>od ÚV ČR<br>na rok {{projekt.dotacni_rok}}</th>
			</tr>
		</thead>
		<tbody>

			<tr class="table__sum-row" v-if="data.pracovnici_pp.length !== 0">
				<td class="alc" colspan="6">Pracovníci v přímé péči</td>
			</tr>
			<tr
				v-for="(radek, index) in data.pracovnici_pp"
				:key="`pp-${index}`"
			>
				<td v-tippy="chybaTooltip('pracovni_pozice', 'pp', index)" v-bind="chyba('pracovni_pozice', 'pp', index)">{{ pracovniPozice("primapece", radek.pracovni_pozice, radek.pracovni_pozice_poznamka) }}</td>
				<td v-tippy="chybaTooltip('specifikace', 'pp', index)" v-bind="chyba('specifikace', 'pp', index)">{{ specifikace("primapece", radek.specifikace, radek.specifikace_poznamka) }}</td>
				<td class="alc" v-tippy="chybaTooltip('pocet_hodin', 'pp', index)" v-bind="chyba('pocet_hodin', 'pp', index)">{{ radek.pocet_hodin }}</td>
				<td class="alr" v-tippy="chybaTooltip('honorar_hodina', 'pp', index)" v-bind="chyba('honorar_hodina', 'pp', index)">{{ formatNumberCZ(radek.honorar_hodina) }}</td>
				<td class="alr" v-tippy="chybaTooltip('odmena_celkem', 'pp', index)" v-bind="chyba('odmena_celkem', 'pp', index)">{{ formatNumberCZ(radek.odmena_celkem) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pozadavek_dotace', 'pp', index)" v-bind="chyba('pozadavek_dotace', 'pp', index)">{{ formatNumberCZ(radek.pozadavek_dotace) }}</td>
			</tr>

			<tr class="table__sum-row" v-if="data.ostatni_pracovnici.length !== 0">
				<td class="alc" colspan="6">Ostatní pracovníci</td>
			</tr>
			<tr
				v-for="(radek, index) in data.ostatni_pracovnici"
				:key="`op-${index}`"
			>
				<td v-tippy="chybaTooltip('pracovni_pozice', 'pp', index)" v-bind="chyba('pracovni_pozice', 'pp', index)">{{ pracovniPozice("ostatni", radek.pracovni_pozice, radek.pracovni_pozice_poznamka) }}</td>
				<td v-tippy="chybaTooltip('specifikace', 'pp', index)" v-bind="chyba('specifikace', 'pp', index)">{{ specifikace("ostatni", radek.specifikace, radek.specifikace_poznamka) }}</td>
				<td class="alc" v-tippy="chybaTooltip('pocet_hodin', 'pp', index)" v-bind="chyba('pocet_hodin', 'pp', index)">{{ radek.pocet_hodin }}</td>
				<td class="alr" v-tippy="chybaTooltip('honorar_hodina', 'pp', index)" v-bind="chyba('honorar_hodina', 'pp', index)">{{ formatNumberCZ(radek.honorar_hodina) }}</td>
				<td class="alr" v-tippy="chybaTooltip('odmena_celkem', 'pp', index)" v-bind="chyba('odmena_celkem', 'pp', index)">{{ formatNumberCZ(radek.odmena_celkem) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pozadavek_dotace', 'pp', index)" v-bind="chyba('pozadavek_dotace', 'pp', index)">{{ formatNumberCZ(radek.pozadavek_dotace) }}</td>
			</tr>

			<tr class="table__sum-row">
				<td colspan="2">Souhrn pracovníci v přímé péči</td>
				<td class="alc" v-tippy="chybaTooltip('pracovnici_pp_hodin')" v-bind="chyba('pracovnici_pp_hodin')">{{ data.pracovnici_pp_hodin }}</td>
				<td>&nbsp;</td>
				<td class="alr" v-tippy="chybaTooltip('pracovnici_pp_odmena')" v-bind="chyba('pracovnici_pp_odmena')">{{ formatNumberCZ(data.pracovnici_pp_odmena) }}</td>
				<td class="alr" v-tippy="chybaTooltip('pracovnici_pp_dotace')" v-bind="chyba('pracovnici_pp_dotace')">{{ formatNumberCZ(data.pracovnici_pp_dotace) }}</td>
			</tr>
			<tr class="table__sum-row">
				<td colspan="2">Souhrn ostatní pracovníci</td>
				<td class="alc" v-tippy="chybaTooltip('ostatni_pracovnici_hodin')" v-bind="chyba('ostatni_pracovnici_hodin')">{{ data.ostatni_pracovnici_hodin }}</td>
				<td>&nbsp;</td>
				<td class="alr" v-tippy="chybaTooltip('ostatni_pracovnici_odmena')" v-bind="chyba('ostatni_pracovnici_odmena')">{{ formatNumberCZ(data.ostatni_pracovnici_odmena) }}</td>
				<td class="alr" v-tippy="chybaTooltip('ostatni_pracovnici_dotace')" v-bind="chyba('ostatni_pracovnici_dotace')">{{ formatNumberCZ(data.ostatni_pracovnici_dotace) }}</td>
			</tr>
			<tr class="table__sum-row">
				<td colspan="2">Celkové mzdové náklady</td>
				<td class="alc" v-tippy="chybaTooltip('mzdove_naklady_hodin')" v-bind="chyba('mzdove_naklady_hodin')">{{ data.mzdove_naklady_hodin }}</td>
				<td>&nbsp;</td>
				<td class="alr" v-tippy="chybaTooltip('mzdove_naklady_odmena')" v-bind="chyba('mzdove_naklady_odmena')">{{ formatNumberCZ(data.mzdove_naklady_odmena) }}</td>
				<td class="alr" v-tippy="chybaTooltip('mzdove_naklady_dotace')" v-bind="chyba('mzdove_naklady_dotace')">{{ formatNumberCZ(data.mzdove_naklady_dotace) }}</td>
			</tr>

		</tbody>
	</table>

	<h3 class="subsection-title mt-5">Komentář k personálnímu obsazení</h3>

	<div class="form__input w100">
		{{ data.personalni_obsazeni }}
	</div>

</template>

<script>
import { formatNumberCZ } from '@/utils/format.js'

import SectionHeader from '@/components/Common/SectionHeader'

export default {
	name: 'CastC5',

	components: {
		SectionHeader,
	},

	props: {
		projekt: {
			type: Object,
			required: true,
		},
		data: {
			type: Object,
			required: true,
		},
		pozicePrimaPece: {
			type: Array,
			required: true,
		},
		specifikacePrimaPece: {
			type: Array,
			required: true,
		},
		poziceNeprimaPece: {
			type: Array,
			required: true,
		},
		specifikaceNeprimaPece: {
			type: Array,
			required: true,
		},
		dosazeneVzdelani: {
			type: Array,
			required: true,
		},
	},

	methods: {
		formatNumberCZ,

		hodnotaCiselniku(arr, id) {
			return arr.find(item => item.id == id)?.nazev
		},

		nahraditSpecifikujte(value, replaceWith) {
			return value ? value.replace('– specifikujte', replaceWith) : ''
		},

		pracovniPozice(typ, pozice, poznamka) {
			const ciselnik = (
				typ === 'primapece'
				? this.pozicePrimaPece
				: this.poziceNeprimaPece
			)

			return (
				this.nahraditSpecifikujte(
					this.hodnotaCiselniku(ciselnik, pozice),
					`: ${poznamka}`
				)
			)
		},

		specifikace(typ, specifikace, poznamka) {
			const ciselnik = (
				typ === 'primapece'
				? this.specifikacePrimaPece
				: this.specifikaceNeprimaPece
			)

			return (
				this.nahraditSpecifikujte(
					this.hodnotaCiselniku(ciselnik, specifikace),
					`: ${poznamka}`
				)
			)
		},

		vzdelani(vzdelani) {
			return this.hodnotaCiselniku(this.dosazeneVzdelani, vzdelani)
		},



		importError(field, cast, radek) {
			if (!Array.isArray(this.projekt.kontrola_c)) return undefined
			return (
				cast
				? this.projekt.kontrola_c.find(err => err.pole === field && err.cast === cast && err.radek === radek && err.list === 'C5')
				: this.projekt.kontrola_c.find(err => err.pole === field && err.list === 'C5')
			)
		},

		chyba(field, cast = undefined, radek = undefined) {
			const importError = this.importError(field, cast, radek)
			return (
				importError
				? {
					class: 'table__error'
				}
				: {}
			)
		},

		chybaTooltip(field, cast = undefined, radek = undefined) {
			const importError = this.importError(field, cast, radek)
			return (
				importError
				? {
					content: `<strong>Chyba na listu ${importError.list} v buňce ${importError.bunka}</strong>` + (importError.chyba ? `<br>${importError.chyba}` : '' )
				}
				: {}
			)
		},

	},


}
</script>