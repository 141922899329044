<template>
	<PageHeader
      v-if="projekt"
		  :title="`ZÁVĚREČNÁ ZPRÁVA ${nazevProjektu}`"
		  :subtitle="`Dotační rok ${dotacniRok}`"
	>
    <div class="buttons" v-if="(isSuperAdmin || isAdmin) && projekt.stav === STAV_PROJEKTU.VYUCTOVANI_ODESLANO">
      <button @click="dialogVraceni()" class="button button--small button--danger button--outline">
        Vrátit k přepracování
      </button>
    </div>
  </PageHeader>

  <Modal
      v-if="modalOpened"
      :overlay="true"
  >
    <ZaverecnaZpravaVratitDialog
        v-if="modalDialog === 'vratit'"
        :id="zprava.id"
        @submit="vratitZpravu"
        @cancel="closeModal"
    />
  </Modal>

	<Tabs
		:items="tabItems"
		:selected="tabSelected"
		@changeTab="changeTab($event)"
	/>

	<div class="content">

		<Message type="error" v-if="error" class="mb-5">
			{{ error }}
		</Message>

		<Loading v-if="!dataLoaded" />

		<template v-if="dataLoaded">

			<ZaverecnaZpravaNavod
				v-if="tabSelected === 1"
				:projekt="projekt"
			/>

			<ZaverecnaZpravaZajisteni
				v-if="tabSelected === 2"
				:projekt="projekt"
				:data="zprava.s1"
				:disabled="!jeZpravaEditovatelna"
			/>

      <ZaverecnaZpravaObsah
          v-if="tabSelected === 3"
          :projekt="projekt"
          :data="zprava.s2"
          :disabled="!jeZpravaEditovatelna"
      />

      <ZaverecnaZpravaVykony
          v-show="tabSelected === 4"
          :projekt="projekt"
          :data="zprava.s3"
          :disabled="!jeZpravaEditovatelna"
      />

      <ZaverecnaZpravaKlienti
          v-if="tabSelected === 5"
          :projekt="projekt"
          :data="zprava.s3"
          :disabled="!jeZpravaEditovatelna"
      />

      <ZaverecnaZpravaVykonyMimoPrimarniPrevenci
          v-if="tabSelected === 6"
          :projekt="projekt"
          :data="zprava.s3"
          :disabled="!jeZpravaEditovatelna"
      />

      <ZaverecnaZpravaExcel
          v-if="tabSelected === 7"
          :projekt="projekt"
          :data="zprava"
          :disabled="!jeZpravaEditovatelna"
          @uploaded="updateFile($event)"
      />


			<template v-if="jeZpravaEditovatelna">
				<hr class="form__divider">

				<Loading title="Ukládám data&hellip;" v-if="isSaving" />

				<Message type="error" v-if="savingError" class="mt-3 mb-3">
					{{ savingError }}
				</Message>

				<Message type="info" v-if="jeZpravaEditovatelna && zprava.uzamceno" class="mt-3 mb-3">
					Závěrečná zpráva je uzamčena a připravena k odeslání. Potřebujete-li údaje upravit, můžete ji znovu odemknout.
				</Message>

				<div class="form__buttons">
					<button
					 	v-if="jeZpravaEditovatelna && !zprava.uzamceno"
						class="button"
						:disabled="isSaving"
						@click.prevent="updatezprava({uzamceno: 1})"
					>
						<font-awesome-icon icon="lock" class="button__icon" />
						Uložit a zamknout pro odeslání
					</button>

					<button
						v-if="jeZpravaEditovatelna && !zprava.uzamceno"
						class="button button--outline"
						:disabled="isSaving"
						@click.prevent="updatezprava({uzamceno: 0})"
					>
						<font-awesome-icon icon="save" class="button__icon" />
						Uložit koncept (k odeslání později)
					</button>

					<button
						v-if="jeZpravaEditovatelna && zprava.uzamceno"
						class="button button--outline"
						:disabled="isSaving"
						@click.prevent="updatezprava({uzamceno: 0})"
					>
						<font-awesome-icon icon="unlock" class="button__icon" />
						Odemknout závěrečnou zprávu pro úpravy
					</button>

				</div>
			</template>

		</template>
	</div>
</template>

<script>
import {
	TYP_PROJEKTU,
  STAV_PROJEKTU
} from '@/constants'

import { toNumberCZ, isNumericCZ } from '@/utils/convert.js'
import { authComputed } from '@/store/helpers.js'
import ProjektyService from '@/services/ProjektyService'

import Modal from '@/components/Common/Modal'
import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import Tabs from '@/components/Common/Tabs'

import ZaverecnaZpravaVratitDialog from '@/components/Projekty/Dialog/ZaverecnaZpravaVratitDialog'
import ZaverecnaZpravaNavod from '@/components/Projekty/ZaverecnaZprava/ZaverecnaZpravaNavod'
import ZaverecnaZpravaZajisteni from '@/components/Projekty/ZaverecnaZprava/ZaverecnaZpravaZajisteni'
import ZaverecnaZpravaObsah from '@/components/Projekty/ZaverecnaZprava/ZaverecnaZpravaObsah'
import ZaverecnaZpravaVykony from '@/components/Projekty/ZaverecnaZprava/ZaverecnaZpravaVykony'
import ZaverecnaZpravaKlienti from '@/components/Projekty/ZaverecnaZprava/ZaverecnaZpravaKlienti'
import ZaverecnaZpravaVykonyMimoPrimarniPrevenci from '@/components/Projekty/ZaverecnaZprava/ZaverecnaZpravaVykonyMimoPrimarniPrevenci'
import ZaverecnaZpravaExcel from '@/components/Projekty/ZaverecnaZprava/ZaverecnaZpravaExcel'

export default {
	name: 'Projektzprava',

	components: {
		PageHeader,
		Message,
		Loading,
		Tabs,
		ZaverecnaZpravaNavod,
		ZaverecnaZpravaZajisteni,
    ZaverecnaZpravaObsah,
    ZaverecnaZpravaVykony,
    ZaverecnaZpravaKlienti,
    ZaverecnaZpravaVykonyMimoPrimarniPrevenci,
    ZaverecnaZpravaExcel,
    ZaverecnaZpravaVratitDialog,
    Modal,

	},

	props: {
		id: {
			type: [Number, String],
			required: true,
		},
	},

	data() {
		return {
      STAV_PROJEKTU: '',
      modalDialog: '',
      modalOpened: false,
			error: '',
			savingError: '',

			zpravaLoaded: false,
			projektLoaded: false,
			isSaving: false,

			zprava: null,
			projekt: null,

			tabSelected: 1,
			tabItems: [
				{
					id: 0,
					title: 'Zpět na projekt',
					icon: 'arrow-left',
				},
				{
					id: 1,
					title: 'Návod',
				},
				{
					id: 2,
					title: 'Personální a organizační zajištění',
					subtitle: '',
				},
				{

					id: 3,
					title: 'Obsah a průběh projektu',
				},
				{
					id: 4,
					title: 'Tabulka Výkony',
					subtitle: 'pro programy primární prevence',
				},
				{
					id: 5,
					title: 'Tabulka Klienti',
					subtitle: 'pro služby mimo programy primární prevence',
				},
				{
					id: 6,
					title: 'Tabulka Výkony',
					subtitle: 'pro služby mimo programy primární prevence',
				},
				{
					id: 7,
					title: 'Export/import XLSX',
				},
			],
		}
	},

	computed: {
		...authComputed,

		dataLoaded() {
			return this.zpravaLoaded && this.projektLoaded
		},

		nazevProjektu() {
			return (
				this.projektLoaded
				? ` - ${this.projekt.kod}: ${this.projekt.nazev}`
				: ''
			)
		},

		dotacniRok() {
			return this.projektLoaded ? this.projekt.dotacni_rok	: ''
		},

		jeZpravaEditovatelna() {
			return true
		}
	},

	methods: {
		isNumericCZ,
		toNumberCZ,

    dialogVraceni() {
      this.modalDialog = 'vratit'
      this.modalOpened = true
    },

    closeModal() {
      this.modalOpened = false
      this.modalDialog = ''
    },

    vratitZpravu() {
      this.closeModal()
      this.loadZprava()
    },

		changeTab(tabId) {
			if (tabId === 0) {
				// zpět na projekt
				this.$router.push({ name: 'Projekt', params: { id: this.id } })
			}
			this.tabSelected = tabId
		},
		toNumberOnlyChars(value) {
			return typeof value === 'string'
				? value.replace(/[^0-9\-\,\.]*/gi, '')
				: value
		},
		toNumber(value) {
			const num = this.toNumberCZ(value)
			return num || 0;
		},
		updateFile(file) {
			// probehl upload souboru, nacteme nova data
			this.loadZprava()
		},
		async updatezprava({uzamceno}) {
			this.isSaving = true
			this.savingError = ''

      const data = {
        ...this.zprava,
        uzamceno
      }

			try {
			 	const response = await ProjektyService.updateZaverecnaZprava(this.projekt.id, data)
			 	if (!response.data.success) {
			 		this.savingError += response.data.error
			 	} else {
			 		this.zprava.uzamceno = uzamceno
			 	}
			 } catch(err) {
			 	this.savingError += err.message
			 }

			this.isSaving = false
		},

		async loadZprava() {
			this.zpravaLoaded = false

			try {
				const response = await ProjektyService.getZaverecnaZprava(this.id)
				if (!response.data.success) {
					this.error += response.data.error
					return
				}
				this.zprava = response.data.data
			} catch(err) {
				this.error += err.message
				return
			}

			this.zpravaLoaded = true
		},

		async loadProjekt() {
			this.projektLoaded = false

			try {
				const response = await ProjektyService.getProjekt(this.id)
				if (!response.data.success) {
					this.error += response.data.error
					return
				}
				this.projekt = response.data.data.projekt
			} catch(err) {
				this.error += err.message
				return
			}

			this.projektLoaded = true
		},

	},


	created() {
		// constants
		this.TYP_PROJEKTU = TYP_PROJEKTU
    this.STAV_PROJEKTU = STAV_PROJEKTU

		this.loadZprava()
		this.loadProjekt()
	},

}
</script>
