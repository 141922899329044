<template>
  {{ data.value }}
</template>

<script>
export default {
  name: 'TableText',
  props: {
    data: Object,
    required: true
  },
  data() {
    return {

    }
  },
  methods: {

  },
  emits: ['update'],
}
</script>