<template>
	<div
		class="form__field"
		:class="{
			'form__field--required': required,
			'form__field--invalid': valid === false,
		}"
	>

		<font-awesome-icon
			class="form__help"
			icon="info-circle"
			v-if="help"
			v-tippy="{ content: help }"
		/>

		<label
			v-if="label"
			:for="name"
			class="form__label"
		>{{ label }}</label>

		<select
			class="form__input"
			:value="modelValue"
			:name="name"
			:id="name"
			:disabled="disabled"
			@input="$emit('update:modelValue', $event.target.value)"
		>
			<option
				v-if="emptyValue"
				value=""
				:selected="(modelValue + '') === ''"
			>
				{{ emptyValueText }}
			</option>

			<option
				v-for="option in options"
				:key="option[valueKey]"
				:value="option[valueKey]"
				:selected="option[valueKey] === modelValue"
			>
				{{ option[optionKey] }}
			</option>

		</select>

		<FieldStatusIcon
			v-if="statusOptions.type === 'icon'"
			:status="status"
			:options="statusOptions"
		/>
		<FieldStatusControl
			v-if="statusOptions.type === 'control'"
			:status="status"
			@updateStatus="updateStatus($event)"
		/>

	</div>
</template>

<script>
import FieldStatusIcon from '@/components/Forms/FieldStatusIcon'
import FieldStatusControl from '@/components/Forms/FieldStatusControl'

export default {
	name: 'FormSelect',

	components: {
		FieldStatusIcon,
		FieldStatusControl,
	},

	props: {
		modelValue: {
			type: [Number, String],
			default: '',
		},
		name: {
			type: String,
			required: true,
		},
		options: {
			type: Array,
			required: true,
		},
		valueKey: {
			type: String,
			required: true,
		},
		optionKey: {
			type: String,
			required: true,
		},
		emptyValue: {
			type: Boolean,
			default: true,
		},
		emptyValueText: {
			type: String,
			default: '',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		required: {
			type: Boolean,
			default: false,
		},
		valid: {
			type: Boolean,
			default: undefined,
		},
		label: {
			type: String,
			default: null,
		},
		placeholder: {
			type: String,
			default: '',
		},
		help: {											// pokud neni prazdna, tak je to napoveda nad ikonou s otaznickem
			type: String,
			default: '',
		},
		status: {										// stav schvaleni pole
			type: Number,
			default: null,
		},
		statusOptions: {
			type: Object,
			default: {
				type: 'none',						// typ stavove ikony - 'none', 'icon', 'controls'
				showAsEmpty: false,			// zobrazit prazdné místo, kde by normálně byla ikona?
				showApproved: true,			// zobrazovat zelenou ikonu schvaleneho pole?
			},
		},
	},

	methods: {
		updateStatus(status) {
			this.$emit('updateStatus', {
				field: this.name,
				status: status,
			})
		}
	},

}
</script>
