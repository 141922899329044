<template>
  <DashboardAdmin v-if="isGov" />

  <DashboardOrganizace v-if="isOrg" />
</template>

<script>
import { authComputed } from '@/store/helpers.js'
import DashboardAdmin from '@/components/Dashboard/DashboardAdmin'
import DashboardOrganizace from '@/components/Dashboard/DashboardOrganizace'

export default {
  name: 'Dashboard',

  components: {
    DashboardAdmin,
    DashboardOrganizace,
  },

  computed: {
    ...authComputed
  },
}
</script>
