<template>
  <section>
    <h3 class="section__title">INFORMACE O ORGANIZACI</h3>

    <Message v-if="error" type="error">
      {{error}}
    </Message>

    <Loading v-if="!dataLoaded" />

    <div class="mt-3 mb-3" v-if="!vyplnenoProAktualniRok">
      <button
        class="button"
        @click="edit(aktivniDotacniRok)"
      >Vyplnit pro rok {{ aktivniDotacniRok }}</button>
    </div>

    <table v-if="dataLoaded && result.length !== 0" class="datagrid">
      <thead>
        <th>Dotační rok</th>
        <th>Dotační řízení</th>
        <th>Stav</th>
      </thead>
      <tbody>
        <tr
          v-for="info in result"
          :key="info.dotacni_rok"
          @click="edit(informace.dotacni_rok)"
        >
          <td>{{ info.dotacni_rok }}</td>
          <td>{{ info.dotacni_rok === 2025 ? 'Otevřené dotační řízení' : 'Uzavřeno' }}</td>
          <td>{{ info.stav_formulare_a ? info.stav_nazev : 'Nevyplněno' }}</td>
        </tr>
      </tbody>
    </table>

    <p v-if="dataLoaded && result.length === 0" >
      <table class="datagrid">
        <tbody>
          <tr>
            <td>Nejsou k dispozici žádná data.</td>
          </tr>
        </tbody>
      </table>
    </p>

  </section>
</template>

<script>
import { authComputed, optionsComputed } from '@/store/helpers.js'
import InformaceOrganizaceService from '@/services/InformaceOrganizaceService'

import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'

export default {
  name: 'InformaceOrganizacePrehled',

  components: {
    Message,
    Loading,
  },

  data() {
    return {
      error: '',
      informace: [],
      dataLoaded: false,
    }
  },

  computed: {
		...authComputed,
    ...optionsComputed,

    result() {
      return this.informace
    },

    dotacniRoky() {
      return this.getOptions.dotacni_roky
    },

    aktivniDotacniRok() {
      const aktivni = Object.keys(this.dotacniRoky)
        .map(key => ({
          rok: key,
          ...this.dotacniRoky[key]
        }))
        .filter(rok => rok.aktivni)

      return aktivni.length === 0 ? undefined : +aktivni[0].rok;
    },

    optionsDotacniRok() {
      return this.getOptionsDotacniRok(this.aktivniDotacniRok)
    },

    vyplnenoProAktualniRok() {
      const dateTo = new Date(Date.parse(this.optionsDotacniRok.prihlaseni_dotacni_rizeni_do))
      dateTo.setHours(23, 59, 59)

      return this.result.map(rok => rok.dotacni_rok).includes(this.aktivniDotacniRok)
        && Date.now() >= Date.parse(this.optionsDotacniRok.prihlaseni_dotacni_rizeni_od)
        && Date.now() <= dateTo.getTime()

    },
  },

  methods: {
    edit(dotacniRok) {
      this.$router.push({ name: 'OrganizaceInformace', params: { dotacniRok: dotacniRok } })
    },

    loadData() {
			InformaceOrganizaceService
      .getInformaceOrganizacePrehled(this.userOrg.idOrganizace)
			.then(response => {
				if (response.data.success) {
					this.informace = response.data.data;
          this.dataLoaded = true
				} else {
          this.error += response.data.error
				}
			})
			.catch(error => {
        this.error += error.message
			})
    }
  },

  created() {
    this.loadData()
  }
}
</script>
