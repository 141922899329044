import ApiClient from './ApiClient'

export default {

	// XLS přehled informací o organizacích
	exportInformaceOrganizace(dotacniRok) {
		return ApiClient.get(
			`/export-informace-o-organizaci/${dotacniRok}`,
			{	responseType: 'blob' }
		)
	},

	// XLS přehled projektů
	exportProjektoveZadosti(dotacniRok) {
		return ApiClient.get(
			`/export-projektove-zadosti/${dotacniRok}`,
			{	responseType: 'blob' }
		)
	},

	// XLS přehled závěrečných zpráv
	exportZaverecneZpravy(dotacniRok) {
		return ApiClient.get(
			`/export-zaverecne-zpravy/${dotacniRok}`,
			{	responseType: 'blob' }
		)
	},

	// ZIP s PDF všech projektů
	exportZipProjekty(dotacniRok) {
		return ApiClient.get(
			`/export-pdf-projekt-all/${dotacniRok}`,
			{	responseType: 'blob' }
		)
	},

	// ZIP s PDF částmi A všech žádostí
	exportZipCastiA(dotacniRok) {
		return ApiClient.get(
			`/export-pdf-a-all/${dotacniRok}`,
			{	responseType: 'blob' }
		)
	},

	// ZIP s přilohami všech žádostí
	exportZipPrilohyZadosti(dotacniRok) {
		return ApiClient.get(
			`/export-zadost-priloha-all/${dotacniRok}`,
			{	responseType: 'blob' }
		)
	},

	// ZIP konečných podob rozpočtu
	exportKonecneRozpocty(dotacniRok) {
		return ApiClient.get(
			`/export-konecne-rozpocty/${dotacniRok}`,
			{	responseType: 'blob' }
		)
	},

}
