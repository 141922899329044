// initial state
const state = () => ({
	options: [],
})


// getters
const getters = {
	getOptions(state) {
		return state.options
	},

	getOptionsDotacniRok: (state) => (year) => {
		try {
			return state.options['dotacni_roky'][year]
		} catch {
			return null
		}
	},
}


// actions
const actions = {

}


// mutations
const mutations = {
	SET_OPTIONS(state, options) {
		state.options = options
	},
}


// export
export default {
  // namespaced: true,
  state,
  getters,
  actions,
  mutations,
}