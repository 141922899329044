<template>
	<div class="modal">
		<div
			class="modal__overlay"
 			@click="closeModal"
		></div>
		<div
			class="modal__content"
			@click="closeModal"
		>
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	name: 'Modal',

	props: {
		close: {
			type: Function,
			default: null,
		},
		overlay: {
			type: Boolean,
			default: true,
		},
	},

	methods: {
		closeModal(event) {
			if (this.close && (event.target.className === 'modal__content' || event.target.className === 'modal__overlay')) {
				this.close()
			}
		},
	}
}
</script>
