<template>
	<div class="filters">

		<div class="filter">
			<label class="filter__label">Rok</label>
			<select
				class="filter__input w100"
				:class="{ 'filter__input--selected': dotacniRok }"
				v-model="dotacniRok"
				@change="updateFilter"
			>
				<option value=""></option>
        <option v-for="item in dotacniRoky" :key="item.rok" :value="item.rok">{{ item.rok }}</option>
			</select>
		</div>

		<div class="filter size4">
			<label class="filter__label">Organizace</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': organizace }"
				v-model="organizace"
				@input="updateFilter"
			/>
		</div>

		<div class="filter size3">
			<label class="filter__label">IČ</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': ic }"
				v-model="ic"
				@input="updateFilter"
			/>
		</div>

		<div class="filter size3">
			<label class="filter__label">DIČ</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': dic }"
				v-model="dic"
				@input="updateFilter"
			/>
		</div>

		<div class="filter size5">
			<label class="filter__label">Statutární zástupce</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': statutar}"
				v-model="statutar"
				@input="updateFilter"
			/>
		</div>

		<div class="filter size4">
			<label class="filter__label">Telefon</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': telefon}"
				v-model="telefon"
				@input="updateFilter"
			/>
		</div>

		<div class="filter size5">
			<label class="filter__label">E-mail</label>
			<input
			 	type="text"
				class="filter__input w100"
				:class="{ 'filter__input--selected': email}"
				v-model="email"
				@input="updateFilter"
			/>
		</div>

		<div class="filter" v-if="filterActive">
			<a
				href="#"
				class="filter__link"
				@click.prevent="clearFilter"
			>Zrušit filtr</a>
		</div>
	</div>
</template>

<script>
import DotacniRokyService from "@/services/DotacniRokyService";

export default {
	name: 'FiltrOrganizace',

	props: {
		filterValues: {
			type: Object,
			default: {},
		},
	},

	data() {
		return {
      dotacniRoky: [],
			dotacniRok: this.filterValues?.dotacniRok || '',
			organizace: this.filterValues?.organizace || '',
			ic: this.filterValues?.ic || '',
			dic: this.filterValues?.dic || '',
			statutar: this.filterValues?.statutar || '',
			telefon: this.filterValues?.telefon || '',
			email: this.filterValues?.email || '',
		}
	},

	computed: {
		filterActive() {
			return (
				!!this.dotacniRok
				|| !!this.organizace
				|| !!this.ic
				|| !!this.dic
				|| !!this.statutar
				|| !!this.telefon
				|| !!this.email
			)
		},
	},

	methods: {
		updateFilter() {
			this.$emit('updateFilter', {
				dotacniRok: this.dotacniRok,
				organizace: this.organizace,
				ic: this.ic,
				dic: this.dic,
				statutar: this.statutar,
				telefon: this.telefon,
				email: this.email,
			})
		},

		clearFilter() {
			this.dotacniRok = ''
			this.organizace = ''
			this.ic = ''
			this.dic = ''
			this.statutar = ''
			this.telefon = ''
			this.email = ''

			this.updateFilter()
		},

    async loadDotacniRoky() {
      try {
        const dotacniRokyResponse = await DotacniRokyService.getDotacniRoky()
        if (dotacniRokyResponse.data.success) {
          this.dotacniRoky = dotacniRokyResponse.data.data
          this.dataLoaded = true
        } else {
          this.error += dotacniRokyResponse.data.error
        }

      } catch(error) {
        this.error += error.message
      }
    },
	},


	created() {
    this.loadDotacniRoky()
	}
}
</script>
