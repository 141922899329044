<template>
	<div class="sort-arrows">
		<button
			class="sort-button"
			:class="{'sort-button--active': field == current.field && current.dir == 'asc' }"
			title="Vzestupně A-Z"
			@click="sort(field, 'asc')"
		>
			<font-awesome-icon icon="chevron-down" />
		</button>
		<button
			class="sort-button"
			:class="{'sort-button--active': field == current.field && current.dir == 'desc' }"
			title="Sestupně Z-A"
			@click="sort(field, 'desc')"
		>
			<font-awesome-icon icon="chevron-up"/>
		</button>
	</div>
</template>

<script>
export default {
	name: 'SortArrows',

	props: {
		field: {
			type: String,
			required: true,
		},
		current: {
			type: Object,
			default: {
				field: '',
				dir: '',
			}
		}
	},

	methods: {
		sort(field, dir) {
			this.$emit('sort', { field, dir });
		}
	}
}
</script>
