<template>
  <Dialog title="Potvrzení odeslání e-mailu" class="dialog">
    <p>Opravdu chcete odeslat e-mail všem statutárním zástupcům organizací přihlášených do dotačního roku {{ dotacniRok }}?</p>

    <hr class="form__divider">

    <div class="buttons">
      <button
          class="button button--outline"
          @click="$emit('submit')"
      >
        Ano, odeslat
      </button>

      <button
          class="button button--outline button--neutral"
          @click="$emit('cancel')"
      >
        Storno
      </button>

    </div>

  </Dialog>
</template>

<script>
import Dialog from '@/components/Common/Dialog'

export default {
  name: 'EmailConfirmDialog',
  components: {
    Dialog,
  },
  emits: ['submit', 'cancel'],
  props: {
    dotacniRok: {
      type: String,
      required: true,
    }
  }
}
</script>
