<template>

	<PageHeader title="PROJEKTY" />

	<Message type="error" v-if="error">
		{{ error }}
	</Message>

	<Loading v-if="!dataLoaded" />

	<div class="filters-container mt-4">
		<FiltrProjekty
			:filterValues="datagridFilter"
			@updateFilter="updateDatagridFilter($event)"
		/>

		<FiltrProjektyZobrazeni
			v-if="isSuperAdmin || isAdmin"
			v-model="datagridView"
			@change="saveDatagridSettings"
		/>
	</div>


	<NastaveniHodnotitelu
		class="mt-4"
		v-if="datagridView === 'hodnotitele'"
		:krajskyKoordinator="krajskyKoordinator"
		:externiHodnotitel="externiHodnotitel"
		:vybraneProjekty="selected"
		@update="loadProjekty"
	/>

	<div class="small mt-4" :class="{ 'color-danger': selected.length > 0 }">
		Označeno projektů: <strong>{{ selected.length }}</strong>
	</div>

	<table v-if="dataLoaded" class="datagrid mt-1">
		<thead>
			<tr>
				<th class="alc">
					Vybrat<br>
					<span class="small nowrap">
						<a class="link" href="#" @click.prevent="selectAll">vše</a>
						|
						<a class="link" href="#" @click.prevent="selectNone">nic</a>
					</span>
				</th>
				<th class="alc">
					Dotační rok
					<SortArrows field="dotacni_rok" :current="datagridSort" @sort="sort" />
				</th>
				<th>
					Organizace
					<SortArrows field="nazev_organizace" :current="datagridSort" @sort="sort" />
				</th>
				<th>
					Kód
					<SortArrows field="kod" :current="datagridSort" @sort="sort" />
				</th>
				<th>
					Název projektu
					<SortArrows field="nazev" :current="datagridSort" @sort="sort" />
				</th>
				<th class="alc">
					Typ
					<SortArrows field="typ" :current="datagridSort" @sort="sort" />
				</th>

				<template v-if="datagridView === 'vychozi'">
					<th class="alc">
						Stav
						<SortArrows field="stav" :current="datagridSort" @sort="sort" />
					</th>
					<th class="alr">
						Celkové náklady
						<SortArrows field="celkove_naklady" :current="datagridSort" @sort="sort" />
					</th>
				</template>

				<template v-if="datagridView === 'hodnotitele'">
					<th class="alc">
						Dotační výbor
						<SortArrows field="dotacni_vybor" :current="datagridSort" @sort="sort" />
					</th>
					<th>
						KPK (hodnotí)
						<SortArrows field="krajsky_koordinator_jmeno" :current="datagridSort" @sort="sort" />
					</th>
					<th>
						KPK (jen vyjádření)
						<SortArrows field="krajsky_komentator_jmeno" :current="datagridSort" @sort="sort" />
					</th>
					<th>
						Externí hodnotitel 1
						<SortArrows field="ext_hodnotitel1_jmeno" :current="datagridSort" @sort="sort" />
					</th>
					<th>
						Externí hodnotitel 2
						<SortArrows field="ext_hodnotitel2_jmeno" :current="datagridSort" @sort="sort" />
					</th>
					<th>
						Externí hodnotitel 3
						<SortArrows field="ext_hodnotitel3_jmeno" :current="datagridSort" @sort="sort" />
					</th>
				</template>

				<template v-if="datagridView === 'cisla-jednaci'">
					<th>
						Č.j. žádosti
						<SortArrows field="cislo_jednaci" :current="datagridSort" @sort="sort" />
					</th>
					<th>
						Č.j. výzvy
						<SortArrows field="cislo_jednaci_vyzvy" :current="datagridSort" @sort="sort" />
					</th>
					<th>
						Č.j. rozhodnutí
						<SortArrows field="cislo_jednaci_rozhodnuti" :current="datagridSort" @sort="sort" />
					</th>
					<th>
						Č.j. změn. rozhodnutí
						<SortArrows field="cislo_jednaci_zmenove_rozhodnuti" :current="datagridSort" @sort="sort" />
					</th>
					<th>
						Spisová značka
						<SortArrows field="spisova_znacka" :current="datagridSort" @sort="sort" />
					</th>
				</template>

				<template v-if="datagridView === 'vychozi' || datagridView === 'dotace'">
					<th class="alr">
						Požadovaná dotace
						<SortArrows field="pozadovana_dotace" :current="datagridSort" @sort="sort" />
					</th>

					<th class="alr">
						Celková dotace
						<SortArrows field="celkova_dotace" :current="datagridSort" @sort="sort" />
					</th>
				</template>

			</tr>
		</thead>
		<tbody>
			<tr v-if="projektyResult.length === 0">
				<td colspan="9" class="alc p-5 color-danger">Nenalezeny žádné projekty.</td>
			</tr>

			<tr
				v-for="projekt in projektyResult"
				:key="projekt.id"
				:class="{ 'datagrid__row--selected': isSelected(projekt.id) }"
        class="datagrid__row datagrid__row--non-hoverable"
			>
				<td class="alc">
					<SimpleCheckbox
						:highlight="true"
						:checked="isSelected(projekt.id)"
						@click.stop="toggleSelected(projekt.id)"
					/>
				</td>
				<td class="alc">{{ projekt.dotacni_rok }}</td>
				<td>{{ projekt.nazev_organizace }}</td>
				<td class="nowrap"><strong>{{ projekt.kod }}</strong></td>
				<td><a @click.prevent="zobrazitProjekt(projekt.id)"><strong>{{ projekt.nazev }}</strong></a></td>
				<td class="alc">{{ typProjektu(projekt.typ) }}</td>

				<template v-if="datagridView === 'vychozi'">
					<td class="alc">{{ projekt.stav_nazev }}</td>
					<td class="alr">{{ formatNumberCZ(projekt.celkove_naklady, '-') }}</td>
					<td class="alr">{{ formatNumberCZ(projekt.pozadovana_dotace, '-') }}</td>
					<td class="alr">{{ formatNumberCZ(projekt.celkova_dotace, '-') }}</td>
				</template>

				<template v-if="datagridView === 'hodnotitele'">
					<td class="alc">
						<SimpleCheckbox
							:checked="projekt.dotacni_vybor"
							@click.stop="setDotacniVybor(projekt.id, !projekt.dotacni_vybor)"
						/>
					</td>
					<td>
						<SimpleSelect
							:value="projekt.krajsky_koordinator"
							:options="krajskyKoordinator"
							valueKey="id"
							optionKey="jmeno"
							:emptyValue="true"
							@click.stop
							@update="setHodnotitel(projekt.id, 'krajsky_koordinator', $event)"
						/>
					</td>
					<td>
						<SimpleSelect
							:value="projekt.krajsky_komentator"
							:options="krajskyKoordinator"
							valueKey="id"
							optionKey="jmeno"
							:emptyValue="true"
							@click.stop
							@update="setHodnotitel(projekt.id, 'krajsky_komentator', $event)"
						/>
					</td>
					<td>
						<SimpleSelect
							:value="projekt.ext_hodnotitel1"
							:options="externiHodnotitel"
							valueKey="id"
							optionKey="jmeno"
							:emptyValue="true"
							@click.stop
							@update="setHodnotitel(projekt.id, 'ext_hodnotitel1', $event)"
						/>
					</td>
					<td>
						<SimpleSelect
							:value="projekt.ext_hodnotitel2"
							:options="externiHodnotitel"
							valueKey="id"
							optionKey="jmeno"
							:emptyValue="true"
							@click.stop
							@update="setHodnotitel(projekt.id, 'ext_hodnotitel2', $event)"
						/>
					</td>
					<td>
						<SimpleSelect
							:value="projekt.ext_hodnotitel3"
							:options="externiHodnotitel"
							valueKey="id"
							optionKey="jmeno"
							:emptyValue="true"
							@click.stop
							@update="setHodnotitel(projekt.id, 'ext_hodnotitel3', $event)"
						/>
					</td>
				</template>


				<template v-if="datagridView === 'cisla-jednaci'">
					<td>
						<div class="d-flex">
							<SimpleInput
								maxlength="50"
								size="7"
								:value="projekt.cislo_jednaci"
								@click.stop
								@update="updateCisloJednaci(projekt.id, $event, 'cislo_jednaci')"
							/>
							<button
								class="button button--tiny button--outline ml-1"
								:disabled="!zmenaCisloJednaci['cislo_jednaci'][projekt.id]"
								@click.stop="setCisloJednaci(projekt.id, 'cislo_jednaci')"
							>OK</button>
						</div>
					</td>
					<td>
						<div class="d-flex">
							<SimpleInput
								maxlength="50"
								size="7"
								:value="projekt.cislo_jednaci_vyzvy"
								@click.stop
								@update="updateCisloJednaci(projekt.id, $event, 'cislo_jednaci_vyzvy')"
							/>
							<button
								class="button button--tiny button--outline ml-1"
								:disabled="!zmenaCisloJednaci['cislo_jednaci_vyzvy'][projekt.id]"
								@click.stop="setCisloJednaci(projekt.id, 'cislo_jednaci_vyzvy')"
							>OK</button>
						</div>
					</td>
					<td>
						<div class="d-flex">
							<SimpleInput
								maxlength="50"
								size="7"
								:value="projekt.cislo_jednaci_rozhodnuti"
								@click.stop
								@update="updateCisloJednaci(projekt.id, $event, 'cislo_jednaci_rozhodnuti')"
							/>
							<button
								class="button button--tiny button--outline ml-1"
								:disabled="!zmenaCisloJednaci['cislo_jednaci_rozhodnuti'][projekt.id]"
								@click.stop="setCisloJednaci(projekt.id, 'cislo_jednaci_rozhodnuti')"
							>OK</button>
						</div>
					</td>
					<td>
						<div class="d-flex">
							<SimpleInput
								maxlength="50"
								size="7"
								:value="projekt.cislo_jednaci_zmenove_rozhodnuti"
								@click.stop
								@update="updateCisloJednaci(projekt.id, $event, 'cislo_jednaci_zmenove_rozhodnuti')"
							/>
							<button
								class="button button--tiny button--outline ml-1"
								:disabled="!zmenaCisloJednaci['cislo_jednaci_zmenove_rozhodnuti'][projekt.id]"
								@click.stop="setCisloJednaci(projekt.id, 'cislo_jednaci_zmenove_rozhodnuti')"
							>OK</button>
						</div>
					</td>

					<td>
						<div class="d-flex">
							<SimpleInput
								maxlength="50"
								size="7"
								:value="projekt.spisova_znacka"
								@click.stop
								@update="updateSpisovaZnacka(projekt.id, $event)"
							/>
							<button
								class="button button--tiny button--outline ml-1"
								:disabled="!zmenaSpisovaZnacka[projekt.id]"
								@click.stop="setSpisovaZnacka(projekt.id)"
							>OK</button>
						</div>
					</td>
				</template>

				<template v-if="datagridView === 'dotace'">
					<td class="alr">{{ formatNumberCZ(projekt.pozadovana_dotace, '-') }}</td>

					<td class="alr">
						<template v-if="projekt.celkova_dotace !== null && !(isSuperAdmin || isAdmin)">
							{{ formatNumberCZ(projekt.celkova_dotace, '') }}
						</template>
						<template v-else>
							<div class="d-flex align-items-center justify-content-space-between">
								<template v-if="projekt.celkova_dotace !== null && !zmenaCelkovaDotace[projekt.id] && !potvrzeniCelkovaDotace[projekt.id]">
									{{ formatNumberCZ(projekt.celkova_dotace, '') }}
									<button
										class="button button--tiny button--outline ml-2"
										@click.stop="editCelkovaDotace(projekt.id)"
									>Upravit</button>
								</template>
								<template v-else>
									<SimpleNumber
										maxlength="12"
										size="12"
										:value="separateThousands(projekt.celkova_dotace)"
										@click.stop
										@update="updateCelkovaDotace(projekt.id, $event)"
									/>
									<button
										class="button button--tiny button--outline ml-1"
										:disabled="!zmenaCelkovaDotace[projekt.id]"
										@click.stop="confirmCelkovaDotace(projekt.id)"
									>OK</button>
								</template>
							</div>
							<div class="mt-1" v-if="potvrzeniCelkovaDotace[projekt.id]">
								<button
									class="button button--danger button--tiny button--outline"
									@click.stop="setCelkovaDotace(projekt.id)"
								>POTVRDIT DOTACI</button>
							</div>
						</template>
					</td>
				</template>

			</tr>
		</tbody>
	</table>


</template>

<script>
import {
	TYP_PROJEKTU,
	STAV_PROJEKTU,
} from '@/constants'

import { authComputed } from '@/store/helpers.js'
import { formatNumberCZ, formatDateCZ } from '@/utils/format.js'
import { toNumberCZ, separateThousands } from '@/utils/convert.js';

import ProjektyService from '@/services/ProjektyService'

import PageHeader from '@/components/Common/PageHeader'
import Message from '@/components/Common/Message'
import Loading from '@/components/Common/Loading'
import SimpleCheckbox from '@/components/Forms/Simple/SimpleCheckbox'
import SimpleSelect from '@/components/Forms/Simple/SimpleSelect'
import SimpleInput from '@/components/Forms/Simple/SimpleInput'
import SimpleNumber from '@/components/Forms/Simple/SimpleNumber'

import FiltrProjekty from '@/components/Toolbar/FiltrProjekty'
import FiltrProjektyZobrazeni from '@/components/Toolbar/FiltrProjektyZobrazeni'
import NastaveniHodnotitelu from '@/components/Toolbar/NastaveniHodnotitelu'

import SortArrows from '@/components/DataGrid/SortArrows'

export default {
	name: 'Projekty',

	components: {
		PageHeader,
		Message,
		Loading,
		SimpleCheckbox,
		SimpleSelect,
		SimpleInput,
		SimpleNumber,
		FiltrProjekty,
		FiltrProjektyZobrazeni,
		NastaveniHodnotitelu,
		SortArrows,
	},

	data() {
		return {
			error: '',
			projektyLoaded: false,
			projekty: [],
			selected: [],
			zmenaCisloJednaci: {
				cislo_jednaci: {},
				cislo_jednaci_vyzvy: {},
				cislo_jednaci_rozhodnuti: {},
				cislo_jednaci_zmenove_rozhodnuti: {},
			},
			zmenaSpisovaZnacka: {},
			zmenaCelkovaDotace: {},
			potvrzeniCelkovaDotace: {},

			hodnotiteleLoaded: false,
			krajskyKoordinator: [],
			externiHodnotitel: [],

			datagridFilter: {},
			datagridSort: {
				field: 'dotacni_rok',
				dir: 'desc',
			},
			datagridView: 'vychozi',
		}
	},

	watch: {
		datagridView() {
			this.saveDatagridSettings()
		},
	},

	computed: {
		...authComputed,

		dataLoaded() {
			return this.projektyLoaded && this.hodnotiteleLoaded
		},

		projektyResult() {
			return this.projekty.filter(projekt => {
				let result

				// zobrazujeme jen projekty, ktere nejsou rozpracovane
				result = projekt.stav !== this.STAV_PROJEKTU.PODAVANI

				// dotacni rok
				if (this.datagridFilter?.dotacniRok) {
					result = (
						result
						&& projekt.dotacni_rok
						&& projekt.dotacni_rok == this.datagridFilter.dotacniRok
					)
				}

				// nazev organizace
				if (this.datagridFilter?.organizace) {
					result = (
						result
						&& projekt.nazev_organizace
						&& projekt.nazev_organizace.toLowerCase().includes(this.datagridFilter.organizace.toLowerCase())
					)
				}

				// kod projektu
				if (this.datagridFilter?.kod) {
					result = (
						result
						&& projekt.kod
						&& projekt.kod.toLowerCase().includes(this.datagridFilter.kod.toLowerCase())
					)
				}

				// nazev projektu
				if (this.datagridFilter?.nazev) {
					result = (
						result
						&& projekt.nazev
						&& projekt.nazev.toLowerCase().includes(this.datagridFilter.nazev.toLowerCase())
					)
				}

				// typ projektu
				if (this.datagridFilter?.typ) {
					result = (
						result
						&& projekt.typ
						&& projekt.typ == this.datagridFilter.typ
					)
				}

				// stav projektu
				if (this.datagridFilter?.stav) {
					result = (
						result
						&& projekt.stav
						&& projekt.stav == this.datagridFilter.stav
					)
				}

				// vratime, zda projekt splnuje kriteria
				return result
			})
			.sort((itemA, itemB) => {
				if (!this.datagridSort.field) return 0

				const dir = this.datagridSort.dir === 'desc' ? 1 : -1

				let a = itemA[this.datagridSort.field]
				let b = itemB[this.datagridSort.field]

				if (typeof a === 'string') a = a.toLowerCase()
				if (typeof b === 'string') b = b.toLowerCase()

				if (a == b) return 0
				if (a == null || a == undefined) return dir
				if (b == null || b == undefined) return -dir

				return (
					typeof a === 'string'
					? a.localeCompare(b) * -dir
					: a < b ? dir : -dir
				)
			})
		},

	},

	methods: {
		formatNumberCZ,
		formatDateCZ,
		separateThousands,

		sort({field, dir}) {
			this.datagridSort.field = field
			this.datagridSort.dir = dir
			this.saveDatagridSettings()
		},

		updateDatagridFilter(filterValues) {
			this.datagridFilter = filterValues
			this.saveDatagridSettings()
		},

		saveDatagridSettings() {
			const data = JSON.stringify({
				filter: this.datagridFilter,
				sort: this.datagridSort,
				view: this.datagridView,
			})
			localStorage.setItem('cadros-projekty-settings', data)
		},

		loadDatagridSettings() {
			const data = JSON.parse(localStorage.getItem('cadros-projekty-settings'))
			this.datagridFilter = data?.filter || {}
			this.datagridSort = data?.sort || {
				field: 'dotacni_rok',
				dir: 'desc',
			}
			this.datagridView = data?.view || 'vychozi'
		},

		updateCelkovaDotace(id, value) {
			const projekt = this.projekty.find(projekt => projekt.id === id)
			projekt.celkova_dotace = value
			this.zmenaCelkovaDotace[id] = true
			this.potvrzeniCelkovaDotace[id] = false
		},

		confirmCelkovaDotace(id) {
			this.zmenaCelkovaDotace[id] = false
			this.potvrzeniCelkovaDotace[id] = true
		},

		editCelkovaDotace(id) {
			this.zmenaCelkovaDotace[id] = true
			this.potvrzeniCelkovaDotace[id] = false
		},

		async setCelkovaDotace(id) {
			const projekt = this.projekty.find(projekt => projekt.id === id)

			// disable ok button vedle policka
			delete this.zmenaCelkovaDotace[id]
			delete this.potvrzeniCelkovaDotace[id]

			tryblock:
			try {
				// nastavime vysi dotace
				const response = await ProjektyService.nastavitCelkovaDotace(id, {
					celkova_dotace: toNumberCZ(projekt.celkova_dotace)
				})
				if (!response.data.success) {
					this.zmenaCelkovaDotace[id] = true
					this.error = response.data.error
					break tryblock
				}

				// nastavime stav projektu
				const stavProjektu = projekt.celkova_dotace == 0 ? STAV_PROJEKTU.NEPODPOREN : STAV_PROJEKTU.UPRAVA_ROZPOCTU
				const res = await ProjektyService.nastavitStavProjektu(id, {
					stav: stavProjektu
				})
				if (!response.data.success) {
					this.zmenaCelkovaDotace[id] = true
					this.error = response.data.error
					break tryblock
				}

				projekt.stav = stavProjektu

			}	catch(error) {
				this.error = error.message
			}
		},


		updateCisloJednaci(id, value, field) {
			const projekt = this.projekty.find(projekt => projekt.id === id)
			projekt[field] = value
			this.zmenaCisloJednaci[field][id] = true
		},

		setCisloJednaci(id, field) {
			const projekt = this.projekty.find(projekt => projekt.id === id)
			const data = {}
			data[field] = projekt[field]

			// disable ok button vedle policka
			delete this.zmenaCisloJednaci[field][id]

			ProjektyService
			.nastavitCisloJednaci(id, data)
			.then(response => {
				if (!response.data.success) {
					this.zmenaCisloJednaci[field][id] = true
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},


		updateSpisovaZnacka(id, value) {
			const projekt = this.projekty.find(projekt => projekt.id === id)
			projekt.spisova_znacka = value
			this.zmenaSpisovaZnacka[id] = true
		},

		setSpisovaZnacka(id) {
			const projekt = this.projekty.find(projekt => projekt.id === id)
			const data = {
				spisova_znacka: projekt.spisova_znacka
			}
			// disable ok button vedle policka
			delete this.zmenaSpisovaZnacka[id]

			ProjektyService
			.nastavitSpisovaZnacka(id, data)
			.then(response => {
				if (!response.data.success) {
					this.zmenaSpisovaZnacka[id] = true
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},


		setHodnotitel(id, typ, newValue) {
			newValue = newValue ? Number(newValue) : null
			const projekt = this.findProject(id)
			const oldValue = projekt[typ]
			projekt[typ] = newValue

			const data = {
				id_projektu: [id],
				id_hodnotitele: newValue,
				typ_hodnotitele: typ
			}

			ProjektyService
			.nastavitHodnotitele(data)
			.then(response => {
				if (!response.data.success) {
					// pokud se nepovedlo, vrátíme data na původní hodnotu
					projekt[typ] = oldValue
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

		setDotacniVybor(id, newValue) {
			const projekt = this.findProject(id)
			const oldValue = projekt.dotacni_vybor
			projekt.dotacni_vybor = newValue

			const data = (
				newValue
				? { nastavit: [id] }
				: { vymazat: [id] }
			)

			ProjektyService
			.nastavitDotacniVybor(data)
			.then(response => {
				if (!response.data.success) {
					// pokud se nepovedlo, vrátíme zaškrtnutí na původní hodnotu
					projekt.dotacni_vybor = oldValue
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

		findProject(id) {
			return this.projekty.find(projekt => projekt.id === id)
		},


		selectAll() {
			this.selected = this.projektyResult.map(projekt => projekt.id)
		},

		selectNone() {
			this.selected = []
		},

		toggleSelected(id) {
			if (this.isSelected(id)) {
				this.selected = this.selected.filter(x => x !== id)
			} else {
				this.selected.push(id)
			}
		},

		isSelected(id) {
			return this.selected.includes(id)
		},


		zobrazitProjekt(id) {
			this.$router.push({ name: 'Projekt', params: { id } })
		},

		typProjektu(typ) {
			switch (typ) {
				case TYP_PROJEKTU.SLUZBA:
					return 'služba'
				case TYP_PROJEKTU.IVH:
					return 'IVH'
			}
			return '-'
		},

		loadProjekty() {
			this.projektyLoaded = false
			ProjektyService
			.getProjekty()
			.then(response => {
				if (response.data.success) {
					this.projekty = response.data.data
					this.projektyLoaded = true
					//console.table(response.data.data)
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},

		loadHodnotitele() {
			this.hodnotiteleLoaded = false
			ProjektyService
			.getHodnotitele()
			.then(response => {
				if (response.data.success) {
					this.krajskyKoordinator = response.data.data.krajsky_koordinator
					this.externiHodnotitel = response.data.data.externi_hodnotitel
					this.hodnotiteleLoaded = true
				} else {
					this.error = response.data.error
				}
			})
			.catch(error => {
				this.error = error.message
			})
		},


		loadData() {
			this.loadProjekty()
			this.loadHodnotitele()
		},

	},

	created() {
		// constants
		this.TYP_PROJEKTU = TYP_PROJEKTU
		this.STAV_PROJEKTU = STAV_PROJEKTU

		this.loadDatagridSettings()
		this.loadData()
	}
}
</script>