<template>
	<div class="section-header">
		<h2 class="section-header__title">
			{{ title }}
		</h2>

		<div v-if="!isEmptySlot" class="section-header__buttons">
			<slot />
		</div>
	</div>
</template>

<script>
export default {
	name: 'SectionHeader',

	props: {
		title: String
	},

	computed: {
		isEmptySlot() {
			return !this.$slots.default
		}
	},
}
</script>
